<template>
  <div>
    <a href="#" @click.prevent="modalsOpen.selector = true">
      <img :src="imageUrl" alt="">
      <p style="font-size: 1.3em;">
        Svara på förfrågningar
      </p>
    </a>

    <b-modal id="modal-survey-replies" v-model="modalsOpen.selector" dialog-class="modal-full" no-stacking centered hide-footer title="Förfrågningar">
      <template v-if="replies && replies.length > 0">
        <a v-for="reply in replies" :key="reply.id" href="#" @click.prevent="modalsOpen.reply = reply.id">
          <h5 class="pt-2">{{reply.survey.intro_header}}</h5>
          <img v-if="reply.survey.image_url" :src="reply.survey.image_url" style="width: 100%; height: auto;">

          <div class="text-center my-3">
            <b-button variant="custom" class="signbuttoninline" @click.prevent.cancel="modalsOpen.reply = reply.id">
              Svara
            </b-button>
          </div>
        </a>
      </template>
      <template v-else>
        <p>Du har svarat på alla förfrågningar.</p>
      </template>
    </b-modal>

    <b-modal v-for="reply in replies" :key="reply.id" dialog-class="modal-full" centered hide-footer no-close-on-esc no-close-on-backdrop title="Enkät" :visible="modalsOpen.reply === reply.id" @hidden="onHide(reply.id)">
      <surveys-input
        :submit-url="route('quote.survey_replies.update', [quoteKey, reply.id])"
        :requires-bankid-signature="reply.survey.requires_bankid_signature"
        :bankid-item-id="reply.bankid_item_id"
        :bankid-app-url="reply.bankid_app_url"
        :bankid-signee-name="reply.bankid_signee_name"
        :image-url="reply.survey.image_url"
        :intro-header="reply.survey.intro_header"
        :intro-body="reply.survey.intro_body"
        :thanks-header="reply.survey.thanks_header"
        :thanks-body="reply.survey.thanks_body"
        :questions="reply.survey.questions"
        :quote-key="quoteKey"
        :button-next-enabled="replies.length > 1"
        @close="onClose(reply.id)"
        @next="onNext(reply.id)"
      ></surveys-input>
    </b-modal>
  </div>

</template>

<style lang="scss" scoped>
  a {
    display: block;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0px;
      text-align: center;
      text-decoration: none;
      color: black;
      text-decoration: none;
    }

    h5 {
      color: black;
    }
  }

  p.preserve-linebrakes {
    white-space: pre-line;
  }
</style>

<script>
import axios from 'axios';

export default {
  props: {
    quoteKey: { type: String, required: true },
    imageUrl: { type: String, required: true },
    selectorInitiallyOpen: { type: Boolean, required: true },
  },
  data() {
    return {
      replies: [],
      modalsOpen: {
        selector: this.selectorInitiallyOpen,
        reply: null,
      },
    };
  },
  async mounted() {
    const resp = await axios.get(route('quote.survey_replies.index', this.quoteKey));
    this.replies = resp.data.data.filter((r) => !r.completed_at);
  },
  methods: {
    onHide(_replyId) {
      this.modalsOpen.reply = null;
    },
    onClose(replyId) {
      this.onHide(replyId);
      this.replies = this.replies.filter((r) => r.id != replyId);
      if (this.replies.length === 0) {
        this.$document.locationReload();
      }
    },
    onNext(replyId) {
      this.onClose(replyId);
      this.modalsOpen.reply = this.replies[0].id;
    }
  }
}
</script>
