<template>
  <div>
    <v-select multiple :options="options" label="label" :reduce="(o) => o.code" v-model="selectedOptions">
      <template #search="{attributes, events}">
        <input
            class="vs__search"
            :required="selectedOptions.length == 0"
            v-bind="attributes"
            v-on="events"
          />
      </template>
    </v-select>

      <input
        v-for="(selected, selectedIdx) in selectedOptions"
        :key="selectedIdx"
        :name="`${name}[]`"
        :value="selected"
        type="hidden"
      >
  </div>
</template>

<style lang="scss">

</style>

<script>
export default {
  props: {
    name: { type: String, required: true },
    value: { type: Array, required: true },
  },
  data() {
    return {
      options: [
        { code: "sv", label: "svenska" },
        { code: "af", label: "afrikaans" },
        { code: "sq", label: "albanska" },
        { code: "am", label: "amhariska" },
        { code: "ar", label: "arabiska" },
        { code: "hy", label: "armeniska" },
        { code: "as", label: "assamesiska" },
        { code: "ay", label: "aymara" },
        { code: "az", label: "azerbajdzjanska" },
        { code: "bm", label: "bambara" },
        { code: "eu", label: "baskiska" },
        { code: "bn", label: "bengali" },
        { code: "bho", label: "bhojpuri" },
        { code: "bs", label: "bosniska" },
        { code: "bg", label: "bulgariska" },
        { code: "my", label: "burmesiska" },
        { code: "ceb", label: "cebuano" },
        { code: "ny", label: "chichewa" },
        { code: "da", label: "danska" },
        { code: "dv", label: "divehi" },
        { code: "doi", label: "dogri" },
        { code: "en", label: "engelska" },
        { code: "eo", label: "esperanto" },
        { code: "et", label: "estniska" },
        { code: "ee", label: "ewe" },
        { code: "tl", label: "filippinska" },
        { code: "fi", label: "finska" },
        { code: "fr", label: "franska" },
        { code: "fy", label: "frisiska" },
        { code: "gd", label: "gaeliska" },
        { code: "gl", label: "galiciska" },
        { code: "ka", label: "georgiska" },
        { code: "el", label: "grekiska" },
        { code: "gn", label: "guarani" },
        { code: "gu", label: "gujarati" },
        { code: "ht", label: "haitiska" },
        { code: "ha", label: "hausa" },
        { code: "haw", label: "hawaiianska" },
        { code: "iw", label: "hebreiska" },
        { code: "hi", label: "hindi" },
        { code: "hmn", label: "hmong" },
        { code: "ig", label: "igbo" },
        { code: "ilo", label: "ilocano" },
        { code: "id", label: "indonesiska" },
        { code: "ga", label: "irländska" },
        { code: "is", label: "isländska" },
        { code: "it", label: "italienska" },
        { code: "ja", label: "japanska" },
        { code: "jw", label: "javanesiska" },
        { code: "yi", label: "jiddisch" },
        { code: "kn", label: "kanaresiska" },
        { code: "ca", label: "katalanska" },
        { code: "kk", label: "kazakiska" },
        { code: "km", label: "khmer" },
        { code: "zh-CN", label: "kinesiska (förenklad)" },
        { code: "zh-TW", label: "kinesiska (traditionell)" },
        { code: "rw", label: "kinyarwanda" },
        { code: "ky", label: "kirgiziska" },
        { code: "gom", label: "konkani" },
        { code: "ko", label: "koreanska" },
        { code: "co", label: "korsiska" },
        { code: "kri", label: "krio" },
        { code: "hr", label: "kroatiska" },
        { code: "ku", label: "kurdiska (kurmanji)" },
        { code: "ckb", label: "kurdiska (sorani)" },
        { code: "lo", label: "laotiska" },
        { code: "la", label: "latin" },
        { code: "lv", label: "lettiska" },
        { code: "ln", label: "lingala" },
        { code: "lt", label: "litauiska" },
        { code: "lg", label: "luganda" },
        { code: "lb", label: "luxemburgska" },
        { code: "mai", label: "maithili" },
        { code: "mk", label: "makedonska" },
        { code: "mg", label: "malagassiska" },
        { code: "ml", label: "malayalam" },
        { code: "ms", label: "malaysiska" },
        { code: "mt", label: "maltesiska" },
        { code: "mi", label: "maori" },
        { code: "mr", label: "marathi" },
        { code: "mni-Mtei", label: "meitei (manipuri)" },
        { code: "lus", label: "mizo" },
        { code: "mn", label: "mongoliska" },
        { code: "nl", label: "nederländska" },
        { code: "ne", label: "nepali" },
        { code: "no", label: "norska" },
        { code: "or", label: "odia (oriya)" },
        { code: "om", label: "oromo" },
        { code: "ps", label: "pashto" },
        { code: "fa", label: "persiska" },
        { code: "pl", label: "polska" },
        { code: "pt", label: "portugisiska" },
        { code: "pa", label: "punjabi" },
        { code: "qu", label: "quechua" },
        { code: "ro", label: "rumänska" },
        { code: "ru", label: "ryska" },
        { code: "sm", label: "samoanska" },
        { code: "sa", label: "sanskrit" },
        { code: "nso", label: "sepedi" },
        { code: "sr", label: "serbiska" },
        { code: "st", label: "sesotho" },
        { code: "sn", label: "shona" },
        { code: "sd", label: "sindhi" },
        { code: "si", label: "singalesiska" },
        { code: "sk", label: "slovakiska" },
        { code: "sl", label: "slovenska" },
        { code: "so", label: "somaliska" },
        { code: "es", label: "spanska" },
        { code: "su", label: "sundanesiska" },
        { code: "sw", label: "swahili" },
        { code: "tg", label: "tadzjikiska" },
        { code: "ta", label: "tamil" },
        { code: "tt", label: "tatariska" },
        { code: "te", label: "telugu" },
        { code: "th", label: "thailändska" },
        { code: "ti", label: "tigrinja" },
        { code: "cs", label: "tjeckiska" },
        { code: "ts", label: "tsonga" },
        { code: "tr", label: "turkiska" },
        { code: "tk", label: "turkmeniska" },
        { code: "ak", label: "twi" },
        { code: "de", label: "tyska" },
        { code: "ug", label: "uiguriska" },
        { code: "uk", label: "ukrainska" },
        { code: "hu", label: "ungerska" },
        { code: "ur", label: "urdu" },
        { code: "uz", label: "uzbekiska" },
        { code: "vi", label: "vietnamesiska" },
        { code: "be", label: "vitryska" },
        { code: "cy", label: "walesiska" },
        { code: "xh", label: "xhosa" },
        { code: "yo", label: "yoruba" },
        { code: "zu", label: "zulu" },
      ],
      selectedOptions: [...this.value]
    };
  },
  watch: {
    value() {
      if (JSON.stringify(this.selectedOptions) == JSON.stringify(this.value)) { return; }

      this.selectedOptions = [...this.value];
    }
  }
}
</script>
