<template>
    <div>
        <template v-if="isPdf">
            <canvas ref="canvas"></canvas><br>

            <div class="w-100 mt-2">
                <button @click.prevent="prev" class="btn btn-primary" :class="pageCount <= 1 ? 'd-none' : ''"><i class="fal fa-arrow-left"></i></button>
                <button @click.prevent="next" class="btn btn-primary pull-right"  :class="pageCount <= 1 ? 'd-none' : ''"><i class="fal fa-arrow-right"></i></button>
                <span>{{pageNum}} / {{pageCount}}</span>
            </div>
        </template>
        <template v-else>
            <img :src="pdflink" class="w-100">
        </template>

        <div class="w-100 mt-2">
            <br>
            <small>{{$t('pdfView.strange')}} <a :href="pdflink" target="_blank">{{$t('pdfView.openNewTab')}}</a></small>
        </div>
    </div>
</template>

<style scoped>
canvas, img {
    border: 1px solid lightgray;
    direction: ltr;
    width: 100%;
}
</style>

<script>
export default {
    props: {
        pdflink: String,
        isPdf: Boolean
    },
    data() {
        return {
            pdfDoc: null,
            pageNum: 0,
            pageRendering: false,
            pageNumPending: null,
            scale: 1.5
        }
    },
    computed: {
        pageCount() {
            return this.pdfDoc ? this.pdfDoc.numPages : 0;
        }
    },
    async mounted() {
        if (!this.isPdf) { return; }

        await this.loadPdf();
    },
    watch: {
        pdflink: async function() {
            if (!this.isPdf) { return; }

            await this.loadPdf();
        },
        pdfDoc: async function() {
            if (this.pdfDoc) {
                await this.renderPage(1)
            } else {
                const canvas = this.$refs['canvas'];
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);
            }
        }
    },
    methods: {
        loadPdf: async function() {
            this.pdfDoc = await pdfjs.getDocument(this.pdflink).promise;
        },
        renderPage: async function(num) {
            this.pageNumPending = num;

            const page = await this.pdfDoc.getPage(num);
            const viewport = page.getViewport({
                scale: this.scale
            });

            const canvas = this.$refs['canvas'];
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            await page.render({canvasContext: canvas.getContext('2d'), viewport: viewport}).promise;
            this.pageNum = num;

            if (this.pageNumPending === num) {
                this.pageNumPending = null;
            } else {
                await this.renderPage(this.pageNumPending);
            }
        },
        prev: async function() {
            if (this.pageNumPending !== null && this.pageNumPending > 1) {
                this.pageNumPending -= 1;
            } else if (this.pageNum > 1) {
                await this.renderPage(this.pageNum - 1);
            }
        },
        next: async function() {
            if (this.pageNumPending !== null && this.pageNumPending < this.pdfDoc.numPages) {
                this.pageNumPending += 1;
            } else if (this.pageNum < this.pdfDoc.numPages) {
                await this.renderPage(this.pageNum + 1);
            }
        }
    }
}
</script>
