<template>
  <expandable-card no-card-padding>
      <template v-slot:header>
        Att göra
        <small v-if="unreadCount">
          – {{unreadCount}} olästa
        </small>
      </template>
      <template v-slot:body>
        <notifications-index :quote-id="quoteId" :for-auth-user="forAuthUser" @read="reload()" no-card-link></notifications-index>
      </template>
  </expandable-card>
</template>

<style lang="scss" scoped>

.badge {
  font-size: 0.7em;
  margin-top: 0;
}

</style>

<script>
import axios from 'axios';

export default {
  props: {
    quoteId: { type: Number, required: true },
    forAuthUser: { type: Boolean, default: true},
  },
  data() {
    return {
      unreadCount: 0,
    }
  },
  async mounted() {
    await this.reload();
    this.interval = setInterval(async () => {
      await this.reload();
    }, 60000);
  },
  unmounted() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    async reload() {
      let payload = { quote_id: this.quoteId, for_auth_user: this.forAuthUser };
      const response = await axios.get(route('notifications.unreadCount', payload));
      this.unreadCount = response.data.count;
    },
  },
}
</script>
