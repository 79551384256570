<template>
    <div>
        <flicking-carousel v-if="!isSigned && !disableProductOptionCarousel" :panels="quoteOptions" @panelClick="onPanelClick($event.idx)">
            <template #panel="{ idx: quoteOptionIdx, panel: quoteOption }">
                <div class="cursor-pointer" role="button">
                    <img :src="companyPdfviewUrl" class="company-pdf-preview my-3 img-wiggle" draggable="false" />
                    <p class="mb-3">
                        <div class="text-secondary h4">
                            {{quoteOption.name}}
                        </div>
                        <template v-if="!isSigned && quoteOption.recommended">
                            <br>
                            <div style="color:#228B22" class="mt-2">
                                <i class="far fa-thumbs-up"></i> Rekommenderat val!
                            </div>
                        </template>
                    </p>

                  <div v-if="quoteOption.extra_documents_with_pdf_url.length > 0">Mappen innehåller</div>
                    <div v-for="(doc, docIdx) in quoteOption.extra_documents_with_pdf_url.slice(0, maxExtraDocuments[quoteOptionIdx])" :key="docIdx" class="mb-2">
                        <a role="button" class="btn w-50" @click.prevent.stop="onClickExtraDocument(quoteOptionIdx, 1 + docIdx)">
                          <template>
                            <img v-if="doc.is_pdf && doc.pdf_thumbnail_url" :src="doc.pdf_thumbnail_url" class="img-fluid" style="width:50px">
                            <i class="fal fa-file-pdf mr-1" v-else></i>
                          </template>
                          <br>
                            {{doc.pdf_name}}
                        </a>
                    </div>
                    <a href="#"
                        class="btn w-50 mb-2"
                        v-if="quoteOption.extra_documents_with_pdf_url.length > maxExtraDocuments[quoteOptionIdx]"
                        @click.prevent.stop="increaseMaxExtraDocuments(quoteOptionIdx, 3)"
                    >
                        Visa fler dokument
                    </a>
                </div>
            </template>
        </flicking-carousel>
        <flicking-carousel v-if="(isSigned || hasDocuments) && !disableProductOptionCarousel" :panels="[1]" :capture-panel-clicks="false">
            <template #panel>
                <div class="pt-3 pb-2 px-3">
                  <h3 class="mb-4 text-center">
                      <i class="fal fa-folder-open mr-1" style="font-size:7rem"></i>
                  </h3>

                  <div class="mb-2">
                      <a
                          v-if="isSigned"
                          :href="`/signed/${quoteKey}`"
                          title="Signerat"
                          class="btn btn-lg w-100 text-left"
                      >
                          <i :class="`fal fa-file-contract mr-1`" style="min-width: 21px;"></i>
                          {{ signedQuoteOption.name }}
                      </a>
                  </div>

                  <slot name="documents"></slot>
                </div>
            </template>
        </flicking-carousel>

        <template v-if="!disablePdfViewer && !isSigned">
            <documents-modal
                :id="`options-modal`"
                :print-url="route('quotes.print', {key: quoteKey, quote_option_id: selectedQuoteOption.id})"
                :quote-key="quoteKey"
                :quote-option-id="selectedQuoteOption.id"
                :quote-option-name="selectedQuoteOption.name"
                :company-image-url="companyImageUrl"
                :main-pdf-name="selectedQuoteOption.main_pdf_name"
                :main-pdf-url="selectedQuoteOption.main_pdf_url"
                :main-pdf-is-pdf="selectedQuoteOption.main_pdf_is_pdf"
                :extra-documents="selectedQuoteOption.extra_documents_with_pdf_url"
                :initially-selected="selectedDocumentIdx[selectedQuoteOptionIdx]"
                :is-signed="isSigned"
                :signable="signable"
                :is-post-sale="false"
                :company-color="companyColor"
                :sign-copy="quoteEsignButtonTxt  ? quoteEsignButtonTxt  : $t('sign.move_forward')"
                @move-forward-with-quote-option="handleQuoteOptionInterest"
            >

              <template #quote-option-selector-button>
                  <button class="btn btn-primary" :style="`background-color: ${companyColor};`" type="button" data-toggle="collapse" :data-target="`#navbar-${uuid}`" :aria-controls="`#navbar-${uuid}`" aria-expanded="false" aria-label="Byt dokument" v-if="quoteOptions.length > 1">
                    <span class="mb-0 h5">
                      {{$t('sign.more_options')}}
                      <i class="when-collapsed fal fa-chevron-down ml-2"></i>
                      <i class="when-expanded fal fa-chevron-up ml-2"></i>
                    </span>
                  </button>
              </template>
              <template #quote-option-selector-collapsible>
                  <div class="collapse navbar-collapse" :id="`navbar-${uuid}`">
                    <ul class="navbar-nav mr-auto mt-2 pl-2">
                      <li class="nav-item" v-for="(quoteOption, idx) in quoteOptions" :key="idx" :class="idx == selectedQuoteOptionIdx ? 'active' : ''">
                        <a class="nav-link" href="#" data-toggle="collapse" :data-target="`#navbar-${uuid}`" @click.prevent="handleQuoteOptionSelect(idx)">{{quoteOption.name}} <span class="sr-only">(visas nu)</span></a>
                      </li>
                    </ul>
                  </div>
              </template>

            </documents-modal>
        </template>
        <bank-id-modal
            id="bankid"
            :app-url="appUrl"
            :quote-key="quoteKey"
            :quote-options="quoteOptions"
            :initially-selected-id="interestedQuoteOptionId"
            :quote-esign-desc="quoteEsignDesc"
            :disable-pdf-viewer="disablePdfViewer"
            :sign-component-name="signComponentName"
            :sign-component-signee-name="signComponentSigneeName"
            :sign-component-signature-button-label="signComponentSignatureButtonLabel"
            :sign-component-item-description="signComponentItemDescription"
            :google-ads-conversion-event-targets="googleAdsConversionEventTargets"
            :meta-ads-conversion-pixel-ids="metaAdsConversionPixelIds"
            @view-quote-option="handleQuoteOptionSelect"
            v-if="!isSigned"
            :options-payments-method="optionsPaymentsMethod"
            :options-payments-dintero-profile-id="
                optionsPaymentsDinteroProfileId
            "
        >
            <template #more-information-header><slot name="more-information-header"></slot></template>
            <template #more-information-fieldset><slot name="more-information-fieldset"></slot></template>
        </bank-id-modal>
    </div>
</template>

<style lang="sass" scoped>
a:focus {
    outline: none;
    box-shadow: unset;
}

img.company-pdf-preview {
    display: block;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    object-position: center center;

    transform: rotate(-10deg);

    outline: none;
}
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

import DocumentsModal from '../modals/documents.vue';
import BankIdModal from './modals/bankid.vue';
import DetailsModal from "../products/modals/details.vue";

export default {
    components: {
      DetailsModal,
        DocumentsModal,
        BankIdModal
    },
    props: {
        appUrl: String,
        quoteKey: String,
        quoteEsignDesc: String,
        quoteEsignButtonTxt: String,
        quoteOptionsIn: Array,
        companyPdfviewUrl: String,
        companyImageUrl: String,
        signComponentName: { type: String, required: true },
        signComponentSigneeName: { type: String, default: null },
        signComponentSignatureButtonLabel: { type: String, required: true },
        signComponentItemDescription: { type: String, default: null },
        isSigned: Boolean,
        hasDocuments: { type: Boolean, required: true },
        signedQuoteOptionId: {
            type: Number,
            optional: true
        },
        disablePdfViewer: Boolean,
        disableProductOptionCarousel: { type: Boolean, default: false },
        signable: Boolean,
        companyColor: {
            type: String,
            default: ''
        },
        googleAdsConversionEventTargets: { type: Array, required: true },
        metaAdsConversionPixelIds: { type: Array, required: true },
        optionsPaymentsMethod: { type: String, default: null },
        optionsPaymentsDinteroProfileId: { type: String, default: null },
    },
    data() {
        const quoteOptions = this.quoteOptionsIn.filter(qo => (!this.isSigned || qo.id === this.signedQuoteOptionId));
        return {
            quoteOptions: quoteOptions,
            selectedDocumentIdx: quoteOptions.map(_ => 0),
            maxExtraDocuments: quoteOptions.map(_ => 2),
            selectedQuoteOptionIdx: 0,
            uuid: uuidv4(),
            interestedQuoteOptionId: quoteOptions[0].id
        }
    },
    computed: {
        signedQuoteOption() {
            return this.quoteOptions.filter((qo) => qo.id == this.signedQuoteOptionId)[0];
        },
        selectedQuoteOption() {
          return this.quoteOptions[this.selectedQuoteOptionIdx];
        }
    },
    methods: {
        setSelectedDocumentIdx: function(quoteOptionIdx, documentIdx) {
            this.selectedDocumentIdx.splice(quoteOptionIdx, 1, documentIdx);
        },
        increaseMaxExtraDocuments: function(quoteOptionIdx, increaseBy) {
            const current = this.maxExtraDocuments[quoteOptionIdx];
            this.maxExtraDocuments.splice(quoteOptionIdx, 1, current + increaseBy);
        },
        onPanelClick(idx) {
            const quoteOption = this.quoteOptions[idx];
            if (this.disablePdfViewer) {
                window.open(quoteOption.main_pdf_url, '_blank');
            } else {
                this.setSelectedDocumentIdx(idx, 0);
                this.selectedQuoteOptionIdx = idx;
                $(`#options-modal`).modal('show');
            }
        },
        onClickExtraDocument(idx, docIdx) {
            const quoteOption = this.quoteOptions[idx];
            const doc = quoteOption.extra_documents_with_pdf_url[docIdx];
            if (this.disablePdfViewer) {
                window.open(doc.pdf_url, '_blank');
            } else {
                this.setSelectedDocumentIdx(idx, docIdx);
                $(`#options-modal`).modal('show');
            }
        },
        handleQuoteOptionSelect(idx) {
          this.selectedQuoteOptionIdx = idx;
        },
        handleQuoteOptionInterest(quoteOptionId) {
            this.interestedQuoteOptionId = quoteOptionId;
        }
    }
}
</script>
