<template>
    <form
        v-if="!signed && !signing && !submitted && !submitting"
        @submit.prevent="onSubmit"
        enctype="multipart/form-data"
    >
        <h4>{{ introHeader }}</h4>
        <p class="preserve-linebrakes mb-5">{{ introBody }}</p>

        <div
            v-for="(answer, answerIdx) in answers"
            :key="answer.question.uuid"
            class="mb-5"
        >
            <h6 class="font-weight-bold">
                {{ answer.question.prompt }}
                <template v-if="!answer.question.optional">*</template>
            </h6>

            <template v-if="answer.question.type === 'text'">
                <input
                    type="text"
                    class="form-control"
                    :required="!answer.question.optional"
                    v-model="answer.value"
                />
            </template>

            <template v-if="answer.question.type === 'textarea'">
                <textarea
                    class="form-control"
                    :required="!answer.question.optional"
                    rows="5"
                    v-model="answer.value"
                ></textarea>
            </template>

            <template
                v-if="
                    answer.question.type === 'checkbox' ||
                    answer.question.type === 'radio'
                "
            >
                <div
                    v-for="(option, optionIdx) in answer.question.options"
                    :key="optionIdx"
                >
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            :type="answer.question.type"
                            :required="
                                !answer.question.optional &&
                                JSON.stringify(answer.value) === '[]'
                            "
                            :id="`${uuid}—question-${answer.question.uuid}-option-${optionIdx}`"
                            :name="`${uuid}—question-${answer.question.uuid}`"
                            :value="option"
                            v-model="answer.value"
                        />
                        <label
                            class="form-check-label"
                            :for="`${uuid}—question-${answer.question.uuid}-option-${optionIdx}`"
                        >
                            {{ option }}
                        </label>
                    </div>
                </div>
            </template>
            <template v-if="answer.question.type === 'documents'">
                <input
                    type="file"
                    :required="!answer.question.optional"
                    :name="`${uuid}—question-${answer.question.uuid}`"
                    v-on:change="
                        (event) => handleFiles(answerIdx, event.target.files)
                    "
                    multiple
                />
            </template>
            <template
                v-if="
                    answer.question.type === 'products-checkbox' ||
                    answer.question.type === 'products-radio'
                "
            >
                <div
                    v-for="(product, productIdx) in answer.question.products"
                    :key="productIdx"
                >
                    <div class="product">
                        <input
                            :type="
                                answer.question.type.replace('products-', '')
                            "
                            :required="
                                !answer.question.optional &&
                                JSON.stringify(answer.value) === '[]'
                            "
                            :id="`${uuid}—question-${answer.question.uuid}-product-${productIdx}`"
                            :name="`${uuid}—question-${answer.question.uuid}`"
                            :value="product"
                            v-model="answer.value"
                        />
                        <label
                            :for="`${uuid}—question-${answer.question.uuid}-product-${productIdx}`"
                        >
                            <img :src="product.image_url" />
                            <div class="product-info">
                                <strong>{{ product.name }}</strong
                                ><br />
                                {{
                                    $t(
                                        "products.unit_with_price." +
                                            product.price_unit,
                                        {
                                            price: product.price_including_vat,
                                            currency: $t(
                                                "currencies." + product.currency
                                            ),
                                            vat_amount: "",
                                        }
                                    )
                                }}<br />
                                <h6
                                    v-if="product.description_title"
                                    class="font-weight-bold"
                                >
                                    {{ product.description_title }}
                                </h6>
                                <p
                                    v-if="product.description"
                                    v-html="product.description"
                                ></p>
                            </div>
                        </label>
                    </div>
                </div>
            </template>
        </div>

        <div v-if="!submitting && !signing" class="text-center">
            <b-button type="submit" variant="custom" class="signbuttoninline">
                <template v-if="requiresBankidSignature && !signed">
                    Signera med BankID
                </template>
                <template v-else> Skicka in </template>
            </b-button>
        </div>
    </form>

    <div v-else-if="signing" class="text-center my-5">
        <bank-id-v2-sign
            :item-id="bankidItemId"
            :item-description="`Jag signerar mina svar på förfrågan ${introHeader}`"
            :app-url="bankidAppUrl"
            :signee-name="bankidSigneeName"
            autostart
            @signed="onSigned"
        ></bank-id-v2-sign>
    </div>
    <div v-else-if="submitting" class="text-center my-5">
        <b-spinner></b-spinner>
    </div>
    <div v-else-if="submitted">
        <h5>{{ thanksHeader }}</h5>
        <p class="preserve-linebrakes">{{ thanksBody }}</p>

        <div class="text-center my-5">
            <b-button
                v-if="buttonNextEnabled"
                type="button"
                variant="custom"
                class="signbuttoninline"
                @click="$emit('next')"
            >
                Gå vidare till nästa enkät
            </b-button>

            <b-button
                v-else
                type="button"
                variant="custom"
                class="signbuttoninline"
                @click="$emit('close')"
            >
                Stäng
            </b-button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
p.preserve-linebrakes {
    white-space: pre-line;
}

.product {
    display: flex;
    align-items: flex-start;

    input {
        margin-right: 8px;
        margin-top: 30px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    label {
        flex-grow: 1;
        display: flex;
        align-items: flex-start;

        img {
            flex-shrink: 0;
            flex-grow: 0;
            height: 70px;
            width: 70px;
            object-fit: contain;
            object-position: center;
            margin-right: 8px;
        }

        img[src=""],
        img:not([src]) {
            opacity: 0;
        }

        .product-info {
            padding-top: 4px;
        }
    }
}
</style>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        imageUrl: { type: String, default: null },
        requiresBankidSignature: { type: Boolean, required: true },
        bankidItemId: { type: String, default: null },
        bankidAppUrl: { type: String, default: null },
        bankidSigneeName: { type: String, default: null },
        introHeader: { type: String, required: true },
        introBody: { type: String, required: true },
        thanksHeader: { type: String, required: true },
        thanksBody: { type: String, required: true },
        questions: { type: Array, required: true },
        quoteKey: { type: String, required: true },
        buttonNextEnabled: { type: Boolean },
        submitUrl: { type: String, default: null },
    },
    data() {
        return {
            answers: this.questions.map((q) => ({
                question: { ...q },
                value: [],
            })),
            submitted: false,
            submitting: false,
            signed: false,
            signing: false,
            uuid: uuidv4(),
        };
    },
    computed: {
        preview() {
            return !this.submitUrl;
        },
    },
    methods: {
        handleFiles(answerIdx, files) {
            if (!files || files.length < 1) {
                return;
            }

            if (!this.answers[answerIdx]) {
                return;
            }

            this.answers[answerIdx].value = Array.from(files);
        },
        async onSigned() {
            this.signing = false;
            this.signed = true;
            await this.onSubmit();
        },
        async onSubmit() {
            if (this.requiresBankidSignature && !this.signed) {
                this.signing = true;
                return;
            }

            this.submitting = true;

            if (this.preview) {
                setTimeout(() => {
                    this.submitted = true;
                    this.submitting = false;
                }, 1000);

                return;
            }

            try {
                await axios.postForm(this.submitUrl, {
                    _method: "PUT",
                    answers: this.answers,
                    completed: true,
                });
                this.submitted = true;
            } catch (err) {
                if (console.error) {
                    console.error(err);
                }
                if (err.response.status == 422) {
                    // We only validate file size and type for documents
                    alert("Ogiltig filtyp eller storlek");
                } else {
                    alert("Något gick fel, försök igen senare.");
                }
            } finally {
                this.submitting = false;
            }
        },
    },
};
</script>
