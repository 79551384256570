<template>
    <fieldset>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Miljö</div>
            </div>
            <select class="custom-select" required v-model="environment" @change="onUpdate">
                <option value="production">Produktionsmiljö</option>
                <option value="playground">Testmiljö</option>
            </select>
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Api Key</div>
            </div>
            <input type="text" class="form-control" required v-model="apiKey" @input="onUpdate">
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Callback Api Key</div>
            </div>
            <input type="text" class="form-control" required v-model="callbackApiKey" @input="onUpdate">
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Fältnamn: PaymentId</div>
            </div>
            <input type="text" class="form-control" v-model="fieldPaymentId" @input="onUpdate">
        </div>
    </fieldset>
</template>

<script>
export default {
    emits: [
        'input'
    ],
    props: {
        value: {type: Object, required: true}
    },
    data() {
        return {
            environment: null,
            apiKey: null,
            callbackApiKey: null,
            fieldPaymentId: null,
        }
    },
    computed: {
        serializedValue() {
            return {
                environment: this.environment,
                api_key: this.apiKey,
                callback_api_key: this.callbackApiKey,
                field_payment_id: this.fieldPaymentId
            };
        }
    },
    watch: {
        value: {
            handler() {
                if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
                    this.environment = this.value.environment;
                    this.apiKey = this.value.api_key;
                    this.callbackApiKey = this.value.callback_api_key;
                    this.fieldPaymentId = this.value.field_payment_id;

                    if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
                        this.onUpdate();
                    }
                }
            },
            immediate: true
        }
    },
    methods: {
        onUpdate() {
            this.$emit('input', this.serializedValue);
        },
    }
};
</script>
