<template>
  <div class="input-group mb-3">
    <input type="text" class="form-control" v-model="title" @keydown.enter.prevent="onEnter">

    <div class="input-group-append">
      <b-button variant="primary" :disabled="title.length === 0" @click="emitOption">
        {{$t('surveys.editor.button_question_option_add')}}
      </b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
  data() {
    return {
      title: ""
    };
  },
  methods: {
    emitOption() {
      this.$emit("selected", this.title);
      this.title = "";
    },
    onEnter() {
      if (this.title.length > 0) {
        this.emitOption();
      }
    }
  }
}
</script>
