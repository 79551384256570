<template>
  <div class="editor-root">
    <div v-if="editor" class="mb-2">
      <button type="button" class="btn btn-sm" :class="{ 'btn-secondary': editor.isActive('bold'), 'btn-outline-secondary': !editor.isActive('bold') }" @click.prevent="editor.chain().focus().toggleBold().run()">
        <i class="far fa-bold fa-sm"></i>
      </button>
      <button type="button" class="btn btn-sm" :class="{ 'btn-secondary': editor.isActive('italic'), 'btn-outline-secondary': !editor.isActive('italic') }" @click.prevent="editor.chain().focus().toggleItalic().run()">
        <i class="far fa-italic fa-sm"></i>
      </button>
      <button type="button" class="btn btn-sm" :class="{ 'btn-secondary': editor.isActive('strike'), 'btn-outline-secondary': !editor.isActive('strike') }" @click.precent="editor.chain().focus().toggleStrike().run()">
        <i class="far fa-strikethrough fa-sm"></i>
      </button>
    </div>
    <editor-content class="editor-content" :editor="editor" />
  </div>
</template>

<style lang="scss" scoped>
  .editor-root {
    height: 100%;
    display: flex;
    flex-direction: column;

    .editor-content {
      flex-grow: 1;
      overflow-y: auto;

      &::v-deep .ProseMirror {
        height: 100%;
      }
    }
  }
</style>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
