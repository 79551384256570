<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" ref="modal">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{viewStrings.project_approval_title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Stäng">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card-body mb-0">
                        <template v-if="!isSigned">
                            <p class="text">{{viewStrings.project_approval_sign_text}}</p>

                            <component
                                :is="signComponentName"
                                :item-id="itemId"
                                :item-description="signComponentItemDescription"
                                :app-url="appUrl"
                                :signee-name="signComponentSigneeName"
                                :signature-button-label="signComponentSignatureButtonLabel"
                                @signed="onSigned"
                            />
                        </template>
                        <template v-else>
                            <p class="text">{{viewStrings.project_approval_note_text}}</p>

                            <textarea class="form-control mb-3" rows="6" v-model="notes">
                            </textarea>

                            <button v-if="!this.isSubmittingNotes" class="projectApprovalNoteButton btn btn-secondary" @click="submitNotes">{{submitNotesButtonTitle}}</button>
                            <button v-else type="button" class="btn btn-primary wait">
                            <div class="spinner-border spinner-border-sm mr-2"></div>
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
button.btn {
    width: 100%;

    &.wait .spinner-border {
        vertical-align: middle;
    }
}

p.text {
    white-space: pre-line;
}
</style>

<script>
import axios from 'axios';
import qs from 'qs';

export default {
    props: {
        appUrl: String,
        quoteKey: String,
        itemId: String,
        signComponentName: {
          type: String,
          required: true
        },
        signComponentSigneeName: { type: String, default: null },
        signComponentSignatureButtonLabel: { type: String, required: true },
        signComponentItemDescription: String,
        viewStrings: { type: Object, required: true }
    },
    data() {
        return {
            isSigned: false,
            isSubmittingNotes: false,
            notes: ''
        };
    },
    mounted() {
        if (qs.parse(document.location.search.substring(1)).project_approval) {
            $(this.$refs.modal).modal('show');
        }
    },
    computed: {
        submitNotesButtonTitle() {
            if (this.notes) {
                return this.viewStrings.project_approval_note_submit_button_text;
            } else {
                return this.viewStrings.project_approval_note_skip_button_text;
            }
        }
    },
    methods: {
        onSigned() {
            this.isSigned = true;
        },
        async submitNotes() {
            if (this.notes) {
                try {
                    this.isSubmittingNotes = true;
                    await axios.put(`/quote/${this.quoteKey}/notes`, { notes: this.notes })
                } catch (error) {
                    this.isSubmittingNotes = false;
                    console.warn("Failed to submit note", error);
                    return;
                }
            }

            document.location.reload();
        }
    }
}
</script>
