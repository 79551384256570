<template>
  <div>
    <a v-show="!isOpen" role="button" @click.prevent="open()">
        Att göra
        <span v-if="unreadCount > 0" class="badge badge-pill badge-danger">{{unreadCount}}</span>
    </a>
    <div v-if="isOpen">Open!</div>

    <portal v-if="isOpen" to="floating-action-buttons-popup">
      <div class="card-container">
        <div class="card h-100">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-start align-items-center">
                <span class="h6 m-0">Att göra</span>
              </div>

              <a href="#" @click.prevent="close()"><i class="fa-light fa-times text-dark"></i></a>
            </div>
          </div>
          <div class="card-body overflow-auto">
            <notifications-index @close="close()"></notifications-index>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<style lang="scss" scoped>
a .badge {
  margin-top: 0.8em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.card-container {
  display: block;
  position: fixed;
  z-index: 1000;

  bottom: 0;
  top: 57px + 20px;
  right: 1em;
  width: 24vw;
  max-width: 320px;

  .card-header {
    background: transparent;
  }

  .card-body {
    padding: 0;
  }
}
</style>

<script>
import axios from 'axios';

export default {
  props: {
    isOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      unreadCount: 0,
    }
  },
  async mounted() {
    await this.reload();
    this.interval = setInterval(async () => {
      await this.reload();
    }, 60000);
  },
  unmounted() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    async reload() {
      const response = await axios.get(route('notifications.unreadCount'));
      this.unreadCount = response.data.count;
    },
    open() {
      this.$emit('open');
    },
    close() {
      this.$emit('close');
      this.reload();
    }
  },
}
</script>
