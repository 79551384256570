<template>
  <div>
    <p v-if="requestedCode">Ange din engångskod för att se ditt erbjudande.</p>
    <p v-else>För att visa ditt erbjudande behöver vi bekräfta din identitet. Ange det telefonnummer eller den e-postaddress du fick erbjudandet på, så skickar vi ut en engångskod.</p>

    <axios-form
      v-if="requestedCode"
      class="mb-3"
      method="post"
      :action="route('model_authentication.authenticate', codeKey)"
      @submit="onAuthenticateSubmit"
      @success="onAuthenticateSuccess"
      @failure="onAuthenticateFailure"
    >
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="input-code"><i class="fa-regular fa-input-numeric"></i></label>
        </div>
        <input class="form-control" type="text" inputmode="numeric" required pattern="[0-9]+" autocomplete="one-time-code" name="code" id="input-code" v-model="code">
      </div>

      <button class="btn btn-outline-primary w-100" type="submit">Logga in</button>

      <p v-if="authenticationFailure" class="mt-2 text-danger">
        Inkorrekt kod, försök igen!
      </p>
    </axios-form>

    <axios-form
      v-else-if="requestingViaSms"
      class="mb-3"
      method="post"
      :action="route('model_authentication.request_sms', codeKey)"
      @submit="onRequestSmsSubmit"
      @success="onRequestSmsSuccess"
      @failure="onRequestSmsFailure"
    >
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="input-sms"><i class="fa-regular fa-phone"></i></label>
        </div>
        <input class="form-control" type="tel" required name="receiver" id="input-sms" v-model="sms">
      </div>

      <p v-if="requestSmsFailure" class="mt-2 text-danger">
        Telefonnummret är ej kopplat till ditt erbjudande.
      </p>

      <button class="btn btn-outline-primary w-100" type="submit">Begär kod</button>
    </axios-form>

    <axios-form
      v-else-if="requestingViaEmail"
      class="mb-3"
      method="post"
      :action="route('model_authentication.request_email', codeKey)"
      @submit="onRequestEmailSubmit"
      @success="onRequestEmailSuccess"
      @failure="onRequestEmailFailure"
    >
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="input-email"><i class="fa-regular fa-envelope"></i></label>
        </div>
        <input class="form-control" type="email" required name="receiver" id="input-email" v-model="email">
      </div>

      <p v-if="requestEmailFailure" class="mt-2 text-danger">
        E-post-adressen är ej kopplad till ditt erbjudande.
      </p>

      <button class="btn btn-outline-primary w-100" type="submit">Begär kod</button>
    </axios-form>

    <template v-else>
      <button type="button" class="btn btn-lg btn-primary w-100 my-1" @click="requestingViaSms = true">
        <i class="fa-regular fa-phone mr-1"></i> Sms
      </button>

      <button type="button" class="btn btn-lg btn-primary w-100 my-1" @click="requestingViaEmail = true">
        <i class="fa-regular fa-envelope mr-1"></i> E-post
      </button>

    </template>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  props: {
    codeKey: { type: String, required: true }
  },
  data() {
    return {
      code: undefined,
      sms: undefined,
      email: undefined,
      requestingViaSms: false,
      requestingViaEmail: false,
      requestedCode: false,
      authenticationFailure: false,
      requestSmsFailure: false,
      requestEmailFailure: false,
    };
  },
  methods: {
    onAuthenticateSubmit() {
      this.authenticationFailure = false;
    },
    onAuthenticateSuccess(response) {
      document.location.href = response.data.return_url;
    },
    onAuthenticateFailure(error) {
      this.authenticationFailure = true;
    },
    onRequestSmsSubmit() {
      this.requestSmsFailure = false;
    },
    onRequestSmsSuccess(response) {
      this.requestedCode = true;
    },
    onRequestSmsFailure(error) {
      this.requestSmsFailure = true;
    },
    onRequestEmailSubmit() {
      this.requestEmailFailure = false;
    },
    onRequestEmailSuccess(response) {
      this.requestedCode = true;
    },
    onRequestEmailFailure(error) {
      this.requestEmailFailure = true;
    },
  }
}
</script>
