import Flicking, { EVENTS } from "@egjs/flicking";


export default class {
  constructor(options = {}) {
    const { scale = 1, tuck = 0.2, perspective = 1000 } = options;

    this._flicking = null;
    this._scale = scale;
    this._tuck = tuck;
    this._perspective = perspective;

    this.update = this.update.bind(this);
    this._onMove = this._onMove.bind(this);
  }

  get scale() { return this._scale; }
  get tuck() { return this._tuck; }
  get perspective() { return this._perspective; }

  set scale(val) { this._scale = val; }
  set tuck(val) { this._tuck = val; }
  set perspective(val) { this._perspective = val; }

  init(flicking) {
    if (this._flicking) {
      this.destroy();
    }

    this._flicking = flicking;

    flicking.on(EVENTS.MOVE, this._onMove);
    flicking.on(EVENTS.AFTER_RESIZE, this.update);
    this._onMove();
  }

  destroy() {
    if (!this._flicking) return;

    this._flicking.off(EVENTS.MOVE, this._onMove);
    this._flicking.off(EVENTS.AFTER_RESIZE, this.update);
    this._flicking = null;
  }

  update() {
    this._onMove();
  }

  _onMove() {
    const flicking = this._flicking;
    const scale = this._scale;
    const tuck = this._tuck;
    const perspective = this._perspective;

    if (!flicking) return;

    const horizontal = flicking.horizontal;
    const panels = flicking.visiblePanels;

    panels.forEach((panel) => {
      const progress = panel.outsetProgress;
      const target = panel.element;
      const panelScale = 1 / (Math.abs(progress * scale) + 1);

      const tuckTranslate = -1 * tuck * progress;
      const [translateX, translateY] = horizontal ? [tuckTranslate, 0] : [0, tuckTranslate];

      target.style.transform = `perspective(${perspective}px) translateX(${translateX}px) translateY(${translateY}px) scale(${panelScale})`;
      target.style.zIndex = Math.round(1000 - (100 * Math.abs(progress)));
    });
  }
}
