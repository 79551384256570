<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Lägg till signatur</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Stäng">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card-body">
                        <div class="form-group">
                            <p>För att lägga till din signatur till avtalet, klicka på {{ signComponentSignatureButtonLabel }}.</p>

                            <component
                                :is="signComponentName"
                                :item-id="itemId"
                                :item-description="signComponentItemDescription"
                                :app-url="appUrl"
                                :redirect-on-signed-url="redirectUrl"
                                :signee-name="signComponentSigneeName"
                                :signature-button-label="signComponentSignatureButtonLabel"
                            />
                        </div>
                        <hr>

                        <terms-show :quote-key="quoteKey"></terms-show>

                        <powered-by-salesbyte class="mt-5"></powered-by-salesbyte>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>

<script>
export default {
    props: {
        appUrl: String,
        quoteKey: String,
        itemId: String,
        documentId: {
            type: Number,
            default: null
        },
        signComponentName: {
          type: String,
          required: true
        },
        signComponentSigneeName: { type: String, default: null },
        signComponentSignatureButtonLabel: { type: String, required: true },
        signComponentItemDescription: { type: String, default: null },
    },
    data() {
        return {
        };
    },
    computed: {
        redirectUrl() {
            if (this.documentId) {
                return `/signed/${this.quoteKey}/documents/${this.documentId}`;
            } else {
                return `/signed/${this.quoteKey}`;
            }
        }
    }
}
</script>
