<script>
export default {
  name: "oauth-auth-code-workflow",
  props: {
    initiator: { type: String, required: true }
  },
  data(){
    return {
      interval : null,
      statusCheckUrl: null
    }
  },
  methods: {
    async initiateOAuth(){
      const response = await axios.post(this.initiator);
      this.statusCheckUrl = response.data.statusCheckUrl

      window.open(response.data.redirectUrl, "myWindow", 'width=800,height=600');

      this.interval = null;
      this.interval = setInterval(async ()=>{
        try {
          const response = await axios.get(this.statusCheckUrl);
          if (response.data.status === "Pending" || response.data.status === "Unset" ){
            return;
          }

          if (response.data.status === "Done"){
            this.$emit('success', response.data.tokens)
          }else{
            this.$emit('failure')
          }

          clearInterval(this.interval);
        }catch (e) {
          console.log(e);
          clearInterval(this.interval);
        }
      }, 1000)
    }
  }
}
</script>

<template>
  <div class="d-flex justify-content-center">
    <button type="button" class="btn btn-secondary" @click="initiateOAuth"> Authenticate with Fortnox</button>
  </div>
</template>

<style scoped lang="scss">

</style>
