<template>
    <form @submit.prevent="submit" ref="form" id="form-data">
        <slot></slot>
    </form>
</template>

<style lang="css" scoped></style>

<script>
import axios from "axios";

export default {
    props: {
        method: {type: String, required: true},
        action: {type: String, required: true},
        shouldSubmit: {type: Function, required: false}
    },
    data() {
        return {
            submitting: false
        };
    },
    methods: {
        submit: async function (event) {
            if (this.submitting) {
                return;
            }
            if (this.shouldSubmit && !this.shouldSubmit()) {
                return;
            }
            this.$emit('submit');

            // Allow any events to propagate before we submit
            // Specifically the tel input hidden fields
            setTimeout(async() => {
                try {
                    this.submitting = true;
                    const response = await axios.request({
                        method: this.method,
                        url: this.action,
                        data: new FormData(this.$refs.form),
                    });

                    this.$emit('success', response);
                    this.submitting = false;
                } catch (error) {
                    this.$emit('failure', error);
                    this.submitting = false;
                    console.warn("Failed to submit form..", error);
                }
            },100);

        }
    }

}
</script>
