<template>
    <div id="bankid" ref="modal" aria-hidden="true" aria-labelledby="bankidLabel" class="modal fade" role="dialog"
         tabindex="-1">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button aria-label="Stäng" class="close" data-dismiss="modal" type="button"
                            @click="userSelected = undefined">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card-body">
                        <div class="form-group">

                            <template v-if="quoteEsignDesc">
                                <p style="white-space: pre-line">{{ quoteEsignDesc }}</p>
                            </template>
                            <template v-else>
                                <p>FÖR ATT LÄGGA BESTÄLLNING:<br/>
                                    1. Markera rätt alternativ nedan (blå ram)<br/>
                                    2. Fyll i personnummer<br/>
                                    3. Klicka på "Signera" för att signera med BankID.
                                </p>
                                <p>
                                    (Vill du istället läsa er offert. Klicka på visa dokument.)
                                </p>
                            </template>

                            <template v-for="(quoteProduct, idx) in quoteProducts">
                                <div v-if="!quoteProduct.is_invalid" :key="idx"
                                     :class="selected == idx ? 'border-color-profile-color' : null"
                                     class="card mb-2 w-100" @click.prevent="userSelected = idx">
                                    <div class="card-body">
                                        <div class="d-flex align-items-center">
                                            <div style="flex-grow: 1;">
                                                <b v-if="quoteProduct.products.length == 1">{{
                                                        quoteProduct.products[0].name
                                                    }}</b>
                                                <b v-else>{{ quoteProduct.name }}</b>

                                                <template
                                                    v-if="!quoteProduct.allows_user_assigned_price || quoteProduct.user_assigned_price">
                                                    -
                                                    <span
                                                        v-if="quoteProduct.products.length > 1 || quoteProduct.user_assigned_price"
                                                        v-html="$t('quoteProducts.view.price_html', {price: quoteProduct.price, currency: quoteProduct.currency_name, vat_suffix: quoteProduct.vat_suffix })"
                                                    ></span>
                                                    <span v-else-if="quoteProduct.products[0].amount == 1">
                                            {{
                                                            $t('products.unit_with_price.' + quoteProduct.products[0].price_unit, {
                                                                price: quoteProduct.price,
                                                                currency: quoteProduct.currency_name,
                                                                vat_suffix: quoteProduct.vat_suffix
                                                            })
                                                        }}
                                          </span>
                                                    <span v-else>
                                            {{
                                                            $t('products.unit_with_amount_and_price.' + quoteProduct.products[0].price_unit, {
                                                                amount: quoteProduct.products[0].amount,
                                                                price: quoteProduct.products[0].price,
                                                                currency: quoteProduct.products[0].currency_name,
                                                                vat_suffix: quoteProduct.vat_suffix
                                                            })
                                                        }}
                                          </span>

                                                    <a
                                                        v-if="quoteProduct.allows_user_assigned_price && !isEditingUserAssignedPrice && userSelected === idx"
                                                        class="small" href="#"
                                                        @click.stop.prevent="isEditingUserAssignedPrice = true">
                                                        (Ändra)
                                                    </a>
                                                </template>
                                                <div class="product-thumbnails">
                                                    <img v-for="(prod, idx) in quoteProduct.products.slice(0, 5)"
                                                         :key="idx" :src="prod.image_url"
                                                         class="product-thumbnail">
                                                </div>
                                                <p class="mb-0">
                                                    <small>
                                                        <a href="#" style="color:black"
                                                           @click="openModal(`#products-modal-${quoteProduct.id}`)">
                                                            Läs mer
                                                        </a>
                                                    </small>
                                                </p>
                                                <span v-if="quoteProduct.recommended" class="mt-3"
                                                      style="color:#228B22"><i
                                                    class="far fa-thumbs-up"></i> Rekommenderat val!</span>
                                            </div>
                                            <div v-if="selected == idx" class="pl-2 py-3 text-color-profile-color"
                                                 style="font-size: 1.8em;">
                                                <i class="far fa-check"></i>
                                            </div>
                                        </div>
                                        <template v-if="selected == idx && isEditingUserAssignedPrice">
                                            <hr>
                                            <form class="d-flex justify-content-between align-items-start" @click.stop
                                                  @submit.prevent="onSubmitUserAssignedPrice">
                                                <div class="flex-grow-1 mr-3">
                                                    <input v-model="userAssignedPriceForSelected"
                                                           :disabled="isSubmittingUserAssignedPrice"
                                                           :placeholder="`Ange summa (${quoteProduct.currency_name}${quoteProduct.vat_suffix})`"
                                                           class="form-control"
                                                           min="1"
                                                           required type="number">
                                                    <div v-if="userAssignedPriceErrored" class="text-danger">
                                                        Summan behöver vara minst {{quoteProduct.minimum_price_including_vat}}
                                                    </div>
                                                </div>
                                                <button v-if=!isSubmittingUserAssignedPrice
                                                        class="btn signbuttoninline flex-shrink-0 my-0"
                                                        type="submit">Välj summa
                                                </button>
                                                <b-spinner v-else></b-spinner>
                                            </form>
                                        </template>
                                    </div>
                                </div>
                            </template>

                            <primary-signature-prompt
                                v-if="!isHidden && (!quoteProducts[selected].allows_user_assigned_price || quoteProducts[selected].user_assigned_price)"
                                :app-url="appUrl"
                                :billecta-bank-account-retrieval="billectaBankAccountRetrieval"
                                :google-ads-conversion="googleAdsConversion"
                                :item-description="signComponentItemDescription"
                                :item-id="`${quoteKey}/${quoteProducts[selected].id}`"
                                :meta-ads-conversion="metaAdsConversion"
                                :products-payments-dintero-profile-id="productsPaymentsDinteroProfileId"
                                :products-payments-method="productsPaymentsMethod"
                                :products-payments-product-id="quoteProducts[selected].id"
                                :quote-key="quoteKey"
                                :redirect-on-signed-url="`/signed/${quoteKey}`"
                                :sign-component-name="signComponentName"
                                :signature-button-label="signComponentSignatureButtonLabel"
                                :signee-name="signComponentSigneeName"
                            >
                                <template #more-information-header>
                                    <slot name="more-information-header"></slot>
                                </template>
                                <template #more-information-fieldset>
                                    <slot name="more-information-fieldset"></slot>
                                </template>
                            </primary-signature-prompt>
                        </div>
                        <hr>

                        <terms-show :quote-key="quoteKey"></terms-show>

                        <powered-by-salesbyte class="mt-5"></powered-by-salesbyte>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card {
    cursor: pointer;
}

.product-thumbnails {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.product-thumbnail {
    max-height: 3rem;

    object-fit: contain;
    object-position: center center;

}

</style>

<script>
import axios from 'axios';

export default {
    props: {
        appUrl: String,
        quoteKey: String,
        quoteProducts: Array,
        quoteEsignDesc: {
            type: String,
            required: true
        },
        initiallySelected: {
            type: Number,
            default: 0
        },
        signComponentName: {
            type: String,
            required: true
        },
        signComponentSigneeName: {type: String, default: null},
        signComponentSignatureButtonLabel: {type: String, required: true},
        signComponentItemDescription: {type: String, default: null},
        productsPaymentsMethod: {type: String, default: null},
        productsPaymentsDinteroProfileId: {type: String, default: null},
        googleAdsConversionEventTargets: {type: Array, required: true},
        metaAdsConversionPixelIds: {type: Array, required: true},
        billectaBankAccountRetrieval: {type: Object, required: false, default: null},
    },
    data() {
        return {
            userSelected: undefined,
            userAssignedPriceForSelected: null,
            isEditingUserAssignedPrice: false,
            isSubmittingUserAssignedPrice: false,
            userAssignedPriceErrored: false,
            isHidden: true
        };
    },
    watch: {
        selected: {
            handler() {
                this.userAssignedPriceForSelected = null;
                this.isEditingUserAssignedPrice = this.quoteProducts[this.selected].allows_user_assigned_price && !this.quoteProducts[this.selected].user_assigned_price;
            },
            immediate: true
        }
    },
    mounted() {
        window.jQuery(this.$refs["modal"]).on('hidden.bs.modal', () => this.isHidden = true);
        window.jQuery(this.$refs["modal"]).on('shown.bs.modal', () => this.isHidden = false);
    },
    computed: {
        selected: function () {
            return this.userSelected !== undefined ? this.userSelected : this.initiallySelected;
        },
        googleAdsConversion() {
            return {
                event_targets: this.googleAdsConversionEventTargets,
                value: this.quoteProducts[this.selected].price_including_vat_raw,
                currency: this.quoteProducts[this.selected].currency
            };
        },
        metaAdsConversion() {
            return {
                pixel_ids: this.metaAdsConversionPixelIds,
                value: this.quoteProducts[this.selected].price_including_vat_raw,
                currency: this.quoteProducts[this.selected].currency
            };
        }
    },
    methods: {
        openModal(modalId) {
            window.jQuery(this.$refs["modal"]).one('hidden.bs.modal', () => window.jQuery(modalId).modal('show'));
            window.jQuery(this.$refs["modal"]).modal('hide');
        },
        async onSubmitUserAssignedPrice() {
            this.isSubmittingUserAssignedPrice = true;
            this.userAssignedPriceErrored = false;

            try {
                const quoteProduct = this.quoteProducts[this.selected];
                if (this.userAssignedPriceForSelected < quoteProduct.minimum_price_including_vat_raw){
                    this.userAssignedPriceErrored = true;
                    this.isSubmittingUserAssignedPrice = false;
                    return;
                }

                const quoteProductId = quoteProduct.id;
                const url = this.route('quote.products.user_assigned_price.store', [this.quoteKey, quoteProductId]);
                const response = await axios.post(url, {
                    price: this.userAssignedPriceForSelected
                });
                this.$emit('updatedQuoteProducts', response.data);
                this.isEditingUserAssignedPrice = false;
            } finally {
                this.isSubmittingUserAssignedPrice = false;
            }
        }
    }
}
</script>
