<template>
    <div>
        <p v-if="hitRate !== undefined">{{hitRateFormatted}}</p>
        <div v-else class="spinner-border" role="status"><span class="sr-only">Laddar</span></div>
    </div>
</template>

<script>
let numberFormat = Intl.NumberFormat('sv-SE', { style: 'percent' });
export default {
    props: {
        userId: { required: false, default: null },
        days: { required: true, type: Number }
    },
    data() {
        return {
            hitRate: undefined
        }
    },
    computed: {
        hitRateFormatted() {
            if (this.hitRate === undefined) { return };

            return numberFormat.format(this.hitRate);
        }
    },
    async mounted() {
        await this.loadData();
    },
    watch: {
        userId: 'loadData',
        days: 'loadData'
    },
    methods: {
        async loadData() {
            const url = `/stats/hit_rate`;
            const parameters = {
                user_id: this.userId,
                days: this.days
            };
            const resp = await axios.get(url, {params: parameters});
            this.hitRate = resp.data.hit_rate;
        }
    }
}
</script>

<style scoped>
    p {
        font-size: 1.5em;
    }
</style>
