<template>
  <div>
    <div class="floating-action-buttons-container">
      <quote-chats-action-button
        :seller-avatar-url="quoteChatsSellerAvatarUrl"
        :filter-enabled="quoteChatsFilterEnabled"
        :is-open="openPanel === 'quote-chats'"
        @open="openPanel = 'quote-chats'"
        @close="openPanel = null"
      ></quote-chats-action-button>

      <hr>

      <notifications-action-button
        :is-open="openPanel === 'notifications'"
        @open="openPanel = 'notifications'"
        @close="openPanel = null"
      ></notifications-action-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.floating-action-buttons-container {
  position: fixed;
  z-index: 1000;
  top: 50%;
  right: 0;

  display: flex;

  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg) translateY(50%);

  hr {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
  }

  &::v-deep a {
    display: block;
    padding: 1em 1em;
    height: 100%;
  }
}
</style>

<script>
const SESSION_STORAGE_KEY_OPEN_PANEL = 'floating-action-buttons-open-panel';

export default {
  props: {
    quoteChatsSellerAvatarUrl: { type: String, default: null },
    quoteChatsFilterEnabled: { type: Boolean, default: false }
  },
  data() {
    return {
      openPanel: sessionStorage.getItem(SESSION_STORAGE_KEY_OPEN_PANEL)
    }
  },
  watch: {
    openPanel() {
      sessionStorage.setItem(SESSION_STORAGE_KEY_OPEN_PANEL, this.openPanel);
    }
  }
}
</script>
