<template>
  <fieldset>
    <div class="mb-3">
      <div class="input-group mb-1">
        <div class="input-group-prepend">
          <div class="input-group-text">Visning</div>
        </div>
        <select class="custom-select" required v-model="mode" @change="onUpdate">
          <option value="hidden">Dold</option>
          <option value="inline">Direkt på kundsidan</option>
          <option value="popup">Länk till popup på kundsidan</option>
        </select>
      </div>

      <p class="small">
        <code>Dold</code> används enbart för Mötesbokning
      </p>
    </div>

    <div class="input-group mb-1">
      <div class="input-group-prepend">
        <div class="input-group-text">Bokningslänk</div>
      </div>
      <input type="url" class="form-control" required pattern="^https://calendly.com/.+" v-model="schedulingUrl" @input="onUpdate">
    </div>
    <p class="small mb-3">
      T.ex. <code>https://calendly.com/jane-doe</code>. Kan även sättas på varje säljares profil.
    </p>
  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      schedulingUrl: null,
      mode: null
    }
  },
  computed: {
    serializedValue() {
      return {
        scheduling_url: this.schedulingUrl,
        mode: this.mode,
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.schedulingUrl = this.value.scheduling_url;
          this.mode = this.value.mode ?? 'inline';
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
