const updateActionLogEventVisibility = (filterNode) => {
    const value = filterNode.value;
    const eventNodeContainer = document.querySelector(filterNode.dataset.target);
    if (!eventNodeContainer) { return; }

    eventNodeContainer.querySelectorAll('.action-log-event').forEach((eventNode) => {
        if (value == "_all" || eventNode.dataset.actionLogPriority == value) {
            eventNode.style.display = 'block';
        } else {
            eventNode.style.display = 'none';
        }
    });
}

export default () => {
    const actionLogPriorityFilter = document.querySelector('select.action-log-priority-filter')
    if (!actionLogPriorityFilter) { return; }
    updateActionLogEventVisibility(actionLogPriorityFilter);

    window.jQuery(actionLogPriorityFilter).on('change', (ev) => {
        updateActionLogEventVisibility(ev.target);
    });
}
