<template>
<div>
  <template v-if="initialized && enabled">
    <h6 :class="printable ? 'text-left' : 'text-center'"><strong>{{header}}</strong></h6>

    <div class="mb-3" :class="printable ? 'text-left' : 'text-center'">
      <i v-if="printable" class="fal fa-check-square"></i>
      <span v-html="body"></span>
    </div>

    <div v-if="printable" class="row">
      <a v-for="(link, idx) in links" :key="idx" class="col-12 mt-3" :href="link.url" target="_blank">
        <strong>{{link.title}}</strong><br>
        {{link.url}}
      </a>
    </div>
    <div v-else class="row">
      <a v-for="(link, idx) in links" :key="idx" class="col-6 mt-3 text-center" :href="link.url" target="_blank">
        {{link.title}}
      </a>
    </div>
  </template>
  <template v-else-if="!initialized">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">{{$t('general.loading')}}...</span>
    </div>
  </template>
</div>
</template>

<style lang="scss" scoped>
a {
  color: black;
  text-decoration: underline;
}
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    quoteKey: { type: String },
    printable: { type: Boolean, default: false }
  },
  data() {
    return {
      enabled: undefined,
      header: undefined,
      body: undefined,
      links: undefined
    };
  },
  computed: {
    initialized() {
      return this.enabled != undefined;
    }
  },
  async mounted() {
    try {
      let response = await axios.get(`/quote/${this.quoteKey}/terms`);
      const terms = response.data;
      this.enabled = true;
      this.header = terms.header;
      this.body = terms.body;
      this.links = terms.links;
    } catch (err) {
      if (!err.response || err.response.status !== 404) { throw err; }

      this.enabled = false;
    }
  }
}
</script>
