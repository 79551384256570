import axios from 'axios';

async function postActionLog(quoteKey, type, description = "") {
    try {
        await axios.post(`/quote/${quoteKey}/logs`, { type, description })
    } catch (error) {
        console.warn("Failed to log action", error);
    }
}

export async function logQuoteDocumentOpen(quoteKey, name) {
    await postActionLog(quoteKey, 'quote_document_open', name);
}

export async function logQuoteOptionExtraDocumentOpen(quoteKey, name) {
    await postActionLog(quoteKey, 'quote_option_extra_document_open', name);
}

export async function logQuoteSignatureViewOpen(quoteKey) {
    await postActionLog(quoteKey, 'quote_signature_view_open');
}

export async function logQuoteDocumentSignatureViewOpen(quoteKey) {
    await postActionLog(quoteKey, 'quote_document_signature_view_open');
}

export async function logReadMoreOpen(quoteKey, title) {
    await postActionLog(quoteKey, 'read_more_open', title);
}
