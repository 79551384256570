<template>
    <div>
        <p v-if="average !== undefined">{{averageFormatted}}</p>
        <div v-else class="spinner-border" role="status"><span class="sr-only">Laddar</span></div>
    </div>
</template>

<script>
export default {
    props: {
        userId: { required: false, default: null },
        days: { required: true, type: Number }
    },
    data() {
        return {
            average: undefined
        }
    },
    computed: {
        averageFormatted() {
            if (!this.average) { return "-"; };

            let result = [];

            let days = Math.floor(this.average / 86400);
            if (days > 0) {
                result.push(`${days}d`);
            }

            let hours = Math.floor((this.average % 86400) / 3600);
            if (result.length > 0 || hours > 0) {
                result.push(`${hours}t`);
            }

            let minutes = Math.floor((this.average % 86400 % 3600) / 60);
            if (result.length > 0 || minutes > 0) {
                result.push(`${minutes}m`);
            }

            //let seconds = Math.floor(this.average % 86400 % 3600 % 60);
            //result.push(`${seconds}s`);

            return result.join(", ");
        }
    },
    async mounted() {
        await this.loadData();
    },
    watch: {
        userId: 'loadData',
        days: 'loadData'
    },
    methods: {
        async loadData() {
            const url = `/stats/time_to_sign_average`;
            const parameters = {
                user_id: this.userId,
                days: this.days
            };
            const resp = await axios.get(url, {params: parameters});
            this.average = resp.data.average;
        }
    }
}
</script>

<style scoped>
    p {
        font-size: 1.5em;
    }
</style>
