<template>
    <div>
        <p v-if="amount !== undefined">{{amountFormatted}}</p>
        <div v-else class="spinner-border" role="status"><span class="sr-only">Laddar</span></div>
    </div>
</template>

<script>
let numberFormat = Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});
export default {
    props: {
        userId: { required: false, default: null },
        period: { required: true }
    },
    data() {
        return {
            amount: undefined
        }
    },
    computed: {
        amountFormatted() {
            if (this.amount === undefined) { return };

            return numberFormat.format(this.amount);
        }
    },
    async mounted() {
        await this.loadData();
    },
    watch: {
        userId: 'loadData',
        period: 'loadData'
    },
    methods: {
        async loadData() {
            const url = `/stats/closed_amount`;
            const parameters = {
                user_id: this.userId,
                period: this.period
            };
            const resp = await axios.get(url, {params: parameters});
            this.amount = resp.data.amount;
        }
    }
}
</script>

<style scoped>
    p {
        font-size: 1.5em;
    }
</style>
