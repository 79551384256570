<template>
  <div>
    <div class="cropimage-wrapper">
      <img ref="cropimage" :src="imageBase64String" />
    </div>

    <div class="d-flex justify-content-start align-items-center mx-2 my-3">
      <button v-on:click.prevent="finishCropping()" class="btn btn-sm btn-primary ml-2">Färdig</button>
      <button v-on:click.prevent="abortCropping()" class="btn btn-sm btn-secondary ml-2">Avbryt</button>
    </div>
  </div>
</template>
<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { nextTick } from 'vue'

export default {
  props: {
    imageBase64String: {
      type: String,
      default: ''
    },
    aspectRatio: {
      type: Number,
      default: NaN
    }
  },
  data(){
    return {
      cropper: null,
      cropping: {
        x: null,
        y: null,
        width: null,
        height: null
      }
    }
  },
  methods: {
    finishCropping(){
      this.$emit('finishCropping', this.cropper.getCroppedCanvas().toDataURL());
    },
    abortCropping(){
      this.$emit('abortCropping');
    },
    setImage(){
      if (this.cropper) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(this.$refs.cropimage, {
        aspectRatio: this.aspectRatio,
        viewMode: 2,
        crop: (event) => {
          this.cropping.x = event.detail.x;
          this.cropping.y = event.detail.y;
          this.cropping.width = event.detail.width;
          this.cropping.height = event.detail.height;
        },
      });
    }
  },
  mounted() {
    this.setImage()
  },
  watch: {
    imageBase64String: 'setImage',
    aspectRatio: 'setImage',
    cropping: {
      deep: true,
      handler: function() {
        this.$emit('updateCropping', this.cropping)
      }
    }
  }
}
</script>
<style scoped>
  div.cropimage-wrapper {
    display: inline-block;

    img {
      max-height: 55vh;
      height: 100%;
      width: auto;
    }
  }
</style>
