<template>
    <div v-if="shouldCollectMoreInformation && !collectedMoreInformation">
        <div v-if="!collectingMoreInformation" class="text-center">
            <button
                type="button"
                class="signatureComponentSignButton btn btn-secondary mt-3"
                @click.prevent="collectingMoreInformation = true"
            >
                Gå vidare till
                {{ productsPaymentsMethod ? "köp" : "signering" }}
            </button>
        </div>
        <div v-else-if="billectaBankAccountRetrieval" class="mt-5">
            <b-tabs
                pills
                justified
                fill
                active-nav-item-class="active-pill-background"
                v-model="tabIndex"
            >
                <b-tab
                    title="Manuell"
                    :title-item-class="tabIndex !== 0 ? 'pill-background' : ''"
                >
                    <axios-form
                        id="more-information-form"
                        method="POST"
                        :action="route('quotes.storeCustomFields', quoteKey)"
                        @success="didStoreMoreInformation"
                        @failure="didFailToStoreMoreInformation"
                    >
                        <h5 class="mt-5 mb-3">
                            <slot name="more-information-header"></slot>
                        </h5>

                        <template v-if="productsPaymentsMethod">
                            <label :for="`${uuid}-signee_name`">{{
                                $t("quotePayment.product.name_label")
                            }}</label>
                            <input
                                :id="`${uuid}-signee_name`"
                                type="text"
                                class="form-control mb-4"
                                required
                                v-model="ourSigneeName"
                                placeholder="John Doe"
                            />
                        </template>

                        <slot name="more-information-fieldset"></slot>

                        <div class="text-center">
                            <button
                                type="submit"
                                class="signatureComponentSignButton btn btn-secondary mt-3"
                            >
                                Spara och gå vidare
                            </button>
                        </div>
                    </axios-form>
                </b-tab>
                <b-tab
                    title="Hämta med BankID"
                    :title-item-class="tabIndex !== 1 ? 'pill-background' : ''"
                >
                    <billecta-accounts-retrieval
                        :quote-key="quoteKey"
                        @success="handleAccount"
                        @switchToManual="tabIndex = 0"
                    />
                </b-tab>
            </b-tabs>
        </div>

        <axios-form
            v-else
            method="POST"
            :action="route('quotes.storeCustomFields', quoteKey)"
            @success="didStoreMoreInformation"
            @failure="didFailToStoreMoreInformation"
        >
            <h5 class="mt-5 mb-3">
                <slot name="more-information-header"></slot>
            </h5>

            <template v-if="productsPaymentsMethod">
                <label :for="`${uuid}-signee_name`">{{
                    $t("quotePayment.product.name_label")
                }}</label>
                <input
                    :id="`${uuid}-signee_name`"
                    type="text"
                    class="form-control mb-4"
                    required
                    v-model="ourSigneeName"
                    placeholder="John Doe"
                />
            </template>

            <slot name="more-information-fieldset"></slot>

            <div class="text-center">
                <button
                    type="submit"
                    class="signatureComponentSignButton btn btn-secondary mt-3"
                >
                    Spara och gå vidare
                </button>
            </div>
        </axios-form>
    </div>

    <quote-product-payment
        v-else-if="productsPaymentsMethod"
        :quote-key="quoteKey"
        quote-type="products"
        :product-id="productsPaymentsProductId"
        :payment-method="productsPaymentsMethod"
        :dintero-profile-id="productsPaymentsDinteroProfileId"
        :signee-name-is-validated="collectedMoreInformation"
        :signee-name="ourSigneeName"
        :item-description="itemDescription"
    ></quote-product-payment>

    <quote-product-payment
        v-else-if="optionsPaymentsMethod"
        :quote-key="quoteKey"
        quote-type="options"
        :product-id="optionsPaymentsOptionId"
        :payment-method="optionsPaymentsMethod"
        :dintero-profile-id="optionsPaymentsDinteroProfileId"
        :signee-name-is-validated="collectedMoreInformation"
        :signee-name="ourSigneeName"
        :item-description="itemDescription"
    ></quote-product-payment>

    <component
        v-else
        :is="signComponentName"
        :item-id="itemId"
        :item-description="itemDescription"
        :app-url="appUrl"
        @signed="onSignature"
        :signee-name="ourSigneeName"
        :signature-button-label="signatureButtonLabel"
    >
    </component>
</template>

<style>
.active-pill-background {
    border: 2px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: white !important;
}

.pill-background {
    margin-left: -2px;
    margin-right: -2px;
    border: 2px solid var(--primary-color);
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    a {
        color: var(--primary-color) !important;
    }
}
</style>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import billectaBankAccountRetrieval from "./integrations/form/billecta-bank-account-retrieval.vue";

export default {
    // Props should be superset of with bankIdSign.vue
    props: {
        quoteKey: String,
        signComponentName: String,
        itemId: String,
        itemDescription: String,
        appUrl: String,
        redirectOnSignedUrl: String,
        signeeName: String,
        signatureButtonLabel: String,
        productsPaymentsMethod: { type: String, default: null },
        productsPaymentsDinteroProfileId: { type: String, default: null },
        productsPaymentsProductId: { type: Number, default: null },
        optionsPaymentsMethod: { type: String, default: null },
        optionsPaymentsDinteroProfileId: { type: String, default: null },
        optionsPaymentsOptionId: { type: Number, default: null },
        metaAdsConversion: { type: Object, required: true },
        googleAdsConversion: { type: Object, required: true },
        billectaBankAccountRetrieval: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            uuid: uuidv4(),
            collectingMoreInformation: false,
            collectedMoreInformation: false,
            ourSigneeName: this.signeeName,
            tabIndex: 0,
        };
    },
    computed: {
        shouldCollectMoreInformation() {
            return !!this.$slots["more-information-fieldset"];
        },
    },
    mounted() {
        if (
            this.billectaBankAccountRetrieval &&
            "default_mode" in this.billectaBankAccountRetrieval
        ) {
            this.tabIndex = this.billectaBankAccountRetrieval.default_mode
                ? 1
                : 0;
        }
    },
    methods: {
        didStoreMoreInformation() {
            this.collectedMoreInformation = true;
        },
        didFailToStoreMoreInformation() {
            alert("Något gick fel, var god försök igen!");
        },
        onSignature() {
            this.onSignatureMetaAds();
        },
        onSignatureMetaAds() {
            if (this.metaAdsConversion.pixel_ids.length === 0) {
                this.onSignatureGoogleAds();
                return;
            }

            this.metaAdsConversion.pixel_ids.forEach((pixelId) => {
                window.metaAdsConversionTracking(
                    pixelId,
                    this.quoteKey,
                    this.googleAdsConversion.value,
                    this.googleAdsConversion.currency
                );
            });

            this.onSignatureGoogleAds();
        },
        onSignatureGoogleAds() {
            if (this.googleAdsConversion.event_targets.length === 0) {
                this.redirectAfterSignature();
                return;
            }

            const gtagLoaded = window.gtag_loaded;

            let remainingCallbacks =
                this.googleAdsConversion.event_targets.length;
            const callback = gtagLoaded
                ? () => {
                      remainingCallbacks -= 1;
                      if (remainingCallbacks === 0) {
                          this.redirectAfterSignature();
                      }
                  }
                : () => {};

            this.googleAdsConversion.event_targets.forEach((eventTarget) => {
                window.googleAdsConversionTracking(
                    eventTarget,
                    this.quoteKey,
                    this.googleAdsConversion.value,
                    this.googleAdsConversion.currency,
                    callback
                );
            });

            if (!gtagLoaded) {
                this.redirectAfterSignature();
            }
        },
        redirectAfterSignature() {
            if (
                this.metaAdsConversion.pixel_ids.length > 0 &&
                this.googleAdsConversion.event_targets.length > 0
            ) {
                setTimeout(() => {
                    window.location.href = this.redirectOnSignedUrl;
                }, 250);
            } else {
                window.location.href = this.redirectOnSignedUrl;
            }
        },
        async handleAccount(account) {
            const response = await axios.get(
                route(
                    "quotes.billecta.accountsRetrieval.getCustomFieldNames",
                    this.quoteKey
                )
            );

            const fields = ["bank", "clearing", "number"];
            const data = {};
            for (const field of fields) {
                data[response.data[field]] = account[field];
            }

            let form = document.querySelector(
                `form[id="more-information-form"]`
            );
            this.tabIndex = 0;
            for (const prop in data) {
                let elem = form.querySelector(`input[name="${prop}"]`);
                if (!elem) {
                    console.warn("Elem not found");
                    this.didFailToStoreMoreInformation();
                    continue;
                }

                elem.value = data[prop];
            }
        },
    },
};
</script>
