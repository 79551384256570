<template>
  <div>
    <slot></slot>
    <input :ref="$attrs.name" class="form-control"  @blur="search" @focusin="clearErrors" v-bind="$attrs" v-model="val">
    <a class="text-danger" v-if="duplicatesFound" :href="duplicateSearchUrl">Kontakten finns redan <i class="fal fa-search"></i></a>
    <small class="text-danger" v-if="telephone && invalidPhoneNumber">Telefonnumret är ogiltigt</small>
  </div>
</template>

<style scoped lang="css">
</style>

<script>
import 'intl-tel-input/build/css/intlTelInput.css'

export default {
  name: "duplicate-validated-input",
  inheritAttrs: false,
  props: {
    field: { type: String, required:false},
    value: String,
    telephone: { type: Boolean, required: false, default: false}
  },
  data() {
    return {
      val: null,
      duplicatesFound: false,
      telInput: null,
      invalidPhoneNumber: false,
    }
  },
  mounted(){
    const input = document.querySelector(`input[name="${this.$attrs.name}"]`);
    if (this.telephone){
      this.telInput = window.IntlTelInput(input,
        {
          initialCountry: 'SE',
          separateDialCode: true,
          hiddenInput: (telInputName) => {
            return {
              phone: telInputName,
              country: `${telInputName}_country`,
            };
          }
        }
      );

      if (this.value){
        this.telInput.setNumber(this.value);
      }

      return;
    }

    this.val = this.value;
  },
  computed : {
    duplicateSearchUrl : function() {
      return route('admin.index', {q: this.telephone? this.telInput.getNumber() : this.val});
    }
  },
  methods: {
    clearErrors: function (e){
      this.duplicatesFound = false;
      this.invalidPhoneNumber = false;
    },
    search: async function(e) {
      if(!e.target.value){
        return;
      }

      if (this.telephone && !this.telInput.isValidNumber()){
        this.invalidPhoneNumber = true;
        return;
      }
      const value = this.telephone? this.telInput.getNumber() : e.target.value;
      if (value === this.value){
        return;
      }

      const url = route('quotes.search', {field: this.field??this.$attrs.name, q: value});
      try {
        const response = await axios.get(url);
        this.duplicatesFound = response.data.length > 0;
      }catch (e) {
        console.log("Failed to search quotes");
        console.log(e);
      }
    }
  }
}
</script>
