const updateRequiredOr =  (currentInput) => {
    return (e) => {
        if (currentInput.value.trim() === '' && e.target.value.trim() !== '') {
            currentInput.removeAttribute('required');
            return;
        } else if (e.target.value.trim() === '' && currentInput.value !== '') {
            e.target.removeAttribute('required');
            return;
        }

        currentInput.setAttribute('required', 'required');
        e.target.setAttribute('required', 'required');
    }
};

const removeCallbacks = (currentInput) => {
    Array
        .from(document.querySelectorAll('input[data-required-or-input-name]'))
        .forEach(input => {
            if (input.getAttribute('data-required-or-input-name') !== currentInput.getAttribute('name')){
                return;
            }
            const blurEvent = new Event('blur', { bubbles: true, cancelable: true });
            input.dispatchEvent(blurEvent);
            input.removeEventListener('blur', updateRequiredOr(currentInput));
        });
}
const registerCallbacks = (currentInput) => {
    Array
        .from(document.querySelectorAll('input[data-required-or-input-name]'))
        .forEach(input => {
            if (input.getAttribute('data-required-or-input-name') !== currentInput.getAttribute('name')) {
                return;
            }
            input.addEventListener('blur', updateRequiredOr(currentInput));

            const blurEvent = new Event('blur', { bubbles: true, cancelable: true });
            input.dispatchEvent(blurEvent);
        });
}

const updateRequired = (input, targets, values) => {
    const checked = targets.find((t) => t.checked);
    const currentValue = checked ? checked.value : null;

    if (values.indexOf(currentValue) !== -1) {
        input.setAttribute('required', 'required');
        if (currentValue === 'auto'){
            registerCallbacks(input);
        }
    } else {
        input.removeAttribute('required');
        if (currentValue === 'auto'){
            removeCallbacks(input)
        }
    }
}

export const requireIfInputSetup = (input) => {
    const inputName = input.getAttribute('data-required-if-input-name');
    const targets = Array.from(document.querySelectorAll(`input[name="${inputName}"]`));
    if (targets.length === 0) { return; }
    const values = input.getAttribute('data-required-if-input-values')
        .split(",")
        .map((v) => v.trim());

    updateRequired(input, targets, values)
    window.jQuery(targets).on('input', (ev) => updateRequired(input, targets, values));
}

export default () => {
    document.querySelectorAll('input[data-required-if-input-name]')
        .forEach((input) => requireIfInputSetup(input));
}
