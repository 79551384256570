<template>
    <div>
        <div v-if="isLocalhost" class="my-5">
            <h6>DEBUG</h6>
            <p>Item ID: {{ itemId }}</p>
            <p>Item Description: {{ itemDescription }}</p>
        </div>

        <button
            v-if="!signing"
            type="button"
            class="signatureComponentSignButton btn btn-secondary mt-3"
            @click.prevent="sign"
        >
            {{ signatureButtonLabel }}
        </button>

        <button
            v-else-if="!signatureRequest"
            type="button"
            class="signatureComponentSignButton btn btn-primary mt-3 wait"
        >
            <div class="spinner-border spinner-border-sm mr-2"></div>
        </button>

        <bank-id-v2-prompt
            v-else
            class="mt-3"
            @completed="onCompleted"
            @failed="onFailed"
            :request="signatureRequest"
        ></bank-id-v2-prompt>
    </div>
</template>

<style lang="scss" scoped>
button {
    width: 100%;

    &.wait .spinner-border {
        vertical-align: middle;
    }
}
</style>

<script>
import axios from "axios";

export default {
    // Props should be in sync with onScreenSign.vue
    props: {
        itemId: String,
        itemDescription: String,
        appUrl: String,
        redirectOnSignedUrl: String,
        signeeName: { type: String, default: null },
        signatureButtonLabel: String,
        autostart: { type: Boolean, default: false },
    },
    data() {
        return {
            signing: false,
            signatureRequest: null,
        };
    },
    computed: {
        isLocalhost() {
            return document.location.hostname == "localhost";
        },
    },
    async mounted() {
        if (this.autostart) {
            await this.sign();
        }
    },
    methods: {
        sign: async function () {
            if (this.signing) {
                return;
            }
            this.signing = true;

            try {
                const response = await axios.post(
                    route("bankid_signature_request.store"),
                    {
                        ref_id: this.itemId,
                        message: this.itemDescription,
                    }
                );
                this.signatureRequest = response.data;
            } catch (error) {
                this.signing = false;
                console.warn(error);
            }
        },
        onCompleted: async function () {
            if (this.redirectOnSignedUrl) {
                window.location.replace(this.redirectOnSignedUrl);
            } else {
                this.$emit("signed");
            }
        },
        onFailed: async function () {
            this.signing = false;
            this.signatureRequest = null;
        },
    },
};
</script>
