<template>
    <div class="row">
        <div class="col-md-6 order-md-2 mb-4 mb-md-0">
            <integration-form
                v-if="editingId"
                :id="editingId"
                @created="onCreated"
                @updated="onUpdated"
                @cancelled="onCancelled"
            />
            <b-button v-else variant="primary" @click.prevent="create"
                >Lägg till integration</b-button
            >
        </div>
        <div class="col-md-6 order-md-1">
            <ul v-if="loaded" class="list-unstyled">
                <li
                    v-for="integration in sortedIntegrations"
                    :key="integration.id"
                    class="mb-4"
                >
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div class="mb-2">
                            <h5 class="mb-0">
                                {{ integration.name }}
                            </h5>
                            <p
                                v-if="
                                    integration.associated_quote_profiles
                                        .length > 0
                                "
                                class="small mb-0"
                            >
                                {{
                                    integration.associated_quote_profiles
                                        .map((qp) => qp.name)
                                        .join(", ")
                                }}
                            </p>
                        </div>
                        <div v-if="!editingId">
                            <b-button
                                size="sm"
                                @click.prevent="edit(integration)"
                                >Redigera</b-button
                            >
                            <b-button
                                size="sm"
                                variant="danger"
                                @click.prevent="remove(integration)"
                                >Ta bort</b-button
                            >
                        </div>
                    </div>
                </li>
            </ul>
            <b-spinner v-else></b-spinner>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import IntegrationForm, { Types as IntegrationTypes } from "./form.vue";

export default {
    components: {
        IntegrationForm,
    },
    data() {
        return {
            integrations: null,
            loaded: false,
            editingId: null,
        };
    },
    computed: {
        sortedIntegrations() {
            return this.integrations.sort((a, b) =>
                a.name.localeCompare(b.name)
            );
        },
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            const response = await axios.get(route("integrations.index"));
            this.integrations = response.data.data;
            this.loaded = true;
        },
        formattedType(type) {
            const t = IntegrationTypes[type];
            return t ? t.label : "- Okänd (inaktiverad) -";
        },
        create() {
            this.editingId = "new";
        },
        edit(integration) {
            this.editingId = integration.id;
        },
        async remove(integration) {
            if (confirm("Är du säker?")) {
                await axios.delete(
                    route("integrations.destroy", integration.id)
                );
                const idx = this.integrations.findIndex(
                    (i) => i.id === integration.id
                );
                this.integrations.splice(idx, 1);
            }
        },
        onCreated(integration) {
            this.integrations.push(integration);
            this.editingId = null;
        },
        onUpdated(integration) {
            const idx = this.integrations.findIndex(
                (i) => i.id === integration.id
            );
            if (idx !== -1) {
                this.integrations.splice(idx, 1, integration);
            } else {
                this.integrations.push(integration);
            }
            this.editingId = null;
        },
        onCancelled() {
            this.editingId = null;
        },
    },
};
</script>
