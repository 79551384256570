<template>
  <div>
    <div class="gallery">
      <a
        href="#"
        data-toggle="modal"
        :data-target="'#' + readmoreid"
        style="color: black; outline: none"
      >
        <img
          :src="readmoreimg"
          width="100%"
          style="border-radius: 5px"
          alt=""
        />
        <span class="text-center" style="font-size: 1.3em;" v-html="readmoretitle"></span>
      </a>
    </div>

    <!-- Modal Read More 1 -->
    <div
      class="modal fade"
      :id="readmoreid"
      tabindex="-1"
      role="dialog"
      aria-labelledby="readmoremodal1Label"
      aria-hidden="true"
      style="padding-right: 0px !important"
      ref="modal"
    >
      <div class="modal-dialog modal-full" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <img
                  :src="readmoreimg"
                  width="100%"
                  style="border-radius: 5px"
                  alt=""
                />
              </div>
              <div class="col-md-6">
                <br />
                <h3 v-html="readmoretitle"></h3>
                <p v-html="readmorebody"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["quoteKey", "readmoretitle", "readmoreimg", "readmorebody", "readmoreid"],
  mounted() {
    const thisModal = window.jQuery(this.$refs["modal"]);
    thisModal.on('shown.bs.modal', () => this.$actionLog.logReadMoreOpen(this.quoteKey, this.readmoretitle));
  }
};
</script>

<style>
</style>
