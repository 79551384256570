<template>
    <form @submit.prevent="sign" class="external-sign mt-4">
        <div v-if="isLocalhost" class="my-5">
            <h6>DEBUG</h6>
            <p>Item ID: {{itemId}}</p>
        </div>

        <p v-if="itemDescription" class="my-3">
          <small class="text-muted font-italic" v-html="itemDescriptionWithBr"></small>
        </p>

        <label :for="`${uuid}-name`">{{$t('sign.external.name_label')}}</label>
        <input :id="`${uuid}-name`" type="text" class="form-control mb-4" required v-model="name" placeholder="John Doe">

        <div v-if="!authenticating">
            <button type="submit" class="signatureComponentSignButton btn btn-secondary mb-3" :disabled="!hasData">
                <i class="fas fa-paper-plane mr-1"></i>
            </button>
        </div>
        <button v-else type="button" class="signatureComponentSignButton btn btn-primary mt-3 wait">
            <div class="spinner-border spinner-border-sm mr-2"></div> <i class="fas fa-paper-plane"></i>
        </button>
    </form>
</template>

<style lang="scss" scoped>
button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 33%;

    &.wait .spinner-border {
        vertical-align: middle;
    }
}
</style>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
    // Props should be in sync with bankIdSign.vue
    props: {
        itemId: String,
        itemDescription: String,
        appUrl: String,
        redirectOnSignedUrl: String,
        signeeName: { type: String, default: null }
    },
    data() {
        return {
            uuid: uuidv4(),
            name: this.signeeName,
            authenticating: false,
            hasData: !!this.signeeName
        };
    },
    computed: {
        isLocalhost() {
            return document.location.hostname == "localhost";
        },
        itemDescriptionWithBr() {
            return this.itemDescription ? this.itemDescription.replaceAll("\n", "<br>") : null;
        }
    },
    watch: {
        name() { this.updateHasData(); }
    },
    methods: {
        sign: async function() {
            try {
                this.authenticating = true;
                await axios.post(`/external_signatures`, {
                    item_id: this.itemId,
                    item_description: this.itemDescription,
                    name: this.name
                })

                if (this.redirectOnSignedUrl) {
                    window.location.replace(this.redirectOnSignedUrl);
                } else {
                    this.$emit('signed');
                }
            } catch (error) {
                this.authenticating = false;
                console.warn("Failed to store signature..", error);
            }
        },
        updateHasData() {
            this.hasData = !!this.name;
        }
    }

}
</script>
