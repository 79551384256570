<template>
    <div>
        <flicking-carousel
            v-if="!disableProductOptionCarousel"
            :panels="quoteProducts"
            @panelClick="onPanelClick($event.idx)"
        >
            <template #panel="{ panel: quoteProduct }">
                <div
                    v-if="!quoteProduct.is_invalid"
                    class="cursor-pointer"
                    role="button"
                >
                    <div class="quote-product-image-container px-2">
                        <img
                            :src="quoteProduct.products[0].image_url"
                            :alt="quoteProduct.products[0].name"
                            class="product-featured my-3"
                            draggable="false"
                        />
                        <i
                            class="quote-product-image-icon fa-solid fa-hand-back-point-up"
                        ></i>
                    </div>

                    <div
                        v-if="
                            quoteProduct.products.filter(
                                (prod) => prod.id !== 'discount_product'
                            ).length > 1
                        "
                        class="px-3"
                    >
                        <div class="quote-product-small-images-container">
                            <img
                                v-for="(
                                    product, productIdx
                                ) in quoteProduct.products
                                    .filter(
                                        (prod) => prod.id !== 'discount_product'
                                    )
                                    .slice(1, 6)"
                                :key="productIdx"
                                :src="product.image_url"
                                :alt="product.name"
                                class="product-small"
                                draggable="false"
                            />
                        </div>

                        <h2>{{ quoteProduct.name }}</h2>

                        <p
                            v-if="
                                !quoteProduct.allows_user_assigned_price ||
                                quoteProduct.user_assigned_price
                            "
                        >
                            <span
                                class="d-block"
                                style="font-size: 1.5em"
                                v-html="
                                    $t('quoteProducts.view.price_html', {
                                        price: quoteProduct.price,
                                        currency: quoteProduct.currency_name,
                                        vat_suffix: quoteProduct.vat_suffix,
                                    })
                                "
                            ></span>
                            <small
                                v-if="
                                    quoteProduct.original_price !=
                                    quoteProduct.price
                                "
                                class="d-block"
                            >
                                <template
                                    v-if="
                                        quoteProduct.discount_valid_for_days !==
                                        null
                                    "
                                >
                                    {{
                                        $tc(
                                            "quoteProducts.view.discount_info",
                                            quoteProduct.discount_valid_for_days,
                                            {
                                                discount: quoteProduct.discount,
                                                currency:
                                                    quoteProduct.currency_name,
                                                vat_suffix: "",
                                                days_left:
                                                    quoteProduct.discount_valid_for_days,
                                            }
                                        )
                                    }}
                                </template>
                                <template v-else>
                                    {{
                                        $t(
                                            "quoteProducts.view.original_price",
                                            {
                                                price: quoteProduct.original_price,
                                                currency:
                                                    quoteProduct.currency_name,
                                                vat_suffix: "",
                                            }
                                        )
                                    }}
                                </template>
                            </small>
                        </p>
                    </div>
                    <div v-else class="px-3">
                        <h2>
                            {{
                                quoteProduct.products[0]
                                    .name_with_prompted_value
                            }}
                        </h2>
                        <p v-if="quoteProduct.products[0].description_title">
                            {{ quoteProduct.products[0].description_title }}
                        </p>
                        <dl
                            v-if="
                                quoteProduct.products[0].specification &&
                                quoteProduct.products[0].specification.length >
                                    0
                            "
                            class="product-specification"
                        >
                            <template
                                v-for="(spec, idx) in quoteProduct.products[0]
                                    .specification"
                            >
                                <dt :key="`dt-${idx}`">{{ spec.label }}</dt>
                                <dd :key="`dd-${idx}`">{{ spec.value }}</dd>
                            </template>
                        </dl>

                        <p
                            v-if="
                                !quoteProduct.allows_user_assigned_price ||
                                quoteProduct.user_assigned_price
                            "
                        >
                            <template
                                v-if="quoteProduct.products[0].amount == 1"
                            >
                                <span class="d-block" style="font-size: 1.5em">
                                    {{
                                        $t(
                                            "products.unit_with_price." +
                                                quoteProduct.products[0]
                                                    .price_unit,
                                            {
                                                price: quoteProduct.price,
                                                currency:
                                                    quoteProduct.currency_name,
                                                vat_suffix:
                                                    quoteProduct.vat_suffix,
                                            }
                                        )
                                    }}
                                </span>
                                <small
                                    v-if="
                                        quoteProduct.original_price !=
                                        quoteProduct.price
                                    "
                                    class="d-block"
                                >
                                    <template
                                        v-if="
                                            quoteProduct.discount_valid_for_days !==
                                            null
                                        "
                                    >
                                        {{
                                            $tc(
                                                "quoteProducts.view.discount_info",
                                                quoteProduct.discount_valid_for_days,
                                                {
                                                    discount:
                                                        quoteProduct.discount,
                                                    currency:
                                                        quoteProduct.currency_name,
                                                    vat_suffix: "",
                                                    days_left:
                                                        quoteProduct.discount_valid_for_days,
                                                }
                                            )
                                        }}
                                    </template>
                                    <template v-else>
                                        ({{
                                            $t(
                                                "quoteProducts.view.original_price_title"
                                            )
                                        }}:
                                        {{
                                            $t(
                                                "products.unit_with_price." +
                                                    quoteProduct.products[0]
                                                        .price_unit,
                                                {
                                                    price: quoteProduct.original_price,
                                                    currency:
                                                        quoteProduct.currency_name,
                                                    vat_suffix: "",
                                                }
                                            )
                                        }})
                                    </template>
                                </small>
                            </template>
                            <template v-else>
                                <span class="d-block" style="font-size: 1.5em">
                                    {{
                                        $t(
                                            "products.unit_with_amount_and_price." +
                                                quoteProduct.products[0]
                                                    .price_unit,
                                            {
                                                amount: quoteProduct.products[0]
                                                    .amount,
                                                price: quoteProduct.products[0]
                                                    .price,
                                                currency:
                                                    quoteProduct.products[0]
                                                        .currency_name,
                                                vat_suffix:
                                                    quoteProduct.vat_suffix,
                                            }
                                        )
                                    }}
                                </span>
                                <small
                                    v-if="
                                        quoteProduct.original_price !=
                                        quoteProduct.price
                                    "
                                    class="d-block"
                                >
                                    ({{
                                        $t("quoteProducts.view.discount_title")
                                    }}:
                                    {{
                                        $t("quoteProducts.view.discount", {
                                            discount: quoteProduct.discount,
                                            currency:
                                                quoteProduct.currency_name,
                                        })
                                    }})
                                </small>
                            </template>
                        </p>
                    </div>

                    <button type="button" class="mt-3 btn signbuttoninline">
                        Läs mer
                    </button>
                </div>
                <div v-else class="pt-3 px-3">
                    <h3>{{ quoteProduct.name }}</h3>
                    <p>{{ $t("quoteProducts.view.no_longer_available") }}</p>
                </div>
            </template>
        </flicking-carousel>

        <template v-for="(quoteProduct, idx) in quoteProducts">
            <details-modal
                v-if="!quoteProduct.is_invalid"
                :key="idx"
                :id="`products-modal-${quoteProduct.id}`"
                :quote-key="quoteKey"
                :quote-product="quoteProduct"
                :quote-products="quoteProducts"
                :quote-product-add-ons="quoteProductAddOns"
                :is-signed="isSigned"
                :quote-esign-button-txt="quoteEsignButtonTxt"
                :company-image-url="companyImageUrl"
                :signable="signable && !hasQuotePaymentOnHold"
                :hide-totals="hideTotals"
                :hide-individual-prices="hideIndividualPrices"
                @selectedForSigning="selectedQuoteProductIdx = idx"
                :amounts-editable="amountsEditable"
                @updatedQuoteProducts="quoteProducts = $event"
                @updatedQuoteProductsWithAddOns="
                    quoteProducts = $event[0];
                    quoteProductAddOns = $event[1];
                "
            ></details-modal>
        </template>
        <bank-id-modal
            id="bankid"
            :app-url="appUrl"
            :quote-key="quoteKey"
            :quote-products="quoteProducts"
            :initially-selected="selectedQuoteProductIdx"
            :quote-esign-desc="quoteEsignDesc"
            :sign-component-name="signComponentName"
            :sign-component-signee-name="signComponentSigneeName"
            :sign-component-signature-button-label="
                signComponentSignatureButtonLabel
            "
            :sign-component-item-description="signComponentItemDescription"
            :products-payments-method="productsPaymentsMethod"
            :products-payments-dintero-profile-id="
                productsPaymentsDinteroProfileId
            "
            :google-ads-conversion-event-targets="
                googleAdsConversionEventTargets
            "
            :meta-ads-conversion-pixel-ids="metaAdsConversionPixelIds"
            :billecta-bank-account-retrieval="billectaBankAccountRetrieval"
            @updatedQuoteProducts="quoteProducts = $event"
            v-if="
                signable &&
                !isSigned &&
                !hasQuotePaymentOnHold &&
                quoteProducts.some((v) => !v.is_invalid)
            "
        >
            <template #more-information-header
                ><slot name="more-information-header"></slot
            ></template>
            <template #more-information-fieldset
                ><slot name="more-information-fieldset"></slot
            ></template>
        </bank-id-modal>
    </div>
</template>

<style lang="scss" scoped>
a:focus {
    outline: none;
}

img.product-featured {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    object-position: center center;

    outline: none;
}

.quote-product-image-container {
    display: block;
    position: relative;
}

.quote-product-small-images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -8px;
    margin-bottom: 16px;

    img {
        flex: 1 1 0px;
        min-width: 0;
        max-width: 20%;

        object-fit: contain;

        &:nth-child(n + 2) {
            margin-left: 20px;
        }
    }
}

a.no-style {
    color: inherit;

    &:hover {
        text-decoration: inherit;
    }
}

.quote-product-image-icon {
    position: absolute;
    bottom: 25%;
    right: 25%;

    color: #c4c4c5;
    text-shadow: black 1px 0 10px;

    font-size: 55px;
    transform: rotate(-38deg) scale(0.8);
    opacity: 0.7;

    animation: pulse 2.68s 5 ease-in-out;
}

@keyframes pulse {
    0% {
        transform: rotate(-38deg) scale(0.8);
        opacity: 0.7;
    }
    50% {
        transform: rotate(-38deg) scale(1);
        opacity: 0.9;
    }
    100% {
        transform: rotate(-38deg) scale(0.8);
        opacity: 0.7;
    }
}

.product-specification {
    dt,
    dd {
        display: inline;
    }

    dt:nth-child(n + 2) {
        margin-left: 2em;
    }
}
</style>

<script>
import DetailsModal from "./modals/details.vue";
import BankIdModal from "./modals/bankid.vue";

export default {
    components: {
        DetailsModal,
        BankIdModal,
    },
    props: {
        quoteEsignDesc: String,
        quoteEsignButtonTxt: String,
        appUrl: String,
        quoteKey: String,
        initialQuoteProducts: Array,
        initialQuoteProductAddOns: Array,
        companyPdfviewUrl: String,
        companyImageUrl: String,
        isSigned: Boolean,
        signComponentName: {
            type: String,
            required: true,
        },
        signComponentSigneeName: { type: String, default: null },
        signComponentSignatureButtonLabel: { type: String, required: true },
        signComponentItemDescription: { type: String, default: null },
        signable: Boolean,
        hasQuotePaymentOnHold: Boolean,
        hideTotals: Boolean,
        hideIndividualPrices: { type: Boolean, default: false },
        disableProductOptionCarousel: { type: Boolean, default: false },
        productsPaymentsMethod: { type: String, default: null },
        productsPaymentsDinteroProfileId: { type: String, default: null },
        googleAdsConversionEventTargets: { type: Array, required: true },
        metaAdsConversionPixelIds: { type: Array, required: true },
        billectaBankAccountRetrieval: {
            type: Object,
            required: false,
            default: null,
        },
        amountsEditable: Boolean,
    },
    data() {
        return {
            selectedQuoteProductIdx: 0,
            quoteProducts: JSON.parse(
                JSON.stringify(this.initialQuoteProducts)
            ),
            quoteProductAddOns: JSON.parse(
                JSON.stringify(this.initialQuoteProductAddOns)
            ),
        };
    },
    methods: {
        onPanelClick(idx) {
            const quoteProduct = this.quoteProducts[idx];
            if (!quoteProduct.is_invalid) {
                $(`#products-modal-${quoteProduct.id}`).modal("show");
            }
        },
    },
};
</script>
