<template>
  <div class="d-flex justify-content-start" :class="senderIsSelf ? 'quote-chat-message quote-chat-message-self' : 'quote-chat-message quote-chat-message-other'">
    <img v-if="senderAvatar" class="quote-chat-message-avatar" :src="senderAvatar" :alt="senderName">
    <div v-else class="quote-chat-message-avatar d-flex justify-content-center align-items-center">
      <i class="fa-regular fa-user"></i>
    </div>

    <div class="quote-chat-message-box rounded p-2 w-100">
      <div class="d-flex justify-content-between align-items-start">
        <strong>{{senderName}}:</strong>
        <small v-if="formattedTimestamp">{{formattedTimestamp}}</small>
      </div>
      {{message}}
    </div>
  </div>
</template>
<style lang="scss" scoped>

.quote-chat-message {
  &.quote-chat-message-self {
    flex-direction: row-reverse;

    .quote-chat-message-avatar {
      margin: 2px 0 2px 0.5em;
    }
    .quote-chat-message-box {
      background: hsl(220, 51%, 90%);
    }
  }

  &.quote-chat-message-other {
    flex-direction: row;

    .quote-chat-message-avatar {
      margin: 2px 0.5em 0 2px;
    }
    .quote-chat-message-box {
      background: hsl(220, 6%, 90%);
    }
  }

  .quote-chat-message-avatar {
    width: 1.4em;
    height: 1.4em;

    border-radius: 50%;
    background-color: #efefef;

    i { font-size: 0.8em; }
  }

  .quote-chat-message-box {
    overflow-wrap: anywhere;
    white-space: pre-line;
  }
}
</style>
<script>
const DATETIME_FORMATTER_OLD = new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'short' });
const DATETIME_FORMATTER_TODAY = new Intl.DateTimeFormat('default', { timeStyle: 'short' });
export default {
  props: {
    senderName: String,
    senderAvatar: String,
    senderIsSelf: Boolean,
    message: String,
    timestamp: { type: Date, default: null },
  },
  computed: {
    formattedTimestamp() {
      if (!this.timestamp) {
        return null;
      }

      if (this.timestamp.toDateString() === new Date().toDateString()) {
        return DATETIME_FORMATTER_TODAY.format(this.timestamp);
      }

      return DATETIME_FORMATTER_OLD.format(this.timestamp);
    },
  },
}
</script>
