const updateDisabled = (checkbox, target) => {
    target.disabled = !checkbox.checked;
}

export default () => {
    window.jQuery('input[type=checkbox][data-checkbox-disables-form-field]').each((index, checkbox) => {
        const target = document.querySelector('input[name="' + checkbox.getAttribute("data-checkbox-disables-form-field") + '"]');
        if (!target) { return; }

        $(checkbox).on('input', (ev) => { updateDisabled(checkbox, target); });
        updateDisabled(checkbox, target);
    });
}
