<template>
    <fieldset>
        <p>OBS! Det är ej lämpligt att koppla fler än en integration av denna typ till en profil.</p>

        <template v-if="oauthDone">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Access Token</div>
                </div>
                <textarea type="text" class="form-control" required v-model="accessToken" @input="onUpdate" rows="5"
                          required>

        </textarea>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Refresh Token</div>
                </div>
                <input type="text" class="form-control" required v-model="refreshToken" @input="onUpdate" required>
            </div>

            <div class="mb-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Skapa kunder från visitkort</div>
                    </div>
                    <select class="custom-select" v-model="useCreateCustomers" @change="onUpdate" required>
                        <option :value="false">Nej</option>
                        <option :value="true">Ja</option>
                    </select>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">Skapa pdf-erbjudanden från Fortnox</div>
                    </div>
                    <select class="custom-select" v-model="usePdfOffers" @change="onUpdate" required>
                        <option :value="false">Nej</option>
                        <option :value="true">Ja</option>
                    </select>
                </div>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Fältnamn: Gatuadress </div>
                </div>
                <input type="text" class="form-control" required v-model="fieldDeliveryStreetAddress" @input="onUpdate">
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Fältnamn: Postnummer</div>
                </div>
                <input type="text" class="form-control" required v-model="fieldDeliveryZipCode" @input="onUpdate">
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Fältnamn: Ort</div>
                </div>
                <input type="text" class="form-control" required v-model="fieldDeliveryCity" @input="onUpdate">
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">Fältnamn: Land</div>
                </div>
                <input type="text" class="form-control" required v-model="fieldDeliveryCountry" @input="onUpdate">
            </div>
        </template>

        <oauth-auth-code-workflow v-else :initiator="route('integrations.fortnox.auth.initiate')"
                                  @success="handleOauthSuccess" @failure="handleOauthFailure"/>
    </fieldset>
</template>

<script>
import OauthAuthCodeWorkflow from "../oauth-auth-code-workflow.vue";

export default {
    components: {OauthAuthCodeWorkflow},
    emits: [
        'input'
    ],
    props: {
        value: {type: Object, required: true}
    },
    data() {
        return {
            accessToken: null,
            refreshToken: null,
            lastUpdated: null,
            useCreateCustomers: false,
            useOffers: false,
            usePdfOffers: false,
            useMarkOfferAsSent: false,
            fieldDeliveryStreetAddress: null ,
            fieldDeliveryZipCode: null,
            fieldDeliveryCity: null,
            fieldDeliveryCountry: null
        }
    },
    computed: {
        oauthDone() {
            return this.accessToken && this.refreshToken;
        },
        serializedValue() {
            return {
                access_token: this.accessToken,
                refresh_token: this.refreshToken,
                last_updated: this.lastUpdated,
                use_create_customers: this.useCreateCustomers,
                use_offers: this.useOffers,
                use_pdf_offers: this.usePdfOffers,
                use_mark_offer_as_sent: this.useMarkOfferAsSent,
                field_delivery_street_address: this.fieldDeliveryStreetAddress,
                field_delivery_zip_code: this.fieldDeliveryZipCode,
                field_delivery_city: this.fieldDeliveryCity,
                field_delivery_country: this.fieldDeliveryCountry,
            };
        }
    },
    watch: {
        value: {
            handler() {
                if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
                    this.accessToken = this.value.access_token;
                    this.refreshToken = this.value.refresh_token;
                    this.lastUpdated = this.value.last_updated;
                    this.useCreateCustomers = this.value.use_create_customers;
                    this.useOffers = this.value.use_offers;
                    this.usePdfOffers = this.value.use_pdf_offers;
                    this.useMarkOfferAsSent = this.value.use_mark_offer_as_sent;
                    this.fieldDeliveryStreetAddress = this.value.field_delivery_street_address;
                    this.fieldDeliveryZipCode = this.value.field_delivery_zip_code;
                    this.fieldDeliveryCity = this.value.field_delivery_city;
                    this.fieldDeliveryCountry = this.value.field_delivery_country;

                    if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
                        this.onUpdate();
                    }
                }
            },
            immediate: true
        },
        defaultMode() {
            // Using this because of @input was faster than v-model on checkbox
            this.$emit('input', this.serializedValue);
        }
    },
    methods: {
        handleOauthSuccess([accessToken, refreshToken]) {
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;
            this.lastUpdated = (new Date()).toISOString().slice(0, 19).replace('T', ' ');
            this.onUpdate();
        },
        handleOauthFailure(error) {
            console.log(error);
        },
        onUpdate() {
            this.$emit('input', this.serializedValue);
        },
    }
};
</script>
