<template>
  <div>
    <ul v-if="notifications && notifications.length > 0" class="notifications-index list-unstyled">
      <li
        v-for="notification in notifications"
        :key="notification.id"
        class="notification p-3"
        :class="notification.read ? '' : 'notification-unread'"
        :role="buttonRole"
        @click="openNotification($event, notification)"
      >
        <div class="d-flex justify-content-between align-items-start mb-0">
          <div>
            <h6 class="d-block font-weight-bold text-dark mx-0 mt-0 mb-1">{{ notification.subject }}</h6>

            <span class="text-nowrap text-muted">{{formatDateTime(new Date(notification.created_at))}}</span>
          </div>
          <div v-if="!notification.read">
            <span v-if="submittingUnreadForId == notification.id" class="spinner-border spinner-border-sm ml-2"></span>
            <button v-else type="button" class="btn btn-primary btn-sm ml-2" style="font-size: 0.7rem;" @click.stop.capture="markAsRead(notification)">
              <i class="fa-light fa-check"></i>
            </button>
          </div>
        </div>
      </li>
    </ul>

    <div class="text-center my-3">
      <b-spinner v-if="isLoading">
      </b-spinner>

      <button v-else-if="nextUrl" role="button" class="btn btn-primary" @click.prevent="loadMore">
        Ladda fler
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import axios from 'axios';
const DATETIME_FORMATTER = new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'short' });

export default {
  props: {
    quoteId: { type: Number, default: undefined },
    forAuthUser : { type: Boolean, default: true},
    noCardLink: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: false,
      notifications: [],
      nextUrl: null,
      submittingUnreadForId: null
    }
  },
  computed: {
    buttonRole() {
      return this.noCardLink ? null : 'button';
    }
  },
  async mounted() {
    let payload = { quote_id: this.quoteId, for_auth_user: this.forAuthUser };
    this.nextUrl = route('notifications.index', payload);
    await this.loadMore();
  },
  methods: {
    async loadMore() {
      try {
        this.isLoading = true;

        const response = await axios.get(this.nextUrl);
        this.nextUrl = response.data.links.next;
        this.notifications = [
          ...this.notifications,
          ...response.data.data
        ];
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.$emit('close');
    },
    openNotification(event, notification) {
      if (this.noCardLink) { return; }

      event.preventDefault();
      this.close();
      this.$document.locationAssign(notification.link);
    },
    async markAsRead(notification) {
      this.submittingUnreadForId = notification.id;

      try {
        await axios.post(route('notifications.markAsRead', notification.id));
        notification.read = true;
        this.$emit('read', notification);
      } finally {
        this.submittingUnreadForId = null;
      }
    },
    formatDateTime(date) {
      return DATETIME_FORMATTER.format(date);
    }
  },
}
</script>
