<template>
  <v-select
    :options="filteredUsers"
    label="name"
    :reduce="(o) => o.id"
    :placeholder="placeholder"
    :multiple="multiple"
    :value="value"
    @input="onInput"
  ></v-select>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    multiple: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    excludeSelf: { type: Boolean, default: false },
    value: { default: null }
  },
  emits: [
    'input'
  ],
  data() {
    return {
      users: []
    };
  },
  computed: {
    filteredUsers() {
      if (this.excludeSelf) {
        return this.users.filter(user => !user.is_self);
      } else {
        return this.users;
      }
    }
  },
  async mounted() {
    const response = await axios.get(route('users.index'));
    this.users = response.data.data
      .map(u => ({...u, label: `${u.name} - ${u.email}` }))
      .sort((a, b) => a.label.localeCompare(b.label));
  },
  methods: {
    onInput(newSelection) {
      this.$emit('input', newSelection);
    }
  }
}
</script>
