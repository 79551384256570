const updateDisableUnlessVisibility = (select, target, value) => {
    target.disabled = (value !== select.value);
}

export default () => {
    window.jQuery('select[data-assigns-style-from-option-target]').each((index, select) => {
        var target = document.querySelector(select.getAttribute('data-assigns-style-from-option-target'));
        if (!target) { return; }

        $(select).on('change', (ev) => {
            var option = window.jQuery("option:selected[data-assignable-style]", select);
            var style = option ? option.data('assignable-style') : '';
            target.setAttribute('style', style);
        });
    });
}
