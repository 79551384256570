<template>
  <div v-if="documents.length > 0">
    <div v-for="(doc, docIdx) in documentsFromSeller" :key="docIdx" class="mb-2">
      <a
        :href="doc.link.href"
        :title="doc.link.title"
        :target="doc.link.target"
        :download="doc.link.download"
        :data-toggle="doc.link.dataToggle"
        :data-target="`#documents-modal-${doc.id}`"
        class="btn btn-lg w-100 text-left"
      >
        <i :class="`fal ${doc.link.icon} mr-1`" style="min-width: 21px;"></i>
        {{doc.link.name}}
        <small v-if="doc.link.nameSuffix"><i>
          - {{doc.link.nameSuffix}}</i>
        </small>
      </a>
    </div>

    <div style="padding-left: 1rem">
      <h5 v-if="documentsFromCustomer.length > 0" class="mt-3 mb-2 text-left">
          <i class="fal fa-folder-open mr-1" style="min-width: 21px;"></i>
          Uppladdade dokument
      </h5>

      <div v-for="(doc, docIdx) in documentsFromCustomer" :key="docIdx" class="mb-2">
        <a
          :href="doc.link.href"
          :title="doc.link.title"
          :target="doc.link.target"
          :download="doc.link.download"
          :data-toggle="doc.link.dataToggle"
          :data-target="`#documents-modal-${doc.id}`"
          class="btn btn-lg w-100 text-left"
        >
          <i :class="`fal ${doc.link.icon} mr-1`" style="min-width: 21px;"></i>
          {{doc.link.name}}
          <small v-if="doc.link.nameSuffix"><i>
            - {{doc.link.nameSuffix}}</i>
          </small>
        </a>
      </div>
    </div>

    <portal v-if="!disablePdfViewer" to="root">
      <documents-modal
          v-for="(doc, idx) in documents"
          v-if="!doc.is_signed"
          :key="`doc-${idx}`"
          :id="`documents-modal-${doc.id}`"
          :print-url="route('quoteDocuments.print', {quoteKey: quoteKey, id: doc.id})"
          :quote-key="quoteKey"
          :main-pdf-name="doc.name"
          :main-pdf-url="doc.pdf_url"
          :main-pdf-is-pdf="doc.is_pdf"
          :is-signed="!doc.signature_requested || doc.is_signed"
          :is-post-sale="true"
          :company-color="companyColor"
          :sign-copy="$t('sign.move_forward')"
          sign-modal-target="#bankid-documents"
          @selectedForSigning="selectedIdx = documentsForBankId.findIndex((docForBankId) => docForBankId.id == doc.id)"
      ></documents-modal>

      <bank-id-modal
          v-if="documentsForBankId.length > 0"
          id="bankid-documents"
          :app-url="appUrl"
          :quote-key="quoteKey"
          :documents="documentsForBankId"
          :initially-selected="selectedIdx"
          :disable-pdf-viewer="disablePdfViewer"
          :sign-component-name="signComponentName"
          :sign-component-signee-name="signComponentSigneeName"
          :sign-component-signature-button-label="signComponentSignatureButtonLabel"
          :sign-component-item-description="signComponentItemDescription"
      ></bank-id-modal>
    </portal>
  </div>
</template>

<style lang="scss" scoped>
a:focus {
    outline: none;
    box-shadow: unset;
}
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

import DocumentsModal from '../modals/documents.vue';
import BankIdModal from './modals/bankid.vue';

export default {
    components: {
        DocumentsModal,
        BankIdModal
    },
    props: {
        appUrl: String,
        quoteKey: String,
        documents: Array,
        signComponentName: { type: String, required: true },
        signComponentSigneeName: { type: String, default: null },
        signComponentSignatureButtonLabel: { type: String, required: true },
        signComponentItemDescription: { type: String, default: null },
        disablePdfViewer: Boolean,
        companyColor: {
            type: String,
            default: ''
        }
    },
    data() {
        this.documents.forEach((doc) => {
            const type = doc.is_pdf ? '-pdf' : (doc.is_image ? '-image' : '');
            if (doc.is_signed) {
                doc.link = {
                    icon: "fa-file-contract",
                    title: "Signerat",
                    nameSuffix: null,
                    href: `/signed/${this.quoteKey}/documents/${doc.id}`,
                    download: null,
                    target: null,
                    dataToggle: null
                };
            } else if (this.disablePdfViewer || doc.is_download) {
                doc.link = {
                    icon: doc.signature_requested ? "fa-file-signature" : `fa-file${type}`,
                    title: doc.signature_requested ? "Väntar på signatur" : doc.name,
                    nameSuffix: doc.signature_requested ? "Väntar på signatur" : null,
                    href: doc.pdf_url,
                    download: doc.is_download ? doc.name_with_extension : null,
                    target: "_blank",
                    dataToggle: null
                };
            } else {
                doc.link = {
                    icon: doc.signature_requested ? "fa-file-signature" : `fa-file${type}`,
                    title: doc.signature_requested ? "Väntar på signatur" : doc.name,
                    nameSuffix: doc.signature_requested ? "Väntar på signatur" : null,
                    href: "#",
                    download: null,
                    target: null,
                    dataToggle: "modal"
                };
            }
            doc.link.name = doc.name_with_extension;
        });
        return {
            selectedIdx: 0,
            documentsForBankId: this.documents.filter(doc => !doc.is_customer_upload && doc.signature_requested && !doc.is_signed),
            documentsFromSeller: this.documents.filter(doc => !doc.is_customer_upload),
            documentsFromCustomer: this.documents.filter(doc => doc.is_customer_upload),
            uuid: uuidv4()
        }
    },
}
</script>
