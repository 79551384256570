<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <div>{{ $t("surveys.editor.header") }}</div>
            <button
                class="btn btn-sm btn-secondary"
                type="button"
                :disabled="saving || editedSurvey"
                @click="createNewSurvey"
            >
                {{ $t("surveys.editor.button_create") }}
            </button>
        </div>

        <div class="card-body">
            <div v-if="products !== null && surveys !== null" class="row">
                <div
                    v-if="surveys.length > 0"
                    class="col-md-4"
                    style="max-height: 90vh; overflow-y: auto"
                >
                    <ul
                        class="d-flex flex-column justify-content-center mb-3 pl-0"
                    >
                        <li
                            v-for="survey in surveys"
                            :key="survey.id"
                            class="d-flex justify-content-between align-items-center mb-3"
                        >
                            <div>
                                <h6 class="my-0 font-weight-bold">
                                    {{ survey.name }}
                                </h6>
                                <small>{{ survey.replies_count }} svar</small>
                            </div>
                            <div>
                                <button
                                    class="btn btn-sm btn-secondary ml-3"
                                    type="button"
                                    :disabled="saving || editedSurvey"
                                    @click="editSurvey(survey)"
                                >
                                    {{ $t("surveys.editor.button_edit") }}
                                </button>
                                <button
                                    class="btn btn-sm btn-secondary ml-2"
                                    type="button"
                                    :disabled="saving || editedSurvey"
                                    @click="previewSurvey(survey)"
                                >
                                    {{ $t("surveys.editor.button_preview") }}
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-else-if="!editedSurvey" class="col-12 text-center">
                    <p class="mb-3">{{ $t("surveys.editor.none_exists") }}</p>
                    <button
                        class="btn btn-sm btn-secondary"
                        type="button"
                        @click="createNewSurvey"
                    >
                        {{ $t("surveys.editor.button_create") }}
                    </button>
                </div>
                <div class="col">
                    <survey-form
                        v-if="editedSurvey"
                        :products="products"
                        :survey="editedSurvey"
                        @updated="onUpdated($event)"
                        @canceled="onCanceled()"
                    ></survey-form>
                </div>
            </div>
            <b-spinner v-else></b-spinner>
        </div>

        <b-modal
            v-if="previewedSurvey"
            hide-footer
            :title="previewedSurvey.name"
            :visible="!!previewedSurvey"
            @hide="onPreviewClosed"
        >
            <surveys-input
                :key="previewedSurvey.id"
                :requires-bankid-signature="
                    previewedSurvey.requires_bankid_signature
                "
                :image-url="previewedSurvey.image_url"
                :intro-header="previewedSurvey.intro_header"
                :intro-body="previewedSurvey.intro_body"
                :thanks-header="previewedSurvey.thanks_header"
                :thanks-body="previewedSurvey.thanks_body"
                :questions="previewedSurveyQuestions"
                quote-key="test"
                :button-next-enabled="!!nextPreviewableSurvey"
                @next="previewedSurvey = nextPreviewableSurvey"
                @close="previewedSurvey = null"
            ></surveys-input>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import SurveyForm from "./_form.vue";

export default {
    components: {
        SurveyForm,
    },
    data() {
        return {
            surveys: null,
            editedSurvey: null,
            previewedSurvey: null,
            products: null,
            saving: false,
        };
    },
    computed: {
        nextPreviewableSurvey() {
            if (!this.previewedSurvey) {
                return null;
            }
            const surveyIdx = this.surveys.findIndex(
                (s) => s.id === this.previewedSurvey.id
            );
            if (surveyIdx === -1 || surveyIdx === this.surveys.length - 1) {
                return null;
            }
            return this.surveys[surveyIdx + 1];
        },
        previewedSurveyQuestions() {
            if (!this.previewedSurvey) {
                return [];
            }

            return this.previewedSurvey.questions.map((q) => {
                const ret = { ...q };
                if (q.products) {
                    ret.products = q.products.map((p) =>
                        this.products.find((p2) => p2.id == p.id)
                    );
                }
                return ret;
            });
        },
    },
    async mounted() {
        await this.loadSurveys();
        await this.loadProducts();
    },
    methods: {
        async loadSurveys() {
            const surveys = [];
            let nextUrl = this.route("surveys.index");
            while (nextUrl) {
                let response = await axios.get(nextUrl);
                surveys.push(...response.data.data);
                nextUrl = response.data.links.next;
            }
            this.surveys = surveys.sort((a, b) => a.name.localeCompare(b.name));
        },
        async loadProducts() {
            const products = [];
            let nextUrl = this.route("products.index");
            while (nextUrl) {
                let productsResponse = await axios.get(nextUrl);
                products.push(...productsResponse.data.data);
                nextUrl = productsResponse.data.links.next;
            }
            this.products = products.sort((a, b) => {
                if (a.deleted === b.deleted) {
                    return 0;
                }
                return a.deleted ? 1 : -1;
            });
        },
        editSurvey(survey) {
            if (this.editedSurvey === survey) {
                return;
            }
            this.editedSurvey = survey;
        },
        previewSurvey(survey) {
            this.previewedSurvey = survey;
        },
        onPreviewClosed() {
            this.previewedSurvey = null;
        },
        createNewSurvey() {
            this.editSurvey({
                id: null,
            });
        },
        onUpdated(survey) {
            this.surveys = this.surveys.filter((b) => b.id !== survey.id);
            this.surveys.push(survey);
            this.surveys.sort((a, b) => a.name.localeCompare(b.name));
            this.editedSurvey = null;
        },
        onCanceled() {
            this.editedSurvey = null;
        },
    },
};
</script>
