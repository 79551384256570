const updateRequiredOr =  (currentInput) => {
    return (e) => {
        if (currentInput.value.trim() === '' && e.target.value.trim() !== '') {
            currentInput.removeAttribute('required');
            return;
        } else if (e.target.value.trim() === '' && currentInput.value !== '') {
            e.target.removeAttribute('required');
            return;
        }

        currentInput.setAttribute('required', 'required');
        e.target.setAttribute('required', 'required');
    }
};

const removeCallbacks = (currentInput) => {
    Array
        .from(document.querySelectorAll('input[data-required-or-select-name]'))
        .forEach(input => {
            if (input.getAttribute('data-required-or-select-name') !== currentInput.getAttribute('name')){
                return;
            }
            const blurEvent = new Event('blur', { bubbles: true, cancelable: true });
            input.dispatchEvent(blurEvent);
            input.removeEventListener('blur', updateRequiredOr(currentInput));
        });
}
const registerCallbacks = (currentInput) => {
    Array
        .from(document.querySelectorAll('input[data-required-or-select-name]'))
        .forEach(input => {
            if (input.getAttribute('data-required-or-select-name') !== currentInput.getAttribute('name')) {
                return;
            }
            input.addEventListener('blur', updateRequiredOr(currentInput));
            const blurEvent = new Event('blur', { bubbles: true, cancelable: true });
            input.dispatchEvent(blurEvent);
        });
}
const updateRequired = (input, select, values) => {
    const currentValue = select.value;
    if (values.indexOf(currentValue) !== -1) {
        input.setAttribute('required', 'required');
        if (currentValue === 'auto'){
            registerCallbacks(input);
        }
    } else {
        input.removeAttribute('required');
        if (currentValue === 'auto'){
            removeCallbacks(input)
        }
    }
}

export const requireIfSelectSetup = (input) => {
    const selectName = input.getAttribute('data-required-if-select-name');
    const select = document.querySelector(`select[name="${selectName}"]`);
    if (!select) {
        console.warn("Could not find target " + selectName);
        return;
    }
    const values = input.getAttribute('data-required-if-select-values')
        .split(",")
        .map((v) => v.trim());

    updateRequired(input, select, values)
    window.jQuery(select).on('change', (ev) => updateRequired(input, select, values));
}

export default () => {
    document.querySelectorAll('input[data-required-if-select-name]')
        .forEach((select) => requireIfSelectSetup(select));
}
