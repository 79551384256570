<template>
  <div>
    <slot v-if="isSigned"></slot>
    <slot v-else name="spinner"></slot>
  </div>
</template>

<style lang="scss">

</style>

<script>
export default {
  props: {
    quoteKey: { type: String, required: true },
    pollingInterval: { type: Number, required: true },
  },
  data() {
    return {
      isSigned: false,
      interval: null
    };
  },
  mounted() {
    this.interval = setInterval(async () => {
      await this.checkSignature();
    }, this.pollingInterval);
  },
  unmouted() {
    this.stopPolling();
  },
  methods: {
    async checkSignature() {
      const response = await axios.get(route('quotes.signatureCount', this.quoteKey));
      if (response.data.count > 0) {
        this.isSigned = true;
      }
    },
    stopPolling() {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
}
</script>
