<template>
    <form @submit.prevent="save">
        <template v-if="products">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.bundles.attributes.name") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    required
                    name="name"
                    v-model="theBundle.name"
                />
            </div>

            <hr />

            <template v-if="theBundle.products">
                <div
                    v-for="(product, productIdx) in theBundle.products"
                    :key="productIdx"
                >
                    <hr v-if="productIdx > 0" />

                    <template v-if="isSoloProductBundle">
                        <div class="d-flex justify-content-between">
                            <div>
                                <h6 class="font-weight-bold">
                                    {{ product.name }}
                                </h6>
                                <p class="mb-0">
                                    {{ $t("products.attributes.solo_product") }}
                                </p>
                            </div>

                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click.prevent="removeProduct(productIdx)"
                            >
                                <i class="fal fa-trash"></i>
                            </button>
                        </div>
                    </template>
                    <template v-else>
                        <h6 class="font-weight-bold mb-2">
                            {{ product.name }}
                        </h6>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    {{
                                        $t(
                                            "products.bundles.attributes.product_amount"
                                        )
                                    }}
                                </div>
                            </div>
                            <input
                                type="number"
                                required
                                min="1"
                                class="form-control"
                                v-model="product.amount"
                            />
                            <button
                                type="button"
                                class="btn btn-secondary"
                                @click.prevent="removeProduct(productIdx)"
                            >
                                <i class="fal fa-trash"></i>
                            </button>
                        </div>
                    </template>
                </div>
            </template>

            <hr />

            <products-search
                v-if="!isSoloProductBundle"
                :products="products"
                ignore-out-of-stock
                ignore-deleted
                :allow-solo-products="!hasProducts"
                :required="!hasProducts"
                @onAddProducts="addProducts"
                class="mb-3"
            ></products-search>

            <div
                v-if="successMessage"
                class="alert alert-success mb-3"
                role="alert"
            >
                {{ successMessage }}
            </div>
            <div
                v-if="errorMessage"
                class="alert alert-danger mb-3"
                role="alert"
            >
                {{ errorMessage }}
            </div>

            <button v-if="!saving" type="submit" class="btn btn-primary">
                {{
                    $t(
                        theBundle.id
                            ? "products.bundles.editor.button_save_existing"
                            : "products.bundles.editor.button_save_new"
                    )
                }}
            </button>
            <b-spinner v-else></b-spinner>
        </template>
        <b-spinner v-else></b-spinner>
    </form>
</template>

<style lang="scss" scoped></style>

<script>
import axios from "axios";
import { downloadProducts } from "../search";

export default {
    props: {
        bundle: { type: Object, required: true },
    },
    data() {
        return {
            theBundle: {},
            products: null,
            soloProductIds: [],
            saving: false,
            errorMessage: null,
            successMessage: null,
        };
    },
    computed: {
        hasProducts() {
            return this.theBundle?.products?.length > 0;
        },
        isSoloProductBundle() {
            return this.theBundle?.products?.some((prod) =>
                this.soloProductIds.includes(prod.id)
            );
        },
    },
    watch: {
        bundle: {
            immediate: true,
            deep: true,
            handler(bundle) {
                const theBundle = JSON.parse(JSON.stringify(bundle));
                if (!theBundle.name) {
                    theBundle.name = "";
                }
                if (!theBundle.products) {
                    theBundle.products = [];
                }

                this.theBundle = theBundle;
            },
        },
    },
    async mounted() {
        this.products = await downloadProducts(false);
        this.soloProductIds = this.products
            .filter((prod) => prod.solo_product)
            .map((prod) => prod.id);
    },
    methods: {
        addProducts(products) {
            products.forEach((prod) =>
                this.theBundle.products.push({
                    id: prod.product.id,
                    name: prod.product.name,
                    amount: prod.amount,
                })
            );
        },
        removeProduct(idx) {
            this.theBundle.products.splice(idx, 1);
        },
        async save() {
            if (this.saving) {
                return;
            }

            this.saving = true;
            this.errorMessage = null;
            this.successMessage = null;

            try {
                const response = this.theBundle.id
                    ? await axios.put(
                          this.route(
                              "products.bundles.update",
                              this.theBundle.id
                          ),
                          this.theBundle
                      )
                    : await axios.post(
                          this.route("products.bundles.store"),
                          this.theBundle
                      );
                this.$emit(
                    "updated",
                    JSON.parse(JSON.stringify(response.data))
                );

                this.successMessage = this.$t(
                    "products.bundles.editor.save_success"
                );
            } catch (e) {
                if (console.warn) {
                    console.warn(e);
                }

                this.errorMessage = this.$t(
                    "products.bundles.editor.save_error"
                );
            }

            this.saving = false;
        },
    },
};
</script>
