<template>
    <div class="modal fade viewmodal" tabindex="-1" role="dialog" aria-hidden="true" style="padding-right: 0px !important" ref="modal">
        <div class="modal-dialog modal-full" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <img :src="companyImageUrl" alt="" class="header-company-image">

                    <button type="button" class="close" data-dismiss="modal" aria-label="Stäng">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <nav class="navbar navbar-light w-100 mb-4">
                      <button v-if="quoteProducts.length > 1" class="btn btn-outline-secondary hover-background-color-profile-color border-color-profile-color" type="button" data-toggle="collapse" :data-target="`#navbar-${uuid}`" :aria-controls="`#navbar-${uuid}`" aria-expanded="false" aria-label="Byt dokument">
                          <h5 class="mb-0">
                              {{quoteProduct.name}}
                              <i class="when-collapsed fal fa-chevron-down ml-2"></i>
                              <i class="when-expanded fal fa-chevron-up ml-2"></i>
                          </h5>
                        </button>

                        <a v-else class="navbar-brand" href="#">{{quoteProduct.name}}</a>

                        <div class="collapse navbar-collapse" :id="`navbar-${uuid}`">
                            <ul class="navbar-nav mr-auto mt-2 pl-2">
                                <template v-for="qp in quoteProducts">
                                    <li v-if="!qp.is_invalid" class="nav-item" data-toggle="collapse" :data-target="`#navbar-${uuid}`" :key="qp.id" :class="qp.id == quoteProduct.id ? 'active' : ''">
                                        <a class="nav-link" href="#" @click.prevent="switchToQuoteProduct(qp)">{{qp.name}}</a>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </nav>

                    <div v-if="this.combinedProducts.filter(prod => prod.id !== 'discount_product').length === 1" class="row">
                        <div class="col-12 mb-3">
                            <img :src="quoteProduct.products[0].image_url" :alt="quoteProduct.products[0].image_name" class="product-featured">
                        </div>

                        <div class="col-12 mb-3">
                            <h2 class="font-weight-bold">{{quoteProduct.products[0].name_with_prompted_value}}</h2>

                            <div v-if="!quoteProduct.allows_user_assigned_price" class="d-flex">
                                <p style="font-size: 1.5em;" :class="amountsEditable ? 'mb-0' : ''" v-if="amountsEditable">
                                  {{$t('products.unit_with_price.' + quoteProduct.products[0].price_unit, { price: quoteProduct.products[0].price, currency: quoteProduct.products[0].currency_name, vat_suffix: '' })}}
                                </p>
                                <p style="font-size: 1.5em;" :class="amountsEditable ? 'mb-0' : ''" v-else-if="quoteProduct.products[0].amount == 1">
                                  {{$t('products.unit_with_price.' + quoteProduct.products[0].price_unit, { price: quoteProduct.products[0].price, currency: quoteProduct.products[0].currency_name, vat_suffix: '' })}}
                                </p>
                                <p style="font-size: 1.5em;" :class="amountsEditable ? 'mb-0' : ''" v-else>
                                    {{$t('products.unit_with_amount_and_price.' + quoteProduct.products[0].price_unit, { amount: quoteProduct.products[0].amount, price: quoteProduct.products[0].price, currency: quoteProduct.products[0].currency_name, vat_suffix: '' })}}
                                </p>
                                <p v-if="quoteProduct.products[0].original_price !== quoteProduct.products[0].price" class="mx-2 align-self-center">
                                  ({{$t('quoteProducts.view.original_price_title')}}:
                                  {{$t('products.unit_with_price.' + quoteProduct.products[0].price_unit, { price: quoteProduct.products[0].original_price, currency: quoteProduct.products[0].currency_name, vat_suffix: '' })}})
                                </p>
                            </div>

                            <div v-if="amountsEditable && !quoteProduct.solo_product" class="btn-group mt-2">
                                <button :disabled="quoteProduct.products[0].amount <= 1" type="button" class="btn btn-sm btn-outline-secondary" @click.stop.prevent="decreaseProductAmount(0)"><i class="fa-light fa-minus"></i></button>
                                <button type="button" disabled class="btn btn-sm btn-outline-secondary" style="min-width: 2.5rem">{{quoteProduct.products[0].amount}}</button>
                                <button type="button" class="btn btn-sm btn-outline-secondary" @click.stop.prevent="increaseProductAmount(0)"><i class="fa-light fa-plus"></i></button>
                            </div>
                        </div>

                        <div class="col-12">
                            <dl v-if="quoteProduct.products[0].specification && quoteProduct.products[0].specification.length > 0" class="product-specification">
                                <template v-for="(spec, idx) in quoteProduct.products[0].specification">
                                    <dt :key="`dt-${idx}`">{{spec.label}}</dt>
                                    <dd :key="`dd-${idx}`">{{spec.value}}</dd>
                                </template>
                            </dl>
                            <h6 v-if="quoteProduct.products[0].description_title" class="font-weight-bold">{{quoteProduct.products[0].description_title}}</h6>
                            <p v-if="quoteProduct.products[0].description" v-html="quoteProduct.products[0].description"></p>
                            <p v-if="quoteProduct.products[0].url">
                                <a :href="quoteProduct.products[0].url" target="_blank" class="pt-2">
                                    {{$t('quoteProducts.view.read_more')}}
                                </a>
                            </p>
                        </div>
                    </div>
                    <template v-else>
                      <div v-for="(product, productIdx) in this.combinedProducts.filter(p => p.id !== 'discount_product')" :key="product.product_key" class="row mb-2">
                          <div class="col-12">
                              <div style="display: grid; grid-template-columns: 4fr 8fr; gap: 1rem;">
                                  <img :src="product.image_url" :alt="product.image_name" class="product-thumbnail">

                                  <div>
                                      <h4 class="font-weight-bold text-break">{{product.name_with_prompted_value}}</h4>
                                    <b-form-checkbox v-if="product.was_add_on" @change="updateAddOnProduct(product.product_key, !$event)" :checked="!product.is_add_on" name="check-button" switch>
                                      {{product.is_add_on?'Möjligt tillval du kan göra':'Tillval du har gjort'}}
                                    </b-form-checkbox>
                                      <template v-if="!hideIndividualPrices && !quoteProduct.allows_user_assigned_price">
                                        <p style="font-size: 1.3em;" class="my-0" v-if="amountsEditable">
                                            {{$t('products.unit_with_price.' + product.price_unit, { price: product.price, currency: product.currency_name, vat_suffix: '' })}}
                                        </p>
                                        <p style="font-size: 1.3em;" class="my-0" v-else-if="product.amount == 1" v-html="$t('quoteProducts.view.price_html', { price: product.price, currency: product.currency_name, vat_suffix: '' })"></p>
                                        <p style="font-size: 1.3em;" class="my-0" v-else>
                                            {{$t('products.unit_with_amount_and_price.' + product.price_unit, { amount: product.amount, price: product.price, currency: product.currency_name, vat_suffix: '' })}}
                                        </p>
                                        <template v-if="product.original_price !== product.price" class="d-block">
                                          ({{$t('quoteProducts.view.original_price_title')}}:
                                          {{$t('products.unit_with_price.' + product.price_unit, { price: product.original_price, currency: product.currency_name, vat_suffix: '' })}})
                                        </template>
                                      </template>
                                      <div v-if="amountsEditable && !quoteProduct.solo_product" class="btn-group my-0">
                                          <button type="button" class="btn btn-sm btn-outline-secondary" @click.stop.prevent="decreaseProductAmount(product.product_key)"><i class="fa-light fa-minus"></i></button>
                                          <button type="button" disabled class="btn btn-sm btn-outline-secondary" style="min-width: 2.5rem">{{product.amount}}</button>
                                          <button type="button" class="btn btn-sm btn-outline-secondary" @click.stop.prevent="increaseProductAmount(product.product_key)"><i class="fa-light fa-plus"></i></button>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <template v-if="(product.specification && product.specification.length > 0) || product.description_title || product.description">
                            <div class="col-12 mt-3" :class="['collapsable-description', openDescriptionForProductIdx !== productIdx ? 'collapsable-description-collapsed' : 'collapsable-description-open']" @click.prevent="openDescription(productIdx)">
                                  <dl v-if="product.specification && product.specification.length > 0" class="product-specification">
                                      <template v-for="(spec, idx) in product.specification">
                                          <dt :key="`dt-${idx}`">{{spec.label}}</dt>
                                          <dd :key="`dd-${idx}`">{{spec.value}}</dd>
                                      </template>
                                  </dl>

                                  <h6 v-if="product.description_title" class="font-weight-bold">{{product.description_title}}</h6>
                                  <p v-if="product.description" v-html="product.description"></p>
                                  <p v-if="product.url">
                                      <a :href="product.url" target="_blank" class="pt-2" @click.stop>
                                          {{$t('quoteProducts.view.read_more')}}
                                      </a>
                                  </p>
                            </div>
                            <a href="#" type="button" class="collapsable-description-toggle signbuttoninline" @click.prevent="openDescription(productIdx)">
                              {{$t('quoteProducts.view.view_more')}}
                            </a>
                          </template>
                          <div v-else-if="product.url" class="col-12 mt-3">
                            <p v-if="product.url">
                                <a :href="product.url" target="_blank" class="pt-2" @click.stop>
                                    {{$t('quoteProducts.view.read_more')}}
                                </a>
                            </p>
                          </div>

                          <div class="col-12">
                              <hr>
                          </div>
                      </div>
                    </template>

                    <div class="d-flex justify-content-center align-items-top mt-5 mb-5">
                        <div v-if="hasDiscount" class="d-flex align-items-center justify-content-center">
                            <i class="fal fa-3x mr-3 fa-piggy-bank"></i>
                            <div>
                                <strong style="text-transform: uppercase;">
                                    {{$t('quoteProducts.view.discount_title')}}
                                </strong>
                                <br>
                                <span style="font-size: 1.5em;" class="text-highlight">
                                    {{$t('quoteProducts.view.discount', {discount: quoteProduct.discount, currency: quoteProduct.currency_name})}}
                                </span>
                            </div>
                        </div>
                        <template v-if="!hideTotals && (!quoteProduct.allows_user_assigned_price || quoteProduct.user_assigned_price)">
                            <div v-if="hasDiscount" style="width: 80px; max-width: 5vw;"></div>
                            <div class="d-flex align-items-center justify-content-center">
                                <i class="fal fa-3x mr-3 fa-shopping-cart"></i>
                                <div>
                                    <strong>
                                        {{$t('quoteProducts.view.price_title')}}
                                    </strong>
                                    <br>
                                    <span style="font-size: 1.5em;">
                                        {{$t('quoteProducts.view.price', {price: quoteProduct.price, currency: quoteProduct.currency_name, vat_suffix: quoteProduct.vat_suffix})}}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="text-center">
                        <a v-if="isSigned" :href="route('signed_quotes.view', quoteKey)">
                          <div class="signbuttoninline-lg">
                            {{$t('quoteProducts.view.button_show_order')}}
                          </div>
                        </a>
                        <a v-else-if="signable" href="#" style="color:black" @click="$emit('selectedForSigning'); openModal('#bankid');">
                            <div class="signbuttoninline-lg"><i class="fal fa-file-signature"></i> {{quoteEsignButtonTxt  ? quoteEsignButtonTxt  : $t('sign.move_forward')}}</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
button[aria-expanded="true"] .when-collapsed { display: none; }
button[aria-expanded="false"] .when-expanded { display: none; }

.header-company-image {
  max-width: 50%;
  max-height: 80px;
  width: auto;
  height: auto;
}

.product-specification {
  dt, dd {
    display: inline;
  }

  dd {
    margin-right: 2em;
  }
}

.product-featured {
    max-height: 40vh;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    height: auto;
    object-position: center center;

}

.product-thumbnail {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    height: auto;
    object-position: left top;

}

.collapsable-description {
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    max-height: 10000px;
    position: relative;

    & + .collapsable-description-toggle {
        position: relative;
        top: -20px;

        margin-left: auto;
        margin-right: auto;
    }
}

.collapsable-description-open {
    & + .collapsable-description-toggle {
        display: none;
    }
}

.collapsable-description-collapsed {
    cursor: pointer;
    max-height: 4em;

  &:after {
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    text-align: center;
    margin: 0;
    padding: 30px 0;

    pointer-events: none;

    background-image: linear-gradient(to bottom, transparent, white);
  }
}
</style>

<script>

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default {
    props: {
        companyImageUrl: String,
        quoteKey: String,
        quoteProduct: Object,
        quoteProducts: Array,
        quoteProductAddOns: Array,
        quoteEsignButtonTxt: String,
        isSigned: Boolean,
        signable: Boolean,
        hideTotals: Boolean,
        hideIndividualPrices: Boolean,
        amountsEditable: Boolean,
    },
    data() {
        return {
            uuid: uuidv4(),
            documents: [{pdf_name: this.mainPdfName, pdf_url: this.mainPdfUrl}].concat(this.extraDocuments),
            openDescriptionForProductIdx: null
        }
    },
    computed: {
        hasDiscount() {
            return this.quoteProduct.price !== this.quoteProduct.original_price;
        },
        combinedProducts() {
          const addOnQuoteProducts = this.quoteProductAddOns.filter((prod) => (prod.id === this.quoteProduct.id));
          if (addOnQuoteProducts.length < 1) {
            return this.quoteProduct.products;
          }
          return this.quoteProduct.products.concat(Object.values(addOnQuoteProducts[0].products));
        }
    },
    mounted() {
      const thisModal = window.jQuery(this.$refs["modal"]);
      thisModal.on('shown.bs.modal', () => this.logOpen());
    },
    methods: {
        openDescription(productIdx) {
            if (this.openDescriptionForProductIdx !== productIdx) {
                this.openDescriptionForProductIdx = productIdx;
            }
        },
        async logOpen() {
          await axios.post(this.route('quote.products.storeOpened', [this.quoteKey, this.quoteProduct.id]));
        },
        switchToQuoteProduct(quoteProduct) {
          if (quoteProduct.id === this.quoteProduct.id) { return; }

          this.openModal(`#products-modal-${quoteProduct.id}`);
        },
        openModal(modalId) {
          window.jQuery(this.$refs["modal"]).one('hidden.bs.modal', () => window.jQuery(modalId).modal('show'));
          window.jQuery(this.$refs["modal"]).modal('hide');
        },
        async decreaseProductAmount(productIdx) {
          this.storeProductAmountChange(productIdx, -1);
        },
        async increaseProductAmount(productIdx) {
          this.storeProductAmountChange(productIdx, 1);
        },
        async storeProductAmountChange(productIdx, change)
        {
          const response = await axios.post(route('quote.products.amount_changes.store', [this.quoteKey, this.quoteProduct.id]), {
            product_idx: productIdx,
            change: change,
          });

          this.$emit('updatedQuoteProductsWithAddOns', response.data);
        },
        async updateAddOnProduct(productIdx, change)
        {
          const response = await axios.post(route('quote.products.add_on.update', [this.quoteKey, this.quoteProduct.id]), {
            product_idx: productIdx,
            change: change
          });

          this.$emit('updatedQuoteProductsWithAddOns', response.data);
        }
    }
}
</script>
