<template>
    <button @click="handle"><slot /></button>
</template>

<script>
import axios from "axios";
export default {
    name: "delete-company-user-button",
    props: {
        companyId: Number,
        userId: Number,
        users: Array,
    },
    data() {
        return {
            deleting: false,
        };
    },
    methods: {
        handle: async function () {
            this.deleting = true;
            try {
                const payload = {
                    company_id: this.companyId,
                    user_id: this.userId,
                };

                const users = this.users.filter(
                    (user) => !user.is_shadow && user.id !== this.userId
                );

                const alert = await this.$swal({
                    text: "Vill du ta bort en användare? (Välj en användare som skall ta över kontaktlistan)",
                    input: "select",
                    inputOptions: Object.fromEntries(
                        users.map((user) => [user.id, user.name])
                    ),
                    inputPlaceholder: "Välj en användare",
                    showCancelButton: true,
                    icon: "warning",
                    iconColor: window
                        .getComputedStyle(document.body)
                        .getPropertyValue("--primary-color"),
                    confirmButtonColor: window
                        .getComputedStyle(document.body)
                        .getPropertyValue("--primary-color"),
                    confirmButtonText: "Ja!",
                    cancelButtonText: "Avbryt",
                });

                if (
                    alert &&
                    alert.isConfirmed &&
                    alert.value &&
                    users.filter((user) => user.id === Number(alert.value))
                ) {
                    payload.quote_user_id = Number(alert.value);
                    await axios.post(route("superadmin.users.delete"), payload);
                    window.location.reload();
                }
            } catch (error) {
                await this.$swal({
                    icon: "error",
                    text: "Det gick inte att ta bort användaren.",
                    iconColor: window
                        .getComputedStyle(document.body)
                        .getPropertyValue("--primary-color"),
                    confirmButtonColor: window
                        .getComputedStyle(document.body)
                        .getPropertyValue("--primary-color"),
                });
            } finally {
                this.deleting = false;
            }
        },
    },
};
</script>
