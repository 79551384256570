<template>
    <div class="text-center">
        <small><i>Powered by</i></small>
        <img
            :src="logo"
            class="mt-2 mx-auto d-block"
            style="opacity: 0.5"
            width="20%"
            alt=""
        />
    </div>
</template>

<style scoped></style>

<script>
import logo from "./../../../public/img/salesbyte-black.png";
export default {
    data() {
        return {};
    },
};
</script>
