<template>
  <div class="px-5">
    <div class="row">
      <slot name="header"></slot>

      <div v-if="isLoaded && filterOptionsLoaded" class="col-md-12">
        <div class="collapse" :class="{show: enableQuotesTable}" id="filter-wrapper">
          <div class="card mb-4">
            <div class="card-header">Inkludera</div>
            <div class="card-body">
              <div class="row">

                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                      <strong>Datum (fr.o.m.)</strong>

                      <b-form-datepicker
                        v-model="filterConfig.include.createdAt.min"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Datum (t.o.m.)</strong>
                      <b-form-datepicker
                        v-model="filterConfig.include.createdAt.max"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Senaste kundaktivitet (fr.o.m.)</strong>

                      <b-form-datepicker
                        v-model="filterConfig.include.latestCustomerInitiatedActionLogAt.min"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Senaste kundaktivitet (t.o.m.)</strong>
                      <b-form-datepicker
                        v-model="filterConfig.include.latestCustomerInitiatedActionLogAt.max"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Signeringsdatum (fr.o.m.)</strong>

                      <b-form-datepicker
                        v-model="filterConfig.include.primarySignatureAt.min"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Signeringsdatum (t.o.m.)</strong>
                      <b-form-datepicker
                        v-model="filterConfig.include.primarySignatureAt.max"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                  </div>
                </div>

                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                      <strong>Kund</strong>

                      <v-select
                        v-model="filterConfig.include.customerName"
                        :options="filterOptions.customerName"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Bolagsnamn</strong>

                      <v-select
                        v-model="filterConfig.include.clientCompany"
                        :options="filterOptions.clientCompany"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Säljare</strong>

                      <v-select
                        v-model="filterConfig.include.seller"
                        :options="filterOptions.seller"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Automatisk uppföljning</strong>

                      <v-select
                        v-model="filterConfig.include.flowStatus"
                        :options="filterOptions.flowStatus"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div v-if="filterOptions.quoteProfile.length > 1" class="col-12 col-md-6 mb-3">
                      <strong>Profil</strong>

                      <v-select
                        v-model="filterConfig.include.quoteProfile"
                        :options="filterOptions.quoteProfile"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div v-if="filterOptions.products.length > 0" class="col-12 col-md-6 mb-3">
                      <strong>Produkter</strong>

                      <v-select
                        v-model="filterConfig.include.product"
                        :options="filterOptions.products"
                        :reduce="(o) => o.id"
                        label="name"
                        multiple
                      >
                        <template v-slot:option="product">
                          <div>
                            <strong>{{product.name}}</strong><br>
                            <div class="text-muted" style="font-size: 70%;">{{product.sku}}</div>
                          </div>
                        </template>

                        <template v-slot:selected-option="product">
                          <div>
                            <strong>{{product.name}}</strong><br>
                            <div class="text-muted" style="font-size: 70%;">{{product.sku}}</div>
                          </div>
                        </template>
                      </v-select>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Status</strong>

                      <v-select
                        v-model="filterConfig.include.status"
                        :options="filterOptions.status"
                        :reduce="(o) => o.value"
                        multiple
                      />

                      <div class="custom-control custom-switch ml-1 mt-1">
                        <input type="checkbox" class="custom-control-input" :id="`${uuid}-filter-config-status-require-all`" v-model="filterConfig.include.statusRequireAll">
                        <label class="custom-control-label" :for="`${uuid}-filter-config-status-require-all`"><small>Alla måste matcha</small></label>
                      </div>
                    </div>

                    <div v-if="filterOptions.tags.length > 0" class="col-12 col-md-6 mb-3">
                      <strong>Taggar</strong>

                      <v-select
                        v-model="filterConfig.include.tags"
                        :options="filterOptions.tags"
                        :reduce="(o) => o.value"
                        multiple
                      />

                      <div class="custom-control custom-switch ml-1 mt-1">
                        <input type="checkbox" class="custom-control-input" :id="`${uuid}-filter-config-tags-require-all`" v-model="filterConfig.include.tagsRequireAll">
                        <label class="custom-control-label" :for="`${uuid}-filter-config-tags-require-all`"><small>Alla måste matcha</small></label>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-header">Exkludera</div>
            <div class="card-body">
              <div class="row">

                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                      <strong>Datum (fr.o.m.)</strong>

                      <b-form-datepicker
                        v-model="filterConfig.exclude.createdAt.min"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Datum (t.o.m.)</strong>
                      <b-form-datepicker
                        v-model="filterConfig.exclude.createdAt.max"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Senaste kundaktivitet (fr.o.m.)</strong>

                      <b-form-datepicker
                        v-model="filterConfig.exclude.latestCustomerInitiatedActionLogAt.min"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Senaste kundaktivitet (t.o.m.)</strong>
                      <b-form-datepicker
                        v-model="filterConfig.exclude.latestCustomerInitiatedActionLogAt.max"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Signeringsdatum (fr.o.m.)</strong>

                      <b-form-datepicker
                        v-model="filterConfig.exclude.primarySignatureAt.min"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Signeringsdatum (t.o.m.)</strong>
                      <b-form-datepicker
                        v-model="filterConfig.exclude.primarySignatureAt.max"
                        v-bind="datePickerConfig"
                      ></b-form-datepicker>
                    </div>

                  </div>
                </div>

                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-12 col-md-6 mb-3">
                      <strong>Kund</strong>

                      <v-select
                        v-model="filterConfig.exclude.customerName"
                        :options="filterOptions.customerName"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Bolagsnamn</strong>

                      <v-select
                        v-model="filterConfig.exclude.clientCompany"
                        :options="filterOptions.clientCompany"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Säljare</strong>

                      <v-select
                        v-model="filterConfig.exclude.seller"
                        :options="filterOptions.seller"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Automatisk uppföljning</strong>

                      <v-select
                        v-model="filterConfig.exclude.flowStatus"
                        :options="filterOptions.flowStatus"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div v-if="filterOptions.quoteProfile.length > 1" class="col-12 col-md-6 mb-3">
                      <strong>Profil</strong>

                      <v-select
                        v-model="filterConfig.exclude.quoteProfile"
                        :options="filterOptions.quoteProfile"
                        :reduce="(o) => o.value"
                        multiple
                      />
                    </div>

                    <div v-if="filterOptions.products.length > 0" class="col-12 col-md-6 mb-3">
                      <strong>Produkter</strong>

                      <v-select
                        v-model="filterConfig.exclude.product"
                        :options="filterOptions.products"
                        :reduce="(o) => o.id"
                        label="name"
                        multiple
                      >
                        <template v-slot:option="product">
                          <div>
                            <strong>{{product.name}}</strong><br>
                            <div class="text-muted" style="font-size: 70%;">{{product.sku}}</div>
                          </div>
                        </template>

                        <template v-slot:selected-option="product">
                          <div>
                            <strong>{{product.name}}</strong><br>
                            <div class="text-muted" style="font-size: 70%;">{{product.sku}}</div>
                          </div>
                        </template>
                      </v-select>
                    </div>

                    <div class="col-12 col-md-6 mb-3">
                      <strong>Status</strong>

                      <v-select
                        v-model="filterConfig.exclude.status"
                        :options="filterOptions.status"
                        :reduce="(o) => o.value"
                        multiple
                      />

                      <div class="custom-control custom-switch ml-1 mt-1">
                        <input type="checkbox" class="custom-control-input" :id="`${uuid}-filter-config-status-require-all`" v-model="filterConfig.exclude.statusRequireAll">
                        <label class="custom-control-label" :for="`${uuid}-filter-config-status-require-all`"><small>Alla måste matcha</small></label>
                      </div>
                    </div>

                    <div v-if="filterOptions.tags.length > 0" class="col-12 col-md-6 mb-3">
                      <strong>Taggar</strong>

                      <v-select
                        v-model="filterConfig.exclude.tags"
                        :options="filterOptions.tags"
                        :reduce="(o) => o.value"
                        multiple
                      />

                      <div class="custom-control custom-switch ml-1 mt-1">
                        <input type="checkbox" class="custom-control-input" :id="`${uuid}-filter-config-tags-require-all`" v-model="filterConfig.exclude.tagsRequireAll">
                        <label class="custom-control-label" :for="`${uuid}-filter-config-tags-require-all`"><small>Alla måste matcha</small></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 d-flex justify-content-center">
            <button class="btn btn-primary btn-lg" @click="handleFilter"><i class="fal fa-search"></i> Sök</button>
          </div>
        </div>

        <slot name="after-filters"></slot>
      </div>

      <div v-else class="col-md-12 mb-4 text-center">
        <b-spinner label="Laddar.."></b-spinner>
      </div>

      <div class="col-md-12 mb-4" v-if="enableQuotesTable">
        <b-table
          :items="provideItems"
          :fields="fields"
          :primary-key="primaryKey"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :filter="combinedFilterConfig"
          :current-page="currentPage"
          :per-page="pageLimit"
          thead-class="text-nowrap"
          tbody-tr-class="text-nowrap"
          responsive
        >
          <template #cell(created_at)="data">
            <a :href="route('quotes.edit', data.item.id)" target="_blank" class="text-decoration-none text-dark">
              {{data.value}}
            </a>
          </template>

          <template #cell(customer_name)="data">
            {{data.value}}
            <template v-if="showContactMethods">
              <i
                v-if="data.item.customer_phone"
                class="fa-light fa-mobile"
                :title="data.item.customer_phone"
              ></i>
              <i
                v-if="data.item.customer_email"
                class="fa-light fa-envelope"
                :title="data.item.customer_email"
              ></i>
            </template>
          </template>

          <template #cell(client_company)="data">
            <small v-if="data.value === 'Privatkund'" style="opacity:0.5">
              {{data.value}}
            </small>
            <small v-else>
              {{data.value}}
            </small>
          </template>

          <template #cell(formatted_quote_value)="data">
            <div v-html="data.value"></div>
          </template>

          <template #cell(statuses)="data">
            <a v-for="(statusItem, statusIdx) in data.value" :key="statusIdx" :href="statusItem.href" class="mr-1">
              <b-badge pill :variant="statusItem.type">
                {{statusItem.title}}
                <i v-if="statusItem.icon" :class="`fal fa-${statusItem.icon} ml-1`"></i>
              </b-badge>


              <br>
            </a>
            <b-badge pill variant="secondary" class="mr-1">
              Automatisk uppföljning: {{data.item.flow_status}}
              <i class="fal fa-paper-plane ml-1"></i>
            </b-badge>
          </template>

          <template #cell(tags)="data">
            <b-badge v-for="(tagItem, tagIdx) in data.value" :key="tagIdx" class="ml-1" pill variant="secondary">
              {{tagItem}}
            </b-badge>
          </template>

          <template #cell(related_products)="data">
            <ul v-if="showProductsQuoteIds.indexOf(data.item.id) !== -1" class="list-unstyled mb-0">
              <li v-for="(prod, prodIdx) in data.value" :key="prodIdx" class="ml-1">
                {{prod.name}}<br>
                <div class="text-muted" style="font-size: 70%;">{{prod.sku}}</div>
              </li>
            </ul>
            <button v-else-if="data.value.length > 0" type="button" class="btn btn-sm btn-primary" @click="showProductsQuoteIds.push(data.item.id)">
              Visa {{data.value.length}} st
            </button>
          </template>
        </b-table>

        <div class="mt-3 d-flex flex-column justify-content-center align-items-center">
            <b-spinner v-if="!isLoaded" label="Laddar.."></b-spinner>
        </div>

        <div class="d-flex justify-content-center align-items-center mt-3">
            <b-pagination
              class="w-full"
              v-model="currentPage"
              :total-rows="quoteCount"
              :per-page="pageLimit"
            ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-select::v-deep .vs__dropdown-toggle {
  min-height: 37.04px;
}
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

import { downloadProducts } from '../products/search.vue';

const FIELDS = [
  {
    key: "created_at",
    label: "Datum",
    sortable: true,
    formatter: (value, _key, _quote) => value.substring(0, 10),
    sortByFormatted: true
  },
  {
    key: "quote_profile_name",
    label: "Profil",
    sortable: true
  },
  {
    key: "customer_name",
    label: "Kund",
    sortable: true
  },
  {
    key: "customer_email",
    label: "E-post",
    sortable: false
  },
  {
    key: "customer_phone",
    label: "Telefon",
    sortable: false,
  },
  {
    key: "person_number",
    label: "Personnummer",
    sortable: false,
    formatter: (value, _key, _quote) => (value? value : "N/A"),
  },
  {
    key: "customer_age",
    label: "Ålder",
    sortable: false,
    formatter: (value, _key, _quote) => (value? value : "N/A"),
  },
  {
    key: "customer_sex",
    label: "Kön",
    sortable: false,
    formatter: (value, _key, _quote) => (value? value : "N/A")
  },
  {
    key: "client_company",
    label: "Bolagsnamn",
    sortable: true,
    formatter: (value, _key, _quote) => (value ? value : "Privatkund")
  },
  {
    key: "formatted_quote_value",
    label: "Värde",
    sortable: true
  },
  {
    key: "latest_customer_initiated_action_log_at",
    label: "Kundaktivitet",
    formatter: (value, _key, _quote) => value ? value.substring(0, 10) : '-'
  },
  {
    key: "primary_signature_at",
    label: "Signerings",
    sortable: true,
    sortDirection: 'desc',
    formatter: (value, _key, _quote) => value ? value.substring(0, 10) : '-'
  },
  {
    key: "seller",
    label: "Kontakt",
    sortable: true,
    formatter: (value, _key, _quote) => value ? value.name : '-'
  },
  {
    key: "statuses",
    label: "Status",
    sortable: false
  },
  {
    key: "tags",
    label: "Taggar",
    sortable: false
  },
  {
    key: "custom_field_1_content",
    label: "Fält 1",
    sortable: false
  },
  {
    key: "custom_field_2_content",
    label: "Fält 2",
    sortable: false
  },
  {
    key: "custom_field_3_content",
    label: "Fält 3",
    sortable: false
  },
  {
    key: "custom_field_4_content",
    label: "Fält 4",
    sortable: false
  },
  {
    key: "custom_field_5_content",
    label: "Fält 5",
    sortable: false
  },
  {
    key: "custom_field_6_content",
    label: "Fält 6",
    sortable: false
  },
  {
    key: "custom_field_7_content",
    label: "Fält 7",
    sortable: false
  },
  {
    key: "custom_field_8_content",
    label: "Fält 8",
    sortable: false
  },
  {
    key: "custom_field_9_content",
    label: "Fält 9",
    sortable: false
  },
  {
    key: "custom_field_10_content",
    label: "Fält 10",
    sortable: false
  },
  {
    key: "related_products",
    label: "Produkter",
    sortable: false
  }
];

const LOCALE = 'sv-SE';

export default {
  props: {
    enableQuotesTable: {
      type: Boolean,
      default: true
    },
    baseFilterConfig: {
      type: Object,
      default() { return {
        include: {},
        exclude : {}
      }; }
    },
    showContactMethods: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'filterConfig',
    'quoteCount'
  ],
  data() {
    return {
      uuid: uuidv4(),
      primaryKey: "id",
      sortBy: "created_at",
      sortDesc: true,
      datePickerConfig: {
        locale: LOCALE,
        dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
        startWeekday: "1",
        resetButton: true,
        closeButton: true,
        labelCloseButton: 'Stäng',
        labelHelp: 'Du kan använda piltangenter för att navigera i kalendern',
        labelNoDateSelected: '',
        labelResetButton: 'Töm',
      },
      filterConfig: {
        include: {
          createdAt: {
            min: null,
            max: null
          },
          latestCustomerInitiatedActionLogAt: {
            min: null,
            max: null
          },
          primarySignatureAt: {
            min: null,
            max: null
          },
          quoteProfile: [],
          customerName: [],
          clientCompany: [],
          seller: [],
          status: [],
          statusRequireAll: false,
          flowStatus: [],
          tags: [],
          tagsRequireAll: false,
          product: [],
        },
        exclude: {
          createdAt: {
            min: null,
            max: null
          },
          latestCustomerInitiatedActionLogAt: {
            min: null,
            max: null
          },
          primarySignatureAt: {
            min: null,
            max: null
          },
          quoteProfile: [],
          customerName: [],
          clientCompany: [],
          seller: [],
          status: [],
          statusRequireAll: false,
          flowStatus: [],
          tags: [],
          tagsRequireAll: false,
          product: [],
        }
      },
      combinedFilterConfig: {},
      filterOptions: {
        quoteProfile: [],
        customerName: [],
        clientCompany: [],
        seller: [],
        status: [],
        flowStatus: [],
        products: [],
      },
      filterOptionsLoaded: false,
      quotes: [],
      quoteProfileNameCount: 0,
      showProductsQuoteIds: [],
      tagCount: 0,
      productCount: 0,
      isLoaded: false,
      currentPage: 1,
      pageLimit: 50,
      quoteCount: null
    };
  },
  computed: {
    fields() {
      return FIELDS
        .filter((field) => { return field.key != "quote_profile_name" || this.quoteProfileNameCount > 1; })
        .filter((field) => { return field.key != "tags"  || this.tagCount > 0; })
        .filter((field) => { return field.key != "related_products"  || this.productCount > 0; })
      ;
    },
  },
  async mounted() {
    await this.updateFilterOptions();
    this.combinedFilterConfig = this.combineFilterConfig();
    if(!this.enableQuotesTable){
      this.isLoaded = true;
    }
  },
  watch: {
    quoteCount: {
      handler: function (newVal) {
        this.$emit('quoteCount', newVal);
      },
      immediate: true
    }
  },
  methods: {
    async updateFilterOptions() {
      const { data: filters } = await axios.get(route("data_analysis.quotes.filterOptions"));

      this.filterOptions.quoteProfile = filters.quote_profiles;
      this.filterOptions.customerName = filters.customer_names;
      this.filterOptions.clientCompany = [
        { value: null, label: 'Privatkund' },
          ...filters.client_companies
      ];
      this.filterOptions.status = filters.statuses;
      this.filterOptions.seller = filters.sellers;
      this.filterOptions.flowStatus = filters.flow_statuses;
      this.filterOptions.tags = filters.tags;

      this.filterOptions.products = await downloadProducts();

      this.filterOptionsLoaded = true;
    },
    async provideItems(ctx) {
      try {
        const sortBy = ctx.sortBy ? {
            sort_by: ctx.sortBy,
            sort_desc: ctx.sortDesc ? 1 : 0,
        } : {};

        const url = route("data_analysis.quotes.index", {
          _query: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
            ...sortBy,
            filter_config: ctx.filter
          }
        });

        const response = await axios.get(url);
        const quotes = response.data.data;

        this.quoteProfileNameCount += (new Set(quotes.map((item) => item.quote_profile_name))).size;
        this.tagCount += (new Set(quotes.flatMap((item) => item.tags))).size;
        this.productCount += (new Set(quotes.flatMap((item) => item.related_products))).size;
        this.quoteCount = response.data.meta.total;
        this.isLoaded = true;

        return quotes;

      } catch (error) {
        console.error(error);
        return [];

      } finally {
        this.isLoaded = true;
      }
    },
    handleFilter() {
      this.combinedFilterConfig = this.combineFilterConfig();
    },
    combineFilterConfig() {
      const combinedFilterConfig = {
        ...JSON.parse(JSON.stringify(this.baseFilterConfig))
      };

      const filterConfig = JSON.parse(JSON.stringify(this.filterConfig));

      for (const key of ['include', 'exclude']){
        for (const f of Object.keys(filterConfig[key])) {
          const v = filterConfig[key][f];

          if (combinedFilterConfig[key][f] && Array.isArray(combinedFilterConfig[key][f])) {
            combinedFilterConfig[key][f] = combinedFilterConfig[key][f].concat(v);
          } else if (!combinedFilterConfig[key][f]) {
            combinedFilterConfig[key][f] = v;
          } else {
            throw "Invalid filter config!";
          }
        }
      }

      this.$emit('filterConfig', JSON.parse(JSON.stringify(combinedFilterConfig)));

      return combinedFilterConfig;
    }
  }
}
</script>
