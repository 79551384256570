<template>
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <div>{{ $t("products.bundles.editor.header") }}</div>
            <button
                class="btn btn-sm btn-secondary"
                type="button"
                :disabled="saving || (theBundle && !theBundle.id)"
                @click="createNewBundle"
            >
                {{ $t("products.bundles.editor.button_create") }}
            </button>
        </div>

        <div class="card-body">
            <div v-if="bundles !== null" class="row">
                <div
                    class="col-md-4"
                    style="max-height: 90vh; overflow-y: auto"
                >
                    <ul
                        class="d-flex flex-column justify-content-center mb-3 pl-0"
                    >
                        <li
                            v-for="bundle in bundles"
                            :key="bundle.id"
                            class="d-flex justify-content-between align-items-center mb-3"
                        >
                            <div>
                                <h6 class="my-0 font-weight-bold">
                                    {{ bundle.name }}
                                    <small>(id: {{ bundle.id }})</small>
                                </h6>
                                <small
                                    >{{
                                        bundle.products.length
                                    }}
                                    produkter</small
                                >
                            </div>
                            <button
                                class="btn btn-sm btn-secondary ml-3"
                                type="button"
                                :disabled="saving || theBundle === bundle"
                                @click="editBundle(bundle)"
                            >
                                {{ $t("products.bundles.editor.button_edit") }}
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="col">
                    <products-bundle-form
                        v-if="theBundle"
                        :bundle="theBundle"
                        @updated="onUpdated($event)"
                    ></products-bundle-form>
                </div>
            </div>
            <b-spinner v-else></b-spinner>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ProductsBundleForm from "./_form.vue";

export default {
    components: {
        ProductsBundleForm,
    },
    data() {
        return {
            bundles: null,
            theBundle: null,
            saving: false,
            errorMessage: null,
            successMessage: null,
        };
    },
    async mounted() {
        const bundles = [];
        let nextUrl = this.route("products.bundles.index");
        while (nextUrl) {
            let response = await axios.get(nextUrl);
            bundles.push(...response.data.data);
            nextUrl = response.data.links.next;
        }
        this.bundles = bundles.sort((a, b) => a.name.localeCompare(b.name));
    },
    methods: {
        editBundle(bundle) {
            if (this.theBundle === bundle) {
                return;
            }
            this.theBundle = bundle;

            this.errorMessage = null;
            this.successMessage = null;
        },
        createNewBundle() {
            this.editBundle({
                id: null,
            });
        },
        onUpdated(bundle) {
            this.bundles = this.bundles.filter((b) => b.id !== bundle.id);
            this.bundles.push(bundle);
            this.bundles.sort((a, b) => a.name.localeCompare(b.name));
            this.theBundle = null;
        },
    },
};
</script>
