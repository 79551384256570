<template>
<div>
  <fieldset class="mt-3">
    <template v-if="initialized">
      <h5>{{$t('quoteProfile.settings.terms')}}</h5>

      <div class="input-group mb-3">
          <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" :id="`${uuid}-terms-enabled`" name="terms_enabled" v-model="enabled">
              <label class="custom-control-label" :for="`${uuid}-terms-enabled`">{{$t('quoteProfile.terms.enabled')}}</label>
          </div>
      </div>

      <Transition>
        <div v-if="enabled">
          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text">{{$t('quoteProfile.terms.header')}} *</div>
              </div>

              <input type="text" class="form-control" name="terms_header" required v-model="header">
          </div>

          <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text">{{$t('quoteProfile.terms.body')}} *</div>
              </div>

              <textarea class="form-control" name="terms_body" rows="8" required v-model="body"></textarea>
          </div>

          <div v-for="(link, idx) in links" :key="idx">
            <div class="d-flex justify-content-between align-items-baseline mb-2">
              <h6>{{$t('quoteProfile.terms.link')}} {{idx + 1}}</h6>
              <button type="button" class="btn btn-sm btn-secondary" @click="removeLink(idx)">{{ $t('quoteProfile.terms.button_remove_link') }}</button>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <div class="input-group-text">{{$t('quoteProfile.terms.link_title')}} *</div>
                </div>

                <input type="text" class="form-control" :name="`terms_links[${idx}][title]`" required v-model="link.title">
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <div class="input-group-text">{{$t('quoteProfile.terms.link_url')}} *</div>
                </div>

                <input type="url" class="form-control" :name="`terms_links[${idx}][url]`" required v-model="link.url">
            </div>
          </div>

          <button type="button" class="btn btn-secondary" @click="addLink">{{ $t('quoteProfile.terms.button_add_link') }}</button>
        </div>
      </Transition>
    </template>
    <template v-else>
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">{{$t('general.loading')}}...</span>
      </div>
    </template>
  </fieldset>
</div>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    quoteProfileId: { type: Number }
  },
  data() {
    return {
      uuid: uuidv4(),
      enabled: undefined,
      header: undefined,
      body: undefined,
      links: undefined
    };
  },
  computed: {
    initialized() {
      return this.enabled != undefined;
    }
  },
  async mounted() {
    try {
      let response = await axios.get(`/quote_profile/${this.quoteProfileId}/terms`);
      const terms = response.data;
      this.enabled = true;
      this.header = terms.header;
      this.body = terms.body;
      this.links = terms.links;
    } catch (err) {
      if (err.response && err.response.status == 404) {
        this.enabled = false;
        this.header = null;
        this.body = null;
        this.links = [];
      } else {
        throw err;
      }
    }
  },
  methods: {
    addLink() {
      this.links.push({
        url: undefined,
        title: undefined
      });
    },
    removeLink(idx) {
      this.links.splice(idx, 1);
    }
  }
}
</script>
