<template>
  <fieldset>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Bas-URL</div>
      </div>
      <input type="url" class="form-control" required pattern="https://.+" v-model="baseUrl" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">ClientId</div>
      </div>
      <input type="text" class="form-control" required v-model="clientId" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">ClientSecret</div>
      </div>
      <input type="text" class="form-control" required v-model="clientSecret" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Användarnamn</div>
      </div>
      <input type="text" class="form-control" required v-model="username" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Lösenord</div>
      </div>
      <input type="text" class="form-control" required v-model="password" @input="onUpdate">
    </div>
  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      baseUrl: null,
      clientId: null,
      clientSecret: null,
      username: null,
      password: null
    }
  },
  computed: {
    serializedValue() {
      return {
        base_url: this.baseUrl,
        client_id: this.clientId,
        client_secret: this.clientSecret,
        username: this.username,
        password: this.password
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.baseUrl = this.value.base_url;
          this.clientId = this.value.client_id;
          this.clientSecret = this.value.client_secret;
          this.username = this.value.username;
          this.password = this.value.password;
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
