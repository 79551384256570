<template>
  <div>
    <form @submit.prevent="onSubmit">
        <div class="form-group">
          <textarea class="form-control" v-model="note" required></textarea>
        </div>

        <div class="mb-3">
          <users-select v-model="users" multiple exclude-self placeholder="Välj användare för att skicka notis"></users-select>
        </div>

        <button type="submit" class="btn btn-primary btn-sm">Spara</button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    quoteId: { type: Number, required: true }
  },
  data() {
    return {
      note: null,
      users: null
    }
  },
  methods: {
    async onSubmit() {
      const { data: newNote } = await axios.post(route('quote.notes.store', this.quoteId), {
        note: this.note,
        users_to_notify: this.users
      });
      this.$emit('note-added', newNote);
      this.note = null;
      this.users = null;
    }
  }
}
</script>
