export default () => {
    const REGEX_INVALID_CHARS = new RegExp("[^0-9]", "g");
    const REGEX_VALID_DATA_WITH_PREFIX = new RegExp("^(19|20)[0-9]{10}$");
    const REGEX_VALID_DATA_WITH_DASH = new RegExp("^[0-9]{6}-[0-9]{4}$");

    const inputSelector = 'input[data-swedish-personal-id-number-input]';

    window.jQuery(document).on('change', inputSelector, (ev) => {
        const input = window.jQuery(ev.target);
        let val = input.val();

        val = val.replaceAll(REGEX_INVALID_CHARS, '');

        if (val.match(REGEX_VALID_DATA_WITH_PREFIX)) {
            val = val.substring(2, 12);
        }

        $(input).val(val);
    });
}
