<template>
  <div>
    <h5 class="mt-5 mb-3">Analys</h5>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Ålder (år)</div>
        </div>
        <input type="number" step="1" class="form-control" :readonly="readonly" v-model.number="alder_ar" @input="emitInput">
      </div>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Senast målat med</div>
        </div>
        <select class="custom-select" :disabled="readonly" v-model="senast_malat_med" @change="emitInput">
          <option :value="undefined">- ingen vald -</option>
          <option>Lasyr</option>
          <option>Akrylatfärg</option>
          <option>Alkydoljefärg</option>
          <option>Akrylat/Alkyd</option>
          <option>Linoljefärg</option>
          <option>Slamfärg</option>
          <option>Silikat</option>
          <option>Silikonharts</option>
          <option>Silikat</option>
          <option>Akrylharts Kalk</option>
          <option>KC</option>
          <option>Ej målat</option>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Målat (år)</div>
        </div>
        <input type="number" step="1" class="form-control" :readonly="readonly" v-model.number="malat_ar" @input="emitInput">
      </div>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Kulör NCS</div>
        </div>
        <input type="text" class="form-control" :readonly="readonly" v-model="kulor_ncs" @input="emitInput">
      </div>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Typ</div>
        </div>
        <select class="custom-select" :disabled="readonly" v-model="typ" @change="emitInput">
          <option :value="undefined">- ingen vald -</option>
          <option>Stående panel</option>
          <option>Liggande panel</option>
          <option>Mur</option>
          <option>Skiva</option>
          <option>Fönster</option>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Material</div>
        </div>
        <select class="custom-select" :disabled="readonly" v-model="material" @change="emitInput">
          <option :value="undefined">- ingen vald -</option>
          <option>Gran</option>
          <option>Lärk</option>
          <option>Tryckt</option>
          <option>Ädelträ</option>
          <option>Kalkputs</option>
          <option>KC</option>
          <option>Betong</option>
          <option>Lättbetong</option>
          <option>Tegel</option>
          <option>Cementfiberskiva</option>
          <option>Alu</option>
          <option>Plast</option>
        </select>
      </div>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Yta</div>
        </div>
        <select class="custom-select" :disabled="readonly" v-model="yta" @change="emitInput">
          <option :value="undefined">- ingen vald -</option>
          <option>Slät</option>
          <option>Medel</option>
          <option>Grov</option>
        </select>
      </div>
    </div>

    <h5 class="mt-5 mb-3">Åtgärder Norr</h5>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Kommentarer</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="kommentarer_norr" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Fuktkvot, Alg/mögel, Smuts, Flagning, Sprickor, Blåsor, Kritning, Gråträ, Rötskador</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Förbättringar</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="forbattringar_norr" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Lagning av fasad mm.</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Rengöring</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="rengoring_norr" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Slipning, målartvätt mm.</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Målning</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="malning_norr" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Olja, grundfärg, täckfärg mm.</small>
    </div>

    <h5 class="mt-5 mb-3">Åtgärder Syd</h5>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Kommentarer</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="kommentarer_syd" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Fuktkvot, Alg/mögel, Smuts, Flagning, Sprickor, Blåsor, Kritning, Gråträ, Rötskador</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Förbättringar</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="forbattringar_syd" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Lagning av fasad mm.</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Rengöring</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="rengoring_syd" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Slipning, målartvätt mm.</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Målning</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="malning_syd" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Olja, grundfärg, täckfärg mm.</small>
    </div>

    <h5 class="mt-5 mb-3">Åtgärder Väst</h5>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Kommentarer</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="kommentarer_vast" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Fuktkvot, Alg/mögel, Smuts, Flagning, Sprickor, Blåsor, Kritning, Gråträ, Rötskador</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Förbättringar</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="forbattringar_vast" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Lagning av fasad mm.</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Rengöring</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="rengoring_vast" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Slipning, målartvätt mm.</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Målning</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="malning_vast" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Olja, grundfärg, täckfärg mm.</small>
    </div>

    <h5 class="mt-5 mb-3">Åtgärder Öst</h5>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Kommentarer</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="kommentarer_ost" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Fuktkvot, Alg/mögel, Smuts, Flagning, Sprickor, Blåsor, Kritning, Gråträ, Rötskador</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Förbättringar</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="forbattringar_ost" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Lagning av fasad mm.</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Rengöring</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="rengoring_ost" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Slipning, målartvätt mm.</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Målning</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="malning_ost" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Olja, grundfärg, täckfärg mm.</small>
    </div>

    <h5 class="mt-5 mb-3">Åtgärder Övriga</h5>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Sockel</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="sockel" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Behöver sockeln målas eller åtgärdas?</small>
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Vindskivor mm</div>
        </div>
        <textarea class="form-control" :readonly="readonly" v-model="vindskivor_mm" @input="emitInput"></textarea>
      </div>

      <small v-if="showHelp" class="form-text text-muted">Behöver något mer målas eller åtgärdas?</small>
    </div>

  </div>
</template>

<style lang="scss" scoped>
textarea {
    min-height: 5.5rem;
}
</style>

<script>
export default {
  props: {
    value: { type: Object, default: {} },
    readonly: { type: Boolean, default: false },
    showHelp: { type: Boolean, default: true }
  },
  data(){
    return {
      alder_ar: undefined,
      senast_malat_med: undefined,
      malat_ar: undefined,
      kulor_ncs: undefined,
      typ: undefined,
      material: undefined,
      yta: undefined,
      kommentarer_norr: undefined,
      kommentarer_syd: undefined,
      kommentarer_ost: undefined,
      kommentarer_vast: undefined,
      forbattringar_norr: undefined,
      rengoring_norr: undefined,
      malning_norr: undefined,
      forbattringar_syd: undefined,
      rengoring_syd: undefined,
      malning_syd: undefined,
      forbattringar_ost: undefined,
      rengoring_ost: undefined,
      malning_ost: undefined,
      forbattringar_vast: undefined,
      rengoring_vast: undefined,
      malning_vast: undefined,
      sockel: undefined,
      vindskivor_mm: undefined,
    }
  },
  mounted() {
    this.captureValue();
  },
  watch: {
    value: 'captureValue'
  },
  methods: {
    captureValue() {
      this.alder_ar           = this.value.alder_ar;
      this.senast_malat_med   = this.value.senast_malat_med;
      this.malat_ar           = this.value.malat_ar;
      this.kulor_ncs          = this.value.kulor_ncs;
      this.typ                = this.value.typ;
      this.material           = this.value.material;
      this.yta                = this.value.yta;
      this.kommentarer_norr   = this.value.kommentarer_norr;
      this.kommentarer_syd    = this.value.kommentarer_syd;
      this.kommentarer_ost    = this.value.kommentarer_ost;
      this.kommentarer_vast   = this.value.kommentarer_vast;
      this.forbattringar_norr = this.value.forbattringar_norr;
      this.rengoring_norr     = this.value.rengoring_norr;
      this.malning_norr       = this.value.malning_norr;
      this.forbattringar_syd  = this.value.forbattringar_syd;
      this.rengoring_syd      = this.value.rengoring_syd;
      this.malning_syd        = this.value.malning_syd;
      this.forbattringar_ost  = this.value.forbattringar_ost;
      this.rengoring_ost      = this.value.rengoring_ost;
      this.malning_ost        = this.value.malning_ost;
      this.forbattringar_vast = this.value.forbattringar_vast;
      this.rengoring_vast     = this.value.rengoring_vast;
      this.malning_vast       = this.value.malning_vast;
      this.sockel             = this.value.sockel;
      this.vindskivor_mm      = this.value.vindskivor_mm;
    },
    emitInput() {
      this.$emit('input', {
        alder_ar:           this.alder_ar,
        senast_malat_med:   this.senast_malat_med,
        malat_ar:           this.malat_ar,
        kulor_ncs:          this.kulor_ncs,
        typ:                this.typ,
        material:           this.material,
        yta:                this.yta,
        kommentarer_norr:   this.kommentarer_norr,
        kommentarer_syd:    this.kommentarer_syd,
        kommentarer_ost:    this.kommentarer_ost,
        kommentarer_vast:   this.kommentarer_vast,
        forbattringar_norr: this.forbattringar_norr,
        rengoring_norr:     this.rengoring_norr,
        malning_norr:       this.malning_norr,
        forbattringar_syd:  this.forbattringar_syd,
        rengoring_syd:      this.rengoring_syd,
        malning_syd:        this.malning_syd,
        forbattringar_ost:  this.forbattringar_ost,
        rengoring_ost:      this.rengoring_ost,
        malning_ost:        this.malning_ost,
        forbattringar_vast: this.forbattringar_vast,
        rengoring_vast:     this.rengoring_vast,
        malning_vast:       this.malning_vast,
        sockel:             this.sockel,
        vindskivor_mm:      this.vindskivor_mm,
      })
    }
  }
}
</script>
