<template>
    <div>
        <a href="#" data-toggle="modal" data-target="#callback" style="color: black;">
            <div class="row">
                <div class="col-4 mt-2">
                    <a :href="siteUrl" target="_blank" @click.stop>
                        <img :src="logo" class="clientlogo">
                    </a>
                </div>
                <div class="col-8 mt-2" style="text-align: right; line-height: 1">
                    <div style="float: right; width: 55px">
                        <img
                            :src="avatar"
                            style="border-radius: 50%; margin-top: 5px"
                            height="55px"
                            width="55px"
                            />
                    </div>
                    <div style="float: right; margin-top: 5px; margin-right: 5px; width: 70%;">
                        <span class="messageicon"><i class="fal fa-comments wiggle"></i></span>
                        <br />
                        <span class="salesname">{{ name }}</span><br />
                        {{ title }}
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
  props: ["logo", "siteUrl", "avatar", "name", "title"],
};
</script>

<style lang="scss" scoped>
a {
    color: black;
    &:focus {
        outline: none;
    }
}

.salesname {
  font-size: 16px;
}

.messageicon {
  font-size: 20px;
}

.clientlogo {
  padding-top: 8px;
  max-height: 60px;
  max-width: 150px;
}

.salesimg {
  width: 55px;
  display: inline-block;
}
@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(6deg);
  }
  95% {
    transform: rotate(-6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wiggle {
  display: inline-block;
  animation: wiggle 1.2s infinite;
}
</style>
