<template>
  <div
    v-if="options.length <= radioLimit"
    class="container w-full"
  >
    <input
      style="opacity: 0; width: 0; height: 0; float: left;"
      required
      tabindex="-1"
      :name="name"
      :value="selected"
      @input="$event.target.value = selected"
    >
    <div class="row row-cols-4">
      <div
        v-for="user in options"
        :key="user.id"
        role="button"
        class="d-flex align-items-center rounded p-2"
        :class="selected === user.id ? 'is-selected' : ''"
        style="min-height: 60px;"
        @click.prevent="selected = user.id"
      >
        <img :src="user.avatar" class="avatar-image">
        <div>
          {{user.name}}
          <template v-if="selected === user.id">
            <br><small class="text-muted">{{user.email}}</small>
          </template>
        </div>
      </div>
    </div>
  </div>
  <v-select
    v-else
    class="w-full"
    :options="options"
    label="name"
    :reduce="(o) => o.id"
    placeholder="Välj en"
    name="impersonation"
    v-model="selected"
  >
    <template #search="{attributes, events}">
      <input
        class="vs__search"
        v-bind="attributes"
        v-on="events"
        :required="!selected"
      />
    </template>
    <template #option="user">
      <div class="d-flex align-items-center">
        <img :src="user.avatar" class="avatar-image">
        <div>{{user.name}}<br><small class="text-muted">{{user.email}}</small></div>
      </div>
    </template>

    <template #selected-option="user">
      <div class="d-flex align-items-center">
        <img :src="user.avatar" class="avatar-image">
        <div>{{user.name}}<br><small class="text-muted">{{user.email}}</small></div>
      </div>
      <input type="hidden" :name="name" :value="user.id">
    </template>
  </v-select>
</template>

<style lang="scss" scoped>
.is-selected {
  background-color: #f5f5f5;
}

.avatar-image {
  width: 2em;
  height: 2em;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 1em;
}
</style>

<script>
export default {
  props: {
    options: { type: Array, required: true },
    name: { type: String, required: true },
    salespersonId: { type: Number, default: null },
    livewireInstanceLoader: { type: Function, default: null },
    radioLimit: { type: Number, default: 24 },
  },
  data() {
    return {
      selected: this.salespersonId
    };
  },
  mounted() {
    document.addEventListener('livewire:load', () =>{
      this.emitToLivewire();
    })
  },
  watch: {
    selected() {
      this.emitToLivewire();
    },
    salespersonId() {
      if (this.selected == this.salespersonId) { return; }

      this.selected = this.salespersonId;
    }
  },
  methods: {
    emitToLivewire() {
      if (this.livewireInstanceLoader) {
        this.livewireInstanceLoader().salesperson_id = this.selected;
      }
    }
  }
}
</script>
