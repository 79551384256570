<template>
    <fieldset>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Länk</div>
            </div>
            <input class="form-control" required readonly :value="route('integrations.createQuoteUsingTemplate')">
        </div>

        <p>Använd följande dataformat i JSON:</p>
        <pre><code>
                {
                uuid: "{{ uuid }}", // Must always be this specific value
                quote_template_id: Integer,

                customer_name: String,
                customer_email: String|null, //
                customer_phone: String|null, // +46NNNNNNNNN
                }
            </code></pre>
    </fieldset>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    emits: [
        'input'
    ],
    props: {
        value: { type: Object, required: true },
    },
    data() {
        return {
            uuid: null,
        }
    },
    computed: {
        serializedValue() {
            return {
                uuid: this.uuid,
            };
        }
    },
    watch: {
        value: {
            handler() {
                if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
                    this.uuid = this.value.uuid;
                }

                if (!this.uuid) {
                    this.uuid = uuidv4();
                }


                if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
                    this.onUpdate();
                }
            },
            immediate: true
        }
    },
    methods: {
        onUpdate() {
            this.$emit('input', this.serializedValue);
        },
    }
};
</script>
