<template>
  <div class="pages row d-flex no-gutters justify-content-around" v-if="initialized">
    <div v-for="page, idx in mainModules" :key="page.id" :class="['col-lg-6 col-md-12', 'px-lg-3', ' my-4']">
      <a  :class="['page', 'text-decoration-none']" href="#" @click.prevent="openPage(page)">
        <img :src="page.image_url" class="img-fluid">
        <p style="font-size: 1.3em;">
          {{page.title}}
        </p>
      </a>
    </div>

    <div class="col-12 row d-flex justify-content-center align-items-center">
      <div v-for="page, idx in subModules" :key="page.id" :class="['col-lg-3', 'col-md-6', 'col-6', 'px-lg-3', ' my-4']">
        <a  :class="['page', 'text-decoration-none']" href="#" @click.prevent="openPage(page)">
          <img :src="page.image_url" class="img-fluid">
          <p style="font-size: 1.3em;">
            {{page.title}}
          </p>
        </a>
      </div>
    </div>

    <b-modal centered hide-footer :title="selectedPage ? selectedPage.title : ''" @shown="onModalShown" v-model="modalOpen" v-if="selectedPage">
      <template v-if="selectedPage">
        <div v-for="block in selectedPage.blocks" :key="block.id" class="mb-3">
          <img :src="block.image_url" class="mb-3" style="width: 100%; height: auto;" v-if="block.has_image">

          <h5 class="mb-2" v-if="block.has_title">
            {{block.title}}
          </h5>

          <div class="mb-3" style="white-space: pre-line;" v-if="block.has_body">{{ block.body }}</div>

          <div class="mb-3" style="position: relative; width: 100%; padding-top: 75%;" v-if="block.has_youtube_url">
            <iframe
              style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;"
              :src="youtubeEmbedUrl(block.youtube_url)"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div class="mb-3" v-if="block.has_cta" style="text-align: center;">
            <b-button variant="primary" :href="block.cta_url" target="_blank">
              {{block.cta_title}}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
  <div v-else>
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">{{$t('general.loading')}}...</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .pages {
    width: 100%;

    a.page {
      img {
        width: 100%;
        height: auto;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      p {
        margin-top: 8px;
        margin-bottom: 0px;
        text-align: center;
        text-decoration: none;
        color: black;
      }
    }
  }
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    quoteKey: { type: String },
    isTemplate: { type: Boolean, default: false }
  },
  data() {
    return {
      pages: undefined,
      selectedPage: undefined,
      modalOpen: false
    };
  },
  computed: {
    initialized() {
      return this.pages !== undefined;
    },
    mainModules() {
      return this.pages.filter(page => page.is_fullwidth);
    },
    subModules() {
      return this.pages.filter(page => !page.is_fullwidth);
    }
  },
  async mounted() {
    await this.loadPages();
  },
  methods: {
    async loadPages() {
      let response = await axios.get(`/quote/${this.quoteKey}/read_more_pages?is_template=${this.isTemplate}`);
      const pages = response.data.data;
      for (const page of pages) {
        const blocksResp = await axios.get(`/quote/${this.quoteKey}/read_more_pages/${page.id}/content_blocks?is_template=${this.isTemplate}`);
        page.blocks = blocksResp.data.data;
      }
      this.pages = pages;
    },
    openPage(page) {
      this.selectedPage = page;
      this.modalOpen = true;
    },
    youtubeEmbedUrl(youtubeUrl) {
      const splitted = youtubeUrl.split("/");
      const videoId = splitted[splitted.length - 1];
      return `https://www.youtube.com/embed/${videoId}`;
    },
    onModalShown() {
      this.$actionLog.logReadMoreOpen(this.quoteKey, this.selectedPage ? this.selectedPage.title : '');
    }
  }
}
</script>
