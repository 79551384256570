// resources/js/bootstrap.js

import Popper from "popper.js";
import $ from "jquery";
import "bootstrap";
import axios from "axios";

/**
 * Make Popper and jQuery globally available (if you need them on window)
 */
window.Popper = Popper;
window.$ = window.jQuery = $;

/**
 * Configure Axios
 */
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Example of setting a dynamic header from meta tags
 */
const activeCompanyIdMeta = document.head.querySelector(
    'meta[name="active-company-id"]'
);
if (activeCompanyIdMeta) {
    window.axios.defaults.headers.common["X-Salesbyte-Active-Company-Id"] =
        activeCompanyIdMeta.content;
}

/**
 * If using Laravel Echo with Pusher:
 */
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';

// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY, // or process.env.MIX_PUSHER_APP_KEY in older setups
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
