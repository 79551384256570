<template>
    <div>
        <nav class="navbar navbar-light bg-light mb-2">
            <a class="navbar-brand" href="#">{{pdfName}}</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" :data-target="`#navbar-${uuid}`" :aria-controls="`#navbar-${uuid}`" aria-expanded="false" aria-label="Byt dokument" v-if="documents.length > 1">
                <span class="navbar-toggler-icon"></span>
                <span class="d-none d-sm-inline">{{$t('pdfView.moreDocuments')}}</span>
            </button>

            <div class="collapse navbar-collapse" :id="`navbar-${uuid}`">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item" v-for="(doc, idx) in documents" :key="idx" :class="idx == selected ? 'active' : ''">
                        <a class="nav-link" href="#" data-toggle="collapse" :data-target="`#navbar-${uuid}`" @click.prevent="selected = idx">{{doc["pdf_name"]}} <span class="sr-only">({{ $t('pdfView.shownNow')}})</span></a>
                    </li>
                    </li>
                </ul>
            </div>
        </nav>

        <pdf-view :pdflink="pdfUrl" :is-pdf="isPdf"></pdf-view>
    </div>
</template>

<style scoped>
nav.navbar {
    display: flex !important;
}
canvas {
    border: 1px solid lightgray;
    direction: ltr;
    width: 100%;
}
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        documents: Array
    },
    data() {
        return {
            uuid: uuidv4(),
            selected: 0
        }
    },
    computed: {
        pdfName() {
            return this.documents[this.selected].pdf_name;
        },
        pdfUrl() {
            return this.documents[this.selected].pdf_url;
        },
        isPdf() {
            return this.documents[this.selected].is_pdf;
        }
    },
    methods: {
    }
}
</script>
