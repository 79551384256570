<template>
    <v-select
        :options="productsTruncated"
        :filterable="false"
        label="name"
        placeholder="Lägg till produkt"
        v-model="productToAdd"
        @input="addProduct($event)"
        @search="onSearch"
    >
        <template #search="{ attributes, events }">
            <input
                class="vs__search"
                v-bind="attributes"
                v-on="events"
                :required="required"
            />
        </template>
        <template v-slot:option="product">
            <div
                class="d-flex align-items-start"
                :style="`white-space: normal; margin: 0 -17px; ${
                    product.deleted || !product.in_stock ? 'opacity: 0.8;' : ''
                }`"
            >
                <template v-if="product.type === 'product'">
                    <img
                        :src="product.image_url"
                        alt=""
                        class="product-image mr-2"
                    />
                    <div
                        class="flex-grow-1 d-flex align-items-start justify-content-between"
                    >
                        <div>
                            <strong>{{ product.name }}</strong
                            ><br />
                            {{ product.price_including_vat }}
                            {{
                                $t("products.units." + product.price_unit, {
                                    currency: formattedCurrency(
                                        product.currency
                                    ),
                                })
                            }}
                            <div class="text-muted" style="font-size: 70%">
                                {{ product.sku }}
                            </div>
                            <template v-if="product.alternative_product">
                                <br />
                                <small
                                    >Alternativ:
                                    {{
                                        product.alternative_product.name
                                    }}</small
                                >
                            </template>
                        </div>
                        <i
                            v-if="product.deleted || !product.in_stock"
                            class="fa-regular fa-warning"
                            style="margin-top: 3px; margin-right: 2px"
                        ></i>
                    </div>
                </template>

                <template v-if="product.type === 'bundle'">
                    <div class="bundle-icon mr-2">
                        <i class="fa-light fa-cubes"></i>
                    </div>
                    <div
                        class="flex-grow-1 d-flex align-items-start justify-content-between"
                    >
                        <div>
                            <strong>{{ product.name }}</strong
                            ><br />
                            <small
                                >{{ product.products.length }} produkter</small
                            >
                        </div>
                        <i
                            v-if="product.deleted || !product.in_stock"
                            class="fa-regular fa-warning"
                            style="margin-top: 3px; margin-right: 2px"
                        ></i>
                    </div>
                </template>
            </div>
        </template>
        <template #list-footer>
            <li
                v-if="canLoadMoreProducts"
                class="d-flex justify-content-center my-2"
            >
                <b-button size="small" @click.prevent="loadMoreProducts"
                    >Ladda fler produkter..</b-button
                >
            </li>
        </template>
    </v-select>
</template>

<style lang="scss" scoped>
.product-image,
.bundle-icon {
    width: 20%;
    height: auto;

    aspect-ratio: 1 / 1;
}

.product-image {
    object-fit: contain;
    object-position: center top;
}

.bundle-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.5rem;
    line-height: 100%;
}
</style>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { route } from "ziggy";

const DEFAULT_PRODUCTS_LIMIT = 500;

export const downloadProducts = async (
    includeBundles,
    onlyRecurring = false
) => {
    const bundles = [];
    if (includeBundles && !onlyRecurring) {
        let bundlesNextUrl = route("products.bundles.index");
        while (bundlesNextUrl) {
            let bundlesResposne = await axios.get(bundlesNextUrl);
            bundles.push(...bundlesResposne.data.data);
            bundlesNextUrl = bundlesResposne.data.links.next;
            if (location.hostname === "localhost") {
                break;
            }
        }
        bundles.forEach((b) => {
            b.id = `bundle-${b.id}`;
            b.type = "bundle";
        });
        bundles.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
    }

    const products = [];
    let productsNextUrl = route("products.index", { recurring: onlyRecurring });
    while (productsNextUrl) {
        let productsResponse = await axios.get(productsNextUrl);
        products.push(...productsResponse.data.data);
        if (window.location.hostname === "localhost") {
            break;
        }
        productsNextUrl = productsResponse.data.links.next;
    }

    products.forEach((p) => (p.type = "product"));
    products.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );

    return bundles.concat(products);
};

export default {
    components: {
        vSelect,
    },
    props: {
        products: {
            type: Array,
            required: true,
        },
        ignoreOutOfStock: {
            type: Boolean,
            required: true,
        },
        ignoreDeleted: {
            type: Boolean,
            required: true,
        },
        allowSoloProducts: {
            type: Boolean,
            required: true,
        },
        required: {
            type: Boolean,
            required: true,
        },
        defaultDiscountType: {
            type: String,
            required: false,
            default: "kr",
        },
    },
    data() {
        return {
            productsQuery: "",
            productsLimit: DEFAULT_PRODUCTS_LIMIT,
            productToAdd: null,
        };
    },
    computed: {
        productsFiltered() {
            return this.products.filter((product) =>
                this.filterProduct(product, product.name, this.productsQuery)
            );
        },
        productsTruncated() {
            return this.productsFiltered.slice(0, this.productsLimit);
        },
        canLoadMoreProducts() {
            return this.productsTruncated.length < this.productsFiltered.length;
        },
    },
    methods: {
        addProduct(product) {
            const products = [];
            if (product.type === "bundle") {
                product.products.forEach((prodRef) => {
                    const prod = this.products.find(
                        (p) => p.type === "product" && p.id === prodRef.id
                    );
                    products.push({
                        product: prod,
                        amount: prodRef.amount,
                        is_add_on: false,
                        promptedValue: "",
                        discount_type: this.defaultDiscountType,
                        discount: undefined,
                    });
                });
            }

            if (product.type === "product") {
                products.push({
                    product: product,
                    amount: 1,
                    is_add_on: false,
                    promptedValue: "",
                    discount_type: this.defaultDiscountType,
                    discount: undefined,
                });
            }

            this.$emit("onAddProducts", products);

            this.productToAdd = null;
        },
        formattedCurrency(currency) {
            return this.$t("currencies." + currency);
        },
        filterProduct(product, label, search) {
            if (!this.ignoreOutOfStock && !product.in_stock) {
                return false;
            }
            if (!this.ignoreDeleted && product.deleted) {
                return false;
            }
            if (!this.allowSoloProducts && product.solo_product) {
                return false;
            }

            return (
                (label || "")
                    .toLocaleLowerCase()
                    .indexOf(search.toLocaleLowerCase()) > -1 ||
                (product.sku || "")
                    .toLocaleLowerCase()
                    .indexOf(search.toLocaleLowerCase()) > -1
            );
        },
        onSearch(query) {
            this.productsQuery = query;
            this.productsLimit = DEFAULT_PRODUCTS_LIMIT;
        },
        loadMoreProducts() {
            this.productsLimit += DEFAULT_PRODUCTS_LIMIT;
        },
    },
};
</script>
