<template>
  <b-modal
    centered
    hide-footer
    dialog-class="modal-full"
    body-class="d-flex flex-column"
    title="Chatt"
    v-model="modalOpen"
  >
  <quote-chat-messages-chat
      class="flex-grow-1"
      :quote-key="quoteKey"
      :is-customer="isCustomer"
      :is-template="isTemplate"
      :mark-as-seen="modalOpen"
      :should-reload-messages="modalOpen"
      :chat-available-for-customer="chatAvailableForCustomer"
      :chat-response-time-text="chatResponseTimeText"
    ></quote-chat-messages-chat>
  </b-modal>
</template>

<style scoped>
</style>

<script>
export default {
  props: {
    value: false,
    quoteKey: String,
    isCustomer: { type: Boolean, default: false },
    isTemplate: { type: Boolean, default: false },
      chatAvailableForCustomer: { type: Boolean, default: true},
      chatResponseTimeText: { type: String}
  },
  data() {
    return {
      modalOpen: this.value
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue != oldValue) {
        this.modalOpen = newValue;
      }
    },
    modalOpen(newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit('input', newValue);
      }
    }
  }
}
</script>
