<template>
  <div>
    <v-select
      v-if="filterEnabled && filterOptions"
      v-model="filter"
      required
      :options="filterOptions"
      :reduce="o => o.filter"
    />
    <div v-if="chats && chats.length > 0" class="quote-chats-index">
      <ul class="list-unstyled">
        <li v-for='chat in chats' :key='chat.id' class="p-3 chat" :class="chat.most_recent_customer_chat_message_is_missing_response ? 'chat-active' : ''" @click="openChat($event, chat)">
          <div class="d-flex justify-content-between align-items-start mb-0">
            <div class="d-flex justify-content-start align-items-start">
              <a :href="route('quotes.edit', chat.id)" class="d-block font-weight-bold text-dark">{{ chat.customer_name }}</a>
              <template v-if="chat.most_recent_customer_chat_message_is_missing_response">
                <div v-if="submittingMuteForId == chat.id" class="spinner-border spinner-border-sm ml-2"></div>
                <button v-else refs="mute" type="button" class="btn btn-primary btn-sm ml-2" style="font-size: 0.35rem;" @click.stop.capture="mute(chat)">
                  <i class="fa-light fa-envelope-circle-check"></i>
                </button>
              </template>
            </div>
            <div class="text-nowrap text-muted">{{formatDateTime(new Date(chat.most_recent_customer_chat_message_at))}}</div>
          </div>

          <div class="chat-message-preview text-truncate text-wrap text-muted">
            <i v-if="chat.most_recent_chat_message_is_customer_message" class="fa-light fa-sm fa-inbox-in"></i>
            <i v-else class="fa-light fa-sm fa-inbox-out"></i>
            {{ chat.most_recent_chat_message_message }}
          </div>
        </li>
      </ul>

      <quote-chat-messages-modal
        v-if="selected"
        :quote-key="selected.quote_key"
        :is-customer="false"
        v-model="modalOpen"
      ></quote-chat-messages-modal>
    </div>
    <div v-else-if="chats" class="text-center font-italic my-4">
      Du har inga meddelande
    </div>
    <div v-else class="text-center my-4">
      <div class="spinner-border"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chat .chat-message-preview {
  max-height: 44px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

<script>
import axios from 'axios';
const DATETIME_FORMATTER = new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'short' });
const SESSION_STORAGE_KEY_FILTER = 'quote-chats-index-filter';

export default {
  components: {
  },
  props: {
    filterEnabled: { type: Boolean, default: false }
  },
  data() {
    return {
      chats: null,
      selected: null,
      profiles: null,
      filter: sessionStorage.getItem(SESSION_STORAGE_KEY_FILTER) ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY_FILTER)) : {},
      modalOpen: false,
      interval: null,
      submittingMuteForId: null
    };
  },
  computed: {
    filterOptions() {
      const filterOptions = [
        { label: 'Egna', filter: {} },
        { label: 'Alla', filter: { all: true } },
      ];
      if (this.profiles) {
        this.profiles.forEach((profile) => {
          filterOptions.push({
            label: "Alla (" + profile.name + ")",
            filter: { quote_profile_id: profile.id, all: true }
          });
        });
      }
      return filterOptions;
    }
  },
  watch: {
    async modalOpen(newValue, _oldValue) {
      if (!newValue) { await this.reload(); }
    },
    async filter() {
      sessionStorage.setItem(SESSION_STORAGE_KEY_FILTER, JSON.stringify(this.filter));
      await this.reload();
    }
  },
  async mounted() {
    await this.loadProfiles();
    await this.reload();
    this.interval = setInterval(async () => {
      await this.reload();
    }, 20000);
  },
  unmounted() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    async reload() {
      const response = await axios.get(route('chats.index', this.filterEnabled ? this.filter : {}));
      this.chats = response.data;
    },
    async loadProfiles() {
      const response = await axios.get(route('quoteProfiles.index'));
      this.profiles = response.data;
    },
    openChat($event, chat) {
      if ($event.target.href && $event.target.href !== '#') {
        return;
      }

      $event.preventDefault();

      this.selected = chat;
      this.modalOpen = true
    },
    async mute(chat) {
      this.submittingMuteForId = chat.id;
      try {
        await axios.post(route('quote.chatMessages.mute', chat.id));
        await this.reload();
      } finally {
        this.submittingMuteForId = null;
      }
    },
    formatDateTime(date) {
      return DATETIME_FORMATTER.format(date);
    }
  }
}
</script>
