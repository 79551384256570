<template>
    <div
        class="modal fade"
        id="bankid"
        tabindex="-1"
        role="dialog"
        aria-labelledby="bankidLabel"
        aria-hidden="true"
        ref="modal"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        @click="userSelected = undefined"
                        data-dismiss="modal"
                        aria-label="Stäng"
                    >
                        <span aria-hidden="true"
                            ><i class="fal fa-times"> </i
                        ></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card-body">
                        <div class="form-group">
                            <template v-if="quoteEsignDesc">
                                <p style="white-space: pre-line">
                                    {{ quoteEsignDesc }}
                                </p>
                            </template>
                            <template v-else>
                                <p>
                                    FÖR ATT LÄGGA BESTÄLLNING:<br />
                                    1. Markera rätt alternativ nedan (blå
                                    ram)<br />
                                    2. Fyll i personnummer<br />
                                    3. Klicka på "Signera" för att signera med
                                    BankID.
                                </p>
                                <p>
                                    (Vill du istället läsa er offert. Klicka på
                                    visa dokument.)
                                </p>
                            </template>

                            <div
                                class="card mb-2 w-100"
                                :class="
                                    selected == idx
                                        ? 'border-color-profile-color'
                                        : null
                                "
                                v-for="(quoteOption, idx) in quoteOptions"
                                @click.prevent="userSelected = idx"
                            >
                                <div
                                    class="card-body d-flex align-items-center"
                                >
                                    <div style="flex-grow: 1">
                                        <b>{{ quoteOption.name }}</b>
                                        <span
                                            v-if="quoteOption.recommended"
                                            style="color: #228b22"
                                            ><i class="far fa-thumbs-up"></i>
                                            Rekommenderat val!</span
                                        ><br />

                                        <template v-if="!disablePdfViewer">
                                            <a
                                                href="#"
                                                class="mb-3 mt-3"
                                                @click.prevent="
                                                    dismissAndOpenQuoteOption(
                                                        idx
                                                    )
                                                "
                                                ><i
                                                    class="fas fa-external-link"
                                                ></i>
                                                Läs mer</a
                                            >
                                        </template>
                                        <template v-else>
                                            <a
                                                :href="quoteOption.main_pdf_url"
                                                target="_blank"
                                                class="mb-3 mt-3"
                                                @click.stop
                                                ><i
                                                    class="fas fa-external-link"
                                                ></i>
                                                Läs mer</a
                                            >
                                        </template>
                                    </div>
                                    <div
                                        v-if="selected == idx"
                                        style="font-size: 1.8em"
                                        class="pl-2 py-3 text-color-profile-color"
                                    >
                                        <i class="far fa-check"></i>
                                    </div>
                                </div>
                            </div>
                            <primary-signature-prompt
                                :quote-key="quoteKey"
                                :sign-component-name="signComponentName"
                                :item-id="`${quoteKey}/${quoteOptions[selected].id}`"
                                :item-description="signComponentItemDescription"
                                :app-url="appUrl"
                                :redirect-on-signed-url="`/signed/${quoteKey}`"
                                :signee-name="signComponentSigneeName"
                                :signature-button-label="
                                    signComponentSignatureButtonLabel
                                "
                                :google-ads-conversion="googleAdsConversion"
                                :meta-ads-conversion="metaAdsConversion"
                                :options-payments-option-id="
                                    quoteOptions[selected].id
                                "
                                :options-payments-dintero-profile-id="
                                    optionsPaymentsDinteroProfileId
                                "
                                :options-payments-method="optionsPaymentsMethod"
                            >
                                <template #more-information-header
                                    ><slot name="more-information-header"></slot
                                ></template>
                                <template #more-information-fieldset
                                    ><slot
                                        name="more-information-fieldset"
                                    ></slot
                                ></template>
                            </primary-signature-prompt>
                        </div>
                        <hr />

                        <terms-show :quote-key="quoteKey"></terms-show>

                        <powered-by-salesbyte
                            class="mt-5"
                        ></powered-by-salesbyte>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card {
    cursor: pointer;
}
</style>

<script>
export default {
    props: {
        appUrl: String,
        quoteKey: String,
        quoteOptions: Array,
        initiallySelectedId: {
            type: Number,
            default: 0,
        },
        quoteEsignDesc: {
            type: String,
            required: true,
        },
        signComponentName: { type: String, required: true },
        signComponentSigneeName: { type: String, default: null },
        signComponentSignatureButtonLabel: { type: String, required: true },
        signComponentItemDescription: { type: String, default: null },
        disablePdfViewer: Boolean,
        googleAdsConversionEventTargets: { type: Array, required: true },
        metaAdsConversionPixelIds: { type: Array, required: true },
        optionsPaymentsMethod: { type: String, default: null },
        optionsPaymentsDinteroProfileId: { type: String, default: null },
    },
    data() {
        return {
            userSelected: undefined,
        };
    },
    computed: {
        selected: function () {
            if (this.userSelected !== undefined) {
                return this.userSelected;
            }

            return this.quoteOptions.findIndex(
                (value) => value.id === this.initiallySelectedId
            );
        },
        googleAdsConversion() {
            return {
                event_targets: this.googleAdsConversionEventTargets,
            };
        },
        metaAdsConversion() {
            return {
                pixel_ids: this.metaAdsConversionPixelIds,
            };
        },
    },
    mounted() {
        const thisModal = window.jQuery(this.$refs["modal"]);
        thisModal.on("shown.bs.modal", () =>
            this.$actionLog.logQuoteSignatureViewOpen(this.quoteKey)
        );
    },
    methods: {
        dismissAndOpenQuoteOption(idx) {
            this.$emit("view-quote-option", idx);
            const thisModal = window.jQuery(this.$refs["modal"]);
            const newModal = window.jQuery(`#options-modal`);
            thisModal.one("hidden.bs.modal", () => newModal.modal("show"));
            thisModal.modal("hide");
        },
    },
};
</script>
