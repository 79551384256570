<template>
  <div>
    <div style="padding: 20px;">
      <quote-notes-form :quote-id="quoteId" @note-added="onNoteAdded"></quote-notes-form>
    </div>

    <ul v-if="notes && notes.length > 0" class="notes-index list-unstyled">
      <li v-for="note in notes" :key="note.id" class="note p-3">
        <div class="d-flex justify-content-between align-items-start mb-0">
            <h6 class="d-block font-weight-bold text-dark mx-0 mt-0 mb-1">{{ note.user.name }}</h6>

            <span class="text-nowrap text-muted">{{formatDateTime(new Date(note.created_at))}}</span>
        </div>

        <p class="whitespace-pre-line">{{ note.note }}</p>
      </li>
    </ul>

    <div class="text-center my-3">
      <b-spinner v-if="isLoading">
      </b-spinner>

      <button v-else-if="nextUrl" role="button" class="btn btn-primary" @click.prevent="loadMore">
        Ladda fler
      </button>
    </div>
  </div>
</template>

<style scoped>
.whitespace-pre-line {
  white-space: pre-line;
}
</style>

<script>
import axios from 'axios';
const DATETIME_FORMATTER = new Intl.DateTimeFormat('default', { dateStyle: 'short', timeStyle: 'short' });

export default {
  props: {
    quoteId: { type: Number, required: true }
  },
  data() {
    return {
      notes: [],
      nextUrl: null,
      isLoading: false,
      users: []
    }
  },
  async mounted() {
    this.notes = [];
    this.nextUrl = route('quote.notes.index', this.quoteId);
    await this.loadMore();
  },
  methods: {
    async loadMore() {
      try {
        this.isLoading = true;

        const response = await axios.get(this.nextUrl);
        this.nextUrl = response.data.links.next;
        this.notes = [
          ...this.notes,
          ...response.data.data
        ];
      } finally {
        this.isLoading = false;
      }
    },
    onNoteAdded(note) {
      this.notes.unshift(note);
    },
    formatDateTime(date) {
      return DATETIME_FORMATTER.format(date);
    }
  }
}
</script>
