<template>
<div>
    <div class="mt-3">
        <div class="card no-shadow" style="border: 0px;">
            <div class="card-body">
                <div class="row">
                    <div v-for="(usp, idx) in usps" :key="idx" class="col-6 mt-3">
                        <h5>
                            <i class="fa fa-check" :style="`color: ${checkmarkColor};`"></i>
                            {{ usp }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ["checkmarkColor", "usp1", "usp2", "usp3", "usp4"],
    data() {
        return {
            usps: [this.usp1, this.usp2, this.usp3, this.usp4]
        };
    }
};
</script>

<style>
</style>
