<template>
  <div>
    <h3 class="mb-3">
      <i class="fal fa-file-signature"></i>
    </h3>

    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" :id="`${uuid}-signable`" name="signable" v-model="signableValue">
      <label class="custom-control-label" :for="`${uuid}-signable`">E-signering till</label>
    </div>

    <div v-if="signableValue" class="input-group input-group-sm mt-2 mb-3">
      <input :key="signableUntilValue" type="date" :min="today" class="form-control" name="signable_until" v-model="signableUntilValue">

      <div v-if="signableUntilValue" class="input-group-append">
        <button type="button" class="btn btn-primary" @click.prevent="clearSignableUntil"><i class="fa-regular fa-times" aria-hidden="true"></i></button>
      </div>
    </div>
    <div v-else>
      <input type="hidden" name="signable_until" :value="signableUntilValue">
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    signable: {
      type: Boolean,
      required: true
    },
    signableUntil: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      uuid: uuidv4(),
      signableValue: this.signable,
      signableUntilValue: this.signableUntil,
    }
  },
  watch: {
    signableValue() {
      this.clearSignableUntil();
    }
  },
  computed: {
    today() {
      return new Date().toISOString().split('T')[0]
    }
  },
  methods: {
    clearSignableUntil() {
      this.signableUntilValue = null;
      this.$forceUpdate(); // Hack to fix date input requiring value in safari
    }
  }
}
</script>
