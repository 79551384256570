<template>
  <fieldset>
    <p>För mer instruktioner om hur du tar fram dessa inställningar, se <a href="https://developer.briqpay.com/docs/3.0.0/">Briqpay dokumentation.</a></p>
    <p>OBS! Det är ej lämpligt att koppla fler än en integration av denna typ till en profil.</p>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Miljö</div>
      </div>
      <select class="custom-select" required v-model="environment" @change="onUpdate">
        <option value="production">Produktionsmiljö</option>
        <option value="playground">Testmiljö</option>
      </select>
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Produkttyp</div>
      </div>
      <select class="custom-select" required v-model="defaultProductType" @change="onUpdate">
        <option value="physical">Fysisk produkt</option>
        <option value="digital">Digital produkt</option>
      </select>
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Username</div>
      </div>
      <input type="text" class="form-control" required v-model="username" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Key</div>
      </div>
      <input type="text" class="form-control" required v-model="key" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Product ID</div>
      </div>
      <input type="text" class="form-control" required v-model="productId" @input="onUpdate" format="^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Länk till era köpvilkor</div>
      </div>
      <input type="url" class="form-control" required v-model="termsUrl" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: Adressrad 1</div>
      </div>
      <input type="text" class="form-control" required v-model="fieldAddress1" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: Adressrad 2</div>
      </div>
      <input type="text" class="form-control" v-model="fieldAddress2" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: Postnr</div>
      </div>
      <input type="text" class="form-control" required v-model="fieldZipCode" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: City</div>
      </div>
      <input type="text" class="form-control" required v-model="fieldCity" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: ID. nr</div>
      </div>
      <input type="text" class="form-control" v-model="fieldIdNumber" @input="onUpdate">
    </div>

  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      username: null,
      key: null,
      productId: null,
      termsUrl: null,
      environment: null,
      defaultProductType: null,
      fieldAddress1: null,
      fieldAddress2: null,
      fieldZipCode: null,
      fieldCity: null,
      fieldIdNumber: null
    }
  },
  computed: {
    serializedValue() {
      return {
        username: this.username,
        key: this.key,
        product_id: this.productId,
        terms_url: this.termsUrl,
        environment: this.environment,
        default_product_type: this.defaultProductType,
        field_address1: this.fieldAddress1,
        field_address2: this.fieldAddress2,
        field_zip_code: this.fieldZipCode,
        field_city: this.fieldCity,
        field_id_number: this.fieldIdNumber,
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.username = this.value.username;
          this.key = this.value.key;
          this.productId = this.value.product_id;
          this.termsUrl = this.value.terms_url;
          this.environment = this.value.environment;
          this.defaultProductType = this.value.default_product_type;
          this.fieldAddress1 = this.value.field_address1;
          this.fieldAddress2 = this.value.field_address2;
          this.fieldZipCode = this.value.field_zip_code;
          this.fieldCity = this.value.field_city;
          this.fieldIdNumber = this.value.field_id_number;
        }

        if (!this.fieldAddress1) {
          this.fieldAddress1 = 'Adress';
        }

        if (!this.fieldZipCode) {
          this.fieldZipCode = 'Postnummer';
        }

        if (!this.fieldCity) {
          this.fieldCity = 'Ort';
        }

        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.onUpdate();
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
