<template>
    <div>
        <div class="px-5">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <h2 class="text-center">
                        <template v-if="!surveysEnabled">
                            Massutskick
                        </template>
                        <template v-else> Enkätutskick </template>
                    </h2>
                </div>

                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-header">Meddelande</div>
                        <div class="card-body">
                            <div v-if="type != 'none'" class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        Meddelande
                                    </div>
                                </div>
                                <textarea
                                    class="form-control"
                                    rows="6"
                                    v-model="message"
                                ></textarea>
                            </div>

                            <template v-if="surveysEnabled">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            Bifoga enkät
                                        </div>
                                    </div>
                                    <select
                                        class="custom-select"
                                        v-model="attachedSurveyId"
                                        required
                                    >
                                        <option :value="null" selected>
                                            Välj...
                                        </option>
                                        <option
                                            v-for="survey in surveys"
                                            :key="survey.id"
                                            :value="survey.id"
                                        >
                                            {{ survey.name }}
                                        </option>
                                    </select>
                                </div>

                                <div
                                    v-if="attachedSurveyId"
                                    class="input-group mb-3"
                                >
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            Enkät måste vara inskickad
                                        </div>
                                    </div>
                                    <b-form-datepicker
                                        class="form-control"
                                        v-model="attachedSurveyRequiredAt"
                                        v-bind="datePickerConfig"
                                    ></b-form-datepicker>
                                </div>
                            </template>

                            <div class="mb-3 text-center">
                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        :id="`${uuid}-type-sms`"
                                        value="sms"
                                        v-model="type"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="`${uuid}-type-sms`"
                                        >SMS</label
                                    >
                                </div>

                                <div class="form-check form-check-inline">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        :id="`${uuid}-type-email`"
                                        value="email"
                                        v-model="type"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="`${uuid}-type-email`"
                                        >e-post</label
                                    >
                                </div>

                                <div
                                    v-if="surveysEnabled"
                                    class="form-check form-check-inline"
                                >
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        :id="`${uuid}-type-none`"
                                        value="none"
                                        v-model="type"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="`${uuid}-type-none`"
                                        >inget utskick</label
                                    >
                                </div>
                            </div>

                            <p
                                v-if="type === 'sms'"
                                class="mb-3 text-center font-italic"
                            >
                                Debiteringsmässigt är alltid ett SMS 160 tecken.
                                Emojis kan innebära flera tecken per styck.
                            </p>

                            <div
                                v-if="!submitting"
                                class="btn-toolbar justify-content-center"
                            >
                                <button
                                    type="button"
                                    class="btn btn-secondary mr-1"
                                    :disabled="submitDisabled"
                                    @click.prevent="submit(true)"
                                >
                                    Skicka till dig själv
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary ml-1 mr-1"
                                    :disabled="submitDisabled"
                                    @click.prevent="submit(false)"
                                >
                                    Skicka till {{ quoteCount }} kunder
                                </button>

                                <button
                                    type="button"
                                    class="btn btn-secondary ml-1"
                                    data-toggle="collapse"
                                    href="#tagInstructions"
                                    aria-expanded="false"
                                    aria-controls="#tagInstructions"
                                >
                                    Läs mer / avancerat
                                </button>
                            </div>
                            <div v-else class="text-center">
                                <b-spinner label="Skickar.."></b-spinner>
                            </div>

                            <b-alert
                                v-if="sendSuccess"
                                class="mt-5"
                                variant="success"
                                show
                                >Ditt meddelande har skickats ut!</b-alert
                            >
                            <b-alert
                                v-if="sendError"
                                class="mt-5"
                                variant="danger"
                                show
                                >Ett fel uppstod när meddelandet skickades ut,
                                och vissa meddelanden kanske inte kunde
                                eventuellt inte levereras. Kontakta SalesByte
                                support för mer hjälp.</b-alert
                            >
                        </div>
                    </div>

                    <div class="collapse" id="tagInstructions">
                        <div class="card mb-4">
                            <div class="card-body">
                                <slot name="tag-instructions"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <data-analysis-quotes-selector
            @filterConfig="onFilterConfig"
            @quoteCount="onQuoteCount"
            :base-filter-config="baseFilterConfig"
            show-contact-methods
        />
    </div>
</template>

<style lang="sass" scoped></style>

<script>
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const LOCALE = "sv-SE";

export default {
    props: {
        surveysEnabled: { type: Boolean, default: false },
    },
    data() {
        return {
            uuid: uuidv4(),
            type: "sms",
            message: "",
            submitting: false,
            sendSuccess: false,
            sendError: false,
            quoteCount: 0,
            filterConfig: {},
            surveys: [],
            attachedSurveyId: null,
            attachedSurveyRequiredAt: null,
            datePickerConfig: {
                locale: LOCALE,
                dateFormatOptions: {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                },
                startWeekday: "1",
                resetButton: true,
                closeButton: true,
                labelCloseButton: "Stäng",
                labelHelp:
                    "Du kan använda piltangenter för att navigera i kalendern",
                labelNoDateSelected: "",
                labelResetButton: "Töm",
            },
        };
    },
    computed: {
        submitDisabled() {
            return (
                !this.quoteCount ||
                this.quoteCount < 1 ||
                (this.type != "none" && !this.message) ||
                !this.type
            );
        },
        baseFilterConfig() {
            const config = {};

            if (this.type == "sms" && !this.attachedSurveyId) {
                config.has_customer_phone = true;
            }

            if (this.type == "email" && !this.attachedSurveyId) {
                config.has_customer_email = true;
            }

            return { include: config, exclude: {} };
        },
        filterQuoteFunc() {
            return this.buildFilterQuoteFunc(false);
        },
    },
    async mounted() {
        if (this.surveysEnabled) {
            await this.loadSurveys();
        }
    },
    methods: {
        buildFilterQuoteFunc(ignoreSurvey) {
            if (
                this.type == "sms" &&
                (!this.attachedSurveyId || ignoreSurvey)
            ) {
                return (quote) => !!quote.customer_phone;
            }

            if (
                this.type == "email" &&
                (!this.attachedSurveyId || ignoreSurvey)
            ) {
                return (quote) => !!quote.customer_email;
            }

            return (_quote) => true;
        },
        async loadSurveys() {
            const surveys = [];
            let nextUrl = this.route("surveys.index");
            while (nextUrl) {
                let response = await axios.get(nextUrl);
                surveys.push(...response.data.data);
                nextUrl = response.data.links.next;
            }
            this.surveys = surveys.sort((a, b) => a.name.localeCompare(b.name));
        },
        async submit(test) {
            if (this.submitting) {
                return;
            }
            this.submitting = true;
            this.sendSuccess = false;
            this.sendError = false;

            if (!test) {
                const confirmMsg = `Är du säker på att du vill skicka till ${this.quoteCount} kunder?`;
                const confirmed = await this.$bvModal.msgBoxConfirm(
                    confirmMsg,
                    {
                        okTitle: "Skicka",
                        cancelTitle: "Avbryt",
                        centered: true,
                    }
                );

                if (confirmed !== true) {
                    this.submitting = false;
                    return;
                }
            }

            try {
                let nextQuoteUrl = route("data_analysis.quotes.index", {
                    _query: {
                        per_page: 10,
                        filter_config: this.filterConfig,
                    },
                });

                while (nextQuoteUrl) {
                    const response = await axios.get(nextQuoteUrl);
                    nextQuoteUrl = response.data.links.next;
                    const quotes = response.data.data;

                    await axios.post(this.route("quote_messages.store"), {
                        quote_ids: quotes.map((q) => q.id),
                        message: this.message,
                        type: this.type,
                        test: test,
                        attached_survey_id: this.attachedSurveyId,
                        attached_survey_required_at:
                            this.attachedSurveyRequiredAt,
                    });

                    // Only run first batch if test
                    if (test) {
                        break;
                    }
                }
                this.sendSuccess = true;
            } catch (err) {
                this.sendError = true;
                console.warn(err);
            } finally {
                this.submitting = false;
            }
        },
        onFilterConfig(filterConfig) {
            this.filterConfig = filterConfig;
        },
        onQuoteCount(quoteCount) {
            this.quoteCount = quoteCount;
        },
    },
};
</script>
