<template>
  <div class="container">
    <div class="row align-items-center mb-3">
      <div class="col-md-6 offset-md-3">
        <h2 class="text-center">{{headline}}</h2>
      </div>

      <div v-if="users.length > 1" class="col-md-3">
        <h5>Filtrera på användare</h5>
        <v-select
          :options="users"
          label="name"
          v-model="selectedUser"
        >
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
      </div>
    </div>

    <stats-dashboard :user-id="userIdForStats" />
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  props: {
    users: { type: Array, required: true },
  },
  data() {
    return {
      selectedUser: (this.users.length == 1 ? this.users[0] : null)
    };
  },
  computed: {
    headline() {
        if (this.selectedUser) {
          return 'Säljstatistik för ' + this.selectedUser.name;
        } else {
          return 'Säljstatistik för hela bolaget';
        }
    },
    userIdForStats() {
      return this.selectedUser ? this.selectedUser.id : null;
    }
  }
}
</script>
