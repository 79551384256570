<template>
    <div v-if="products" class="row">
        <div class="col-md-4" style="max-height: 90vh; overflow-y: auto">
            <div class="d-flex justify-content-between mb-3">
                <h4 class="my-0">
                    {{ $t("products.editor.header_manually_created") }}
                </h4>
                <button
                    class="btn btn-sm btn-secondary"
                    type="button"
                    :disabled="theProduct"
                    @click="createNewProduct"
                >
                    {{ $t("products.editor.button_create") }}
                </button>
            </div>

            <ul class="d-flex flex-column justify-content-center mb-3 pl-0">
                <li
                    v-for="product in productsManuallyCreated"
                    :key="product.id"
                    class="d-flex justify-content-between align-items-center mb-3"
                    :class="{ 'is-deleted-product': product.deleted }"
                >
                    <div class="flex-grow-1">
                        <h6 class="my-0 font-weight-bold">
                            {{ product.name }}
                        </h6>
                        <small>{{ product.sku }}</small>
                    </div>
                    <button
                        class="btn btn-sm btn-secondary ml-2"
                        type="button"
                        :disabled="theProduct"
                        @click="pickProduct(product)"
                    >
                        {{ $t("products.editor.button_edit") }}
                    </button>
                    <button
                        class="btn btn-sm btn-secondary ml-2"
                        type="button"
                        :disabled="theProduct || cloning"
                        @click="cloneProduct(product)"
                    >
                        {{ $t("products.editor.button_clone") }}
                        <i
                            class="fal fa-circle-notch fa-spin"
                            v-show="cloning && cloning.id === product.id"
                        ></i>
                    </button>
                </li>
            </ul>

            <div class="d-flex justify-content-between mb-3">
                <h4 class="my-0">
                    {{ $t("products.editor.header_integration_created") }}
                </h4>
            </div>

            <ul
                v-if="productsIntegrationCreated"
                class="d-flex flex-column justify-content-center mb-3 pl-0"
            >
                <li
                    v-for="product in productsIntegrationCreated"
                    :key="product.id"
                    class="d-flex justify-content-between align-items-center mb-3"
                    :class="{ 'is-deleted-product': product.deleted }"
                >
                    <div>
                        <h6 class="my-0 font-weight-bold">
                            {{ product.name }}
                        </h6>
                        <small>{{ product.sku }}</small>
                    </div>
                    <button
                        class="btn btn-sm btn-secondary ml-3"
                        type="button"
                        @click="pickProduct(product)"
                    >
                        {{ $t("products.editor.button_view") }}
                    </button>
                </li>
            </ul>
            <div v-else></div>
        </div>
        <div v-if="theProduct" class="col-md-7 offset-md-1">
            <products-form
                ref="form"
                :products="products"
                :product="theProduct"
                @updated="onUpdated"
                @created="onCreated"
                @cancel="onCancel"
            ></products-form>
        </div>
    </div>
    <b-spinner v-else></b-spinner>
</template>

<style lang="scss" scoped>
.is-deleted-product {
    opacity: 0.5;
}
</style>

<script>
import axios from "axios";
const PRICE_CLEANUP_REGEXP = /[^0-9\.]/g;

import ProductsForm from "./_form.vue";

export default {
    components: {
        ProductsForm,
    },
    data() {
        return {
            products: null,
            theProduct: null,
            cloning: null,
        };
    },
    async mounted() {
        const products = [];
        let nextUrl = this.route("products.index");
        while (nextUrl) {
            let productsResponse = await axios.get(nextUrl);
            products.push(...productsResponse.data.data);
            nextUrl = productsResponse.data.links.next;
        }
        const sortedProducts = products.sort((a, b) => {
            if (a.deleted === b.deleted) {
                return 0;
            }
            return a.deleted ? 1 : -1;
        });
        sortedProducts.forEach((p) => this.formatProductCategories(p));
        this.products = sortedProducts;
    },
    computed: {
        productsManuallyCreated() {
            return this.products.filter((p) => p.manually_created);
        },
        productsIntegrationCreated() {
            return this.products.filter((p) => !p.manually_created);
        },
    },
    methods: {
        pickProduct(product) {
            this.theProduct = product;
        },
        async cloneProduct(product) {
            this.cloning = product;
            const url = route("products.clone", [product.id]);
            try {
                const response = await axios.post(url);
                const newProduct = response.data;
                this.products.push(newProduct);
                this.theProduct = newProduct;
            } finally {
                this.cloning = null;
            }
        },
        createNewProduct() {
            this.pickProduct({ id: "_new" });
        },
        onUpdated(product) {
            this.formatProductCategories(product);
            this.products.splice(
                this.products.indexOf(this.theProduct),
                1,
                product
            );
            this.theProduct = product;
        },
        onCreated(product) {
            this.formatProductCategories(product);
            this.products.splice(0, 0, product);
            this.theProduct = product;
        },
        onCancel() {
            this.theProduct = null;
        },
        formatProductCategories(product) {
            if (!product.categories) {
                product.categories = [];
            }
            if (product.categories.length === 0) {
                return;
            }
            product.categories = product.categories.map((cat) => ({
                name: cat,
            }));
        },
    },
};
</script>
