<template>
    <div class="mt-4">
        <template v-if="chatMessages && chatMessages.length > 0">
            <chat-message
                v-for="msg in chatMessages"
                :key="msg.id"
                class="my-3"
                :senderName="msg.sender.name"
                :senderAvatar="msg.sender.avatar"
                :senderIsSelf="msg.sender.is_customer === isCustomer"
                :message="msg.message"
                :timestamp="isCustomer ? null : new Date(msg.created_at)"
            ></chat-message>
        </template>
        <div v-else-if="chatMessages" class="text-center font-italic mb-4">
            Starta en chatt med oss här!
        </div>
        <button
            class="d-block btn signbuttoninline my-2 mx-auto"
            @click="modalOpen = true"
        >
            {{
                chatAvailableForCustomer
                    ? "Skriv meddelande"
                    : "Öppna meddelande"
            }}
        </button>
        <div v-if="unreadCount > 0" class="font-italic text-muted text-center">
            {{ unreadCount }} oläst{{ unreadCount > 1 ? "a" : "" }}
        </div>
        <quote-chat-messages-modal
            :quote-key="quoteKey"
            :is-customer="isCustomer"
            :is-template="isTemplate"
            :chat-available-for-customer="chatAvailableForCustomer"
            :chat-response-time-text="chatResponseTimeText"
            v-model="modalOpen"
        ></quote-chat-messages-modal>
    </div>
</template>

<style scoped></style>

<script>
import axios from "axios";

import ChatMessage from "./message.vue";

export default {
    components: {
        ChatMessage,
    },
    props: {
        quoteKey: String,
        isCustomer: { type: Boolean, default: false },
        isTemplate: { type: Boolean, default: false },
        numberToShow: { type: Number, default: 1 },
        chatAvailableForCustomer: { type: Boolean, default: true },
        chatResponseTimeText: {
            type: String,
            default: "Vår svarstid är 1-2 timmar under vardagar",
        },
    },
    data() {
        return {
            chatMessages: undefined,
            unreadCount: 0,
            modalOpen: false,
            interval: null,
        };
    },
    watch: {
        async modalOpen(newValue, oldValue) {
            if (!newValue) {
                await this.reload();
            }
        },
    },
    async mounted() {
        await this.reload();
        this.interval = setInterval(async () => {
            await this.reload();
        }, 20000);
    },
    unmounted() {
        clearInterval(this.interval);
        this.interval = null;
    },
    methods: {
        async reload() {
            try {
                const response = await axios.get(
                    route("quote.chatMessages.index", {
                        quoteKey: this.quoteKey,
                        is_template: this.isTemplate,
                    })
                );
                if (!Array.isArray(response.data.data)) {
                    return;
                }

                const chatMessages = response.data.data.sort((a, b) =>
                    a.id < b.id ? 1 : -1
                );

                let unreadCount = 0;
                for (const msg of chatMessages) {
                    if (msg.seen) {
                        continue;
                    }
                    if (msg.sender.is_customer === this.isCustomer) {
                        continue;
                    }

                    unreadCount += 1;
                }
                this.unreadCount = unreadCount;
                this.chatMessages = chatMessages
                    .slice(0, this.numberToShow)
                    .reverse();
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
