<template>
    <form @submit.prevent="sign" class="on-screen-sign mt-4">
        <div v-if="isLocalhost" class="my-5">
            <h6>DEBUG</h6>
            <p>Item ID: {{itemId}}</p>
        </div>

        <p v-if="itemDescription" class="my-3">
          <small class="text-muted font-italic" v-html="itemDescriptionWithBr"></small>
        </p>

        <div class="padContainer mb-4">
            <vue-signature-pad :options="{ onBegin: onBeginStroke, onEnd: onEndStroke }" ref="signaturePad" />
        </div>

        <input type="text" class="form-control mb-4" required v-model="name" placeholder="John Doe">

        <div v-if="!authenticating">
            <button type="submit" class="signatureComponentSignButton btn btn-secondary mb-3" :disabled="!hasData">
                <i class="fas fa-paper-plane mr-1"></i> {{ signatureButtonLabel }}
            </button>

            <button class="btn" :disabled="!hasImageData" @click.prevent="clear">
                <i class="fas fa-undo"></i>
            </button>
        </div>
        <button v-else type="button" class="signatureComponentSignButton btn btn-primary mt-3 wait">
            <div class="spinner-border spinner-border-sm mr-2"></div> <i class="fas fa-paper-plane"></i>
        </button>
    </form>
</template>

<style lang="scss" scoped>

.padContainer {
    background-color: #faf8f8;
    position: relative;
    width: 100%;
    height: 150px;

    &:after {
        content: '';
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: #d3d1d1;
    }
}

button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 33%;

    &.wait .spinner-border {
        vertical-align: middle;
    }
}

</style>

<style lang="scss">
  .modal-body .card-body form.on-screen-sign .padContainer {
    width: calc(100% + 20px + 20px);
    margin-left: -20px;
  }
</style>

<script>
import axios from 'axios';

export default {
    // Props should be in sync with bankIdSign.vue
    props: {
        itemId: String,
        itemDescription: String,
        appUrl: String,
        redirectOnSignedUrl: String,
        signeeName: { type: String, default: null },
        signatureButtonLabel: String
    },
    data() {
        return {
            name: this.signeeName,
            authenticating: false,
            hasData: false,
            hasImageData: false
        };
    },
    computed: {
        isLocalhost() {
            return document.location.hostname == "localhost";
        },
        itemDescriptionWithBr() {
            return this.itemDescription ? this.itemDescription.replaceAll("\n", "<br>") : null;
        }
    },
    watch: {
        name() { this.updateHasData(); }
    },
    methods: {
        sign: async function() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            if (isEmpty) {
                this.updateHasData();
                return;
            }

            try {
                this.authenticating = true;
                await axios.post(`/on_screen_signatures`, {
                    item_id: this.itemId,
                    item_description: this.itemDescription,
                    name: this.name,
                    data
                })

                if (this.redirectOnSignedUrl) {
                    window.location.replace(this.redirectOnSignedUrl);
                } else {
                    this.$emit('signed');
                }
            } catch (error) {
                this.authenticating = false;
                console.warn("Failed to store signature..", error);
            }
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
            this.updateHasData();
        },
        onBeginStroke() {
            // Needed when we use this in a modal
            this.$refs.signaturePad.resizeCanvas();
            this.updateHasData();
        },
        onEndStroke() {
            this.updateHasData();
        },
        updateHasData() {
            this.hasImageData = !this.$refs.signaturePad.isEmpty()
            this.hasData = this.hasImageData && !!this.name;
        }
    }

}
</script>
