<template>
  <li class="nav-item dropdown">
    <a id="organisation" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
       aria-haspopup="true" aria-expanded="false" v-pre>
      Organisation
    </a>

    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
      <a class="dropdown-item"
         type="button"
         v-for="company in companies"
         @click="handleCompanySelect(company.id)"
      >
        {{ company.name }} <i class="fas fa-check" v-if="company.id === activeCompanyId"></i>
      </a>
    </div>
  </li>
</template>

<style scoped lang="scss">

</style>

<script>

import axios from 'axios'

export default {
  name: "organisationSelector",
  props: {
    companies: Array,
    activeCompanyId: Number,
  },
  data() {
    return {
      selectedCompanyId: null
    };
  },
  methods: {
    mounted: () => {
      this.selectedCompanyId = this.activeCompanyId;
    },
    pagePreflight: async (newCompanyId) => {
      try {
        await axios.get(
          window.location.href,
          { headers: { 'X-Salesbyte-Active-Company-Id': newCompanyId } }
        );
        return true;
      } catch (e) {
        console.log("Page no longer exists")
        return false;
      }
    },
    handleCompanySelect: async function(id){
      let url = route('company.active.set.store');
      let payload = {
        companyId: id
      };

      try {
        await axios.post(url, payload);
      } catch (e) {
        console.log("Failed to set active company")
        return;
      }
      const response = await this.pagePreflight(id);
      if (!response){
        console.log("Redirecting to home")
        window.location.replace(route('home'))
        return;
      }

      window.location.reload();
    }
  }
}
</script>
