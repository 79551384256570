export default {
    "en": {
        "quoteProducts": {
            "view": {
                "price_html": "<strong>{price}</strong> {currency}{vat_suffix}",
                "price_title": "Total",
                "price": "{price} {currency}{vat_suffix}",
                "vat": "VAT: {vat} {currency}",
                "discount_title": "Discount",
                "discount": "{discount} {currency}",
                "original_price": "(Original price: {price} {currency}{vat_suffix})",
                "discount_info": "{0} (Your discount - {discount} {currency}{vat_suffix} - expires today)|{1} (Your discount - {discount} {currency}{vat_suffix} - expires in {days_left} day)| (Your discount - {discount} {currency}{vat_suffix} - expires in {days_left} days)",
                "recommended_choice": "Recommended choice!",
                "no_longer_available": "This offer is no longer available, please contact the seller for more help.",
                "package_of_n_products": "Bundle with {count} products",
                "button_show_package_no_count": "Show bundle",
                "button_show_package": "Show products ({count})",
                "button_show_product": "Show product",
                "button_show_order": "Show order",
                "read_more": "Read more..",
                "view_more": "View more"
            },
            "signed": {
                "header": "{order_title} #{no}"
            }
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "phone": "The {attribute} field must be a valid number.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "quoteProfile": {
            "signature_types": {
                "bankid": "Swedish BankID (old)",
                "bankidv2": "Swedish BankID (v3)",
                "on-screen": "E-Sign on Screen",
                "simple": "E-sign (button)"
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "sign": {
            "sign_order": "Sign",
            "clear_on_screen_signature_pad": "Start over",
            "saving_on_screen_signature": "Saving your signature",
            "name_label": "Your Name",
            "on_screen_signature_pad_label": "Sign here"
        },
        "currencies": {
            "sek_kr": "kr",
            "sek_ore": "öre"
        },
        "products": {
            "units": {
                "st": "{currency}/st",
                "kWh": "{currency}/kWh",
                "month": "{currency}/mån",
                "month-billed-yearly": "{currency}/mån (faktureras årsvis)",
                "year": "{currency}/år"
            },
            "unit_with_price": {
                "st": "{price} {currency}/st{vat_suffix}",
                "kWh": "{price} {currency}/kWh{vat_suffix}",
                "month": "{price} {currency}/mån{vat_suffix}",
                "month-billed-yearly": "{price} {currency}/mån{vat_suffix} (faktureras årsvis)",
                "year": "{price} {currency}/år{vat_suffix}"
            },
            "unit_with_amount_and_price": {
                "st": "{amount} st á {price} {currency}{vat_suffix}",
                "kWh": "{amount} st á {price} {currency}/kWh{vat_suffix}",
                "month": "{amount} st á {price} {currency}/mån{vat_suffix}",
                "month-billed-yearly": "{amount} st á {price} {currency}/mån{vat_suffix} (faktureras årsvis)",
                "year": "{amount} st á {price} {currency}/år{vat_suffix}"
            }
        }
    },
    "sv": {
        "quoteProducts": {
            "view": {
                "price_html": "<strong>{price}</strong> {currency}{vat_suffix}",
                "price_title": "Totalt",
                "price": "{price} {currency}{vat_suffix}",
                "vat": "Moms: {vat} {currency}",
                "discount_title": "Rabatt",
                "discount": "{discount} {currency}",
                "original_price_title": "Ordinarie pris",
                "original_price": "(Ordinarie pris: {price} {currency}{vat_suffix})",
                "discount_info": "{0} (Er rabatt på {discount} {currency}{vat_suffix} löper idag)|{1} (Er rabatt på {discount} {currency}{vat_suffix} löper ut om {days_left} dag)| (Er rabatt på {discount} {currency}{vat_suffix} löper ut om {days_left} dagar)",
                "recommended_choice": "Rekommenderat val!",
                "no_longer_available": "Detta erbjudande är ej längre tillgängligt, vad god kontakta säljare för mer hjälp.",
                "package_of_n_products": "Paket om {count} produkter",
                "button_show_package_no_count": "Visa paket",
                "button_show_package": "Visa produkter ({count})",
                "button_show_product": "Visa produkt",
                "button_show_order": "Visa order",
                "read_more": "Läs mer..",
                "view_more": "Visa mer"
            },
            "signed": {
                "header": "{order_title} #{no}"
            },
            "vat_suffix": {
                "including": "",
                "excluding": " ex. moms"
            }
        },
        "surveys": {
            "editor": {
                "button_cancel": "Avbryt",
                "button_create": "Skapa ny",
                "button_edit": "Ändra",
                "button_preview": "Se",
                "button_question_add": "Lägg till fråga",
                "button_question_move_down": "↓",
                "button_question_move_up": "↑",
                "button_question_option_add": "Lägg till",
                "button_save_existing": "Spara",
                "button_save_new": "Skapa",
                "header": "Enkäter",
                "none_exists": "Du har inga enkäter än.",
                "question_title": "Fråga {num}",
                "save_error": "Kunde inte spara enkäten.",
                "save_success": "Enkäten sparades.",
                "pick_product": "Välj produkt",
                "pick_image": "Välj bild"
            },
            "attributes": {
                "name": "Namn",
                "image": "Bild",
                "intro_header": "Introduktion: Rubrik",
                "intro_body": "Introduktion: Text",
                "thanks_header": "Tack: Rubrik",
                "thanks_body": "Tack: Text",
                "requires_bankid_signature": "Kräver BankID-signatur",
                "question": {
                    "prompt": "Fråga",
                    "type": "Typ",
                    "optional": "Valfri",
                    "options": "Alternativ"
                }
            }
        },
        "company": {
            "attributes": {
                "api_provider": "API-typ",
                "api_url": "API-url",
                "api_token": "API-token"
            },
            "api_providers": {
                "google-shopping": "Google Shopping",
                "jetshop": "Jetshop",
                "jetshop-caparol": "Jetshop (Caparol)",
                "blocket": "Blocket"
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "phone": "Telefonfältet måste vara ett giltigt nummer",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "contact": {
            "callBack": "Uppringning",
            "email": "Maila",
            "call": "Ring",
            "cta": "Hej {customerName}, jag hjälper gärna till om ni har frågor. Välj det alternativ nedan som passar er bäst."
        },
        "quoteProfile": {
            "clone_name_prefix": "Kopia av",
            "clone": "Kopiera",
            "setAsDefault": "Standard",
            "delete": "Radera",
            "change": "Ändra",
            "deleteNotAllowed": "Profilen kan ej raderas då det finns data i systemet kopplat till den.",
            "quote_created_email": "Nytt erbjudande",
            "brand_awareness_created_email": "Nytt visitkort",
            "quote_update_emails": "Uppdateringar om erbjudandet (ändringar, signaturbekräftelse, förfrågan om projektbekräftelse etc)",
            "quote_created_email_default": "Din offert från <company_legal>",
            "quote_message_automation_emails": "Automatiska utskick",
            "default_send_type_title": "Utskickstyp",
            "default_send_type_description": "Välj vilka kanaler som skall vara förvalda",
            "allows_external_signature": "Tillåt extern signatur (rapporterad av säljare)",
            "email_sender_format": "Avsändare",
            "email": {
                "header": "E-post",
                "subject": "Ämnesrad",
                "body": "Innehåll"
            },
            "signed_quotes_view": {
                "header": "Verifikat"
            },
            "signature_types": {
                "bankid": "Svenskt BankID (gammal)",
                "bankidv2": "Svenskt BankID (v3)",
                "on-screen": "E-Signatur på skärmen",
                "simple": "E-signatur (knapptryck)"
            },
            "vibe": {
                "header": "VIBBA",
                "description": "Standardmeddelande för VIBBA-utskick",
                "message": "Meddelande"
            },
            "settings": {
                "terms": "Villkor"
            },
            "terms": {
                "enabled": "Aktivera modul",
                "header": "Rubrik",
                "body": "Text",
                "link": "Länk",
                "link_url": "URL",
                "link_title": "Namn",
                "button_remove_link": "Ta bort",
                "button_add_link": "Lägg till länk"
            },
            "custom-fields": {
                "header": "Mer information",
                "subheader": "Fält {n}:",
                "type": "Typ",
                "label": "Label",
                "types": {
                    "text": "Text",
                    "date": "Datum",
                    "number": "Nummer",
                    "email": "E-mail",
                    "tel": "Telefonnummer",
                    "textarea": "Längre text",
                    "swedish-id-number": "Svenskt ID-nummer",
                    "swedish-bank-account-number": "Svenskt clearingnummer",
                    "swedish-bank-clearing-number": "Svenskt kontonummer"
                }
            },
            "field-types": {
                "fixed": "Fixed",
                "custom": "Custom"
            },
            "custom-fields-versions": {
                "v1": "Ver 1",
                "v2": "Ver 2"
            }
        },
        "pdfView": {
            "shownNow": "Visas nu",
            "moreDocuments": "Fler dokument",
            "page": "Sida",
            "strange": "Not loading?",
            "openNewTab": "Open in new tab"
        },
        "general": {
            "close": "Stäng",
            "image": "Bild",
            "desc": "Beskrivning",
            "loading": "Laddar",
            "title": "Rubrik",
            "save": "Spara",
            "email": "E-post",
            "placeholderOptional": "(Behöver ej anges)",
            "activateModule": "Aktivera modul",
            "remove": "Ta bort"
        },
        "sign": {
            "show_signed": "Visa signering",
            "sign_document": "Signera dokument",
            "show_document": "Visa dokument",
            "show_order": "Visa order",
            "sign_quote": "Signera offert",
            "move_forward": "Gå vidare",
            "modal_explainer": "FÖR ATT LÄGGA BESTÄLLNING:\n1. Markera rätt alternativ nedan (blå ram)\n2. Fyll i personnummer\n3. Klicka på \"Signera\" för att signera med BankID.\n\n(Vill du istället läsa er offert. Klicka på visa dokument.)",
            "pno": "Personnummer",
            "pno_format": "12 siffror utan bindestreck",
            "sign_order": "Signera",
            "more_documents": "Fler dokument",
            "more_options": "Se alternativ",
            "move_forward_max_length": "Max 30 tecken",
            "add_signature_max_length": "Max 30 tecken",
            "clear_on_screen_signature_pad": "Börja om",
            "saving_on_screen_signature": "Sparar din signatur",
            "name_label": "Ditt namn",
            "on_screen_signature_pad_label": "Signera här",
            "external": {
                "name_label": "Signerat av"
            },
            "simple": {
                "name_label": "Signerat av"
            }
        },
        "quote": {
            "default_email_subject": "Din offert från <company_legal>"
        },
        "integrations": {
            "editor": {
                "header": "Integrationer"
            }
        },
        "form": {
            "clone_name_prefix": "Kopia av",
            "clone": "Kopiera",
            "delete": "Radera",
            "publish": "Publicera",
            "draft": "Förslag",
            "fields": {
                "name": "Namn",
                "phoneNumber": "Mobilnummer",
                "email": "E-post",
                "company": "Företag",
                "notes": "Anteckningar"
            }
        },
        "currencies": {
            "sek_kr": "kr",
            "sek_ore": "öre"
        },
        "readmore": {
            "moduleName": "Modul: Läs mer",
            "page": "Läs mer-sida",
            "addPage": "Lägg till ny \"Läs mer\"-sida",
            "replaceImage": "Ersätt bild",
            "image": "Bild",
            "title": "Title",
            "body": "Text",
            "youtube_url": "Youtube-länk",
            "cta_title": "Knapp-titel",
            "cta_url": "Knapp-länk",
            "cta_placeholderOptional": "(Behöver ej anges om knapp inte skall visas)",
            "isFullwidth": "Visa i fullbredd på kundsidan",
            "pageContentBlock": "Innehållsblock",
            "pageContentBlocks": "Sidans innehåll",
            "addPageContentBlock": "Lägg till nytt innehållsblock",
            "noTemplateSelected": "Ingen mall vald",
            "templatePrefix": "Mall: "
        },
        "upload": {
            "submitExplain": "Skicka in bild/dokument",
            "instructions": "För att skicka in en bild eller ett dokument till säljaren, använd formuläret nedan. Formuläret accepterar filer i formaten pdf, jpg och png.",
            "label": "Bild/Dokument *",
            "desc": "Beskrivning *"
        },
        "view": {
            "upload_image": "Ladda upp bild",
            "unsubscribe": "Avregistrera",
            "decline_quote": "Tacka nej till offert",
            "contact": "Kontakta"
        },
        "products": {
            "attributes": {
                "sku": "Artikelnummer",
                "url": "Länk",
                "name": "Namn",
                "description_title": "Beskrivning, rubrik",
                "description": "Beskrivning, text",
                "image": "Bild",
                "image_url": "Bild-URL",
                "categories": "Kategorier",
                "category": "Kategori",
                "allows_user_assigned_price": "Kund anger själv pris på produkten",
                "solo_product": "Produkt kan ej kombineras med andra.",
                "price_including_vat": "Pris (inkl. ev. moms)",
                "minimum_price_including_vat": "Lägsta Pris (inkl. ev. moms)",
                "currency": "Valuta",
                "price_unit": "Enhet",
                "input_amount": "Säljare kan ange antal vid offertskapande",
                "input_prompt": "Fråga vid offertskapande?",
                "color": "Färg",
                "pattern": "Mönster",
                "material": "Material",
                "age_group": "Åldersgrupp",
                "gender": "Användare",
                "size": "Storlek",
                "alternative_product": "Alternativ produkt",
                "in_stock": "I Lager",
                "deleted": "Dold",
                "manuellt_skapad": "Manuellt skapad",
                "is_recurring": "Är återkommande"
            },
            "genders": {
                "male": "Herr",
                "female": "Dam",
                "unisex": "Unisex"
            },
            "age_groups": {
                "newborn": "Nyfödd",
                "infant": "Spädbarn",
                "toddler": "Småbarn",
                "kids": "Barn",
                "adult": "Vuxen"
            },
            "units": {
                "st": "{currency}/st",
                "kWh": "{currency}/kWh",
                "month": "{currency}/mån",
                "month-billed-yearly": "{currency}/mån (faktureras årsvis)",
                "year": "{currency}/år"
            },
            "unit_with_price": {
                "st": "{price} {currency}/st{vat_suffix}",
                "kWh": "{price} {currency}/kWh{vat_suffix}",
                "month": "{price} {currency}/mån{vat_suffix}",
                "month-billed-yearly": "{price} {currency}/mån{vat_suffix} (faktureras årsvis)",
                "year": "{price} {currency}/år{vat_suffix}"
            },
            "unit_with_amount_and_price": {
                "st": "{amount} st á {price} {currency}{vat_suffix}",
                "kWh": "{amount} st á {price} {currency}/kWh{vat_suffix}",
                "month": "{amount} st á {price} {currency}/mån{vat_suffix}",
                "month-billed-yearly": "{amount} st á {price} {currency}/mån{vat_suffix} (faktureras årsvis)",
                "year": "{amount} st á {price} {currency}/år{vat_suffix}"
            },
            "editor": {
                "header": "Produkter",
                "header_manually_created": "Produkter (Manuella)",
                "header_integration_created": "Produkter (API)",
                "header_categories": "Kategorier",
                "button_create": "Skapa ny produkt",
                "button_edit": "Redigera",
                "button_clone": "Kopiera",
                "button_save_existing": "Spara",
                "button_save_new": "Skapa",
                "button_view": "Visa",
                "button_add_category": "Lägg till kategori",
                "save_error": "Fel uppstod, var god försök igen.",
                "save_success": "Dina ändringar är sparade!",
                "select_true": "ja",
                "select_false": "nej",
                "sku_in_use_error": "Artikelnummer används redan till en annan produkt.",
                "pick_alternative_product": "Välj alternativ produkt"
            },
            "reload": {
                "button": "Ladda om från API",
                "success": "Omladdning av produkter har påbörjats!",
                "failure": "Omladdning av produkter kunde ej genomföras, försök igen senare!"
            },
            "configure_api_url": {
                "button_open": "Konfigurera API-url",
                "button_submit": "Spara",
                "title": "Konfigurera API-url",
                "success": "Din ändring har sparats!",
                "failure": "Din ändring kunde inte sparas, försök igen senare!"
            },
            "bundles": {
                "attributes": {
                    "name": "Namn",
                    "product_amount": "Antal"
                },
                "editor": {
                    "header": "Produkt-paket",
                    "button_create": "Skapa nytt",
                    "button_edit": "Redigera",
                    "button_save_existing": "Spara",
                    "button_save_new": "Skapa",
                    "pick_product": "Lägg till produkt",
                    "save_error": "Fel uppstod, var god försök igen.",
                    "save_success": "Dina ändringar är sparade!"
                }
            }
        },
        "chat": {
            "moduleName": "Chatt",
            "moduleEnable": "Lägg till chatt som standard",
            "disabledOnFullySigned": "Inaktivera efter signering",
            "defaultMessageTitle": "Standardmeddelande (kan redigeras)",
            "defaultMessageOffer": "Initialt chattmeddelande vid utskick med erbjudande",
            "defaultMessageBusinessCard": "Initialt chattmeddelande vid utskick som visitkort/lead",
            "reminderUnreadTitle": "Påminnelse om oläst chatt-meddelande (sms, email)",
            "responseTimeText": "Förväntad svarstid"
        },
        "user": {
            "signature_image_width": {
                "small": "Liten (200px)",
                "medium": "Standard (350px)",
                "large": "Stor (500px)"
            }
        },
        "imageCarousel": {
            "addImage": "Lägg till bild",
            "replaceImage": "Byt bild",
            "deleteImage": "Radera bild"
        },
        "quotePayment": {
            "product": {
                "name_label": "Bekräfta ditt namn",
                "go_to_payment_button": "Gå vidare till betalning"
            }
        }
    }
}
