<template>
  <fieldset>
    <div v-for="(contact, idx) in contacts" :key="idx">
      <div class="d-flex justify-content-between align-items-baseline mt-2 mb-3">
        <h5>Signatör {{2 + idx}}</h5>
        <button type="button" class="btn btn-sm btn-secondary" @click="deleteContact(idx)"><i class="fal fa-trash mr-1"></i> Ta bort</button>
      </div>

      <div class="row">
        <div class="col-md-4">
          <input type="text" :name="`contacts[${idx}][name]`" class="form-control mb-3" placeholder="Namn" required>
        </div>
        <div class="col-md-4">
          <input type="tel" :name="`contacts[${idx}][phone]`" class="form-control mb-3" placeholder="Mobilnummer" minlength=5>
        </div>
        <div class="col-md-4">
          <input type="email" :name="`contacts[${idx}][email]`" class="form-control mb-3" placeholder="E-post">
        </div>
      </div>
    </div>

    <a type="button" class="float-right text-dark" :class="contacts.length == 0 ? 'mt-2' : ''" @click="newContact"><i class="fal fa-smile-plus"></i></a>
  </fieldset>
</template>

<style lang="scss" scoped>
</style>

<script>

export default {
  data() {
    return {
      send_type: null,
      contacts: []
    };
  },
  mounted() {
    const targets = Array.from(document.querySelectorAll(`input[name="send_type"]`));
    const updateSendType = () => {
      const checked = targets.find((t) => t.checked);
      this.send_type = checked ? checked.value : null;
    };
    window.jQuery(targets).on('input', updateSendType);
  },
  methods: {
    newContact() {
      this.contacts.push({
        name: null,
        phone: null,
        email: null
      });
    },
    deleteContact(idx) {
      this.contacts.splice(idx, 1);
    }
  }
}
</script>
