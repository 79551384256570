<template>
    <form @submit.prevent="save">
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("surveys.attributes.name") }}
                </div>
            </div>
            <input
                type="text"
                class="form-control"
                required
                v-model="theSurvey.name"
            />
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("surveys.attributes.image") }}
                </div>
            </div>

            <div class="custom-file">
                <label class="custom-file-label" :for="`${uuid}-survey-image`">
                    <img
                        v-if="!imageFile && theSurvey.image_url"
                        class="current-image"
                        :src="theSurvey.image_url"
                    />
                    <span v-else-if="imageFile">{{ imageFile.name }}</span>
                    <span v-else>{{ $t("surveys.editor.pick_image") }}</span>
                </label>

                <input
                    type="file"
                    class="custom-file-input"
                    :id="`${uuid}-survey-image`"
                    name="file"
                    accept="image/png, image/jpeg, *.png, *.jpg, *.jpeg"
                    @change="onFileChanged"
                />
            </div>
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("surveys.attributes.requires_bankid_signature") }}
                </div>
            </div>
            <select
                type="text"
                class="custom-select"
                required
                v-model="theSurvey.requires_bankid_signature"
            >
                <option :value="false">Nej</option>
                <option :value="true">Ja</option>
            </select>
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("surveys.attributes.intro_header") }}
                </div>
            </div>
            <input
                type="text"
                class="form-control"
                required
                v-model="theSurvey.intro_header"
            />
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("surveys.attributes.intro_body") }}
                </div>
            </div>
            <textarea
                class="form-control"
                required
                rows="5"
                v-model="theSurvey.intro_body"
            ></textarea>
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("surveys.attributes.thanks_header") }}
                </div>
            </div>
            <input
                type="text"
                class="form-control"
                required
                v-model="theSurvey.thanks_header"
            />
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("surveys.attributes.thanks_body") }}
                </div>
            </div>
            <textarea
                class="form-control"
                required
                rows="5"
                v-model="theSurvey.thanks_body"
            ></textarea>
        </div>

        <hr />

        <div
            v-for="(question, questionIdx) in theSurvey.questions"
            :key="question.uuid"
        >
            <div class="d-flex justify-content-between align-items-center mb-1">
                <h6 class="font-weight-bold">
                    {{
                        $t("surveys.editor.question_title", {
                            num: questionIdx + 1,
                        })
                    }}
                </h6>
                <div class="d-flex">
                    <b-button
                        :disabled="questionIdx === 0"
                        variant="secondary"
                        size="sm"
                        class="mr-2"
                        @click="moveUpQuestion(questionIdx)"
                    >
                        {{ $t("surveys.editor.button_question_move_up") }}
                    </b-button>

                    <b-button
                        :disabled="
                            questionIdx === theSurvey.questions.length - 1
                        "
                        variant="secondary"
                        size="sm"
                        class="mr-2"
                        @click="moveDownQuestion(questionIdx)"
                    >
                        {{ $t("surveys.editor.button_question_move_down") }}
                    </b-button>

                    <b-button
                        :disabled="theSurvey.questions.length === 1"
                        variant="danger"
                        size="sm"
                        @click="removeQuestion(questionIdx)"
                    >
                        <i class="fa-regular fa-trash"></i>
                    </b-button>
                </div>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("surveys.attributes.question.prompt") }}
                    </div>
                </div>

                <input
                    type="text"
                    class="form-control"
                    required
                    v-model="question.prompt"
                />

                <div class="input-group-append">
                    <span class="input-group-text">
                        {{ $t("surveys.attributes.question.optional") }}
                        <input
                            type="checkbox"
                            class="ml-2"
                            v-model="question.optional"
                        />
                    </span>
                </div>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("surveys.attributes.question.type") }}
                    </div>
                </div>
                <select
                    type="text"
                    class="custom-select"
                    required
                    v-model="question.type"
                    @change="onQuestionTypeChanged(question)"
                >
                    <option value="text">Fritext</option>
                    <option value="textarea">Fritext, flera rader</option>

                    <option value="checkbox">
                        Checkbox (ett eller flera val)
                    </option>
                    <option value="radio">Radioknappar (ett val)</option>

                    <option value="products-checkbox">Produkter (flera)</option>
                    <option value="products-radio">Produkt (en)</option>
                    <option value="radio">Radioknappar (ett val)</option>
                    <option value="documents">Bild / Dokument</option>
                </select>
            </div>

            <template
                v-if="question.type == 'checkbox' || question.type == 'radio'"
            >
                <strong>{{ $t("surveys.attributes.question.options") }}</strong>
                <ul v-if="question.options && question.options.length > 0">
                    <li
                        v-for="(option, optionIdx) in question.options"
                        :key="optionIdx"
                        class="mb-2"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            {{ option }}
                            <b-button
                                variant="danger"
                                size="sm"
                                @click="removeOption(question, optionIdx)"
                                ><i class="fa-regular fa-trash"></i
                            ></b-button>
                        </div>
                    </li>
                </ul>
                <!-- hidden input forcing validation error -->
                <input
                    v-else
                    required
                    style="height: 0px; opacity: 0"
                    @focus="this.blur()"
                />

                <survey-input-option @selected="addOption(question, $event)" />
            </template>

            <template
                v-if="
                    question.type == 'products-checkbox' ||
                    question.type == 'products-radio'
                "
            >
                <strong>{{ $t("surveys.attributes.question.options") }}</strong>

                <ul v-if="question.products && question.products.length > 0">
                    <li
                        v-for="(product, productIdx) in question.products"
                        :key="productIdx"
                        class="mb-2"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <span
                                >{{ product.name }} -
                                <small class="text-muted">{{
                                    product.sku
                                }}</small></span
                            >
                            <b-button
                                variant="danger"
                                size="sm"
                                @click="removeProduct(question, productIdx)"
                                ><i class="fa-regular fa-trash"></i
                            ></b-button>
                        </div>
                    </li>
                </ul>
                <!-- hidden input forcing validation error -->
                <input
                    v-else
                    required
                    style="height: 0px; opacity: 0"
                    @focus="this.blur()"
                />

                <survey-input-product
                    :products="products"
                    @selected="addProduct(question, $event)"
                />
            </template>

            <hr />
        </div>

        <div class="text-right">
            <b-button variant="primary" @click="addQuestion">
                {{ $t("surveys.editor.button_question_add") }}
            </b-button>
        </div>

        <hr />

        <div
            v-if="successMessage"
            class="alert alert-success mb-3"
            role="alert"
        >
            {{ successMessage }}
        </div>
        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
            {{ errorMessage }}
        </div>

        <b-button v-if="!saving" type="submit" variant="primary">
            {{
                $t(
                    theSurvey.id
                        ? "surveys.editor.button_save_existing"
                        : "surveys.editor.button_save_new"
                )
            }}
        </b-button>
        <b-button v-if="!saving" variant="secondary" @click="cancel">
            {{ $t("surveys.editor.button_cancel") }}
        </b-button>
        <b-spinner v-if="saving"></b-spinner>
    </form>
</template>

<style lang="scss" scoped>
.current-image {
    max-height: 100%;
    width: auto;
}
</style>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import SurveyInputOption from "./_inputOption.vue";
import SurveyInputProduct from "./_inputProduct.vue";

function serializeFormData(formData, key, value) {
    if (value != null && typeof value == "object") {
        for (let [entryKey, entryValue] of Object.entries(value)) {
            serializeFormData(formData, `${key}[${entryKey}]`, entryValue);
        }
    } else if (typeof value === "boolean") {
        formData.append(key, value ? "1" : "0");
    } else {
        formData.append(key, value);
    }
}

export default {
    components: {
        SurveyInputOption,
        SurveyInputProduct,
    },
    props: {
        products: { type: Array, required: true },
        survey: { type: Object, required: true },
    },
    data() {
        return {
            uuid: uuidv4(),
            theSurvey: {},
            imageFile: null,
            saving: false,
            errorMessage: null,
            successMessage: null,
        };
    },
    watch: {
        survey: {
            immediate: true,
            deep: true,
            handler(survey) {
                this.theSurvey = JSON.parse(JSON.stringify(survey));
                this.theSurvey.requires_bankid_signature =
                    !!this.theSurvey.requires_bankid_signature;

                if (!this.theSurvey.questions) {
                    this.addQuestion();
                }
            },
        },
    },
    methods: {
        onFileChanged(event) {
            this.imageFile = event.target.files[0];
        },
        addQuestion() {
            if (!this.theSurvey.questions) {
                Vue.set(this.theSurvey, "questions", []);
            }
            this.theSurvey.questions.push({
                uuid: uuidv4(),
            });
        },
        moveUpQuestion(questionIdx) {
            const question = this.theSurvey.questions[questionIdx];
            this.theSurvey.questions.splice(questionIdx, 1);
            this.theSurvey.questions.splice(questionIdx - 1, 0, question);
        },
        moveDownQuestion(questionIdx) {
            const question = this.theSurvey.questions[questionIdx];
            this.theSurvey.questions.splice(questionIdx, 1);
            this.theSurvey.questions.splice(questionIdx + 1, 0, question);
        },
        removeQuestion(questionIdx) {
            this.theSurvey.questions.splice(questionIdx, 1);
        },
        onQuestionTypeChanged(question) {
            if (
                question.type !== "products-checkbox" &&
                question.type !== "products-radio"
            ) {
                Vue.delete(question, "products");
            }

            if (question.type !== "checkbox" && question.type !== "radio") {
                Vue.delete(question, "options");
            }
        },
        addProduct(question, product) {
            if (!question.products) {
                Vue.set(question, "products", []);
            }
            question.products.push({
                id: product.id,
                name: product.name,
                sku: product.sku,
            });
        },
        removeProduct(question, productIdx) {
            question.products.splice(productIdx, 1);
        },
        addOption(question, option) {
            if (!question.options) {
                Vue.set(question, "options", []);
            }
            question.options.push(option);
        },
        removeOption(question, optionIdx) {
            question.options.splice(optionIdx, 1);
        },
        async save() {
            if (this.saving) {
                return;
            }

            this.saving = true;
            this.errorMessage = null;
            this.successMessage = null;

            try {
                const formData = new FormData();

                serializeFormData(formData, "survey", this.theSurvey);

                if (this.imageFile) {
                    formData.append("survey[image]", this.imageFile);
                }

                if (this.survey.id) {
                    formData.append("_method", "PUT");
                }

                const response = this.theSurvey.id
                    ? await axios.post(
                          this.route("surveys.update", this.theSurvey.id),
                          formData
                      )
                    : await axios.post(this.route("surveys.store"), formData);
                this.$emit(
                    "updated",
                    JSON.parse(JSON.stringify(response.data))
                );

                this.successMessage = this.$t("surveys.editor.save_success");
            } catch (e) {
                if (console.warn) {
                    console.warn(e);
                }

                this.errorMessage = this.$t("surveys.editor.save_error");
            }

            this.saving = false;
        },
        cancel() {
            this.$emit("canceled");
        },
    },
};
</script>
