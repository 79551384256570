<template>
  <div class="d-flex flex-column">
    <div ref="scrollcontainer" class="messages-list">
      <div>
        <chat-message
          v-for="msg in chatMessages"
          :key="msg.id"
          class="my-3"
          :senderName="msg.sender.name"
          :senderAvatar="msg.sender.avatar"
          :senderIsSelf="msg.sender.is_customer === isCustomer"
          :message="msg.message"
          :timestamp="isCustomer ? null : new Date(msg.created_at)"
        ></chat-message>
      </div>
    </div>
    <chat-form
       :chat-available-for-customer="chatAvailableForCustomer"
       :chat-response-time-text="chatResponseTimeText"
      :quote-key="quoteKey"
      :is-customer="isCustomer"
      :is-template="isTemplate"
      @submitted="loadMessages()"
    ></chat-form>
  </div>
</template>

<style lang="scss" scoped>
  .messages-list {
    overflow-y: auto;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
  }
</style>

<script>
import axios from 'axios';

import ChatForm from './form';
import ChatMessage from './message';

export default {
  components: {
    ChatForm,
    ChatMessage
  },
  props: {
    quoteKey: String,
    isCustomer: { type: Boolean, default: false },
    isTemplate: { type: Boolean, default: false },
    markAsSeen: { type: Boolean, default: false },
    shouldReloadMessages: Boolean,
      chatAvailableForCustomer: { type: Boolean, default: true},
      chatResponseTimeText: { type: String}
  },
  data() {
    return {
      chatMessages: undefined,
      interval: null
    };
  },
  watch: {
    async markAsSeen(newValue) {
      if (newValue) { await this.markMessagesAsSeen(); }
    }
  },
  async mounted() {
    await this.loadMessages();
    if (this.markAsSeen) {
      await this.markMessagesAsSeen();
    }

    this.timer = setInterval(async () => {
      if (this.shouldReloadMessages) {
        await this.loadMessages();
      }
      if (this.markAsSeen) {
        await this.markMessagesAsSeen();
      }
    }, 5000);
  },
  unmounted() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    async loadMessages() {
      const response = await axios.get(route('quote.chatMessages.index', { quoteKey: this.quoteKey, is_template: this.isTemplate }));
      const oldCount = this.chatMessages ? this.chatMessages.length : 0;
      this.chatMessages = response.data.data.sort((a, b) => (a.id > b.id) ? 1 : -1);

      if (this.chatMessages.length > oldCount) {
        await this.$nextTick();
        // hack to make sure we always scroll on iOS
        this.$refs.scrollcontainer.scrollTop = -1;
        this.$refs.scrollcontainer.scrollTop = 0;
      }
    },
    async markMessagesAsSeen() {
      await axios.post(route('quote.chatMessages.markAsSeen', this.quoteKey));
    }
  }
}
</script>
