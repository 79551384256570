const updateDisableUnlessVisibility = (select, target, value) => {
    target.disabled = (value !== select.value);
}

export default () => {
    window.jQuery('select[data-disable-unless-value]').each((index, select) => {
        const target = document.querySelector(select.getAttribute("data-disable-unless-target"));
        const value = select.getAttribute("data-disable-unless-value");
        if (!target) { return; }

        $(select).on('change', (ev) => { updateDisableUnlessVisibility(select, target, value); });
        updateDisableUnlessVisibility(select, target, value);
    });
}
