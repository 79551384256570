<template>
    <div>
        <img
            v-if="previewImage"
            :src="previewImage"
            class="preview d-block mb-3"
            ref="previewImage"
        />

        <input
            :name="`${name}[file]`"
            :required="required"
            type="file"
            accept="image/jpeg, *.jpeg, *.jpg, image/png, *.png"
            @change.prevent="updateFiles($event.target.files)"
            @click="abortCropping"
            ref="inputFile"
        />

        <template v-if="cropping">
          <input type="hidden" :name="`${name}[cropping][x]`" :value="cropping.x">
          <input type="hidden" :name="`${name}[cropping][y]`" :value="cropping.y">
          <input type="hidden" :name="`${name}[cropping][width]`" :value="cropping.width">
          <input type="hidden" :name="`${name}[cropping][height]`" :value="cropping.height">
        </template>

        <cropper
            class="mt-3"
            v-if="croppableImageBase64String"
            :imageBase64String="croppableImageBase64String"
            :aspect-ratio="aspectRatio"
            @updateCropping="updateCropping($event)"
            @finishCropping="finishCropping($event)"
            @abortCropping="abortCropping"
        ></cropper>
    </div>
</template>

<style lang="scss" scoped>
  img.preview {
    max-width: 240px;
    width: 100%;
    height: auto;
  }
</style>

<script>
import Cropper from './_cropper.vue';

const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      reader.onload = null;
      reader.onerror = null;
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reader.onload = null;
      reader.onerror = null;
      reject(error);
    }
});

export default {
  components: {
    Cropper
  },
  props: {
    name: String,
    currentImage: String,
    required: Boolean,
    aspectRatio: {
      type: Number,
      default: NaN
    }
  },
  data() {
    return {
      previewImage: this.currentImage ? this.currentImage : '',
      croppableImageBase64String: null,
      cropping: null
    }
  },
  methods: {
    async updateFiles(files) {
      if (files.length > 0) {
        this.croppableImageBase64String = await fileToBase64(files[0])
        this.previewImage = null;
      }
    },
    updateCropping(cropdata) {
      this.cropping = {
        x: cropdata.x,
        y: cropdata.y,
        width: cropdata.width,
        height: cropdata.height
      };
    },
    finishCropping(base64Image) {
      this.previewImage = base64Image;
      this.croppableImageBase64String = null;

      if (this.$refs.previewImage) {
        if (window.pageYOffset > this.$refs.previewImage.offsetTop - 20) {
          window.scrollTo({
            top: this.$refs.previewImage.offsetTop - 20,
            behavior: 'smooth'
          });
        }
      }
    },
    abortCropping() {
      this.$refs.inputFile.value = null;
      this.cropping = null;
      this.croppableImageBase64String = '';
      if (this.currentImage) {
        this.previewImage = this.currentImage;
      }
    },
  }
}
</script>
