import Kontonummer from 'kontonummer';
import { v4 as uuidv4 } from 'uuid';

export default () => {
    const setBankNameLabel = (input, bankName) => {
        if (!input.data('bankNameLabel')) {
            input.data('bankNameLabel', document.createElement('small'));
            input.get(0).labels[0].appendChild(input.data('bankNameLabel'));
        }

        input.data('bankNameLabel').innerText = bankName ? (" (Bank: " + bankName + ")") : "";
    };

    const onChange = (clearingNumberInput, accountNumberInput) => {
        const clearingNumber = clearingNumberInput.val();
        const accountNumber = accountNumberInput.val();
        if (clearingNumber && accountNumber) {
            try {
                const k = new Kontonummer(clearingNumber, accountNumber);

                accountNumberInput.get(0).setCustomValidity('');
                accountNumberInput.val(k.accountNumber);
                accountNumberInput.removeClass('is-invalid');
                setBankNameLabel(clearingNumberInput, k.bankName);
                setBankNameLabel(accountNumberInput, k.bankName);

                clearingNumberInput.get(0).setCustomValidity('');
                clearingNumberInput.val(k.sortingCode);
                clearingNumberInput.removeClass('is-invalid');
                setBankNameLabel(clearingNumberInput, k.bankName);
            } catch (e) {
                const invalidAccountNumber = e.message == 'Invalid account number';
                if (invalidAccountNumber) {
                    accountNumberInput.get(0).setCustomValidity('Felaktigt kontonummer');
                    accountNumberInput.get(0).reportValidity();
                    accountNumberInput.addClass('is-invalid');
                } else {
                    accountNumberInput.get(0).setCustomValidity('');
                    accountNumberInput.removeClass('is-invalid');
                }

                const invalidClearingNumber = e.message == 'Invalid sorting code';
                if (invalidClearingNumber) {
                    clearingNumberInput.get(0).setCustomValidity('Felaktigt clearingnummer');
                    clearingNumberInput.get(0).reportValidity();
                    clearingNumberInput.addClass('is-invalid');
                } else {
                    clearingNumberInput.get(0).setCustomValidity('');
                    clearingNumberInput.removeClass('is-invalid');
                }

                setBankNameLabel(clearingNumberInput, null);
                setBankNameLabel(accountNumberInput, null);

                return false;
            }
        }
    };

    const accountNumberSelector = 'input[data-swedish-bank-account-number-input]';
    const clearingNumberSelector = 'input[data-swedish-bank-clearing-number-input]';

    window.jQuery(document).on('change', accountNumberSelector, (ev) => {
        const accountNumberInput = $(ev.target);
        const clearingNumberInput = accountNumberInput.parent('form').find(clearingNumberSelector);
        onChange(clearingNumberInput, accountNumberInput);
    });

    window.jQuery(document).on('change', clearingNumberSelector, (ev) => {
        const clearingNumberInput = $(ev.target);
        const accountNumberInput = clearingNumberInput.parent('form').find(accountNumberSelector);
        onChange(clearingNumberInput, accountNumberInput);
    });
}
