<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ textBlocks.share }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Stäng">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="form-group">
              <form @submit.prevent="submit">
                <div class="mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="fal fa-user-alt ml-2"></i></div>
                    </div>
                    <input type="text" class="form-control" placeholder="John Doe" required v-model="name">
                  </div>
                </div>

                <div class="mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="fal fa-envelope ml-2"></i></div>
                    </div>
                    <input type="email" class="form-control" placeholder="john@salesbyte.com" required v-model="email">
                  </div>
                </div>

                <div class="mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="fal fa-mobile ml-2"></i></div>
                    </div>
                    <input type="phone" class="form-control" placeholder="+46..." pattern="\+[1-9]\d+"  required v-model="phone">
                  </div>
                </div>

                <button v-if="!submitting" type="submit" class="btn btn-secondary">
                  <i class="fas fa-paper-plane"></i>
                </button>
                <button v-else-if="submitted" type="button" class="btn btn-success wait">
                  <i class="fal fa-check mr-1"></i> <i class="fas fa-paper-plane"></i>
                </button>
                <button v-else type="button" class="btn btn-primary wait">
                  <div class="spinner-border spinner-border-sm mr-2"></div> <i class="fas fa-paper-plane"></i>..
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
form button {
    width: 100%;

    &.wait .spinner-border {
        vertical-align: middle;
    }
}
p.share-text {
  white-space: pre-line;
}
</style>

<script>
export default {
    props: {
        quoteKey: String,
        isSigned: {
            type: Boolean,
            default: false
        },
        documentId: {
            type: Number,
            default: null
        },
        textBlocks: Object
    },
    data() {
        return {
            name: undefined,
            phone: "",
            email: undefined,
            submitting: false,
            submitted: false
        };
    },
    methods: {
        submit() {
            try {
                this.submitting = true;
                axios
                    .post(this.url, { name: this.name, phone: this.phone, email: this.email })
                    .then((response) => {

                        this.submitted = true;

                        setTimeout(() => {
                            $(this.$refs['modal']).modal('hide');

                            setTimeout(() => {
                                this.name = undefined;
                                this.phone = "";
                                this.email = undefined;
                                this.submitting = false;
                                this.submitted = false;
                            }, 500);
                        }, 2000);
                    })
                    .catch((error) => {
                        this.submitting = false;
                        alert("Ett fel uppstod, kontrollera formuläret och var god försök igen..")

                        throw error;
                    });
            } catch(e) {
            }
        }
    },
    computed: {
        url() {
            if (this.documentId) {
                return `/quote/${this.quoteKey}/documents/${this.documentId}/invite`;
            } else {
                return `/quote/${this.quoteKey}/invite`;
            }
        },
        indefiniteNoun() {
            if (this.documentId) {
                return 'dokument';
            } else if (this.isSigned) {
                return 'avtal';
            } else {
                return 'offert';
            }
        },
        definiteNoun() {
            if (this.documentId) {
                return 'dokumentet';
            } else if (this.isSigned) {
                return 'avtalet';
            } else {
                return 'offerten';
            }
        },
        linkNoun() {
            if (this.documentId) {
                return 'dokumentlänken';
            } else if (this.isSigned) {
                return 'avtalslänken';
            } else {
                return 'offertlänken';
            }
        },
        updatesDescription() {
            if (this.documentId) {
                return 'statusuppdateringar för hela avtalet';
            } else if (this.isSigned) {
                return 'statusuppdateringar';
            } else {
                return 'statusuppdateringar';
            }
        }
    }
}
</script>
