<template>
  <a :href="eventUrl">
    <img :src="imageUrl" alt="">
    <p style="font-size: 1.3em;">
        Skaffa biljetter
    </p>
  </a>
</template>

<style lang="scss" scoped>
  a {
    display: block;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0px;
      text-align: center;
      text-decoration: none;
      color: black;
    }
  }
</style>

<script>
export default {
  props: {
    eventUrl: { type: String, required: true },
    imageUrl: { type: String, required: true }
  },
  data() {
    return {};
  },
  methods: {
  }
}
</script>
