<template>
  <fieldset>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Pixel ID *</div>
      </div>
      <input type="text" class="form-control" required v-model="pixelId" @input="onUpdate">
    </div>
  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      pixelId: null
    }
  },
  computed: {
    serializedValue() {
      return {
        pixel_id: this.pixelId,
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.pixelId = this.value.pixel_id;
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
