<template>
  <div>
    <a href="#" @click.prevent="modalOpen = true">
      <img :src="imageUrl" alt="">
      <p style="font-size: 1.3em;">
          Boka möte
      </p>
    </a>
    <b-modal
      centered
      hide-footer
      title="Mötesförfrågan"
      v-model="modalOpen"
    >
      <p v-if="meetingRequested">Tack för din förfrågan!</p>
      <axios-form v-else method="post" :action="route('meetingRequests.store', quoteKey)" @success="meetingRequested = true">
        <p>Skicka ett förslag på tid nedan, så återkommer vi:</p>
        <textarea name="message" required class="form-control w-100"></textarea>
        <button type="submit" class="btn signbuttoninline mt-3 d-block mx-auto">
          <i class="fa-regular fa-paper-plane mr-1"></i> Skicka mötesförfrågan
        </button>
      </axios-form>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
  a {
    display: block;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0px;
      text-align: center;
      text-decoration: none;
      color: black;
    }
  }
</style>

<script>
export default {
  props: {
    quoteKey: { type: String, required: true },
    imageUrl: { type: String, required: true }
  },
  data() {
    return {
      modalOpen: false,
      meetingRequested: false
    };
  }
}
</script>
