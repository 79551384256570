<template>
  <fieldset>
    <p>För mer instruktioner om hur du tar fram dessa inställningar, se <a href="https://docs.dintero.com/docs/integrations/checkout-prereq">Dinteros dokumentation.</a></p>
    <p>OBS! Det är ej lämpligt att koppla fler än en integration av denna typ till en profil.</p>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">ClientId</div>
      </div>
      <input type="text" class="form-control" required v-model="clientId" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">ClientSecret</div>
      </div>
      <input type="text" class="form-control" required v-model="clientSecret" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">AccountId</div>
      </div>
      <input type="text" class="form-control" required v-model="accountId" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">ProfileId</div>
      </div>
      <input type="text" class="form-control" required v-model="profileId" @input="onUpdate">
    </div>
  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      clientId: null,
      clientSecret: null,
      accountId: null,
      profileId: null
    }
  },
  computed: {
    serializedValue() {
      return {
        client_id: this.clientId,
        client_secret: this.clientSecret,
        account_id: this.accountId,
        profile_id: this.profileId
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.clientId = this.value.client_id;
          this.clientSecret = this.value.client_secret;
          this.accountId = this.value.account_id;
          this.profileId = this.value.profile_id;
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
