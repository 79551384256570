<template>
    <fieldset>
        <p>
            OBS! Det är ej lämpligt att koppla fler än en integration av denna
            typ till en profil.
        </p>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">CreditorId</div>
            </div>
            <input
                type="text"
                class="form-control"
                required
                v-model="creditorId"
                @input="onUpdate"
            />
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Username</div>
            </div>
            <input
                type="text"
                class="form-control"
                required
                v-model="username"
                @input="onUpdate"
            />
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Password</div>
            </div>
            <input
                type="password"
                class="form-control"
                required
                v-model="password"
                @input="onUpdate"
            />
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Fältnamn: Banknamn</div>
            </div>
            <input
                type="text"
                class="form-control"
                required
                v-model="fieldBankName"
                @input="onUpdate"
            />
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Fältnamn: Clearing</div>
            </div>
            <input
                type="text"
                class="form-control"
                required
                v-model="fieldClearing"
                @input="onUpdate"
            />
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Fältnamn: Kontonummer</div>
            </div>
            <input
                type="text"
                class="form-control"
                required
                v-model="fieldAccountNumber"
                @input="onUpdate"
            />
        </div>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Skapa avtalsfaktura</div>
            </div>
            <select
                class="custom-select"
                v-model="useCreateContractInvoice"
                @change="onUpdate"
                required
            >
                <option :value="false">Nej</option>
                <option :value="true">Ja</option>
            </select>
        </div>
    </fieldset>
</template>

<script>
export default {
    emits: ["input"],
    props: {
        value: { type: Object, required: true },
    },
    data() {
        return {
            creditorId: null,
            username: null,
            password: null,
            defaultMode: false,
            fieldBankName: null,
            fieldClearing: null,
            fieldAccountNumber: null,
            useCreateContractInvoice: null,
        };
    },
    computed: {
        serializedValue() {
            return {
                creditor_id: this.creditorId,
                username: this.username,
                password: this.password,
                default_mode: this.defaultMode,
                field_bank_name: this.fieldBankName,
                field_clearing: this.fieldClearing,
                field_account_number: this.fieldAccountNumber,
                use_create_contract_invoice: this.useCreateContractInvoice,
            };
        },
    },
    watch: {
        value: {
            handler() {
                if (
                    JSON.stringify(this.value) !==
                    JSON.stringify(this.serializedValue)
                ) {
                    this.creditorId = this.value.creditor_id;
                    this.username = this.value.username;
                    this.password = this.value.password;
                    this.defaultMode = this.value.default_mode;
                    this.fieldBankName = this.value.field_bank_name;
                    this.fieldClearing = this.value.field_clearing;
                    this.fieldAccountNumber = this.value.field_account_number;
                    this.useCreateContractInvoice =
                        this.value.use_create_contract_invoice;
                }
            },
            immediate: true,
        },
        defaultMode() {
            // Using this because of @input was faster than v-model on checkbox
            this.$emit("input", this.serializedValue);
        },
    },
    methods: {
        onUpdate() {
            this.$emit("input", this.serializedValue);
        },
    },
};
</script>
