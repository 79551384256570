<template>
  <div v-if="!isDone" class="text-center">
    <h5>Signering</h5>

    <a :href="autoStartUrl" target="_blank" class="signatureComponentSignButton btn btn-primary my-3 w-full">
      Öppna BankID på denna enhet
    </a>

    <template v-if="qrEnabled && currentQrToken">
      <p v-if="qrVisible" class="mt-4">
        Använd BankID-appen för att scanna bilden nedan:<br>
        <vue-qr :text="currentQrToken" :size="380" :correctLevel="3"></vue-qr>
      </p>
      <p v-else class="font-italic">
        <small>Du kan även använda <a href="#" class="text-color-profile-color" @click.prevent="qrVisible = true">BankID på en annan enhet</a></small>
      </p>
    </template>
  </div>
</template>

<style type="text/css" scoped>
img {
  max-width: 100%;
  height: auto;
}
</style>

<script>
import axios from 'axios';
import { v4 as uuid } from 'uuid';

export default {
  props: {
    // request is a `App\Http\ResourcesBankIdRequestResource`
    request: {
      type: Object,
      required: true
    }
  },
  data() {
    const qrTokens = [...this.request.qr_tokens];
    let redirectUrl = `${document.location}#${uuid()}`;
    return {
      orderRef: this.request.order_ref,
      autoStartUrl: `bankid:///?autostarttoken=${this.request.auto_start_token}&redirect=${encodeURIComponent(redirectUrl)}`,

      qrVisible: false,
      qrEnabled: this.request.enable_qr,
      qrTokens: qrTokens,
      currentQrToken: qrTokens && qrTokens.shift(),

      statusUrl: this.request.status_url,

      isDone: false,
      intervals: []
    };
  },
  async mounted() {
    this.intervals.push(setInterval(() => {
      const nextToken = this.qrTokens.shift();
      if (nextToken) {
        this.currentQrToken = nextToken;
      }
    }, 1000));

    this.intervals.push(setInterval(async () => {
      if (this.isDone) { return; }

      try {
        const response = await axios.get(this.statusUrl);

        if (response.data.is_completed) {
          this.onCompleted(response.data);
        } else {
          this.onFailed(response.data);
        }
      } catch (err) {
        if (!err.response || err.response.status !== 404) { throw err; }
      }
    }, 2500));
  },
  beforeDestroy() {
    this.intervals.forEach(clearInterval);
  },
  methods: {
    onCompleted(data) {
      if (this.isDone) { return; }

      if (data.order_ref == this.orderRef) {
        this.isDone = true;
        this.$emit('completed');
      }
    },
    onFailed(data) {
      if (this.isDone) { return; }

      if (data.order_ref == this.orderRef) {
        this.isDone = true;
        this.$emit('failed');
      }
    }
  }
};
</script>
