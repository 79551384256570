<template>
    <div>
        <div
            class="loadingscreen"
            v-bind:style="{ 'background-image': 'url(' + splash + ')' }"
            id="loadingscreen"
        >
            <div class="splashtext">
                <div class="splashlogo">
                    <img :src="logo" class="splashimg" />
                </div>
                <br />&nbsp;<br />&nbsp;<br />
                <img
                    src="https://sb-quotes.s3.eu-north-1.amazonaws.com/assets/Chunk-7.7s-200px.svg"
                    style="opacity:0.7"
                />
                <div class="blur">
                    <span style="font-size:80%">{{ title }}</span
                    ><br />
                    {{ name }}
                </div>
                <br />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["logo", "splash", "name", "opened", "title"],
    mounted() {
        this.splashTimer = setTimeout(function() {
            document.getElementById("loadingscreen").style.display = "none";
        }, 5000);
    }
};
</script>

<style>
.loadingscreen {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    display: inline-block;
    z-index: 1080; /* higher than all bootstrap components, see https://getbootstrap.com/docs/4.6/layout/overview/#z-index */
    background-size: cover;
    background-position: center;
}
.splashlogo {
    width: 100%;
    padding: 10px;
    top: 0px;
    background-color: white;
}
.splashimg {
    max-height: 50px;
    max-width: 40%;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #5e9c50; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin-left: 46%;
    margin-right: 10px;
    opacity: 0.7;
}
.splashtext {
    margin-top: 0%;
    font-size: 24px;
    font-weight: 400;
    color: white;
    text-align: center;
    backdrop-filter: blur(5px);
    backdrop-filter: brightness(70%);
    height: 100vh;
}
</style>
