<template>
    <div>
        <p v-if="count !== undefined">{{count}}</p>
        <div v-else class="spinner-border" role="status"><span class="sr-only">Laddar</span></div>
    </div>
</template>

<script>
export default {
    props: {
        userId: { required: false, default: null },
        period: { required: true }
    },
    data() {
        return {
            count: undefined
        }
    },
    async mounted() {
        await this.loadData();
    },
    watch: {
        userId: 'loadData',
        period: 'loadData'
    },
    methods: {
        async loadData() {
            const url = `/stats/quote_count`;
            const parameters = {
                user_id: this.userId,
                period: this.period
            };
            const resp = await axios.get(url, {params: parameters});
            this.count = resp.data.count;
        }
    }
}
</script>

<style scoped>
    p {
        font-size: 1.5em;
    }
</style>
