<template>
  <fieldset>
    <p>Konfigurera en woocommerce integration med följande inställningar:</p>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Länk</div>
      </div>
      <input class="form-control" required readonly :value="url">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Topic</div>
      </div>
      <input class="form-control" readonly value="Order Created">
    </div>
  </fieldset>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      uuid: null,
    }
  },
  computed: {
    serializedValue() {
      return {
        uuid: this.uuid
      };
    },
    url() {
      return route('integrations.woocommerce', this.uuid);
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.uuid = this.value.uuid;
        }

        if (!this.uuid) {
          this.uuid = uuidv4();
        }

        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.onUpdate();
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
