<template>
    <form ref="form" @submit.prevent="save">
        <fieldset :disabled="!theProduct.manually_created">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.sku") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    required
                    name="sku"
                    v-model="theProduct.sku"
                />
            </div>

            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.url") }}
                    </div>
                </div>
                <input
                    type="url"
                    class="form-control"
                    placeholder="http(s)://.."
                    name="url"
                    v-model="theProduct.url"
                />
            </div>
        </fieldset>

        <hr />

        <div v-if="theProduct.alternative_product" class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    {{ $t("products.attributes.alternative_product") }}
                </div>
            </div>
            <input
                type="text"
                class="form-control"
                disabled
                :value="theProduct.alternative_product.name"
            />
            <button
                type="button"
                class="btn btn-secondary"
                @click.prevent="theProduct.alternative_product = null"
            >
                <i class="fal fa-trash"></i>
            </button>
            <input
                type="hidden"
                name="alternative_product_id"
                :value="theProduct.alternative_product.id"
            />
        </div>
        <v-select
            v-else
            :options="optionsForAlternativeProduct"
            label="name"
            :placeholder="$t('products.editor.pick_alternative_product')"
            v-model="theProduct.alternative_product"
            :disabled="theProduct.allows_user_assigned_price"
        >
            <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
        </v-select>

        <hr />

        <fieldset :disabled="!theProduct.manually_created">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.name") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    required
                    name="name"
                    v-model="theProduct.name"
                />
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.description_title") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    name="description_title"
                    v-model="theProduct.description_title"
                />
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.description") }}
                    </div>
                </div>
                <tiptap-editor
                    v-if="theProduct.manually_created"
                    class="form-control"
                    style="height: 300px !important"
                    v-model="theProduct.description"
                ></tiptap-editor>
                <textarea
                    v-else
                    class="form-control h-auto"
                    style="min-height: 300px"
                    v-model="theProduct.description"
                ></textarea>
            </div>

            <div class="d-flex justify-content-center mb-3">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            {{ $t("products.attributes.image") }}
                            <img
                                v-if="theProduct.image_url"
                                :src="theProduct.image_url"
                                style="width: 150px; height: auto"
                                class="ml-2"
                            />
                        </div>
                    </div>
                    <input
                        type="file"
                        class="form-control"
                        :required="!theProduct.image_url"
                        name="image"
                        style="height: 100%"
                    />
                </div>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{
                            $t("products.attributes.allows_user_assigned_price")
                        }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    required
                    name="allows_user_assigned_price"
                    v-model="theProduct.allows_user_assigned_price"
                >
                    <option :value="false">
                        {{ $t("products.editor.select_false") }}
                    </option>
                    <option :value="true">
                        {{ $t("products.editor.select_true") }}
                    </option>
                </select>
            </div>

            <div
                class="input-group mb-3"
                v-show="theProduct.allows_user_assigned_price"
            >
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{
                            $t(
                                "products.attributes.minimum_price_including_vat"
                            )
                        }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    :required="theProduct.allows_user_assigned_price"
                    v-model="theProduct.minimum_price_including_vat"
                />
                <input
                    type="hidden"
                    name="minimum_price_including_vat"
                    :value="theProduct.minimum_price_including_vat"
                />
            </div>

            <div
                class="input-group mb-3"
                v-show="!theProduct.allows_user_assigned_price"
            >
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.price_including_vat") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    :required="!theProduct.allows_user_assigned_price"
                    v-model="theProduct.price_including_vat"
                />
                <input
                    type="hidden"
                    name="price_including_vat"
                    :value="theProduct.price_including_vat"
                />
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.currency") }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    required
                    name="currency"
                    v-model="theProduct.currency"
                >
                    <option value="sek_kr">
                        {{ $t("currencies.sek_kr") }}
                    </option>
                    <option value="sek_ore">
                        {{ $t("currencies.sek_ore") }}
                    </option>
                </select>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.price_unit") }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    required
                    name="price_unit"
                    v-model="theProduct.price_unit"
                >
                    <option value="st">
                        {{
                            $t("products.units.st", {
                                currency: $t(
                                    "currencies." + theProduct.currency
                                ),
                            })
                        }}
                    </option>
                    <option disabled>---</option>
                    <option value="month">
                        {{
                            $t("products.units.month", {
                                currency: $t(
                                    "currencies." + theProduct.currency
                                ),
                            })
                        }}
                    </option>
                    <option value="month-billed-yearly">
                        {{
                            $t("products.units.month-billed-yearly", {
                                currency: $t(
                                    "currencies." + theProduct.currency
                                ),
                            })
                        }}
                    </option>
                    <option value="year">
                        {{
                            $t("products.units.year", {
                                currency: $t(
                                    "currencies." + theProduct.currency
                                ),
                            })
                        }}
                    </option>
                    <option disabled>---</option>
                    <option value="kWh">
                        {{
                            $t("products.units.kWh", {
                                currency: $t(
                                    "currencies." + theProduct.currency
                                ),
                            })
                        }}
                    </option>
                </select>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.input_amount") }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    required
                    v-model="theProduct.input_amount"
                    :disabled="theProduct.allows_user_assigned_price"
                >
                    <option :value="false">
                        {{ $t("products.editor.select_false") }}
                    </option>
                    <option :value="true">
                        {{ $t("products.editor.select_true") }}
                    </option>
                </select>
                <input
                    type="hidden"
                    name="input_amount"
                    :value="theProduct.input_amount"
                />
            </div>

            <input
                type="hidden"
                name="input_prompt"
                v-model="theProduct.input_prompt"
            />

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.color") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    name="color"
                    v-model="theProduct.color"
                />
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.pattern") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    name="pattern"
                    v-model="theProduct.pattern"
                />
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.material") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    name="material"
                    v-model="theProduct.material"
                />
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.size") }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    name="size"
                    v-model="theProduct.size"
                />
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.age_group") }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    name="age_group"
                    v-model="theProduct.age_group"
                >
                    <option :value="null">-</option>
                    <option value="newborn">
                        {{ $t("products.age_groups.newborn") }}
                    </option>
                    <option value="infant">
                        {{ $t("products.age_groups.infant") }}
                    </option>
                    <option value="toddler">
                        {{ $t("products.age_groups.toddler") }}
                    </option>
                    <option value="kids">
                        {{ $t("products.age_groups.kids") }}
                    </option>
                    <option value="adult">
                        {{ $t("products.age_groups.adult") }}
                    </option>
                </select>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.gender") }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    name="gender"
                    v-model="theProduct.gender"
                >
                    <option :value="null">-</option>
                    <option value="male">
                        {{ $t("products.genders.male") }}
                    </option>
                    <option value="female">
                        {{ $t("products.genders.female") }}
                    </option>
                    <option value="unisex">
                        {{ $t("products.genders.unisex") }}
                    </option>
                </select>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.in_stock") }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    required
                    name="in_stock"
                    v-model="theProduct.in_stock"
                >
                    <option :value="false">
                        {{ $t("products.editor.select_false") }}
                    </option>
                    <option :value="true">
                        {{ $t("products.editor.select_true") }}
                    </option>
                </select>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.is_recurring") }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    required
                    name="is_recurring"
                    v-model="theProduct.is_recurring"
                >
                    <option :value="false">
                        {{ $t("products.editor.select_false") }}
                    </option>
                    <option :value="true">
                        {{ $t("products.editor.select_true") }}
                    </option>
                </select>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.deleted") }}
                    </div>
                </div>
                <select
                    class="custom-select"
                    required
                    name="deleted"
                    v-model="theProduct.deleted"
                >
                    <option :value="false">
                        {{ $t("products.editor.select_false") }}
                    </option>
                    <option :value="true">
                        {{ $t("products.editor.select_true") }}
                    </option>
                </select>
            </div>

            <div class="d-flex justify-content-between mb-3">
                <h5 class="my-0">
                    {{ $t("products.editor.header_categories") }}
                </h5>
                <button
                    v-if="theProduct.manually_created"
                    type="button"
                    class="btn btn-sm btn-secondary"
                    @click.prevent="theProduct.categories.push({ name: null })"
                >
                    {{ $t("products.editor.button_add_category") }}
                </button>
            </div>

            <div
                v-for="(category, categoryIdx) in theProduct.categories"
                :key="categoryIdx"
                class="input-group mb-3"
            >
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        {{ $t("products.attributes.category") }}
                        {{ categoryIdx + 1 }}
                    </div>
                </div>
                <input
                    type="text"
                    class="form-control"
                    required
                    name="categories[]"
                    v-model="category.name"
                />
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click.prevent="
                        theProduct.categories.splice(categoryIdx, 1)
                    "
                >
                    <i class="fal fa-trash"></i>
                </button>
            </div>

            <p v-if="theProduct.categories.length === 0" class="mb-3">
                -
                <input type="hidden" name="categories[]" value="" />
            </p>
        </fieldset>

        <div
            v-if="successMessage"
            class="alert alert-success mb-3"
            role="alert"
        >
            {{ successMessage }}
        </div>
        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
            {{ errorMessage }}
        </div>

        <div v-if="!saving" class="d-flex justify-content-between">
            <button type="submit" class="btn btn-primary">
                {{
                    $t(
                        theProduct.id
                            ? "products.editor.button_save_existing"
                            : "products.editor.button_save_new"
                    )
                }}
            </button>
            <button
                type="button"
                class="btn btn-secondary"
                @click="$emit('cancel')"
            >
                Stäng / Avbryt
            </button>
        </div>
        <b-spinner v-else></b-spinner>
    </form>
</template>

<style lang="scss" scoped>
.is-deleted-product {
    opacity: 0.5;
}
</style>

<script>
import axios from "axios";

const PRICE_CLEANUP_REGEXP = /[^0-9\.]/g;

export default {
    props: {
        product: { type: Object, required: true },
        products: { type: Array, required: true },
    },
    data() {
        return {
            theProduct: null,
            saving: false,
            errorMessage: null,
            successMessage: null,
        };
    },
    computed: {
        optionsForAlternativeProduct() {
            return this.products
                .filter(
                    (prod) =>
                        prod.id &&
                        prod.sku != this.theProduct.sku &&
                        !prod.solo_product
                )
                .map((prod) => ({ id: prod.id, name: prod.name }));
        },
    },
    watch: {
        product: {
            handler() {
                if (
                    this.product?.id !== "_new" &&
                    JSON.stringify(this.product) ===
                        JSON.stringify(this.theProduct)
                ) {
                    return;
                }

                if (this.product?.id === "_new") {
                    this.createNewProduct();
                } else {
                    this.theProduct = JSON.parse(JSON.stringify(this.product));
                }

                this.errorMessage = null;
                this.successMessage = null;

                if (this.$refs.form) {
                    this.$refs.form
                        .querySelectorAll('input[type="file"]')
                        .forEach((i) => (i.value = null));
                }
            },
            immediate: true,
        },
        "theProduct.sku"() {
            if (!this.$refs.form) {
                return;
            }

            const skuInput = this.$refs.form.querySelector('input[name="sku"]');
            if (
                this.theProduct.sku &&
                this.products.filter(
                    (p) =>
                        p !== this.theProduct &&
                        p.sku.toLowerCase() ===
                            this.theProduct.sku.toLowerCase()
                ).length > 0
            ) {
                skuInput.setCustomValidity(
                    this.$t("products.editor.sku_in_use_error")
                );
            } else {
                skuInput.setCustomValidity("");
            }
        },
        "theProduct.allows_user_assigned_price"(newVal) {
            if (!newVal) {
                return;
            }

            this.theProduct.price_including_vat = 0;
            this.theProduct.minimum_price_including_vat = 0;
            this.theProduct.input_amount = false;
            this.theProduct.input_prompt = null;
            this.theProduct.alternative_product = null;
        },
        "theProduct.price_including_vat"(newValue) {
            if (this.theProduct.manually_created && newValue) {
                const cleanupValue = newValue
                    .replaceAll(",", ".")
                    .replaceAll(PRICE_CLEANUP_REGEXP, "");
                if (newValue !== cleanupValue) {
                    this.theProduct.price_including_vat = cleanupValue;
                }
            }
        },
        "theProduct.minimum_price_including_vat"(newValue) {
            if (this.theProduct.manually_created && newValue) {
                const cleanupValue = newValue
                    .replaceAll(",", ".")
                    .replaceAll(PRICE_CLEANUP_REGEXP, "");
                if (newValue !== cleanupValue) {
                    this.theProduct.minimum_price_including_vat = cleanupValue;
                }
            }
        },
    },
    methods: {
        createNewProduct() {
            this.theProduct = {
                id: null,
                sku: null,
                url: null,
                name: null,
                description_title: null,
                description: null,
                image_url: null,
                categories: [],
                allows_user_assigned_price: false,
                price_including_vat: null,
                minimum_price_including_vat: null,
                price_unit: "st",
                currency: "sek_kr",
                input_amount: true,
                input_prompt: null,
                color: null,
                pattern: null,
                material: null,
                age_group: null,
                gender: null,
                size: null,
                in_stock: true,
                deleted: false,
                manually_created: true,
                is_recurring: false,
            };
        },
        async save() {
            if (this.saving) {
                return;
            }

            this.saving = true;
            this.errorMessage = null;
            this.successMessage = null;

            try {
                const formData = new FormData(this.$refs.form);
                // tiptap-field
                if (this.theProduct.description) {
                    formData.append("description", this.theProduct.description);
                } else {
                    this.errorMessage = "Beskrivning måste fyllas in";
                    this.saving = false;
                    return;
                }

                if (this.theProduct.id) {
                    formData.append("_method", "put");
                    const response = await axios.post(
                        this.route("products.update", this.theProduct.id),
                        formData
                    );

                    this.$emit("updated", response.data);
                } else {
                    const response = await axios.post(
                        this.route("products.store"),
                        formData
                    );
                    this.$emit("created", response.data);
                }

                this.successMessage = this.$t("products.editor.save_success");
            } catch (e) {
                if (console.warn) {
                    console.warn(e);
                }

                this.errorMessage = this.$t("products.editor.save_error");
            }

            this.saving = false;
        },
    },
};
</script>
