<template>
    <div class="card" v-if="alternative !== null">
        <input
            type="hidden"
            :name="`alternatives[${idx}][id]`"
            :value="alternative.id"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][name]`"
            :value="alternative.name"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][currency]`"
            :value="alternative.currency"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][discount_type]`"
            :value="alternative.discount_type"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][discount]`"
            :value="alternative.discount"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][discount_valid_until]`"
            :value="alternative.discount_valid_until"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][price_locked_until]`"
            :value="alternative.price_locked_until"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][recommended]`"
            :value="alternative.recommended ? 1 : 0"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][recurring_start_date]`"
            :value="alternative.recurring_start_date"
        />
        <input
            type="hidden"
            :name="`alternatives[${idx}][recurring_interval]`"
            :value="alternative.recurring_interval"
        />

        <div class="card-header text-center">
            <i
                aria-hidden="true"
                class="fal fa-cart-shopping mb-3 mt-3"
                style="font-size: 3rem"
            ></i>

            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    v-model="alternative.name"
                    required
                />
                <div v-if="deletable" class="input-group-append">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click.prevent="onDelete"
                    >
                        <i aria-hidden="true" class="far fa-times"></i>
                        <span class="sr-only">Radera</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body px-0 pb-0">
            <div
                v-for="(
                    alternativeProduct, alternativeProductIdx
                ) in alternative.products"
                :key="alternativeProductIdx"
                class="px-3"
            >
                <input
                    type="hidden"
                    :name="`alternatives[${idx}][products][${alternativeProductIdx}][id]`"
                    :value="alternativeProduct.product.id"
                />
                <input
                    type="hidden"
                    :name="`alternatives[${idx}][products][${alternativeProductIdx}][input_prompt]`"
                    :value="alternativeProduct.product.input_prompt"
                />
                <input
                    type="hidden"
                    :name="`alternatives[${idx}][products][${alternativeProductIdx}][is_add_on]`"
                    :value="alternativeProduct.is_add_on"
                />
                <input
                    type="hidden"
                    :name="`alternatives[${idx}][products][${alternativeProductIdx}][prompted_value]`"
                    :value="alternativeProduct.promptedValue"
                />
                <input
                    type="hidden"
                    :name="`alternatives[${idx}][products][${alternativeProductIdx}][amount]`"
                    :value="alternativeProduct.amount"
                />
                <input
                    type="hidden"
                    :name="`alternatives[${idx}][products][${alternativeProductIdx}][discount_type]`"
                    :value="alternativeProduct.discount_type"
                />
                <input
                    type="hidden"
                    :name="`alternatives[${idx}][products][${alternativeProductIdx}][discount]`"
                    :value="alternativeProduct.discount"
                />

                <div class="row mb-2">
                    <div class="col">
                        <div class="d-flex align-items-start">
                            <img
                                :src="alternativeProduct.product.image_url"
                                alt=""
                                class="product-image mr-2"
                            />
                            <div>
                                <strong>{{
                                    alternativeProduct.product.name
                                }}</strong>
                                <div class="text-muted" style="font-size: 70%">
                                    {{ alternativeProduct.product.sku }}
                                </div>
                                <template
                                    v-if="
                                        alternativeProduct.product
                                            .allows_user_assigned_price
                                    "
                                >
                                    {{
                                        $t(
                                            "products.attributes.allows_user_assigned_price"
                                        )
                                    }}
                                </template>
                                <template v-else>
                                    {{
                                        alternativeProduct.product
                                            .price_including_vat
                                    }}
                                    {{
                                        $t(
                                            "products.units." +
                                                alternativeProduct.product
                                                    .price_unit,
                                            {
                                                currency: formattedCurrency(
                                                    alternativeProduct.product
                                                        .currency
                                                ),
                                            }
                                        )
                                    }}
                                </template>
                                <template
                                    v-if="
                                        alternativeProduct.product
                                            .alternative_product
                                    "
                                >
                                    <br />
                                    <small
                                        >Alternativ:
                                        {{
                                            alternativeProduct.product
                                                .alternative_product.name
                                        }}</small
                                    >
                                </template>
                            </div>
                        </div>
                    </div>

                    <button
                        type="button"
                        class="btn btn-secondary btn-sm mr-3"
                        @click.prevent="deleteProduct(alternativeProductIdx)"
                    >
                        <i aria-hidden="true" class="far fa-trash"></i>
                        <span class="sr-only">Radera</span>
                    </button>
                </div>

                <div class="row" v-if="!isSoloProduct">
                    <div class="col">
                        <div
                            class="input-group input-group-sm mb-2"
                            v-if="alternativeProduct.product.cost_of_goods_sold"
                        >
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    Kostnad för varor
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                required
                                readonly
                                :value="
                                    alternativeProduct.product
                                        .cost_of_goods_sold +
                                    ' ' +
                                    formattedCurrency(alternative.currency)
                                "
                            />
                        </div>
                        <div
                            v-if="alternativeProduct.product.input_amount"
                            class="input-group input-group-sm mb-2"
                        >
                            <div class="input-group-prepend">
                                <span class="input-group-text">Antal</span>
                            </div>

                            <input
                                type="text"
                                class="form-control"
                                required
                                v-model="alternativeProduct.amount"
                            />
                        </div>

                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                    >Visa som möjligt tillval</span
                                >
                            </div>
                            <select
                                class="custom-select"
                                v-model="alternativeProduct.is_add_on"
                            >
                                <option :value="true">Ja</option>
                                <option :value="false">Nej</option>
                            </select>
                        </div>

                        <hr />
                        <div
                            v-if="alternativeProduct.product.input_prompt"
                            class="input-group input-group-sm mb-2"
                        >
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{
                                    alternativeProduct.product.input_prompt
                                }}</span>
                            </div>

                            <input
                                type="text"
                                class="form-control"
                                required
                                v-model="alternativeProduct.promptedValue"
                            />
                        </div>

                        <template v-if="allowProductDiscount">
                            <div class="input-group input-group-sm mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"
                                        >Produktrabatt Typ</span
                                    >
                                </div>
                                <select
                                    class="custom-select"
                                    v-model="alternativeProduct.discount_type"
                                >
                                    <option value="kr" selected>kr</option>
                                    <option value="pct">%</option>
                                </select>
                            </div>

                            <div
                                v-if="alternativeProduct.discount_type === 'kr'"
                                class="input-group input-group-sm mb-3"
                            >
                                <div class="input-group-prepend">
                                    <span class="input-group-text"
                                        >Produktrabatt ({{
                                            formattedCurrency(
                                                alternative.currency
                                            )
                                        }})</span
                                    >
                                </div>

                                <input
                                    type="number"
                                    min="0"
                                    :max="
                                        getPriceBeforeDiscount(
                                            alternativeProduct
                                        )
                                    "
                                    class="form-control"
                                    v-model="alternativeProduct.discount"
                                />
                            </div>

                            <div
                                v-if="
                                    alternativeProduct.discount_type === 'pct'
                                "
                                class="input-group input-group-sm mb-3"
                            >
                                <div class="input-group-prepend">
                                    <span class="input-group-text"
                                        >Produktrabatt (%)</span
                                    >
                                </div>

                                <input
                                    type="number"
                                    min="0"
                                    :max="100"
                                    class="form-control"
                                    v-model="alternativeProduct.discount"
                                />
                            </div>

                            <template
                                v-if="
                                    alternativeProduct.discount &&
                                    alternativeProduct.discount > 0
                                "
                            >
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"
                                            >Pris inkl. rabatt</span
                                        >
                                    </div>
                                    <input
                                        class="form-control"
                                        readonly
                                        :value="`${getPriceAfterDiscount(
                                            alternativeProduct
                                        )} ${formattedCurrency(
                                            alternative.currency
                                        )}`"
                                    />
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div v-if="isSoloProduct" class="row">
                    <div class="col">
                        <div
                            class="alert alert-info text-center mb-2"
                            role="alert"
                        >
                            {{ $t("products.attributes.solo_product") }}
                        </div>
                    </div>
                </div>

                <div class="row" v-if="!alternativeProduct.product.in_stock">
                    <div class="col">
                        <div
                            class="alert alert-danger text-center mb-2"
                            role="alert"
                        >
                            <i class="far fa-warning mr-1"></i> Produkten finns
                            ej i lager!
                        </div>
                    </div>
                </div>

                <div class="row" v-if="alternativeProduct.product.deleted">
                    <div class="col">
                        <div
                            class="alert alert-danger text-center mb-2"
                            role="alert"
                        >
                            <i class="far fa-warning mr-1"></i> Produkten är
                            borttagen!
                        </div>
                    </div>
                </div>

                <hr />
            </div>

            <template v-if="!isSoloProduct">
                <div class="row px-3">
                    <div class="col">
                        <products-search
                            :products="products"
                            :ignore-out-of-stock="ignoreOutOfStock"
                            :ignore-deleted="ignoreDeleted"
                            :allow-solo-products="!hasProducts"
                            :required="!hasProducts"
                            :default-discount-type="defaultDiscountType"
                            @onAddProducts="addProducts"
                        ></products-search>
                    </div>
                </div>

                <hr />
            </template>

            <template v-if="editRecommended">
                <div class="input-group input-group-sm mb-3 px-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Rekommenderat val</span>
                    </div>
                    <select
                        class="custom-select"
                        v-model="alternative.recommended"
                    >
                        <option :value="true">Ja</option>
                        <option :value="false">Nej</option>
                    </select>
                </div>

                <hr />
            </template>

            <template v-if="!isAllowingUserAssignedPrice">
                <div
                    :class="{
                        'p-3': true,
                        'card-section-background':
                            alternative.products.length > 0,
                    }"
                >
                    <div
                        v-if="totalCostOfGoodsSold > 0"
                        class="input-group input-group-sm mb-3"
                    >
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                >Paketkostnad för varor</span
                            >
                        </div>

                        <input
                            class="form-control"
                            readonly
                            :value="`${totalCostOfGoodsSold} ${formattedCurrency(
                                alternative.currency
                            )}`"
                        />
                    </div>
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Pris</span>
                        </div>

                        <input
                            class="form-control"
                            readonly
                            :value="`${priceBeforeDiscount} ${formattedCurrency(
                                alternative.currency
                            )}`"
                        />
                    </div>
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Lås priset tom</span>
                        </div>
                        <input
                            type="date"
                            class="form-control"
                            v-model="alternative.price_locked_until"
                        />
                        <div class="input-group-append">
                            <button
                                v-if="alternative.price_locked_until"
                                type="button"
                                class="btn btn-primary"
                                @click="alternative.price_locked_until = null"
                            >
                                <i class="fa-regular fa-times"></i>
                            </button>
                        </div>
                    </div>

                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                >Paketrabatt Typ</span
                            >
                        </div>
                        <select
                            class="custom-select"
                            v-model="alternative.discount_type"
                        >
                            <option value="kr">kr</option>
                            <option value="pct">%</option>
                        </select>
                    </div>

                    <div
                        v-if="alternative.discount_type === 'kr'"
                        class="input-group input-group-sm mb-3"
                    >
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                >Paketrabatt ({{
                                    formattedCurrency(alternative.currency)
                                }})</span
                            >
                        </div>

                        <input
                            type="number"
                            min="0"
                            :max="priceBeforeDiscount"
                            class="form-control"
                            v-model="alternative.discount"
                        />
                    </div>

                    <div
                        v-if="alternative.discount_type === 'pct'"
                        class="input-group input-group-sm mb-3"
                    >
                        <div class="input-group-prepend">
                            <span class="input-group-text"
                                >Paketrabatt (%)</span
                            >
                        </div>

                        <input
                            type="number"
                            min="0"
                            :max="100"
                            class="form-control"
                            v-model="alternative.discount"
                        />
                    </div>

                    <template
                        v-if="alternative.discount && alternative.discount > 0"
                    >
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                    >Pris inkl. rabatt</span
                                >
                            </div>
                            <input
                                class="form-control"
                                readonly
                                :value="`${priceAfterDiscount} ${formattedCurrency(
                                    alternative.currency
                                )}`"
                            />
                        </div>
                    </template>

                    <template
                        v-if="
                            (alternative.discount &&
                                alternative.discount > 0) ||
                            hasDiscountedProductItem
                        "
                    >
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                    >Rabatt giltig tom</span
                                >
                            </div>
                            <input
                                type="date"
                                class="form-control"
                                v-model="alternative.discount_valid_until"
                            />
                            <div class="input-group-append">
                                <button
                                    v-if="alternative.discount_valid_until"
                                    type="button"
                                    class="btn btn-primary"
                                    @click="
                                        alternative.discount_valid_until = null
                                    "
                                >
                                    <i class="fa-regular fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </template>
                    <!--
                          We will need this later
              -->
                    <template v-if="false">
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                    >Återkommande från</span
                                >
                            </div>

                            <input
                                type="date"
                                class="form-control"
                                v-model="alternative.recurring_start_date"
                            />
                            <div class="input-group-append">
                                <button
                                    v-if="alternative.recurring_start_date"
                                    type="button"
                                    class="btn btn-primary"
                                    @click="
                                        alternative.recurring_start_date = null
                                    "
                                >
                                    <i class="fa-regular fa-times"></i>
                                </button>
                            </div>
                        </div>
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text"
                                    >Återkommande intervall</span
                                >
                            </div>
                            <select
                                class="custom-select"
                                v-model="alternative.recurring_interval"
                            >
                                <option value="monthly" selected>
                                    Månatlig
                                </option>
                            </select>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
    <div class="card" v-else>
        <div class="card-header text-center">
            <i
                aria-hidden="true"
                class="fal fa-cart-shopping mb-3 mt-3"
                style="font-size: 3rem"
            ></i>

            <div class="input-group">
                <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="nextAlternativeName"
                />
            </div>
        </div>
        <div class="card-body">
            <button
                type="button"
                class="btn btn-light btn-lg"
                @click.prevent="onCreate"
            >
                <i class="fal fa-plus"></i> Lägg till alternativ
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.product-image {
    width: 20%;
    height: auto;

    aspect-ratio: 1 / 1;

    object-fit: contain;
    object-position: center top;
}
.card-section-background {
    background-color: var(--primary-color) !important;
    background: linear-gradient(
            rgba(255, 255, 255, 0.6),
            /* Light overlay */ rgba(255, 255, 255, 0.6)
        ),
        var(--primary-color);
}
</style>

<script>
import moment from "moment";

export default {
    props: {
        products: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
            default: null,
        },
        idx: {
            type: Number,
            required: true,
        },
        nextAlternativeName: {
            type: String,
            required: true,
        },
        nextAlternativeRecommended: {
            type: Boolean,
            required: true,
        },
        deletable: {
            type: Boolean,
            required: true,
        },
        editRecommended: {
            type: Boolean,
            required: true,
        },
        defaultPriceLockedForDays: {
            type: Number,
            default: undefined,
        },
        ignoreOutOfStock: {
            type: Boolean,
            required: true,
        },
        ignoreDeleted: {
            type: Boolean,
            required: true,
        },
        recurringPayments: {
            type: Object,
            default: null,
        },
        allowProductDiscount: {
            type: Boolean,
            default: false,
        },
        defaultDiscountType: {
            type: String,
            default: "kr",
        },
    },
    data() {
        return {
            alternative: null,
        };
    },
    computed: {
        priceBeforeDiscount() {
            let price = 0;
            this.alternative.products.forEach((prod) => {
                if (prod.product && !prod.is_add_on) {
                    price += Number(this.getPriceAfterDiscount(prod));
                }
            });
            return price.toFixed(2);
        },
        priceAfterDiscount() {
            let price = Number(this.priceBeforeDiscount);

            if (this.alternative.discount_type === "kr") {
                price -= this.alternative.discount;
            } else if (this.alternative.discount_type === "pct") {
                price -= price * (this.alternative.discount / 100);
            }

            return (price >= 0 ? price : 0).toFixed(2);
        },
        totalCostOfGoodsSold() {
            return this.alternative.products
                .reduce((result, product) => {
                    if (!product.product.cost_of_goods_sold) result;
                    return Number(product.product.cost_of_goods_sold) + result;
                }, 0)
                .toFixed(2);
        },
        isAllowingUserAssignedPrice() {
            return (
                this.alternative.products?.length > 0 &&
                this.alternative.products.every(
                    (prod) => prod.product.allows_user_assigned_price
                )
            );
        },
        hasProducts() {
            return this.alternative.products?.length > 0;
        },
        isSoloProduct() {
            return (
                this.alternative.products?.length > 0 &&
                this.alternative.products.every(
                    (prod) => prod.product.solo_product
                )
            );
        },
        hasDiscountedProductItem() {
            return (
                this.alternative.products?.filter(
                    (prod) => prod.discount && prod.discount > 0
                ).length > 0
            );
        },
    },
    watch: {
        value: {
            handler() {
                if (JSON.stringify(this.value) === "{}") {
                    this.alternative = {
                        id: undefined,
                        name: this.nextAlternativeName,
                        amount: 1,
                        recommended: this.nextAlternativeRecommended,
                        currency: "sek_kr",
                        discount: undefined,
                        discount_type: this.defaultDiscountType,
                        recurring_start_date: (() => {
                            if (
                                this.recurringPayments &&
                                this.recurringPayments.start_date
                            ) {
                                return moment(
                                    this.recurringPayments.start_date
                                ).format("YYYY-MM-DD");
                            }

                            return undefined;
                        })(),
                        recurring_interval: this.recurringPayments
                            ? "monthly"
                            : undefined,
                        price_locked_until: (() => {
                            if (this.defaultPriceLockedForDays) {
                                return moment()
                                    .add(this.defaultPriceLockedForDays, "days")
                                    .format("YYYY-MM-DD");
                            } else {
                                return undefined;
                            }
                        })(),
                        products: [],
                    };
                } else {
                    this.alternative = JSON.parse(JSON.stringify(this.value));
                }
            },
            immediate: true,
            deep: true,
        },
        alternative: {
            handler() {
                if (
                    this.alternative !== null &&
                    JSON.stringify(this.alternative) !==
                        JSON.stringify(this.value)
                ) {
                    this.$emit(
                        "update",
                        JSON.parse(JSON.stringify(this.alternative))
                    );
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        onCreate() {
            this.$emit("create");
        },
        onDelete() {
            this.$emit("delete");
        },
        addProducts(products) {
            products.forEach((product) =>
                this.alternative.products.push(product)
            );
            this.updateCurrency();
        },
        deleteProduct(idx) {
            this.alternative.products.splice(idx, 1);
            this.updateCurrency();
        },
        updateCurrency() {
            if (this.alternative.products.length > 0) {
                this.alternative.currency =
                    this.alternative.products[0].product.currency;
            }
        },
        convertPrice(targetCurrency, price, priceCurrency) {
            if (targetCurrency === priceCurrency) {
                return price;
            }
            if (price === 0) {
                return 0;
            }

            if (targetCurrency === "sek_kr" && priceCurrency === "sek_ore") {
                return price / 100;
            }

            if (targetCurrency === "sek_ore" && priceCurrency === "sek_kr") {
                return price * 100;
            }

            throw `Incompatible currencies ${targetCurrency} and ${priceCurrency}`;
        },
        formattedCurrency(currency) {
            return this.$t("currencies." + currency);
        },
        getPriceBeforeDiscount(alternativeProduct) {
            if (!alternativeProduct.product) return 0;

            return this.convertPrice(
                this.alternative.currency,
                alternativeProduct.product.price_including_vat *
                    alternativeProduct.amount,
                alternativeProduct.product.currency
            ).toFixed(2);
        },
        getPriceAfterDiscount(alternativeProduct) {
            let reducedPrice = this.getPriceBeforeDiscount(alternativeProduct);
            if (
                !this.allowProductDiscount ||
                alternativeProduct.discount === undefined
            )
                return reducedPrice;

            if (alternativeProduct.discount_type === "kr") {
                reducedPrice -= alternativeProduct.discount;
            } else if (alternativeProduct.discount_type === "pct") {
                reducedPrice -=
                    this.getPriceBeforeDiscount(alternativeProduct) *
                    (alternativeProduct.discount / 100);
            }

            return (reducedPrice >= 0 ? reducedPrice : 0).toFixed(2);
        },
    },
};
</script>
