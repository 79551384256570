export default function (pixelId, quoteKey, value, currency) {
    const eventData = {};
    if (value !== undefined && currency == 'sek_kr') {
        eventData.value = value;
        eventData.currency = 'SEK';
    } else if (value !== undefined && currency == 'sek_ore') {
        eventData.value = value / 100.0;
        eventData.currency = 'SEK';
    } else {
        return;
    }

    window.fbq('trackSingle', pixelId, 'Purchase', eventData, { eventID: 'SalesByte/' + quoteKey });
};
