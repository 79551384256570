<template>
  <div>
    <a v-show="!isOpen" role="button" @click.prevent="open()">
        Meddelande
        <span v-if="unreadCount > 0" class="badge badge-pill badge-danger">{{unreadCount}}</span>
    </a>
    <div v-if="isOpen">Open!</div>
    <portal v-if="isOpen" to="floating-action-buttons-popup">
      <div class="card-container">
        <div class="card h-100">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-start align-items-center">
                <img v-if="sellerAvatarUrl" :src="sellerAvatarUrl" class="mr-2 seller-avatar">
                <span class="h6 m-0">Meddelande</span>
              </div>

              <a href="#" @click.prevent="close()"><i class="fa-light fa-times text-dark"></i></a>
            </div>
          </div>
          <div class="card-body overflow-auto">
            <quote-chats-index :filter-enabled="filterEnabled" />
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<style lang="scss" scoped>
a .badge {
  margin-top: 0.8em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.card-container {
  display: block;
  position: fixed;
  z-index: 1000;

  bottom: 0;
  top: 57px + 20px;
  right: 1em;
  width: 24vw;
  max-width: 320px;

  .card-header {
    background: transparent;

    .seller-avatar {
      width: 25px;
      height: 25px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      margin-left: -8px;
    }
  }

  .card-body {
    padding: 0;
  }
}
</style>

<script>
import axios from 'axios';

export default {
  props: {
    isOpen: { type: Boolean, default: false },
    sellerAvatarUrl: { type: String, default: null },
    filterEnabled: { type: Boolean, default: false }
  },
  data() {
    return {
      unreadCount: 0,
      newMessageAudio: new Audio("/sounds/new-message.wav")
    }
  },
  async mounted() {
    await this.reload(true);

    this.interval = setInterval(async () => {
      await this.reload();
    }, 20000);

    function unlockAudio() {
      const sound = new Audio('/sounds/new-message.wav');
      sound.play().catch((err) => console.log("Somewhat expected error unlocking audio (play): ", err));
      sound.pause();
      sound.currentTime = 0;

      document.body.removeEventListener('click', unlockAudio)
      document.body.removeEventListener('touchstart', unlockAudio)
    }
    document.body.addEventListener('click', unlockAudio);
    document.body.addEventListener('touchstart', unlockAudio);
  },
  methods: {
    async reload(suppressAudio) {
      const oldCount = this.unreadCount || 0;
      const response = await axios.get(route('chats.index'));

      if (response.data === null || response.data === undefined) {
        throw "No data returned from server: " + JSON.stringify(response);
      };

      this.unreadCount = response.data.filter((chat) => chat.most_recent_customer_chat_message_is_missing_response).length;

      if (this.unreadCount > oldCount && !suppressAudio) { this.playNewMessageSound(); }
    },
    open() {
      this.$emit('open');
    },
    close() {
      this.$emit('close');
      this.reload();
    },
    playNewMessageSound() {
      this.newMessageAudio.play();
    }
  },
}
</script>
