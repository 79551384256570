<template>
  <form method="post" :action="route('quote_products.updateByQuote', id, {is_template : isTemplate})">
    <input type="hidden" name="_method" value="put">

    <quotes-products-create
      :id="id"
      :edit-recommended="editRecommended"
      :ignore-out-of-stock="ignoreOutOfStock"
      :ignore-deleted="ignoreDeleted"
      :recurring-payments="recurringPayments"
      :allow-product-discount="allowProductDiscount"
      :default-discount-type="defaultDiscountType"
      ></quotes-products-create>

    <hr class="mb-3 mt-3">

    <div class="row mt-4">
      <div class="col">
        <quotes-products-signable-editor :signable="signable" :signable-until="signableUntil"></quotes-products-signable-editor>
      </div>

      <div class="col">
        <h3 class="mb-3">
          <i class="fal fa-gears"></i>
        </h3>
        <div class="custom-control custom-switch">
          <input class="custom-control-input" type="checkbox" :id="`${uuid}-amounts_editable`" name="amounts_editable" v-model="amountsEditable">
          <label class="custom-control-label" :for="`${uuid}-amounts_editable`">
            Kontakten kan själv redigera antal produkter i varukorgen
          </label>
        </div>
      </div>
    </div>

    <template>
      <hr class="mb-4 mt-4">

      <h5 class="mb-3">
        Uppdatering
      </h5>

      <div class="row mt-4">
        <div class="col">
          <div v-if="canNotifyCustomer" class="form-check">
            <input class="form-check-input" type="checkbox" :id="`${uuid}-notify_customer`" name="notify_customer" v-model="notifyCustomer">
            <label class="form-check-label" :for="`${uuid}-notify_customer`">
              Skicka notis till kontakt om att erbjudandet är uppdaterad
            </label>
            </div>
        </div>

        <div class="col" :class="canNotifyCustomer ? 'text-center' : null">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :id="`${uuid}-ignore_out_of_stock`" name="ignore_out_of_stock" v-model="ignoreOutOfStock">
            <label class="form-check-label" :for="`${uuid}-ignore_out_of_stock`">
              Ignorera lagerstatus
            </label>
          </div>
        </div>

        <div class="col">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :id="`${uuid}-ignore_deleted`" name="ignore_deleted" v-model="ignoreDeleted">
            <label class="form-check-label" :for="`${uuid}-ignore_deleted`">
              Tillåt köp av borttagna produkter
            </label>
          </div>
        </div>
      </div>
    </template>

    <button x- type="submit" class="btn btn-primary mt-4">
      <template v-if="notifyCustomer">
        Spara ändring och skicka notis till kund
      </template>
      <template v-else>
        Spara
      </template>
    </button>
  </form>
</template>

<style lang="scss" scoped>
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        id: {
            type: Number,
            default: undefined
        },
        editRecommended: {
            type: Boolean,
            default: false
        },
        canNotifyCustomer: {
            type: Boolean,
            default: false
        },
        ignoreOutOfStock: {
            type: Boolean,
            default: false
        },
        ignoreDeleted: {
            type: Boolean,
            default: false
        },
        amountsEditable: {
            type: Boolean,
            default: false
        },
        signable: {
            type: Boolean,
            default: false
        },
        signableUntil: {
            type: String,
            default: null
        },
        recurringPayments: {
          type: Object,
          default: null
        },
        isTemplate: {
          type: Boolean,
          default: null
        },
        allowProductDiscount: {
          type: Boolean,
          default: false
       },
        defaultDiscountType: {
          type: String,
          default: 'kr'
        }
    },
    data() {
        return {
          notifyCustomer: this.canNotifyCustomer,
          uuid: uuidv4()
        };
    }
}
</script>
