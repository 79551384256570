<template>
  <fieldset>
    <div class="mb-3">
      <div class="input-group mb-1">
        <div class="input-group-prepend">
          <div class="input-group-text">Google Conversion ID</div>
        </div>
        <input
          type="text" class="form-control"
          required pattern="^[0-9]+$"
          v-model="tagId" @input="onUpdate">
      </div>
      <p class="small italic">T.ex. <code>11137983405</code></p>
    </div>

    <div class="mb-3">
      <div class="input-group mb-1">
        <div class="input-group-prepend">
          <div class="input-group-text">Google Conversion Label</div>
        </div>
        <input
          type="text" class="form-control"
          required
          v-model="conversionId" @input="onUpdate">
      </div>
      <p class="small italic">T.ex. <code>V_K5CLyn5ZgYEK3HgL8p</code></p>
    </div>
  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      tagId: null,
      conversionId: null,
    }
  },
  computed: {
    serializedValue() {
      return {
        tag_id: this.tagId,
        conversion_id: this.conversionId,
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.tagId = this.value.tag_id;
          this.conversionId = this.value.conversion_id;
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
