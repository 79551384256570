<template>
    <div class="card mb-4">
        <div class="card-header">
            <div v-on:click="collapseExpand" class="float-right">
                <small role="button" v-if="collapsed" class="wiggle"><i class="fa-sharp fa-solid fa-plus"></i></small>
                <small role="button" v-if="!collapsed"><i class="fa-regular fa-minus"></i></small>
            </div>
            <slot name="header"></slot>
        </div>

        <div class="card-body" v-if="$slots['preview'] || !collapsed" :class="noCardPadding ? 'p-0' : ''">
            <slot v-if="collapsed" name="preview"></slot>
            <slot v-if="!collapsed" name="body"></slot>
        </div>

    </div>
</template>
<script>
export default {
  props: {
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
    noCardPadding: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      collapsed: !this.initiallyExpanded,
    };
  },
  async mounted() {
  },
  methods: {
    collapseExpand() {
      if(this.collapsed === true) {
        this.collapsed = false;
      } else {
        this.collapsed = true;
      }
    },
  },
}
</script>
