<template>
    <div class="modal fade" id="bankid-documents" tabindex="-1" role="dialog" aria-hidden="true" ref="modal">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" @click="userSelected = undefined" data-dismiss="modal" aria-label="Stäng">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="card-body">
                        <div class="form-group">
                            <p>
                                Välj dokument och klicka på Signera för att signera med ditt bankid.
                            </p>

                            <div class="card mb-2 w-100" :class="selected == idx ? 'border-color-profile-color' : null" v-for="(doc, idx) in documents" @click.prevent="userSelected = idx">
                                <div class="card-body d-flex align-items-center">
                                    <div style="flex-grow: 1;">
                                        <b>{{doc.name}}</b><br>

                                        <template v-if="!disablePdfViewer && !doc.is_download">
                                            <a href="#" class="mb-3 mt-3" @click.prevent="dismissAndOpenDocument(doc.id)"><i class="fas fa-external-link"></i> Läs mer</a>
                                        </template>
                                        <template v-else>
                                            <a :href="doc.pdf_url" target="_blank" class="mb-3 mt-3" :download="doc.is_download ? doc.name_with_extension : null" @click.stop><i class="fas fa-external-link"></i> Läs mer</a>
                                        </template>
                                    </div>
                                    <div v-if="selected == idx" style="font-size: 1.8em;" class="pl-2 py-3 text-color-profile-color">
                                        <i class="far fa-check"></i>
                                    </div>
                                </div>
                            </div>
                            <component
                              :is="signComponentName"
                              :item-id="itemId"
                              :item-description="signComponentItemDescription"
                              :app-url="appUrl"
                              :redirect-on-signed-url="redirectUrl"
                              :signee-name="signComponentSigneeName"
                              :signature-button-label="signComponentSignatureButtonLabel"
                            />
                        </div>

                        <hr>

                        <powered-by-salesbyte></powered-by-salesbyte>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card {
    cursor: pointer;
}
</style>

<script>
export default {
    props: {
        appUrl: String,
        quoteKey: String,
        documents: Array,
        signComponentName: { type: String, required: true },
        signComponentSigneeName: { type: String, default: null },
        signComponentSignatureButtonLabel: { type: String, required: true },
        signComponentItemDescription: { type: String, default: null },
        disablePdfViewer: Boolean,
        initiallySelected: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            userSelected: undefined
        };
    },
    computed: {
        redirectUrl() {
            return `/signed/${this.quoteKey}/documents/${this.documents[this.selected].id}`;
        },
        itemId() {
            return `quote-documents/${this.quoteKey}/${this.documents[this.selected].id}`;
        },
        selected() {
            return this.userSelected !== undefined ? this.userSelected : this.initiallySelected;
        }
    },
    mounted() {
        const thisModal = window.jQuery(this.$refs["modal"]);
        thisModal.on('shown.bs.modal', () => this.$actionLog.logQuoteDocumentSignatureViewOpen(this.quoteKey));
    },
    methods: {
        dismissAndOpenDocument(doc_id) {
            const thisModal = window.jQuery(this.$refs["modal"]);
            const newModal = window.jQuery(`#documents-modal-${doc_id}`)
            thisModal.one("hidden.bs.modal", () => newModal.modal('show'));
            thisModal.modal('hide');
        }
    }
}
</script>
