<template>
  <form @submit.prevent="submit">
    <textarea class="form-control w-100" required v-model="message"></textarea>
      <div class="text-center">
          <small class="text-muted" v-if="chatAvailableForCustomer">{{chatResponseTimeText}}</small>
      </div>
    <button type="submit" class="d-block btn signbuttoninline mt-3 mx-auto" :disabled="!message || !chatAvailableForCustomer">
      <div v-if="submitting" class="spinner-border spinner-border-sm mr-2"></div>
        <template>
            <i v-if="chatAvailableForCustomer" class="fa-regular fa-paper-plane"></i>
            <template v-else>Chatten är stängd</template>
        </template>
    </button>
  </form>
</template>

<style lang="scss" scoped>
</style>

<script>
import axios from 'axios';

export default {
  props: {
    quoteKey: String,
    isCustomer: { type: Boolean, default: false },
    isTemplate: { type: Boolean, default: false },
      chatAvailableForCustomer: { type: Boolean, default: true},
      chatResponseTimeText: { type: String}
  },
  data() {
    return {
      submitting: false,
      message: null
    }
  },
  methods: {
    async submit() {
      this.submitting = true;

      try {
        await axios.post(route('quote.chatMessages.store', {quoteKey: this.quoteKey, is_template: this.isTemplate }), {
          is_customer_message: this.isCustomer,
          message: this.message
        });

        this.message = null;
        this.$emit('submitted');
      } finally {
        this.submitting = false;
      }
    }
  }
}
</script>
