<template>
  <Flicking ref="flicking" :options="carouselOptions" :plugins="carouselPlugins">
    <div
      class="panel pt-5 pb-4"
      v-for="(panel, panelIdx) in panels"
      :key="panelIdx"
      @click="onPanelClick($event, panelIdx, panel)"
    >
      <div class="panel-content pb-2 text-center">
        <slot name="panel" :idx="panelIdx" :panel="panel"></slot>
      </div>
    </div>

    <template v-if="panels.length > 1">
      <div slot="viewport" class="flicking-pagination"></div>
      <span slot="viewport" class="flicking-arrow-prev is-circle"></span>
      <span slot="viewport" class="flicking-arrow-next is-circle"></span>
    </template>
  </Flicking>
</template>

<style lang="scss" scoped>
.panel {
  width: 75%;
  @media (min-width: 798px) {
    width: 40%;
  }
}

.panel-content {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}

.flicking-pagination {
  top: 10px;
  bottom: unset;
}

.flicking-arrow-prev, .flicking-arrow-next {
  zoom: 68%;
  @media (min-width: 798px) {
    zoom: unset;
  }
}
</style>

<script>
import { EVENTS } from "@egjs/flicking";
import { Fade, Arrow, Pagination } from "@egjs/flicking-plugins";
import Tuck from './_plugins/tuck.js';

export default {
  components: {
  },
  props: {
    panels: {
      type: Array,
      default: () => []
    },
    capturePanelClicks: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      carouselOptions: {
        camera: '0%',
        panel: '75%',
        moveType: 'strict',
        resizeOnContentsReady: true
      },
      carouselPlugins: [
        new Fade("", 0.7),
        new Tuck({ tuck: 200, scale: 0.5, perspective: 100 })
      ].concat(this.panels.length > 1 ? [
        new Arrow(),
        new Pagination({ type: 'bullet' })
      ] : [])
    }
  },
  methods: {
    async onPanelClick(event, idx, panel) {
      if (this.$refs.flicking.index == idx) {
        if (this.capturePanelClicks) {
          event.preventDefault();
          this.$emit('panelClick', { idx, panel });
        }
      } else {
        event.preventDefault();
        this.$refs.flicking.interruptable = false;
        this.$refs.flicking.once(EVENTS.MOVE_END, () => {
          this.$refs.flicking.interruptable = true;
        });
        await this.$refs.flicking.moveTo(idx);
      }
    }
  }
}
</script>
