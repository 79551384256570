<template>
  <fieldset>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Länk</div>
      </div>
      <input class="form-control" required readonly :value="route('integrations.disableAutomations')">
    </div>

    <div class="mb-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">Maila säljare om flera offerter matchar</div>
        </div>
        <select class="custom-select" v-model="emailSellersOnMultiDisable" @change="onUpdate">
          <option :value="false">Nej</option>
          <option :value="true">Ja</option>
        </select>
      </div>
    </div>

    <p>Använd följande dataformat i JSON:</p>
    <pre><code>
      {
        uuid: "{{uuid}}", // Must always be this specific value
        customer_email: String|null,
        customer_phone: String|null, // +46NNNNNNNNN
      }
    </code></pre>
  </fieldset>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      uuid: null,
      emailSellersOnMultiDisable: null,
    }
  },
  computed: {
    serializedValue() {
      return {
        uuid: this.uuid,
        email_sellers_on_multi_disable: this.emailSellersOnMultiDisable
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.uuid = this.value.uuid;
          this.emailSellersOnMultiDisable = this.value.email_sellers_on_multi_disable;
        }

        if (!this.uuid) {
          this.uuid = uuidv4();
        }

        if (this.emailSellersOnMultiDisable !== true && this.emailSellersOnMultiDisable !== false) {
          this.emailSellersOnMultiDisable = false;
        }

        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.onUpdate();
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
