<template>
  <div>
    <input :ref="$attrs.name" type="tel" class="form-control" @focusin="clearErrors" v-bind="$attrs">
    <small class="text-danger" v-if="invalidPhoneNumber">Telefonnumret är ogiltigt</small>
  </div>
</template>

<style lang="css">

</style>

<script>
import 'intl-tel-input/build/css/intlTelInput.css'

export default {
  name: "phone-input",
  inheritAttrs: false,
  props: {
    field: { type: String, required:false},
    value: String,
    countryCode: String,
    seOnly: { type: Boolean, required:false, default: true },
    initialCountry: { type: String, required:false, default: 'SE'},
  },
  data() {
    return {
      telInput: null,
      invalidPhoneNumber: false,
    }
  },
  mounted(){
    const input = document.querySelector(`input[name="${this.$attrs.name}"]`);
    this.telInput = window.IntlTelInput(input,
      {
        initialCountry: this.initialCountry,
        separateDialCode: !this.seOnly,
        allowDropdown: !this.seOnly,
        hiddenInput: (telInputName) => {
          return {
            phone: telInputName,
            country: this.countryCode,
          };
        }
      }
    );

    if (this.value){
      this.telInput.setNumber(this.value);
    }
  },
  methods: {
    clearErrors: function (e){
      this.invalidPhoneNumber = false;
    }
  }
}
</script>
