<template>
  <fieldset>
    <div class="input-group mb-1">
      <div class="input-group-prepend">
        <div class="input-group-text">Personal Access Token</div>
      </div>
      <input type="text" class="form-control" required v-model="personalAccessToken" @input="onUpdate">
    </div>
    <p class="small mb-3">
      För att hämta er din Personal Access Token, se  <a href="https://developer.calendly.com/how-to-authenticate-with-personal-access-tokens">instruktioner från Calendly</a>
    </p>
  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      personalAccessToken: null
    }
  },
  computed: {
    serializedValue() {
      return {
        personal_access_token: this.personalAccessToken,
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.personalAccessToken = this.value.personal_access_token;
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
