<template>
  <div
    class="modal fade"
    id="callback"
    tabindex="-1"
    role="dialog"
    aria-labelledby="callbackLabel"
    aria-hidden="true"
    style="padding-right: 0px !important"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5>
            <img :src="sellerAvatar" class="contactavatar" /> {{ sellerName }}
          </h5>

          <button
            type="button"
            class="btn btn-secondary float-right"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">X</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row g-5">
            <div class="col-12">
              <h5>{{ textBlocks.contact_cta }}</h5>
            </div>

            <div class="col-sm-4 text-center mt-3" style="padding-left:5px;padding-right:5px">
              <a href="#" class="callbackbtn" @click.prevent="requestCallback">
                <h5>
                  <i class="far mb-2" :class="callbackIconClass"></i><br>
                  <small>Ring upp mig</small>
                  <br />
                </h5>
              </a>
            </div>

            <div class="col-sm-4 text-center mt-3" style="padding-left:5px;padding-right:5px">
              <a
                v-if="sellerEmail && sellerEmail.length > 3"
                :href="`mailto:${sellerEmail}`"
                class="callbackbtn"
              >
                <h5>
                  <i class="far fa-envelope mb-2"></i><br>
                  <small>Email</small>
                  <br />
                </h5>
              </a>
            </div>

            <div class="col-sm-4 text-center mt-3" style="padding-left:5px;padding-right:5px">
              <a
                v-if="sellerPhone && sellerPhone.length > 3"
                :href="`tel:${sellerPhone}`"
                class="callbackbtn"
              >
                <h5>
                  <i class="far fa-phone mb-2"></i><br>
                  <small>{{ sellerPhone }}</small>
                  <br />
                </h5>
              </a>
            </div>
          </div>

          <div v-if="validityPassed" class="col-sm-12 col-12 text-center mt-3" style="padding-left:5px;padding-right:5px">
            <a
              type="button"
              @click="requestQuoteRenewal"
              class="callbackbtn"
            >
              <h5 class="m-2">
                <i class="fas fa-rotate-right mb-2"></i><br>
                <small>Förnya mitt erbjudande</small>
                <br />
              </h5>
            </a>
          </div>
        </div>
          <p v-if="callbackRequested" class="mt-3 mb-0">
            Din förfrågan har tagits emot. Vi kontaktar dig så snart som möjligt!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.contactavatar {
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.callbackbtn h5 {
  background: #6c757d;
  border-radius: 7px;
  width: 100%;
  margin-left: 0%;
  padding: 20px 0px 20px 0px;
  color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.callbackbtn:hover {
  text-decoration: none;
}
.callbackbtn:focus {
  outline: none;
}
</style>

<script>
import axios from 'axios';

export default {
  props: [
    "quoteKey",
    "customerName",
    "sellerName",
    "sellerAvatar",
    "sellerPhone",
    "sellerEmail",
    "textBlocks",
    "validityPassed"
  ],
  data() {
    return {
      callbackRequesting: false,
      callbackRequested: false,
      requestingRenewal: false
    };
  },
  computed: {
    callbackIconClass() {
      if (this.callbackRequested) {
        return 'fa-check';
      }

      if (this.callbackRequesting) {
        return 'fa-spinner fa-pulse';
      }

      return 'fa-undo';
    }
  },
  methods: {
    async requestCallback() {
      if (this.callbackRequesting) { return; }
      this.callbackRequesting = true;

      try {
        await axios.post(route('quotes.requestCallback', this.quoteKey));
        this.callbackRequested = true;
      } catch(error) {
        this.callbackRequesting = false;
        alert('Din callback-förfrågan kunde ej hanteras, vad god försök igen senare.')
        console.warn(error);
      }
    },
    async requestQuoteRenewal() {
      if (this.requestingRenewal) { return; }
      this.requestingRenewal= true;

      try {
        await axios.post(route('quotes.requestRenewal', this.quoteKey));
        this.requestingRenewal = true;
        this.$swal({
          text: "Begäran om förnyelse har mottagits",
          icon: "success",
          iconColor: window.getComputedStyle(document.body).getPropertyValue('--primary-color'),
          confirmButtonColor: window.getComputedStyle(document.body).getPropertyValue('--primary-color'),
        });
      } catch(error) {
        this.requestingRenewal = false;
        this.$swal({
          icon: "error",
          text: "Din begäran om förnyelse kunde inte hanteras, försök igen senare.",
          iconColor: window.getComputedStyle(document.body).getPropertyValue('--primary-color'),
          confirmButtonColor: window.getComputedStyle(document.body).getPropertyValue('--primary-color'),
        });
        console.warn(error);
      }
    }
  }
}
</script>
