<template>
    <div class="mt-4">
        <template v-if="submitted">
            <div v-if="checkoutSession.type === 'woocommerce-link'">
                Du skickas automatiskt vidare. Om inget händer,
                <a :href="checkoutSession.url">klicka här för att gå vidare</a>.
            </div>

            <div v-if="checkoutSession.type === 'dintero'">
                Du skickas automatiskt vidare till Dintero. Om inget händer,
                <a :href="checkoutSession.url">klicka här för att gå vidare</a>.
            </div>

            <div
                v-if="checkoutSession.type === 'briqpay'"
                id="briqpay"
                class="briqpay-container"
            >
                <iframe
                    id="briqpay-iframe"
                    title="Checkout"
                    scrolling="no"
                    :src="`https://msc.briqpay.com?sessionToken=${checkoutSession.token}`"
                ></iframe>
            </div>

            <div
                v-if="checkoutSession.type === 'svea-checkout'"
                ref="svea-checkout"
                class="svea-container"
            >
                <div
                    id="svea-checkout-spinner"
                    v-if="!sveaCheckoutShow"
                    class="d-flex justify-content-center align-items-center"
                    style="min-height: inherit"
                >
                    <i
                        class="fa-solid fa-circle-notch fa-spin fa-3x"
                        style="color: var(--primary-color)"
                    ></i>
                </div>
                <div
                    ref="svea-checkout-container"
                    v-show="sveaCheckoutShow"
                    style="min-height: inherit"
                ></div>
            </div>
            <div v-if="checkoutSession.type === 'zaver'">
                Du skickas automatiskt vidare till Zaver. Om inget händer,
                <a :href="checkoutSession.url">klicka här för att gå vidare</a>.
            </div>
        </template>
        <form v-else @submit.prevent="submit">
            <div v-if="isLocalhost" class="my-5">
                <h6>DEBUG</h6>
                <p>Item ID: {{ productId }}</p>
            </div>

            <p v-if="itemDescription" class="my-3">
                <small
                    class="text-muted font-italic"
                    v-html="itemDescriptionWithBr"
                ></small>
            </p>

            <template v-if="!signeeNameIsValidated && hasData">
                <label :for="`${uuid}-name`">{{
                    $t("quotePayment.product.name_label")
                }}</label>
                <input
                    :id="`${uuid}-name`"
                    type="text"
                    :disabled="submitting"
                    class="form-control mb-4"
                    required
                    v-model="name"
                    placeholder="John Doe"
                />
            </template>

            <button
                v-if="!submitting"
                type="submit"
                class="signatureComponentSignButton btn btn-secondary mb-3"
                :disabled="!hasData"
            >
                <i class="fas fa-paper-plane mr-1"></i>
                {{ $t("quotePayment.product.go_to_payment_button") }}
            </button>
            <button
                v-else
                type="button"
                class="signatureComponentSignButton btn btn-primary mt-3 wait"
            >
                <i class="fas fa-paper-plane mr-2"></i>
                <div class="spinner-border spinner-border-sm"></div>
            </button>
        </form>
        <img
            v-if="dinteroBrandingImageUrl"
            class="d-block w-75 h-auto mx-auto my-4"
            :src="dinteroBrandingImageUrl"
        />
    </div>
</template>

<style lang="scss" scoped>
button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 33%;

    &.wait .spinner-border {
        vertical-align: middle;
    }
}

div.briqpay-container,
div.svea-container {
    min-height: 400px;

    iframe {
        width: 100%;
        min-height: 400px;
        border: none;
    }
}
</style>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        quoteKey: { type: String, required: true },
        quoteType: { type: String, required: true },
        productId: { type: Number, required: true },
        paymentMethod: { type: String, required: true },
        dinteroProfileId: { type: String, default: null },
        signeeNameIsValidated: { type: Boolean, default: false },
        signeeName: { type: String, default: null },
        itemDescription: { type: String, default: null },
    },
    data() {
        return {
            uuid: uuidv4(),
            name: this.signeeName,
            submitting: false,
            submitted: false,
            checkoutSession: {},
            sveaCheckoutLoadingInterval: null,
            sveaCheckoutShow: false,
        };
    },
    computed: {
        isLocalhost() {
            return document.location.hostname == "localhost";
        },
        dinteroBrandingImageUrl() {
            if (this.paymentMethod === "dintero" && this.dinteroProfileId) {
                return `https://backoffice.dintero.com/checkouts/v1/branding/profiles/${this.dinteroProfileId}/variant/colors/width/420/dintero_top_frame.svg`;
            } else {
                return null;
            }
        },
        itemDescriptionWithBr() {
            return this.itemDescription
                ? this.itemDescription.replaceAll("\n", "<br>")
                : null;
        },
        hasData() {
            return !!this.signeeName;
        },
        url() {
            if (this.quoteType === "options") {
                return route("quote.options.payments.store", [
                    this.quoteKey,
                    this.productId,
                ]);
            }

            return route("quote.products.payments.store", [
                this.quoteKey,
                this.productId,
            ]);
        },
    },
    async mounted() {
        await this.resetState();
    },
    watch: {
        async productId() {
            if (!this.submitting) {
                await this.resetState();
            }
        },
        checkoutSession(value) {
            if (value && value.type === "svea-checkout") {
                const fragment = document
                    .createRange()
                    .createContextualFragment(value.gui_snippet);
                if (this.sveaCheckoutLoadingInterval) {
                    return;
                }

                this.sveaCheckoutLoadingInterval = setInterval(() => {
                    if (!this.$refs["svea-checkout"]) {
                        return;
                    }

                    this.$refs["svea-checkout-container"].innerHTML = "";
                    this.$refs["svea-checkout-container"].appendChild(fragment);
                    clearInterval(this.sveaCheckoutLoadingInterval);
                }, 100);

                document.addEventListener("checkoutReady", () => {
                    this.sveaCheckoutShow = true;
                });
            }
        },
    },
    methods: {
        async resetState() {
            this.submitted = false;
            this.submitting = false;
            this.checkoutSession = {};
            this.sveaCheckoutLoadingInterval = null;
            this.sveaCheckoutShow = false;

            if (this.signeeNameIsValidated && this.hasData) {
                await this.submit();
            }
        },
        submit: async function () {
            try {
                const productId = this.productId;

                this.submitting = true;

                const response = await axios.post(this.url, {
                    signature_item_description: this.itemDescription,
                    signature_name: this.name,
                    payment_method: this.paymentMethod,
                });

                if (productId !== this.productId) {
                    await this.resetState();
                    return;
                }

                this.submitted = true;
                this.checkoutSession = response.data.checkout_session;
                switch (this.checkoutSession.type) {
                    case "dintero":
                    case "zaver":
                        window.location.replace(this.checkoutSession.url);
                        break;
                    case "woocommerce-link":
                        window.location.replace(this.checkoutSession.url);
                        break;

                    case "briqpay":
                        // noop
                        break;

                    case "svea-checkout":
                        // noop
                        break;

                    default:
                        throw `Unknown checkout session type ${this.checkoutSession.type}`;
                        break;
                }
            } catch (error) {
                console.warn("Failed to create payment request..", error);
            } finally {
                this.submitting = false;
            }
        },
    },
};
</script>
