<template>
  <div ref="container">
    <div class="d-none" ref="placeholder"></div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  props: {
    eventId: { type: String, required: true },
    color: { type: String, required: true },
  },
  data() {
    return {
      setupInterval: null,
      instance: null
    };
  },
  mounted() {
    this.setupInterval = setInterval(this.setup, 100);
  },
  unmounted() {
    this.clearSetupInterval();
    if (this.instance) { this.instance.destroy(); }
  },
  methods: {
    clearSetupInterval() {
      clearInterval(this.setupInterval);
      this.setupInterval = null;
    },
    setup() {
      const manager = window.$billetto.manager;
      if (!manager) { return; }

      this.clearSetupInterval();

      this.instance = manager.register(
        this.$refs["container"],
        "event",
        {
          identifier: this.eventId,
          color: this.color,
          whitelabel: true,
          locale: "sv",
          theme: "light",
          organization: "billetto.se"
        }
      );
      this.instance.mount(this.$refs["placeholder"]);
    }
  },
}
</script>
