<template>
    <div v-if="isPdf">
        <div class="page-container" v-for="pageNum in pageCount" :key="pageNum">
            <canvas :ref="`pageNum-${pageNum}`"></canvas>
        </div>
    </div>
    <div v-else>
        <img :src="pdflink" class="w-100">
    </div>
</template>

<style lang="scss" scoped>
.page-container {
    background-color: none;
    text-align: center;
    padding: 10px;
}

.page-container canvas, img {
    border: 1px solid lightgray;
    direction: ltr;
    width: 100%;
}

</style>

<script>
export default {
    props: {
        pdflink: String,
        isPdf: Boolean
    },
    data() {
        return {
            pdfDoc: null,
            scale: 1.5
        };
    },
    computed: {
        pageCount() {
            return this.pdfDoc ? this.pdfDoc.numPages : 0;
        }
    },
    async mounted() {
        if (!this.isPdf) { return; }

        await this.loadPdf();
    },
    watch: {
        pdflink: async function() {
            if (!this.isPdf) { return; }

            await this.loadPdf();
        },
        pdfDoc: function() {
            if (!this.isPdf) { return; }

            this.$nextTick(async () => {
                for (let pageNum = 1; pageNum <= this.pdfDoc.numPages; pageNum += 1) {
                    const canvas = this.$refs[`pageNum-${pageNum}`][0];
                    await this.renderPage(this.pdfDoc, pageNum, canvas);
                }
            })
        }
    },
    methods: {
        loadPdf: async function() {
          this.pdfDoc = await pdfjs.getDocument(this.pdflink).promise;
        },
        renderPage: async function(pdfDoc, pageNum, canvas) {
            const page = await pdfDoc.getPage(pageNum)
            const viewport = page.getViewport({
                scale: this.scale
            });

            canvas.width = viewport.width;
            canvas.height = viewport.height;

            await page.render({canvasContext: canvas.getContext('2d'), viewport: viewport}).promise;
        }
    }
}
</script>
