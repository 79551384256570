export default function (eventTarget, quoteKey, value, currency, callback) {
    const eventData = {
        'send_to': eventTarget,
        'transaction_id': 'SalesByte/' + quoteKey,
        'event_callback': callback
    };

    if (value !== undefined && currency == 'sek_kr') {
        eventData.value = value;
        eventData.currency = 'SEK';
    } else if (value !== undefined && currency == 'sek_ore') {
        eventData.value = value / 100.0;
        eventData.currency = 'SEK';
    }

    window.gtag('event', 'conversion', eventData);
};
