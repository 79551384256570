<template>
  <div
    :class="['file-upload', cssClass]"
    @dragenter.prevent="droppingFile = true"
    @drop.prevent="handleFiles($event.dataTransfer.files)"
    @dragleave.prevent="droppingFile = false"
    @click="handleClick($event)"
  >
    <i v-if="!uploadingFile" class="file-upload-icon fa-regular fa-upload"></i>
    <i v-if="uploadingFile" class="file-upload-icon fa-regular fa-spinner fa-spin"></i>
    <p v-if="!droppedIncorrectFileTypes" class="text-center">
      Släpp alla filer här för att lägga dem i mappen.<br>
      <small>(Eller klicka för att välja enskild fil)</small>
    </p>
    <p v-if="droppedIncorrectFileTypes" class="text-center">Filerna hade fel format, vi stödjer:<br> PDF, JPG, och PNG.</p>
    <input
      type="file"
      ref="inputfile"
      accept="application/pdf, *.pdf, image/png, image/jpeg, *.png, *.jpg, *.jpeg"
      multiple
      style="display: none;"
      @change.prevent="handleInputChange($event)"
    >
  </div>
</template>

<script>
export default {
  props: {
    quoteIndex: { type: Number, required: true },
    livewireInstanceLoader: { type: Function, required: true }
  },
  data() {
    return {
      uploadingFile: false,
      droppingFile: false,
      droppedIncorrectFileTypes: false,
    };
  },
  computed: {
    cssClass() {
      if (this.uploadingFile) {
        return 'file-upload-active';
      } else if (this.droppingFile) {
        return 'file-upload-hover';
      } else if (this.droppedIncorrectFileTypes) {
        return 'file-upload-invalid';
      } else {
        return 'file-upload-passive';
      }
    }
  },
  methods: {
    filterFiles(files) {
      const types = ["application/pdf", "image/png", "image/jpeg"];
      const extensions = [".pdf", ".png", ".jpg", ".jpeg"];
      return Array.from(files).filter((f) => {
        return types.indexOf(f.type) !== -1 || extensions.filter((ext) => f.name.endsWith(ext)).length > 0;
      });
    },
    handleFiles(filesFromEvent) {
      if (this.uploadingFile) { return; }

      const files = this.filterFiles(filesFromEvent);
      if (files.length == 0) {
        this.droppedIncorrectFileTypes = (filesFromEvent.length > 0);
        return;
      }

      this.uploadingFile = true;

      this.livewireInstanceLoader().uploadMultiple(`droppedFiles.${this.quoteIndex}`, files, () => {
        this.uploadingFile = false;
      });
    },
    handleClick(event) {
      if (event.target !== this.$refs.inputfile) {
        event.preventDefault();
        this.$refs.inputfile.click();
      }
    },
    handleInputChange(event) {
      this.handleFiles(event.target.files);
      event.target.value = null;
    }
  }
};
</script>
