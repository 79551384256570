<template>
    <form @submit.prevent="sign">
        <label>Personnummer (12 siffror utan bindestreck)</label>
        <input type="number" placeholder="12 siffror" class="form-control" required pattern="\d{12}" v-model="pno">

        <div v-if="isLocalhost" class="my-5">
            <h6>DEBUG</h6>
            <p>Item ID: {{itemId}}</p>
            <p>Item Description: {{itemDescription}}</p>
        </div>

        <button v-if="!authenticating" type="submit" class="signatureComponentSignButton btn btn-secondary mt-3">
            {{ signatureButtonLabel }}
        </button>
        <button v-else type="button" class="signatureComponentSignButton btn btn-primary mt-3 wait">
            <div class="spinner-border spinner-border-sm mr-2"></div> Öppna Bank-ID på din telefon
        </button>
    </form>
</template>

<style lang="scss" scoped>
button {
    width: 100%;

    &.wait .spinner-border {
        vertical-align: middle;
    }
}
</style>

<script>
import axios from 'axios';

export default {
    // Props should be in sync with onScreenSign.vue
    props: {
        itemId: String,
        itemDescription: String,
        appUrl: String,
        redirectOnSignedUrl: String,
        signeeName: { type: String, default: null },
        signatureButtonLabel: String,
    },
    data() {
        return {
            pno: null,
            authenticating: false,
            verifyTimer: null
        };
    },
    beforeUnmounted() {
        if (this.verifyTimer) {
            clearInterval(this.verifyTimer);
        }
    },
    computed: {
        isLocalhost() {
            return document.location.hostname == "localhost";
        }
    },
    methods: {
        sign: async function() {
            const requestHeaders = new Headers();
            requestHeaders.append("Content-Type", "application/json");

            const requestBody = JSON.stringify({
                "itemId": this.itemId,
                "itemDescription": this.itemDescription,
                "pno": this.pno,
                "ipAddress": "213.136.48.244", // TODO: Why is this hardcoded?
                "callbackUrl": `${this.appUrl}/auth/verify`
            });

            const requestUrl = "https://liveapi03.idkollen.se/api/seal-service/8d846efd-bb84-11ea-9758-067809246251/sign-item";
            const requestOptions = {
                method: 'POST',
                headers: requestHeaders,
                body: requestBody,
                redirect: 'follow'
            };

            try {
                this.authenticating = true;
                const response = await fetch(requestUrl, requestOptions)

                if (response.status >= 200 && response.status < 300) {
                    this.verifyTimer = setInterval(() => this.verifySignature(), 3000);
                } else {
                    throw response;
                }
            } catch (error) {
                this.authenticating = false;
                console.log('error', error);
            }
        },
        verifySignature: async function() {
            const response = await axios.get("/auth/control/" + this.pno);
            if(response.status === 200) {
                if (this.redirectOnSignedUrl) {
                    window.location.replace(this.redirectOnSignedUrl);
                } else {
                    this.$emit('signed');
                }
            }
        }
    }

}
</script>
