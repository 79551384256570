<template>
    <div class="mt-5 mb-3">
        <form v-if="!authenticating" @submit.prevent="submitRequest">
            <div class="form-group">
                <label for="bank">Välj din bank*</label>
                <b-form-select
                    id="bank"
                    v-model="bank"
                    :options="billectaBanks"
                    value-field="code"
                    text-field="name"
                    required
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled
                            >Välj bank</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </div>

            <div class="form-group">
                <label for="ssn">Personnummer (YYYYMMDD-NNNN)*</label>
                <input
                    class="form-control"
                    id="ssn"
                    type="text"
                    v-model="ssn"
                    @blur.prevent="validateSSNInput"
                    minlength="13"
                    maxlength="13"
                    required
                />
                <div v-if="errorMessage" class="text-danger pt-2">
                    {{ errorMessage }}
                </div>
            </div>

            <div class="form-group text-center">
                <button
                    type="submit"
                    class="signatureComponentSignButton btn btn-secondary mt-3"
                >
                    Visa konton
                </button>
            </div>
        </form>

        <button
            id="spinner"
            v-else-if="!requestId"
            type="button"
            class="signatureComponentSignButton btn btn-primary mt-3 wait"
        >
            <div class="spinner-border spinner-border-sm mr-2"></div>
        </button>

        <template v-else>
            <div v-if="!isDone" class="text-center">
                <template v-if="!autoStartUrl && !currentQrToken">
                    <button
                        id="spinner"
                        type="button"
                        class="signatureComponentSignButton btn btn-primary mt-3 wait"
                    >
                        <div
                            class="spinner-border spinner-border-sm mr-2"
                        ></div>
                    </button>
                </template>

                <div v-if="autoStartUrl" class="my-3">
                    <a
                        :href="autoStartUrl"
                        target="_blank"
                        class="signatureComponentSignButton btn btn-primary w-full mb-3"
                    >
                        Öppna BankID på denna enhet
                    </a>

                    <div>
                        <small> Legitimera med BankID </small>
                    </div>
                </div>

                <template v-if="currentQrToken">
                    <p v-if="qrVisible" class="mt-4">
                        Använd BankID-appen för att scanna bilden nedan:<br />
                        <img
                            :src="currentQrToken"
                            class="img-fluid my-5"
                            style="width: 175px"
                        />
                    </p>
                    <div v-else class="font-italic">
                        <button
                            @click.prevent="qrVisible = true"
                            v-if="!autoStartUrl"
                            class="signatureComponentSignButton btn btn-primary my-3 w-full"
                        >
                            Öppna BankID på en annan enhet
                        </button>
                        <small v-else
                            >Du kan även använda
                            <a
                                href="#"
                                class="text-color-profile-color"
                                @click.prevent="qrVisible = true"
                                >BankID på en annan enhet</a
                            ></small
                        >

                        <div>
                            <small> Legitimera med BankID </small>
                        </div>
                    </div>
                </template>
            </div>

            <div v-else-if="accounts.length > 0" class="list-group">
                <h5>Välj vilket konto du vill använda:</h5>
                <div class="form-group mt-3">
                    <b-form-select
                        id="bank"
                        v-model="selectedAccountIndex"
                        :options="displayAccounts"
                        required
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled
                                >Välj bankkonto</b-form-select-option
                            >
                        </template>
                    </b-form-select>
                </div>
                <div class="form-group text-center">
                    <button
                        class="signatureComponentSignButton btn btn-secondary my-3"
                        type="button"
                        @click.prevent="handlePrefill"
                        :disabled="selectedAccountIndex === null"
                    >
                        Använd detta konto
                    </button>
                </div>
                <div class="text-center mt-3">
                    <a type="button" @click.prevent="resetState"
                        ><i class="fal fa-rotate-right"></i> Starta om</a
                    >
                </div>
            </div>

            <div
                v-else
                class="text-center d-flex flex-column justify-content-center"
            >
                <template>
                    <h6 v-if="failed">Ett fel har tyvärr uppstått!</h6>
                    <h6 v-else>Vi hittar tyvärr inget konto.</h6>
                </template>
                <a href="#" @click.prevent="switchToManual">Fyll i manuellt!</a>
                <a class="mt-3" type="button" @click.prevent="resetState"
                    ><i class="fal fa-rotate-right"></i> Starta om</a
                >
            </div>
        </template>
    </div>
</template>

<style scoped lang="scss">
#spinner {
    width: 100%;

    &.wait .spinner-border {
        vertical-align: middle;
    }
}
</style>

<script>
import axios from "axios";
import { DateTime, Settings } from "luxon";
import { v4 as uuid } from "uuid";

export default {
    name: "billecta-accounts-retrieval",
    props: {
        quoteKey: String,
    },
    data() {
        return this.initialState();
    },
    beforeDestroy() {
        this.clearIntervals();
    },
    async mounted() {
        this.billectaBanks.push(...(await this.getBillectaBanks()));
    },
    computed: {
        autoStartUrl() {
            if (!this.autoStartToken) {
                return null;
            }

            return `bankid:///?autostarttoken=${this.autoStartToken}&redirect=null`;
        },
        displayAccounts() {
            return this.accounts.map((account, index) => {
                return {
                    value: index,
                    text: `Namn: ${account.name}, Kontonummer: ${account.number}`,
                };
            });
        },
    },
    methods: {
        async getBillectaBanks() {
            const URL = route("quotes.billecta.accountsRetrieval.getBanks", {
                key: this.quoteKey,
            });
            const response = await axios.get(URL);
            return response.data;
        },
        initialState() {
            return {
                billectaBanks: [],
                bank: null,
                ssn: "",
                authenticating: false,
                errorMessage: null,
                requestId: null,
                accounts: [],
                selectedAccountIndex: null,

                autoStartToken: null,
                qrVisible: false,
                currentQrToken: null,

                failed: false,

                isDone: false,
                intervals: [],
            };
        },
        async resetState() {
            this.clearIntervals();
            const banks = this.billectaBanks;
            Object.assign(this.$data, this.initialState());
            this.billectaBanks = banks;
        },
        switchToManual() {
            this.$emit("switchToManual");
        },
        validateSSNInput(e) {
            let cleanedInput = this.ssn.replace(/[^0-9-]/g, "");
            if (cleanedInput.length < 10 || cleanedInput.length > 13) {
                this.ssn = cleanedInput;
                return;
            }

            // Split the date part and the numeric part
            let parts = cleanedInput.split("-");
            let datePart, numPart;
            if (parts.length > 1) {
                datePart = parts[0];
                numPart = parts[1];
            } else {
                const datePartLength = cleanedInput.length === 10 ? 6 : 8;
                datePart = cleanedInput.slice(0, datePartLength);
                numPart = cleanedInput.slice(datePartLength);
            }
            // Handle date part (assuming it's always in YYMMDD format)
            if (datePart.length >= 6) {
                Settings.twoDigitCutoffYear =
                    new Date().getFullYear() - 15 - 2000;
                let yearFormat = datePart.length >= 8 ? "yyyy" : "yy";
                const dateObj = DateTime.fromFormat(
                    datePart,
                    `${yearFormat}MMdd`
                );
                if (dateObj.isValid) {
                    datePart = dateObj.toFormat("yyyyMMdd");
                } else {
                    console.log("Invalid date format.");
                }
            }

            // Combine the parts into the final formatted string
            this.ssn = `${datePart}-${numPart}`;
        },
        async submitRequest() {
            if (this.authenticating) {
                return;
            }

            const regexPattern =
                /^(19[0-9]{2}|20[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
            // Test the input against the regex pattern
            if (!regexPattern.test(this.ssn)) {
                console.log("Invalid format");
                this.errorMessage =
                    "Ogiltig inmatning. Kontrollera och skicka igen";
                return false;
            }

            this.authenticating = true;
            this.errorMessage = null;

            const payload = {
                bank: this.bank,
                ssn: this.ssn,
            };

            try {
                const URL = route("quotes.billecta.accountsRetrieval.request", {
                    key: this.quoteKey,
                });
                const response = await axios.post(URL, payload);
                this.requestId = response.data["PublicId"];
                this.intervals.push(
                    setInterval(this.pollRetrievalRequestStatus, 1000)
                );
            } catch (error) {
                this.errorMessage =
                    "Ogiltig inmatning. Kontrollera och skicka igen";
                console.warn(error);
                this.authenticating = false;
            }
        },
        handlePrefill: async function () {
            if (this.selectedAccountIndex === null) {
                console.log("Account not selected!");
                return;
            }

            let account = this.accounts[this.selectedAccountIndex];
            account.bank = this.bank;
            this.$emit("success", account);
        },
        clearIntervals() {
            this.intervals.forEach(clearInterval);
        },
        pollRetrievalRequestStatus: async function () {
            if (this.isDone) {
                return;
            }

            try {
                const URL = route(
                    "quotes.billecta.accountsRetrieval.getRequest",
                    { key: this.quoteKey, retrievalRequestId: this.requestId }
                );

                const response = await axios.get(URL);
                switch (response.data["Status"]) {
                    case "Waiting":
                        this.currentQrToken = response.data["QR"];
                        this.autoStartToken =
                            response.data["BankIdAutostartToken"];
                        break;
                    case "Success":
                        this.accounts = response.data["AccountNumbers"].map(
                            (account) => {
                                return {
                                    name: account.Name,
                                    number: account.AccountNo,
                                    clearing: account.ClearingNo,
                                };
                            }
                        );
                        this.isDone = true;
                        this.clearIntervals();
                        break;
                    default:
                        this.failed = true;
                        this.isDone = true;
                        this.$emit("failed");
                        this.clearIntervals();
                        break;
                }
            } catch (err) {
                if (!err.response || err.response.status !== 404) {
                    throw err;
                }
            }
        },
    },
};
</script>
