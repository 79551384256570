<template>
    <div class="card">
        <div class="card-header d-flex justify-content-end">
            <b-button v-b-toggle.settings variant="secondary" size="sm"
                ><i class="fal fa-sliders"></i> Preferenser</b-button
            >
        </div>

        <div class="card-body">
            <b-collapse id="settings">
                <b-form-group label="Föredragna fält:" class="mb-3">
                    <b-form-checkbox-group
                        :options="preferredFieldOptions"
                        v-model="preferredFields"
                        value-field="key"
                        text-field="label"
                        class="mr-2"
                    >
                    </b-form-checkbox-group>
                </b-form-group>

                <div class="mb-3">
                    <div>Snabbfilter:</div>
                    <div
                        class="col d-flex justify-content-start flex-wrap d-flex-gap"
                    >
                        <a role="button" @click.prevent="filter.group = 'all'">
                            <span class="badge badge-pill badge-primary"
                                >Alla <i class="fal fa-file-alt ml-2"></i
                            ></span>
                        </a>
                        <a
                            role="button"
                            @click.prevent="filter.group = 'to_sign'"
                        >
                            <span class="badge badge-pill badge-primary"
                                >För signering
                                <i class="fal fa-file-signature ml-2"></i
                            ></span>
                        </a>

                        <a
                            role="button"
                            @click.prevent="filter.group = 'signed'"
                        >
                            <span class="badge badge-pill badge-success"
                                >Signerad
                                <i class="fal fa-file-signature ml-2"></i
                            ></span>
                        </a>
                        <a
                            role="button"
                            @click.prevent="filter.group = 'completed'"
                        >
                            <span class="badge badge-pill badge-success"
                                >Slutförd <i class="fal fa-trophy-alt ml-2"></i
                            ></span>
                        </a>
                        <a
                            role="button"
                            @click.prevent="filter.group = 'payed'"
                        >
                            <span class="badge badge-pill badge-success"
                                >Betalad <i class="fal fa-money-bill ml-2"></i
                            ></span>
                        </a>
                        <a
                            role="button"
                            @click.prevent="
                                filter.group = 'externally_purchased'
                            "
                        >
                            <span class="badge badge-pill badge-success"
                                >Köpt i externt system
                                <i class="fal fa-money-bill ml-2"></i
                            ></span>
                        </a>
                        <a
                            role="button"
                            @click.prevent="filter.group = 'archived'"
                        >
                            <span class="badge badge-pill badge-success"
                                >Arkiverad
                                <i class="fal fa-box-archive ml-2"></i
                            ></span>
                        </a>

                        <a role="button" @click.prevent="filter.group = 'note'">
                            <span class="badge badge-pill badge-warning"
                                >Kommentar
                                <i class="fal fa-engine-warning ml-2"></i
                            ></span>
                        </a>
                        <a
                            role="button"
                            @click.prevent="filter.group = 'invalid'"
                        >
                            <span class="badge badge-pill badge-warning"
                                >Ej giltig
                                <i class="fal fa-exclamation-triangle ml-2"></i
                            ></span>
                        </a>
                        <a
                            role="button"
                            @click.prevent="filter.group = 'missing_response'"
                        >
                            <span class="badge badge-pill badge-warning"
                                >Obesvarat <i class="fal fa-comments ml-2"></i
                            ></span>
                        </a>

                        <a
                            role="button"
                            @click.prevent="filter.group = 'duplicate'"
                        >
                            <span class="badge badge-pill badge-danger"
                                >Dubblett <i class="fal fa-copy ml-2"></i
                            ></span>
                        </a>

                        <a
                            role="button"
                            v-for="priority in priorities"
                            @click.prevent="filter.priority = priority.value"
                        >
                            <span
                                class="badge badge-pill"
                                :style="{ backgroundColor: priority.color }"
                            >
                                {{ priority.name }}
                                <i class="fal fa-star ml-2"></i>
                            </span>
                        </a>
                        <a
                            v-if="filter.group || filter.priority !== undefined"
                            role="button"
                            @click.prevent="
                                filter.group = null;
                                filter.priority = undefined;
                            "
                        >
                            <span class="badge badge-pill badge-danger"
                                ><i class="fal fa-times"></i
                            ></span>
                        </a>
                    </div>
                </div>
            </b-collapse>
            <b-input-group class="mb-3">
                <b-input-group-prepend>
                    <b-input-group-text
                        >Filtrera* <i class="fal fa-search ml-2"></i
                    ></b-input-group-text>
                </b-input-group-prepend>

                <b-form-input
                    v-model="filter.text"
                    type="text"
                    debounce="500"
                ></b-form-input>
            </b-input-group>

            <b-table
                ref="quotesTable"
                :items="provideItems"
                :fields="fields"
                :primary-key="primaryKey"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :filter="filter"
                :current-page="currentPage"
                :per-page="pageLimit"
                :class="isAdmin ? 'admin' : 'standard'"
                responsive
            >
                <template #cell(leadscore)="data">
                    <h5 v-if="data.value.value >= 40 && data.value.value < 51">
                        😐
                    </h5>
                    <h5 v-if="data.value.value >= 50 && data.value.value < 71">
                        🚀
                    </h5>
                    <h5
                        v-if="data.value.value >= 71 && data.value.value <= 100"
                    >
                        🔥
                    </h5>
                </template>

                <template #cell(client_company)="data">
                    <small
                        v-if="data.value === 'Privatkund'"
                        style="opacity: 0.5"
                    >
                        <i class="fal fa-user-alt mr-1"></i> Privat
                    </small>
                    <small v-else>
                        <i class="fal fa-industry-alt mr-1"></i>
                        {{ data.value }}
                    </small>
                </template>

                <template #cell(formatted_quote_value)="data">
                    <div v-html="data.value"></div>
                </template>

                <template #cell(seller)="data">
                    <small v-if="data.value">
                        <img
                            v-if="data.value.avatar_url"
                            :src="data.value.avatar_url"
                            class="avatar-icon mr-1"
                        />
                        {{ data.value.name }}
                    </small>
                    <small v-else>N/A</small>
                </template>

                <template #cell(sales_note)="{ item, value }">
                    <template v-if="value">
                        <small :id="`sales_note-${item.id}`" class="ws-pre-line"
                            >{{ value.substring(0, 50)
                            }}{{ value.length > 100 ? "..." : "" }}</small
                        >
                        <b-popover
                            :target="`sales_note-${item.id}`"
                            triggers="hover"
                            placement="top"
                        >
                            <div class="ws-pre-line">{{ value }}</div>
                        </b-popover>
                    </template>
                    <small v-else>-</small>
                </template>

                <template #cell(products_options)="{ item, value }">
                    <div
                        v-if="value.type === 'products'"
                        class="d-flex justify-content-start d-flex-gap"
                        style="width: max-content"
                    >
                        <div v-for="(product, idx) in value.items">
                            <a
                                @click="
                                    currentQuote = item;
                                    currentOption = null;
                                    currentQuoteProduct = product;
                                "
                                :key="idx"
                                v-if="
                                    product.products.length > 0 &&
                                    product.products[0].image_url
                                "
                            >
                                <img-badge
                                    :src="product.products[0].image_url"
                                    :count="product.opened"
                                />
                            </a>
                        </div>
                    </div>
                    <div
                        v-else-if="value.type === 'options'"
                        class="d-flex justify-content-start d-flex-gap"
                        style="width: max-content"
                    >
                        <div v-for="(option, idx) in value.items">
                            <a
                                @click="
                                    currentQuote = item;
                                    currentQuoteProduct = null;
                                    currentQuoteOption = option;
                                "
                                :key="idx"
                                v-if="
                                    option.documents.length > 0 &&
                                    option.documents[0].pdf_thumbnail_url
                                "
                            >
                                <img-badge
                                    :src="option.documents[0].pdf_thumbnail_url"
                                    :count="option.opened"
                                />
                            </a>
                        </div>
                    </div>
                </template>

                <template #cell(statuses)="data">
                    <a
                        v-for="(statusItem, statusIdx) in data.value"
                        :key="statusIdx"
                        :href="statusItem.href"
                        class="ml-1"
                    >
                        <b-badge pill :variant="statusItem.type">
                            {{ statusItem.title }}
                            <i
                                v-if="statusItem.icon"
                                :class="`fal fa-${statusItem.icon} ml-1`"
                            ></i>
                        </b-badge>
                    </a>
                </template>

                <template #cell(priority)="data">
                    <b-dropdown
                        size="sm"
                        variant="default"
                        class="btn-default"
                        toggle-class="text-decoration-none text-dark"
                        no-caret
                        menu-class="z-index-dropdown"
                    >
                        <template #button-content>
                            <span
                                type="button"
                                class="px-3 py-2 text-dark text-decoration-none"
                                :style="{ backgroundColor: data.value.color }"
                            >
                                {{ data.value.value }}
                            </span>
                        </template>
                        <b-dropdown-item-button
                            v-for="priority in priorities"
                            @click="
                                updatePriority(data.item.id, priority.value)
                            "
                            v-bind:key="priority.value"
                        >
                            {{ priority.name }}
                            <i
                                class="fal fa-check"
                                v-if="priority.value == data.value.value"
                            ></i>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </template>

                <template #cell(tags)="data">
                    <b-badge
                        v-for="(tagItem, tagIdx) in data.value"
                        :key="tagIdx"
                        class="ml-1"
                        pill
                        variant="secondary"
                    >
                        {{ tagItem }}
                    </b-badge>
                </template>

                <template #cell(links)="data">
                    <div class="text-left">
                        <a
                            v-for="(linkItem, linkIndex) in data.value"
                            :key="linkIndex"
                            :href="linkItem.href"
                            :title="linkItem.title"
                            class="btn btn-default btn-sm float-left"
                        >
                            <i
                                v-if="linkItem.icon"
                                :class="`fal fa-${linkItem.icon}`"
                            ></i>
                        </a>
                        <b-dropdown
                            size="sm"
                            variant="default"
                            class="btn-default"
                            toggle-class="text-decoration-none text-dark"
                            no-caret
                            menu-class="z-index-dropdown"
                        >
                            <template #button-content>
                                <i class="fal fa-ellipsis-vertical"> </i
                                ><span class="sr-only">Dropdown</span>
                            </template>
                            <template v-if="!data.item.is_template">
                                <template>
                                    <b-dropdown-item-button
                                        v-if="data.item.flow_status > 0"
                                        @click="play(data.item.id)"
                                    >
                                        <i class="far fa-play mr-1 fa-fw"></i>
                                        Starta uppföljning
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button
                                        v-else
                                        @click="pause(data.item.id)"
                                    >
                                        <i
                                            class="far fa-pause mr-1 fa-fw"
                                            style="grid-column: 1/5"
                                        ></i>
                                        Pausa uppföljning
                                    </b-dropdown-item-button>
                                </template>
                                <template>
                                    <b-dropdown-item-button
                                        v-if="data.item.lost"
                                        @click="found(data.item.id)"
                                    >
                                        <i class="far fa-smile mr-1 fa-fw"></i>
                                        Har INTE tackat nej
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button
                                        v-else
                                        @click="lost(data.item.id)"
                                    >
                                        <i class="fal fa-frown mr-1 fa-fw"></i>
                                        Har tackat nej
                                    </b-dropdown-item-button>
                                </template>
                                <b-dropdown-item-button
                                    v-if="!data.item.delivered"
                                    @click="deliver(data.item.id)"
                                >
                                    <i class="far fa-truck mr-1 fa-fw"></i>
                                    Levererad
                                </b-dropdown-item-button>
                                <template>
                                    <b-dropdown-item-button
                                        v-if="data.item.archived"
                                        @click="unarchive(data.item.id)"
                                    >
                                        <i
                                            class="far fa-box-open mr-1 fa-fw"
                                        ></i>
                                        Av-arkivera kontakt
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button
                                        v-else
                                        @click="archive(data.item.id)"
                                    >
                                        <i
                                            class="far fa-box-archive mr-1 fa-fw"
                                        ></i>
                                        Arkivera kontakt
                                    </b-dropdown-item-button>
                                </template>
                            </template>
                            <b-dropdown-item-button
                                @click="
                                    deleteQuote(
                                        data.item.id,
                                        data.item.is_template
                                    )
                                "
                            >
                                <i class="far fa-trash-alt mr-1 fa-fw"></i> Ta
                                bort
                                {{ data.item.is_template ? "mall" : "kontakt" }}
                            </b-dropdown-item-button>
                        </b-dropdown>
                    </div>
                </template>
            </b-table>
            <div
                class="mt-3 d-flex flex-column justify-content-center align-items-center"
            >
                <b-spinner v-if="!isLoaded" label="Laddar.."></b-spinner>
            </div>

            <div class="d-flex justify-content-center align-items-center mt-3">
                <b-pagination
                    class="w-full"
                    v-model="currentPage"
                    :total-rows="quotesCount"
                    :per-page="pageLimit"
                ></b-pagination>
            </div>

            <ProductOptionPreview
                id="product-options-thumbnail-preview"
                :quote="currentQuote"
                :quoteProduct="currentQuoteProduct"
                :quoteOption="currentQuoteOption"
                @modal-closed="
                    currentQuote = null;
                    currentQuoteOption = null;
                    currentQuoteProduct = null;
                "
            >
                <template slot="edit-button">
                    <a class="btn btn-primary" :href="editQuoteUrl">Redigera</a>
                </template>
            </ProductOptionPreview>
        </div>
    </div>
</template>

<style lang="scss" scoped>
table.admin td {
    font-size: 80%;
}

.avatar-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.ws-pre-line {
    white-space: pre-line;
}

.d-flex-gap {
    gap: 10px;
}
</style>

<script>
import { Axios } from "axios";
import ProductOptionPreview from "./modals/product_option_preview.vue";

const fieldCreatedAt = {
    key: "created_at",
    label: "Skickad",
    sortable: true,
    formatter: (value, key, item) => value.substring(0, 10),
};
const fieldQuoteProfileName = {
    key: "quote_profile_name",
    label: "Profil",
    sortable: true,
};
const fieldCustomerName = {
    key: "customer_name",
    label: "Namn",
    formatter: (value, key, item) => (value ? value : value),
    sortable: true,
};
const fieldClientCompany = {
    key: "client_company",
    label: "Typ",
    sortable: true,
    formatter: (value, key, item) => (value ? value : "Privatkund"),
};
const fieldFormattedQuoteValue = {
    key: "formatted_quote_value",
    label: "Värde",
    sortable: true,
};
const fieldSeller = {
    key: "seller",
    label: "Kontakt",
    sortable: true,
};
const fieldLatestCustomerInitiatedActionLogAt = {
    key: "latest_customer_initiated_action_log_at_text",
    label: "Senaste aktivitet",
    sortable: true,
    sortDirection: "desc",
};
const fieldStatuses = {
    key: "statuses",
    label: "Status",
    sortable: false,
};
const fieldTags = {
    key: "tags",
    label: "Taggar",
    sortable: false,
};
const fieldLeadscore = {
    key: "leadscore",
    label: "Vera Score",
    sortable: true,
    sortDirection: "desc",
};
const fieldSalesNote = {
    key: "sales_note",
    label: "Note",
    sortable: true,
};
const fieldPriority = {
    key: "priority",
    label: "Prio",
    sortable: true,
    sortDirection: "desc",
};
const fieldProductOptions = {
    key: "products_options",
    label: "Erbjudande",
    sortable: false,
};
const fieldLinks = {
    key: "links",
    label: "",
    sortable: false,
};

const fieldTemplateName = {
    key: "template_name",
    label: "Mallnamn",
    formatter: (value, key, item) => (value ? value : value),
    sortable: true,
};
const adminFields = [
    fieldLinks,
    fieldCreatedAt,
    fieldLeadscore,
    fieldQuoteProfileName,
    fieldCustomerName,
    fieldClientCompany,
    fieldFormattedQuoteValue,
    fieldLatestCustomerInitiatedActionLogAt,
    fieldSeller,
    fieldStatuses,
    fieldPriority,
    fieldTags,
    fieldSalesNote,
    fieldProductOptions,
];

const standardFields = [
    fieldLinks,
    fieldCreatedAt,
    fieldLeadscore,
    fieldQuoteProfileName,
    fieldCustomerName,
    fieldClientCompany,
    fieldFormattedQuoteValue,
    fieldLatestCustomerInitiatedActionLogAt,
    fieldStatuses,
    fieldPriority,
    fieldTags,
    fieldSalesNote,
    fieldProductOptions,
];

const templateFields = [
    fieldLinks,
    fieldCreatedAt,
    fieldTemplateName,
    fieldQuoteProfileName,
    fieldTags,
    fieldSalesNote,
    fieldProductOptions,
];

export default {
    components: { ProductOptionPreview },
    props: {
        isAdmin: { type: Boolean, default: false },
        templatesOnly: { type: Boolean, default: false },
        searchQuery: { type: String, default: null },
    },
    data() {
        return {
            primaryKey: "id",
            sortBy: "created_at",
            sortDesc: true,
            filter: { text: null, group: null, priority: undefined },
            quoteProfileNameCount: 0,
            tagCount: 0,
            isLoaded: false,
            currentPage: 1,
            pageLimit: 50,
            quotesCount: null,
            currentQuote: null,
            currentQuoteProduct: null,
            currentQuoteOption: null,
            preferredFields: [],
            priorities: [],
        };
    },
    computed: {
        baseFields() {
            let fields = this.isAdmin ? adminFields : standardFields;
            if (this.templatesOnly) {
                fields = templateFields;
            }

            return fields
                .filter((field) => {
                    return (
                        field.key !== "quote_profile_name" ||
                        this.quoteProfileNameCount > 1
                    );
                })
                .filter((field) => {
                    return field.key !== "tags" || this.tagCount > 0;
                });
        },
        fields() {
            return this.baseFields.filter((field) => {
                if (field.key === "links") {
                    return true;
                }

                return this.preferredFields.includes(field.key);
            });
        },
        editQuoteUrl() {
            if (this.currentQuoteOption || this.currentQuoteProduct) {
                return this.route("quotes.edit", [this.currentQuote.id]);
            }

            return "#";
        },
        preferredFieldOptions() {
            return this.baseFields.filter((field) => field.key !== "links");
        },
    },
    mounted() {
        if (this.searchQuery) {
            this.filter = {
                text: this.searchQuery,
                group: null,
                priority: undefined,
            };
        }

        axios.get(route("utilities.priorities")).then((response) => {
            this.priorities = response.data;
        });
    },
    watch: {
        preferredFields(value) {
            this.savePreferredFields(value);
        },
    },
    methods: {
        savePreferredFields(preferredFields) {
            localStorage.setItem(
                "preferredFields",
                JSON.stringify(preferredFields)
            );
        },
        getPreferredFields() {
            let fields = localStorage.getItem("preferredFields");
            if (fields === null) {
                return [];
            }

            fields = JSON.parse(fields);
            if (!Array.isArray(fields)) {
                return [];
            }

            return fields;
        },
        setPreferredFields() {
            let preferredFields = this.getPreferredFields();
            if (preferredFields.length === 0) {
                preferredFields = this.preferredFieldOptions.map(
                    ({ key }) => key
                );
                this.savePreferredFields(preferredFields);
            }

            this.preferredFields = preferredFields;
        },
        async provideItems(ctx) {
            this.isLoaded = false;
            try {
                let url = `/offerter?page=${ctx.currentPage}&per_page=${ctx.perPage}`;
                if (ctx.sortBy) {
                    url += `&sort_by=${ctx.sortBy}&sort_desc=${
                        ctx.sortDesc ? 1 : 0
                    }`;
                }
                if (ctx.filter && ctx.filter.text) {
                    url += `&filter_text=${ctx.filter.text}`;
                }
                if (ctx.filter && ctx.filter.group) {
                    url += `&filter=${ctx.filter.group}`;
                }
                if (ctx.filter && ctx.filter.priority !== undefined) {
                    url += `&filter_priority=${
                        Number(ctx.filter.priority) === 0
                            ? "zero"
                            : ctx.filter.priority
                    }`;
                }
                if (this.isAdmin) {
                    url += "&is_admin=1";
                }
                if (this.templatesOnly) {
                    url += "&templates_only=true";
                }

                const response = await axios.get(url);
                const quotes = response.data.data;

                this.quoteProfileNameCount += new Set(
                    quotes.map((item) => item.quote_profile_name)
                ).size;
                this.tagCount += new Set(
                    quotes.flatMap((item) => item.tags)
                ).size;
                this.quotesCount = response.data.meta.total;

                this.setPreferredFields();

                this.isLoaded = true;

                return quotes;
            } catch (error) {
                console.error(error);
                return [];
            } finally {
                this.isLoaded = true;
            }
        },
        async play(id) {
            this.isLoaded = false;
            try {
                const response = await axios.post(route("quotes.play", id));
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
        async pause(id) {
            this.isLoaded = false;
            try {
                const response = await axios.post(route("quotes.pause", id));
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
        async found(id) {
            this.isLoaded = false;
            try {
                const response = await axios.post(route("quotes.found", id));
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
        async lost(id) {
            this.isLoaded = false;
            try {
                const response = await axios.post(route("quotes.lost", id));
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
        async archive(id) {
            this.isLoaded = false;
            try {
                const response = await axios.post(route("quotes.archive", id));
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
        async unarchive(id) {
            this.isLoaded = false;
            try {
                const response = await axios.post(
                    route("quotes.unarchive", id)
                );
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
        async deliver(id) {
            this.isLoaded = false;
            try {
                const response = await axios.post(route("quotes.deliver", id));
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
        async updatePriority(id, priority) {
            this.isLoaded = false;
            try {
                const response = await axios.post(
                    route("quotes.updatePriority", id),
                    { priority }
                );
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
        async deleteQuote(id, is_template) {
            let alert = null;
            if (is_template) {
                try {
                    const response = await axios.get(
                        route("quotes.getForms", id)
                    );
                    let message = "Vill du fortsätta att radera?";
                    if (response.data && response.data.length > 0) {
                        message = `Denna mall har ${
                            response.data.length
                        } formulär [${response.data
                            .map((form) => form.name)
                            .join(
                                ","
                            )}] bifogade, vill du fortfarande ta bort?`;
                    }
                    alert = await this.$swal({
                        text: message,
                        icon: "question",
                        confirmButtonText: "Fortsätta",
                        confirmButtonColor: window
                            .getComputedStyle(document.body)
                            .getPropertyValue("--primary-color"),
                        showCancelButton: true,
                    });
                } catch (e) {
                    console.log(e);
                    return;
                }
            } else {
                alert = await this.$swal({
                    text: "Vill du fortsätta att radera?",
                    icon: "question",
                    confirmButtonColor: window
                        .getComputedStyle(document.body)
                        .getPropertyValue("--primary-color"),
                    showCancelButton: true,
                });
            }
            if (!alert || !alert.isConfirmed) {
                return;
            }

            this.isLoaded = false;
            try {
                const response = await axios.post(route("quotes.delete", id));
                this.$refs.quotesTable.refresh();
            } catch (e) {
                console.error(error);
            } finally {
                this.isLoaded = true;
            }
        },
    },
};
</script>
