<template>
  <v-select :options="optionsForProduct" label="name" :placeholder="$t('surveys.editor.pick_product')" v-model="selectedProduct" class="mb-3">
    <template v-slot:option="product">
      {{product.name}}<br>
      <small class="text-muted">{{product.sku}}</small>
    </template>
  </v-select>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
  props: {
    products: Array
  },
  data() {
    return {
      selectedProduct: null
    };
  },
  watch: {
    selectedProduct() {
      if (this.selectedProduct) {
        this.$emit('selected', this.selectedProduct);
        this.selectedProduct = null;
      }
    }
  },
  computed: {
    optionsForProduct() {
      if (!this.products) { return []; }

      return this.products.map((prod) => ({id: prod.id, name: prod.name, sku: prod.sku}));
    }
  }
}
</script>
