<template>
<div>
  <fieldset class="mt-3">
    <template v-if="initialized">
      <div v-for="item, idx in existingItems" :key="item.uuid" class="my-3">
        <h6>
          {{$t('general.image')}} {{idx + 1}}

          <a type="button" class="text-danger ml-2" @click="item.deleted = true" title="Ta bort">
            <i class="fal fa-trash-alt"></i>
          </a>
        </h6>

        <input type="hidden" :name="`image_carousel_items[${item.uuid}][id]`" :value="item.id">
        <input type="hidden" :name="`image_carousel_items[${item.uuid}][_deleted]`" value="0">

        <div v-if="item.image_url" class="mb-3">
          <img :src="item.image_url" class="w-100">
        </div>

        <div class="mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <template v-if="item.image_url">
                  {{$t('imageCarousel.replaceImage')}}
                </template>
                <template v-else>
                  {{$t('general.image')}} *
                </template>
              </div>
            </div>
            <input type="file" class="form-control" accept="image/png, image/jpeg, *.png, *.jpg, *.jpeg" :required="!item.image_url" :name="`image_carousel_items[${item.uuid}][image]`">
          </div>
        </div>


        <div class="mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">{{$t('general.title')}}</div>
            </div>
            <input type="text" class="form-control" placeholder="(Behöver ej anges)" :name="`image_carousel_items[${item.uuid}][title]`" v-model="item.title">
          </div>
        </div>

        <div class="mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">{{$t('general.desc')}}</div>
            </div>
            <input type="phone" class="form-control" placeholder="(Behöver ej anges)" :name="`image_carousel_items[${item.uuid}][description]`" v-model="item.description">
          </div>
        </div>
      </div>

      <div v-for="item in deletedItems" :key="item.uuid">
        <input type="hidden" :name="`image_carousel_items[${item.uuid}][id]`" :value="item.id">
        <input type="hidden" :name="`image_carousel_items[${item.uuid}][_deleted]`" value="1">
      </div>

      <button type="button" class="btn btn-secondary" @click="addItem">{{ $t('imageCarousel.addImage') }}</button>
    </template>
    <template v-else>
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">{{$t('general.loading')}}...</span>
      </div>
    </template>
  </fieldset>
</div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    quoteProfileId: { type: Number }
  },
  data() {
    return {
      items: undefined
    };
  },
  computed: {
    initialized() {
      return this.items != undefined;
    },
    existingItems() {
      return this.items.filter((item) => !item.deleted);
    },
    deletedItems() {
      return this.items.filter((item) => item.deleted);
    }
  },
  async mounted() {
    let response = await axios.get(`/quote_profile/${this.quoteProfileId}/image_carousel_items`);
    const items = response.data.data;
    items.forEach((item) => {
      item.uuid = uuidv4();
      item.deleted = false;
    });
    this.items = items;
  },
  methods: {
    addItem() {
      this.items.push({
        uuid: uuidv4(),
        id: 0,
        image_url: undefined,
        title: undefined,
        description: undefined,
        deleted: false
      });
    }
  }
}
</script>
