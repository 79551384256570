<template>
  <fieldset>
    <div class="mb-3">
      <h6>Säljare</h6>
      <v-select :options="usersForSelect" placeholder="Slumpa.." label="label" :reduce="(u) => u.value" v-model="salespersonEmail" @input="onUpdate" multiple></v-select>
      <p class="small italic">Används om ingen annan säljare angetts i systemet, anges flera så slumpas en säljare från listan</p>
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Länk</div>
      </div>
      <input class="form-control" required readonly :value="route('integrations.createQuote')">
    </div>

    <p>Använd följande dataformat i JSON:</p>
    <pre><code>
      {
        uuid: "{{uuid}}", // Must always be this specific value
        quote_profile_id: Integer, // {{quoteProfileIdDocumentation}}
        salesperson_email: String|null, // if null, default configuration above will be used. Must belong to user registered in system

        customer_name: String,
        customer_email: String|null, // Must be specified if `send_type` = 'email' or 'dual'
        customer_phone: String|null, // +46NNNNNNNNN, Must be specified if `send_type` = 'phone' or 'dual'
        customer_company: String|null,

        send_type: String // 'email', 'sms', 'dual' or 'auto'

        chat_enabled: Boolean,
        message: null|String, // If empty, default message will be used

        workflows_enabled: Boolean,

        allow_customer_document_upload: Boolean,

        trigger_created_messages_to_customer: Boolean,

        custom_field_1_content: String|null,
        custom_field_2_content: String|null,
        custom_field_3_content: String|null,
        custom_field_4_content: String|null,
        custom_field_5_content: String|null,
        custom_field_6_content: String|null,
        custom_field_7_content: String|null,
        custom_field_8_content: String|null,
        custom_field_9_content: String|null,
        custom_field_10_content: String|null,

        tag_1: String|null,
        tag_2: String|null,
        tag_3: String|null,
        tag_4: String|null,
        tag_5: String|null,
        tag_6: String|null,
        tag_7: String|null,
        tag_8: String|null,
        tag_9: String|null,
        tag_10: String|null,

        signable: Boolean,
        signable_until: String|null, // YYYY-MM-DD

        alternative_1_bundle_id: Integer|null,
        alternative_1_title: String|null,

        alternative_2_bundle_id: Integer|null,
        alternative_2_title: String|null,

        alternative_3_bundle_id: Integer|null,
        alternative_3_title: String|null,
      }
    </code></pre>
  </fieldset>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true },
    associatedQuoteProfiles: { type: Array, required: true }
  },
  data() {
    return {
      uuid: null,
      salespersonEmail: null,

      availableUsers: null,
    }
  },
  computed: {
    serializedValue() {
      return {
        uuid: this.uuid,
        salesperson_email: this.salespersonEmail,
      };
    },
    quoteProfileIdDocumentation() {
      return this.associatedQuoteProfiles?.map((qp) => `${qp.id} - ${qp.name}`).join(", ");
    },
    usersForSelect() {
      const users = [
      ];

      this.availableUsers?.forEach((user) => users.push({
        label: `${user.name} - ${user.email}`,
        value: user.email
      }));
      return users;
    }
  },
  async mounted() {
    const usersResponse = await axios.get(route('company.users'));
    this.availableUsers = usersResponse.data.sort((a, b) => a.name.localeCompare(b.name));
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.uuid = this.value.uuid;
          this.salespersonEmail = this.value.salesperson_email;
        }

        if (!this.uuid) {
          this.uuid = uuidv4();
        }

        if (this.salespersonEmail === undefined) {
          this.salespersonEmail = null;
        }

        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.onUpdate();
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
