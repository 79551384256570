<template>
  <fieldset>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">URL (https://..)</div>
      </div>
      <input type="url" class="form-control" required pattern="https://.+" v-model="url" @input="onUpdate">
    </div>
  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      url: null,
    }
  },
  computed: {
    serializedValue() {
      return {
        url: this.url,
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.url = this.value.url;
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
