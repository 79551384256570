<template>
    <div>
        <template v-if="isLoaded">
            <div class="row p-3">
                <div
                    v-for="(alternative, alternativeIdx) in this.alternatives"
                    :key="alternativeIdx"
                    class="col-md-4 mb-3"
                >
                    <quotes-products-alternative-editor
                        :products="products"
                        :value="alternative"
                        :idx="alternativeIdx"
                        :next-alternative-name="nextAlternativeName"
                        :next-alternative-recommended="
                            nextAlternativeRecommended
                        "
                        :deletable="alternatives.length > 1"
                        :edit-recommended="editRecommended"
                        :default-price-locked-for-days="
                            defaultPriceLockedForDays
                        "
                        :ignore-out-of-stock="ignoreOutOfStock"
                        :ignore-deleted="ignoreDeleted"
                        :recurring-payments="recurringPayments"
                        :allow-product-discount="allowProductDiscount"
                        :default-discount-type="defaultDiscountType"
                        @create="onAlternativeCreate(alternativeIdx)"
                        @update="onAlternativeUpdate(alternativeIdx, $event)"
                        @delete="onAlternativeDelete(alternativeIdx)"
                    ></quotes-products-alternative-editor>
                </div>
                <div v-if="alternatives.length < 3" class="col-md-4 mb-3">
                    <quotes-products-alternative-editor
                        :products="products"
                        :value="null"
                        :idx="alternatives.length"
                        :next-alternative-name="nextAlternativeName"
                        :next-alternative-recommended="
                            nextAlternativeRecommended
                        "
                        :edit-recommended="editRecommended"
                        :default-price-locked-for-days="
                            defaultPriceLockedForDays
                        "
                        :ignore-out-of-stock="ignoreOutOfStock"
                        :ignore-deleted="ignoreDeleted"
                        :deletable="false"
                        :recurring-payments="recurringPayments"
                        :allow-product-discount="allowProductDiscount"
                        :default-discount-type="defaultDiscountType"
                        @create="onAlternativeCreate(alternatives.length)"
                        @update="
                            onAlternativeUpdate(alternatives.length, $event)
                        "
                        @delete="onAlternativeDelete(alternatives.length)"
                    ></quotes-products-alternative-editor>
                </div>
            </div>

            <div v-if="alternatives.length >= 3" class="row px-3">
                <p class="col-12 text-small text-right">
                    <a
                        href="#"
                        role="button"
                        @click.prevent="
                            onAlternativeCreate(alternatives.length)
                        "
                        >Lägg till ytterligare alternativ</a
                    >
                </p>
            </div>
        </template>
        <div v-else class="row">
            <div class="spinner-border text-info" role="status">
                <span class="sr-only">Laddar...</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>

<script>
import { downloadProducts } from "./../../products/search";

export default {
    props: {
        id: {
            type: Number,
            default: undefined,
        },
        editRecommended: {
            type: Boolean,
            default: false,
        },
        defaultPriceLockedForDays: {
            type: Number,
            default: undefined,
        },
        ignoreOutOfStock: {
            type: Boolean,
            default: false,
        },
        ignoreDeleted: {
            type: Boolean,
            default: false,
        },
        recurringPayments: {
            type: Object,
            default: null,
        },
        allowProductDiscount: {
            type: Boolean,
            default: false,
        },
        defaultDiscountType: {
            type: String,
            default: "kr",
        },
    },
    data() {
        return {
            isLoaded: false,
            alternatives: [],
            products: undefined,
        };
    },
    computed: {
        nextAlternativeName() {
            let nameIdx = this.alternatives.filter((a) => a.name).length + 1;
            let name = undefined;
            while (
                !name ||
                this.alternatives.map((a) => a.name).includes(name)
            ) {
                name = `Paket ${nameIdx++}`;
            }
            return name;
        },
        nextAlternativeRecommended() {
            return this.alternatives.length == 0;
        },
    },
    async mounted() {
        if (this.recurringPayments) {
            this.products = await downloadProducts(false, true);
        } else {
            this.products = await downloadProducts(true);
        }

        if (this.id !== undefined) {
            let qpResponses = await axios.get(
                this.route("quote_products.indexByQuote", this.id)
            );
            this.alternatives = qpResponses.data.data.map((qp) => ({
                id: qp.id,
                name: qp.name,
                amount: qp.amount,
                recommended: qp.recommended,
                currency: qp.currency,
                discount: qp.discount,
                discount_type: qp.discount_type,
                discount_valid_until: qp.discount_valid_until,
                price_locked_until: qp.price_locked_until,
                recurring_start_date: qp.recurring_start_date,
                recurring_interval: qp.recurring_interval,
                products: qp.product_references.map((prodRef) => ({
                    product: this.products.find(
                        (p) =>
                            p.type === "product" && p.id == prodRef.product_id
                    ),
                    amount: prodRef.amount,
                    promptedValue: prodRef.prompted_value,
                    discount: prodRef.discount ?? undefined,
                    discount_type: prodRef.discount_type ?? "kr",
                    is_add_on: prodRef.is_add_on ?? false,
                })),
            }));
        }

        if (this.alternatives.length === 0) {
            this.alternatives.push({});
        }

        this.isLoaded = true;
    },
    methods: {
        onAlternativeCreate(idx) {
            this.alternatives.splice(idx, 1, {});
        },
        onAlternativeUpdate(idx, alternative) {
            this.alternatives.splice(
                idx,
                1,
                JSON.parse(JSON.stringify(alternative))
            );
        },
        onAlternativeDelete(idx) {
            this.alternatives.splice(idx, 1);
        },
    },
};
</script>
