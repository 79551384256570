export default {
    install(Vue, options) {
        Vue.prototype.$_ = {
            eachSlice:  (array, num) => Array.from(
                { length: Math.ceil(array.length / num) },
                (_, i) => array.slice(i * num, i * num + num)
            )
        };
    }
}
