<template>
    <form v-if="loaded && !submitting" @submit.prevent="onSubmit">
        <h4 class="mb-3">{{ id === 'new' ? 'Lägg till' : 'Redigera' }} integration</h4>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <div class="input-group-text">Namn</div>
            </div>
            <input type="text" class="form-control" required v-model="name">
        </div>

        <div class="mb-3">
            <h6>Välj typ av integration</h6>

            <v-select :options="optionsForType" :reduce="(o) => o.value" class="mb-3" placeholder="Välj en"
                v-model="type">
                <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!type" v-bind="attributes" v-on="events" />
                </template>
            </v-select>
        </div>

        <div class="mb-3" v-if="typeDefinition">
            <component :is="typeDefinition.component" v-model="config"
                :associated-quote-profiles="associatedQuoteProfiles" />

            <hr>
        </div>

        <div class="mb-3" v-if="quoteProfiles">
            <h6>Koppla till profiler</h6>

            <v-select :options="quoteProfiles" label="name" :reduce="(o) => o.id" class="mb-3"
                placeholder="Välj en eller flera" multiple v-model="associatedQuoteProfileIds">
            </v-select>
        </div>

        <div class="d-flex justify-content-between">
            <b-button variant="secondary" @click.prevent="cancel">
                Avbryt
            </b-button>

            <b-button type="submit" variant="primary">
                {{ id === 'new' ? 'Skapa' : 'Spara' }} integration
            </b-button>
        </div>

    </form>
    <b-spinner v-else></b-spinner>
</template>

<script>
import axios from 'axios';

import IntegrationsFormDinteroPayments from './form/dintero-payments';
import IntegrationsFormBriqpayPayments from './form/briqpay-payments';
import IntegrationsFormSveaCheckoutPayments from './form/svea-checkout-payments';
import IntegrationsFormZaverPayments from './form/zaver-payments.vue';
import IntegrationsFormWoocommerceLinkPayments from './form/woocommerce-link-payments';
import IntegrationsFormCheckoutWebhookGeneric from './form/checkout-webhook-generic';
import IntegrationsFormCheckoutWebhookGoogleAds from './form/checkout-webhook-google-ads';
import IntegrationsFormCheckoutWebhookMeta from './form/checkout-webhook-meta';
import IntegrationsFormCheckoutWebhookRaddningsmissionen from './form/checkout-webhook-raddningsmissionen';
import IntegrationsFormIncomingWebhookCreateBrandawareness from './form/incoming-webhook-create-brandawareness';
import IntegrationsFormIncomingWebhookCreateQuote from './form/incoming-webhook-create-quote';
import IntegrationsFormIncomingWebhookCreateQuoteUsingTemplate from './form/incoming-webhook-create-quote-using-template';
import IntegrationsFormIncomingWebhookDisableAutomations from './form/incoming-webhook-disable-automations';
import IntegrationsFormIncomingWebhookWoocommerce from './form/incoming-webhook-woocommerce';
import IntegrationsFormCalendlyLinkQuotes from './form/calendly-link-quotes';
import IntegrationsFormCalendlyEmbeddedCalendar from './form/calendly-embedded-calendar';
import IntegrationsFormBillettoEmbeddedWidget from './form/billetto-embedded-widget';
import IntegrationsFormBillectaBankAccountRetrieval from './form/billecta-bank-account-retrieval';
import IntegrationsFormBillectaDebtors from './form/billecta-debtors.vue';
import IntegrationsFormFortnox from './form/fortnox.vue';

export const Types = {
    'dintero-payments': {
        label: 'Betalning: Dintero',
        component: 'integrations-form-dintero-payments',
    },
    'briqpay-payments': {
        label: 'Betalning: Briqpay',
        component: 'integrations-form-briqpay-payments',
    },
    'svea-checkout-payments': {
        label: 'Betalning: Svea Checkout',
        component: 'integrations-form-svea-checkout-payments',
    },
    'zaver-payments': {
        label: 'Betalning: Zaver',
        component: 'integrations-form-zaver-payments',
    },
    'woocommerce-link-payments': {
        label: 'Betalning: Woocommerce Länk',
        component: 'integrations-form-woocommerce-link-payments',
    },
    'checkout-webhook-generic': {
        label: 'Checkout Webhook: Generell',
        component: 'integrations-form-checkout-webhook-generic',
    },
    'checkout-webhook-google-ads': {
        label: 'Checkout Webhook: Google Ads',
        component: 'integrations-form-checkout-webhook-google-ads',
    },
    'checkout-webhook-meta': {
        label: 'Checkout Webhook: Meta',
        component: 'integrations-form-checkout-webhook-meta',
    },
    'checkout-webhook-raddningsmissionen': {
        label: 'Checkout Webhook: Räddningsmissionen',
        component: 'integrations-form-checkout-webhook-raddningsmissionen',
    },
    'incoming-webhook-create-brandawareness': {
        label: 'Ingående Webhook: Skapa mötesinbjudan & visitkort',
        component: 'integrations-form-incoming-webhook-create-brandawareness',
    },
    'incoming-webhook-create-quote': {
        label: 'Ingående Webhook: Skapa offert',
        component: 'integrations-form-incoming-webhook-create-quote',
    },
    'incoming-webhook-create-quote-using-template': {
        label: 'Ingående Webhook: Skapa med mall',
        component: 'integrations-form-incoming-webhook-create-quote-using-template',
    },
    'incoming-webhook-disable-automations': {
        label: 'Ingående Webhook: Inaktivera automation',
        component: 'integrations-form-incoming-webhook-disable-automations',
    },
    'incoming-webhook-woocommerce': {
        label: 'Ingående Webhook: Woocommerce',
        component: 'integrations-form-incoming-webhook-woocommerce',
    },
    'calendly-link-quotes': {
        label: 'Calendly: Länka kunder till bokade möten',
        component: 'integrations-form-calendly-link-quotes',
    },
    'calendly-embedded-calendar': {
        label: 'Calendly: Inbäddad kalender',
        component: 'integrations-form-calendly-embedded-calendar',
    },
    'billetto-embedded-widget': {
        label: 'Billetto: Inbäddad Widget',
        component: 'integrations-form-billetto-embedded-widget',
    },
    'billecta-bank-account-retrieval': {
        label: 'Billecta: Hämta bankkonto',
        component: 'integrations-form-billecta-bank-account-retrieval',
    },
    'billecta-debtors': {
        label: 'Billecta: Debtor',
        component: 'integrations-form-billecta-debtors',
    },
    'fortnox': {
        label: 'Fortnox',
        component: 'integrations-form-fortnox',
    }
};

export default {
    components: {
        IntegrationsFormDinteroPayments,
        IntegrationsFormBriqpayPayments,
        IntegrationsFormSveaCheckoutPayments,
        IntegrationsFormZaverPayments,
        IntegrationsFormWoocommerceLinkPayments,
        IntegrationsFormCheckoutWebhookGeneric,
        IntegrationsFormCheckoutWebhookGoogleAds,
        IntegrationsFormCheckoutWebhookMeta,
        IntegrationsFormCheckoutWebhookRaddningsmissionen,
        IntegrationsFormIncomingWebhookCreateBrandawareness,
        IntegrationsFormIncomingWebhookCreateQuote,
        IntegrationsFormIncomingWebhookCreateQuoteUsingTemplate,
        IntegrationsFormIncomingWebhookDisableAutomations,
        IntegrationsFormIncomingWebhookWoocommerce,
        IntegrationsFormCalendlyLinkQuotes,
        IntegrationsFormCalendlyEmbeddedCalendar,
        IntegrationsFormBillettoEmbeddedWidget,
        IntegrationsFormBillectaBankAccountRetrieval,
        IntegrationsFormBillectaDebtors,
        IntegrationsFormFortnox,
    },
    props: {
        id: { type: [Number, String], required: true }
    },
    emits: [
        'created',
        'updated',
        'cancelled',
    ],
    data() {
        return {
            name: null,
            type: null,
            config: {},
            loaded: false,
            quoteProfiles: null,
            associatedQuoteProfileIds: [],
            submitting: false,
        };
    },
    computed: {
        optionsForType() {
            return Object.keys(Types).map((type) => ({
                label: Types[type].label,
                value: type
            }));
        },
        typeDefinition() {
            return Types[this.type];
        },
        associatedQuoteProfiles() {
            if (!this.quoteProfiles) {
                return [];
            }
            return this.quoteProfiles.filter((qp) => this.associatedQuoteProfileIds.indexOf(qp.id) !== -1);
        }
    },
    async mounted() {
        const response = await axios.get(route('quoteProfiles.index'));
        this.quoteProfiles = response.data.sort((a, b) => a.name.localeCompare(b.name));
    },
    watch: {
        id: {
            async handler() {
                this.loaded = false;
                await this.load()
            },
            immediate: true
        },
        type(newValue, oldValue) {
            if (!this.name || this.name == Types[oldValue]?.label) {
                this.name = Types[newValue]?.label;
            }
        }
    },
    methods: {
        async load() {
            if (this.id === 'new') {
                this.name = null;
                this.type = null;
                this.config = {};
                this.associatedQuoteProfileIds = [];
                this.loaded = true;
                return;
            }

            const response = await axios.get(route('integrations.show', this.id));
            this.name = response.data.name;
            this.type = response.data.type;
            this.config = response.data.config;
            this.associatedQuoteProfileIds = response.data.associated_quote_profiles.map((qp) => qp.id);
            this.loaded = true;
        },
        async onSubmit() {
            this.submitting = true;
            const creating = this.id === 'new';
            const data = {
                name: this.name,
                type: this.type,
                config: this.config,
                associated_quote_profiles: this.associatedQuoteProfileIds
            };

            try {
                let response;
                if (creating) {
                    response = await axios.post(route('integrations.store'), data);
                } else {
                    response = await axios.put(route('integrations.update', this.id), data);
                }

                this.id = response.data.id;
                this.$emit(creating ? 'created' : 'updated', response.data);
            } catch (err) {
                console.error(err);
                throw err;
            } finally {
                this.submitting = false;
            }
        },
        cancel() {
            this.$emit('cancelled');
        }
    }
}
</script>
