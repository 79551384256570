<template>
    <div>
        <h5>Aktiva fält <i class="fal fa-square-check active-color"></i></h5>
        <b-table-lite :fields="fields" :items="selectedFieldValues" :busy="loadingTables" striped hover small responsive >
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(links)="row">
                <div class="d-flex" style="gap: 10px">
                    <a v-if="row.index < values.length-1" @click="handleMoveDown(row.index)" type="button"><i class="fal fa-arrow-down"></i></a>
                    <a v-if="row.index > 0" @click="handleMoveUp(row.index)" type="button"><i class="fal fa-arrow-up"></i></a>
                    <a size="sm" @click="row.toggleDetails" type="button"><i class="fal fa-edit"></i></a>
                    <a size="sm" @click="moveToAvailable(row.index)" type="button"><i class="fal fa-minus"></i></a>
                </div>
            </template>
            <template #cell(settings)="row">
                <div class="d-flex" style="gap: 10px">
                    <i v-bind:class="{'active-color':!row.item.hideForContact}" class="fal fa-eye"></i>
                    <i v-bind:class="{'active-color':row.item.required}" class="fal fa-octagon-exclamation"></i>
                    <i v-bind:class="{'active-color':row.item.editableByContact}" class="fal fa-pencil"></i>
                </div>
            </template>
            <template #row-details="row">
                <quote-field :key="row.id" :inputTypes="inputTypes" :attribute="row.item" @submitted="(payload) => handleFieldEdit(row, payload)" @cancelled="row.toggleDetails"/>
            </template>
        </b-table-lite>

        <hr/>

        <h5>Inaktiva fält <i class="fal fa-square-check"></i></h5>
        <b-table-lite :fields="fields" :items="availableFieldValues" :busy="loadingTables" striped hover small responsive >
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(links)="row">
                <div class="d-flex" style="gap: 10px">
                    <a size="sm" @click="moveToSelected(row.index)" type="button"><i class="fal fa-plus"></i></a>
                </div>
            </template>
            <template #cell(settings)="row">
                <div class="d-flex" style="gap: 10px">
                    <i v-bind:class="{'active-color':!row.item.hideForContact}" class="fal fa-eye"></i>
                    <i v-bind:class="{'active-color':row.item.required}" class="fal fa-octagon-exclamation"></i>
                    <i v-bind:class="{'active-color':row.item.editableByContact}" class="fal fa-pencil"></i>
                </div>
            </template>
            <template #row-details="row">
                <quote-field :key="row.id" :inputTypes="inputTypes" :attribute="row.item" @submitted="(payload) => handleFieldEdit(row, payload)" @cancelled="row.toggleDetails"/>
            </template>
        </b-table-lite>

        <div>
            <quote-field :key="-1" v-if="creating" :inputTypes="inputTypes" :attribute="customFieldTemplate()" @submitted="(payload) => handleFieldCreated(payload)" @cancelled="creating = false"/>
            <div v-else class="d-flex" style="gap: 10px">
                <button @click="submit" class="btn btn-primary"><i class="fal fa-save"></i> Spara</button>
                <button  @click="creating = true" class="btn btn-secondary"><i class="fal fa-add"></i></button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .active-color{
        color: var(--primary-color)
    }
</style>
<script>

const tableFields = [
    {key : 'links', label: ''},
    {key : 'label', label: 'Fältnamn'},
    {key : 'placeholder', label: 'Visningstext'},
    {key : 'settings', label: 'Inställningar'},
    {key : 'name', label: 'Systemnamn'},
    {key : 'fieldType', label: 'Fälttyp'},
    {key : 'type', label: 'Typ'},
];
export default {
    name: "QuoteFieldset",
    props: {
        quoteProfileId: Number,
        definitions : Array,
        inputTypes : Array,
        values: Array,
    },
    mounted: function () {
        this.loadingTables = true;
        if (this.values && Array.isArray(this.values) && this.values.length > 0) {
            this.selectedFieldValues = this.values.filter(value => value.enabled);
            this.availableFieldValues = this.values.filter(value => !value.enabled);
        }else {
            this.availableFieldValues = this.definitions;
        }
        this.loadingTables = false;
    },
    methods: {
        moveToSelected(index) {
            let fieldValue = this.availableFieldValues.splice(index, 1)[0];
            fieldValue.enabled = true;
            this.selectedFieldValues.push(fieldValue);
        },
        moveToAvailable(index) {
            let fieldValue = this.selectedFieldValues.splice(index, 1)[0];
            fieldValue.enabled = false;
            this.availableFieldValues.push(fieldValue);
        },
        handleFieldEdit (row, payload) {
            this.selectedFieldValues[row.index] = payload;
            row.toggleDetails();
            if (!this.selectedFieldValues[row.index].enabled) {
                this.moveToAvailable(row.index);
            }
        },
        handleFieldCreated(payload) {
            this.availableFieldValues.push(payload);
            this.creating = false;
        },
        handleMoveUp(index) {
            if(index < 1) {
                return;
            }

            this.swapValues(index-1, index);
        },
        handleMoveDown(index) {
            if(index >= this.values.length) {
                return;
            }

            this.swapValues(index+1, index);
        },
        swapValues(i, j) {
            this.selectedFieldValues[j] = this.selectedFieldValues.splice(i, 1, this.selectedFieldValues[j])[0];
        },
        customFieldTemplate() {
            return {
                name: '',
                label: '',
                type: null,
                fieldType: 'custom',
                required: false,
                enabled: false,
                placeholder: '',
                hideForContact: false,
                editableByContact: false,
            }
        },
        async submit () {
            let fields = [];
            fields.push(...this.selectedFieldValues);
            fields.push(...this.availableFieldValues);

            const payload = {
                fields
            };

            try {
                this.updating = true;
                const response = await axios.post(route('quoteProfiles.updateFieldAttributes', this.quoteProfileId), payload);
                window.location.reload();
            }catch(err) {
                console.log(err);
                this.updating = false;
            }
        }
    },
    data: function (){
        return {
            fields : tableFields,
            creating: false,
            updating: false,
            loadingTables: false,
            selectedFieldValues: [],
            availableFieldValues: []
        };
    }
}
</script>
