<template>
  <form @submit.prevent="submit">
    <template v-if="initialized">
      <h5 class="mb-3">
        Kontaktuppgifter
      </h5>

      <div class="mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Namn* <i class="fal fa-user-alt ml-2"></i></div>
          </div>
          <input type="text" class="form-control" placeholder="John Doe" required v-model="customerName">
        </div>
      </div>

      <div class="mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Bolagsnamn <i class="fal fa-industry-alt ml-2"></i></div>
          </div>
          <input type="text" class="form-control" placeholder="SalesByte AB" v-model="clientCompany">
        </div>
      </div>

      <div class="mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Mobilnummer* <i class="fal fa-mobile ml-2"></i></div>
          </div>
          <input type="phone" class="form-control" placeholder="+467..." required minlength=5 v-model="customerPhone">
        </div>
      </div>

      <div class="mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">E-post <i class="fal fa-envelope ml-2"></i></div>
          </div>
          <input type="email" class="form-control" placeholder="john@salesbyte.se" v-model="customerEmail">
        </div>
      </div>

      <template v-if="formType == 'caparol-basic'">
        <leads-fieldset-caparol-basic v-model="formData" />
      </template>

      <hr>

      <button type="submit" class="btn btn-primary" @click="createQuoteAfterSubmit = false">{{submitText}}</button>

      <a v-if="quoteId" :href="`/offerter/${quoteId}/redigera`" class="btn btn-primary">Gå till offert</a>
      <button v-else type="submit" class="btn btn-primary" @click="createQuoteAfterSubmit = true">{{submitAndCreateOffertText}}</button>
    </template>
    <template v-else>
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Laddar...</span>
      </div>
    </template>
  </form>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  props: {
    id: { type: String, default: "new" },
    formType: { type: String, default: null }
  },
  data() {
    return {
      customerName: undefined,
      clientCompany: undefined,
      customerPhone: undefined,
      customerEmail: undefined,
      formData: null,
      quoteId: null,

      initialized: false,
      createQuoteAfterSubmit: false
    };
  },
  computed: {
    submitText() {
      return this.id !== "new" ? "Spara" : "Skapa";
    },
    submitAndCreateOffertText() {
      return this.id !== "new" ? "Spara och konvertera till offert" : "Skapa och konvertera till offert";
    }
  },
  async mounted() {
    if (this.id !== "new") {
      let response = await axios.get(`/leads/${this.id}`);
      this.customerName = response.data.customer_name;
      this.clientCompany = response.data.client_company;
      this.customerPhone = response.data.customer_phone;
      this.customerEmail = response.data.customer_email;
      this.formData = response.data.form_data;
      this.quoteId = response.data.quote_id;
    }

    this.initialized = true;
  },
  methods: {
    async submit() {
      const method = this.id !== "new" ? 'patch' : 'post';
      const url = this.id !== "new" ? `/leads/${this.id}` : "/leads";
      let response = await axios.request({
        method: method,
        url: url,
        data: {
          customer_name: this.customerName,
          client_company: this.clientCompany,
          customer_phone: this.customerPhone,
          customer_email: this.customerEmail,
          form_data: this.formData
        }
      });

      if (this.createQuoteAfterSubmit) {
          document.location = `/offerter/skapa?lead_id=${response.data.id}`;
      } else {
          document.location = "/leads";
      }
    }
  }
}
</script>
