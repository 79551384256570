<template>
  <div v-if="isLoaded" class="px-3">
    <div class="row">
      <form ref="form" class="col-md-4" @submit.prevent="onSubmit">
        <div class="mb-3">
          <quotes-products-alternative-editor
            :products="products"
            :value="alternative"
            :idx="0"
            next-alternative-name="Nytt erbjudande"
            :next-alternative-recommended="false"
            :deletable="false"
            :edit-recommended="false"
            :ignore-out-of-stock="false"
            :ignore-deleted="false"
            @update="onAlternativeUpdate(0, $event)"
          ></quotes-products-alternative-editor>
        </div>

        <div class="mb-3">
          <div class="card">
            <div class="card-header text-center">
            </div>
            <div class="card-body">
              <div class="input-group mb-3">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="notify_customer" name="notify_customer">
                  <label class="custom-control-label" for="notify_customer">
                    Skicka notis till kund(er) om uppdateringen
                  </label>
                </div>
              </div>

              <button type="submit" class="btn btn-light" :disabled="quoteCount === 0 || isSubmitting">
                <div v-if="isSubmitting" class="spinner-border spinner-border-sm text-info" role="status">
                  <span class="sr-only">Laddar...</span>
                </div>
                <i v-else class="fal fa-airplane"></i>
                Lägg till erbjudandet på samtliga {{quoteCount}} kunder i urvalet
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="col-md-8">
        <data-analysis-quotes-selector @filterConfig="onFilterConfig" @quoteCount="onQuoteCount" :base-filter-config="baseFilterConfig" />
      </div>
    </div>
  </div>
  <div v-else class="container">
    <div class="row">
      <div class="spinner-border text-info" role="status">
        <span class="sr-only">Laddar...</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { downloadProducts } from '../../products/search';

export default {
  props: {
  },
  data() {
    return {
      isLoaded: false,
      isSubmitting: false,
      alternative: {},
      products: undefined,
      quoteCount: 0,
      baseFilterConfig: {
        include: {
          quoteType: 'products',
          not_signed: true
        },
        exclude: {}
      },
      filterConfig: {}
    }
  },
  async mounted() {
    this.products = await downloadProducts(true);
    this.isLoaded = true;
  },
  methods: {
    onAlternativeUpdate(_idx, alternative) {
      this.alternative = alternative;
    },
    onFilterConfig(filterConfig) {
      this.filterConfig = filterConfig;
    },
    onQuoteCount(quoteCount) {
      this.quoteCount = quoteCount;
    },
    async onSubmit() {
      try {
        this.isSubmitting = true;

        let nextQuoteUrl = route("data_analysis.quotes.index", {
          _query: {
            per_page: 10,
            filter_config: this.filterConfig
          }
        });

        while (nextQuoteUrl) {
          const response = await axios.get(nextQuoteUrl);
          nextQuoteUrl = response.data.links.next;
          const quotes = response.data.data;

          const data = new FormData(this.$refs.form);
          quotes.forEach((quote, idx) => data.set(`quote_ids[${idx}]`, quote.id));
          await axios.post(route('quote_products.storeMulti'), data);
        }

        alert("Dina offerter är uppdaterade!");
      } finally {
        this.isSubmitting = false;
      }
    },
  }
}
</script>
