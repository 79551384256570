<template>
  <div>
    <div v-if="tags && tags.length > 0" class="badge-box">
      <b-badge v-for="(tag, tagIdx) in tags" :key="tagIdx" pill variant="secondary" class="mr-1" @click.prevent="removeTag(tagIdx)" role="button">
        {{tag}} <i class="fa-regular fa-times"></i>

        <input v-if="inputName" type="hidden" :name="`${inputName}[]`" :value="tag">
      </b-badge>

      <hr>
    </div>

    <div class="d-flex mt-3">
      <input placeholder="Lägg till" type="text" class="form-control form-control-sm flex-grow mr-2" v-model="newTag" @keydown.enter.prevent="addNewTag">
      <b-button type="button" variant="primary" size="sm" :disabled="newTag.length === 0" @click.prevent="addNewTag">
        <i class="fa-regular fa-plus"></i>
      </b-button>
    </div>

    <div v-if="autocompleteTags && autocompleteTags.length > 0" class="list-unstyled mt-3 badge-box">
      <b-badge v-for="(tag, tagIdx) in autocompleteTags" :key="tagIdx" role="button" pill variant="company-color" class="mr-1" @click.prevent="addTag(tag)">
        {{tag}} <i class="fa-regular fa-plus"></i>
      </b-badge>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.badge-box {
  font-size: 1.1em;
}
</style>

<script>
import axios from 'axios';

export default {
  props: {
    quoteProfileId: { type: Number, required: true },
    value: { type: Array, default: () => [] },
    quoteId: { type: Number, default: undefined },
    inputName: { type: String, default: undefined },
    isTemplate: { type: Boolean, default: false},
  },
  data() {
    return {
      tags: JSON.parse(JSON.stringify(this.value)),
      availableTags: [],
      newTag: ''
    }
  },
  computed: {
    autocompleteTags() {
      if (!this.availableTags) { return []; }
      if (!this.tags) { return []; }

      const potentialTags = this.availableTags.filter((tag) => !this.tags.includes(tag))
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

      if (!this.newTag) {
        return potentialTags.length <= 50 ? potentialTags : [];
      }

      return potentialTags.filter((tag) => tag.toLowerCase().includes(this.newTag.toLowerCase()));
    }
  },
  async mounted() {
    const response = await axios.get(this.route('quoteProfiles.tags', this.quoteProfileId));
    this.availableTags = response.data.data.map((tag) => tag.title);
  },
  watch: {
    value: {
      handler(val) { this.captureValue(val); },
      immediate: true
    },
    tags: {
      handler: async function (val) {
        if (this.quoteId === undefined) {
          this.$emit('input', JSON.parse(JSON.stringify(val)));
          return;
        }


        await axios.post(this.route('quotes.updateTags', {id: this.quoteId, is_template: this.isTemplate}), { tags: val });
      },
      deep: true
    }
  },
  methods: {
    captureValue(val) {
      if (this.quoteId !== undefined) { return; }

      if (JSON.stringify(this.tags) !== JSON.stringify(val)) {
        this.tags = JSON.parse(JSON.stringify(val));
      }
    },
    addNewTag() {
      this.addTag(this.newTag);
    },
    addTag(tag) {
      this.tags.push(tag);
      this.newTag = '';
    },
    removeTag(idx) {
      this.tags.splice(idx, 1);
    }
  }
}
</script>
