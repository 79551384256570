<template>
  <div class="d-flex align-items-start">
    <div class="indicator align-self-end">
      <div class="count" role="status">{{count}}</div>
    </div>
    <img :src="src" class="img-fluid shadow-sm rounded" style="height: 45px" alt="product option image"/>
  </div>
</template>

<style scoped>
.count {
  font-family: 'Montserrat', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: aliceblue;
  font-weight: 200;
  font-size: 0.6rem;
}

.indicator {
  z-index: 999;
  margin-right: -11px;
  margin-bottom: -9px;
  width: 20px;
  height: 20px;
  background-color: var(--primary-color);
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>

<script>
export default {
    props: {
      src: String,
      count: Number
    }
}
</script>
