<template>
  <div :id="id" class="modal fade viewmodal" tabindex="-1" role="dialog" aria-hidden="true"
       style="padding-right: 0px !important" ref="modal">
    <div class="modal-dialog modal-full" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <nav class="navbar navbar-light w-100">
            <slot name="edit-button"></slot>
            <div class="buttons">
              <a class="btn" data-dismiss="modal" aria-label="Stäng">
                <i class="fal fa-times"></i>
              </a>
            </div>
          </nav>
        </div>

        <div class="modal-body">
          <template v-if="quoteProductData && quoteProductData.products">
            <div v-if="quoteProductData.products.length === 1" class="row">
              <div class="col-12 mb-3">
                <img :src="quoteProductData.products[0].image_url"
                     :alt="quoteProductData.products[0].image_name" class="product-featured">
              </div>

              <div class="col-12 mb-3">
                <h2 class="font-weight-bold">
                  {{ quoteProductData.products[0].name_with_prompted_value }}</h2>

                <div v-if="!quoteProductData.allows_user_assigned_price" class="d-flex">
                  <p style="font-size: 1.5em;" :class="quoteProduct.amountsEditable ? 'mb-0' : ''"
                     v-if="quoteProduct.amountsEditable">
                    {{
                      $t('products.unit_with_price.' + quoteProductData.products[0].price_unit, {
                        price: quoteProductData.products[0].price,
                        currency: quoteProductData.products[0].currency_name,
                        vat_suffix: ''
                      })
                    }}
                  </p>
                  <p style="font-size: 1.5em;" :class="quoteProduct.amountsEditable ? 'mb-0' : ''"
                     v-else-if="quoteProductData.products[0].amount == 1">
                    {{
                      $t('products.unit_with_price.' + quoteProductData.products[0].price_unit, {
                        price: quoteProductData.products[0].price,
                        currency: quoteProductData.products[0].currency_name,
                        vat_suffix: ''
                      })
                    }}
                  </p>
                  <p style="font-size: 1.5em;" :class="quoteProduct.amountsEditable ? 'mb-0' : ''" v-else>
                    {{
                      $t('products.unit_with_amount_and_price.' + quoteProductData.products[0].price_unit, {
                        amount: quoteProductData.products[0].amount,
                        price: quoteProductData.products[0].price,
                        currency: quoteProductData.products[0].currency_name,
                        vat_suffix: ''
                      })
                    }}
                  </p>
                </div>

                <div v-if="quoteProduct.amountsEditable && !quoteProductData.solo_product" class="btn-group mt-2">
                  <button :disabled="quoteProductData.products[0].amount <= 1" type="button"
                          class="btn btn-sm btn-outline-secondary"
                          @click.stop.prevent="decreaseProductAmount(0)"><i
                    class="fa-light fa-minus"></i></button>
                  <button type="button" disabled class="btn btn-sm btn-outline-secondary"
                          style="min-width: 2.5rem">{{ quoteProductData.products[0].amount }}
                  </button>
                  <button type="button" class="btn btn-sm btn-outline-secondary"
                          @click.stop.prevent="increaseProductAmount(0)"><i
                    class="fa-light fa-plus"></i></button>
                </div>
              </div>

              <div class="col-12">
                <dl v-if="quoteProductData.products[0].specification && quoteProductData.products[0].specification.length > 0"
                    class="product-specification">
                  <template v-for="(spec, idx) in quoteProductData.products[0].specification">
                    <dt :key="`dt-${idx}`">{{ spec.label }}</dt>
                    <dd :key="`dd-${idx}`">{{ spec.value }}</dd>
                  </template>
                </dl>
                <h6 v-if="quoteProductData.products[0].description_title" class="font-weight-bold">
                  {{ quoteProductData.products[0].description_title }}</h6>
                <p v-if="quoteProductData.products[0].description"
                   v-html="quoteProductData.products[0].description"></p>
                <p v-if="quoteProductData.products[0].url">
                  <a :href="quoteProductData.products[0].url" target="_blank" class="pt-2">
                    {{ $t('quoteProducts.view.read_more') }}
                  </a>
                </p>
              </div>
            </div>
            <template v-else>
              <div v-for="(product, productIdx) in quoteProductData.products" :key="productIdx"
                   class="row mb-2">
                <div class="col-12">
                  <div style="display: grid; grid-template-columns: 4fr 8fr; gap: 1rem;">
                    <img :src="product.image_url" :alt="product.image_name"
                         class="product-thumbnail">

                    <div>
                      <h4 class="font-weight-bold text-break">
                        {{ product.name_with_prompted_value }}</h4>

                      <template v-if="!quoteProductData.allows_user_assigned_price">
                        <p style="font-size: 1.3em;" class="my-0" v-if="quoteProduct.amountsEditable">
                          {{
                            $t('products.unit_with_price.' + product.price_unit, {
                              price: product.price,
                              currency: product.currency_name,
                              vat_suffix: ''
                            })
                          }}
                        </p>
                        <p style="font-size: 1.3em;" class="my-0"
                           v-else-if="product.amount == 1"
                           v-html="$t('quoteProducts.view.price_html', { price: product.price, currency: product.currency_name, vat_suffix: '' })"></p>
                        <p style="font-size: 1.3em;" class="my-0" v-else>
                          {{
                            $t('products.unit_with_amount_and_price.' + product.price_unit, {
                              amount: product.amount,
                              price: product.price,
                              currency: product.currency_name,
                              vat_suffix: ''
                            })
                          }}
                        </p>
                      </template>
                      <div v-if="quoteProduct.amountsEditable && !quoteProductData.solo_product"
                           class="btn-group my-0">
                        <button type="button" class="btn btn-sm btn-outline-secondary"
                                @click.stop.prevent="decreaseProductAmount(productIdx)"><i
                          class="fa-light fa-minus"></i></button>
                        <button type="button" disabled class="btn btn-sm btn-outline-secondary"
                                style="min-width: 2.5rem">{{ product.amount }}
                        </button>
                        <button type="button" class="btn btn-sm btn-outline-secondary"
                                @click.stop.prevent="increaseProductAmount(productIdx)"><i
                          class="fa-light fa-plus"></i></button>
                      </div>
                    </div>
                  </div>
                </div>

                <template
                  v-if="(product.specification && product.specification.length > 0) || product.description_title || product.description">
                  <div class="col-12 mt-3"
                       :class="['collapsable-description', openDescriptionForProductIdx !== productIdx ? 'collapsable-description-collapsed' : 'collapsable-description-open']"
                       @click.prevent="openDescription(productIdx)">
                    <dl v-if="product.specification && product.specification.length > 0"
                        class="product-specification">
                      <template v-for="(spec, idx) in product.specification">
                        <dt :key="`dt-${idx}`">{{ spec.label }}</dt>
                        <dd :key="`dd-${idx}`">{{ spec.value }}</dd>
                      </template>
                    </dl>

                    <h6 v-if="product.description_title" class="font-weight-bold">
                      {{ product.description_title }}</h6>
                    <p v-if="product.description" v-html="product.description"></p>
                    <p v-if="product.url">
                      <a :href="product.url" target="_blank" class="pt-2" @click.stop>
                        {{ $t('quoteProducts.view.read_more') }}
                      </a>
                    </p>
                  </div>
                  <a href="#" type="button" class="collapsable-description-toggle signbuttoninline"
                     @click.prevent="openDescription(productIdx)">
                    {{ $t('quoteProducts.view.view_more') }}
                  </a>
                </template>
                <div v-else-if="product.url" class="col-12 mt-3">
                  <p v-if="product.url">
                    <a :href="product.url" target="_blank" class="pt-2" @click.stop>
                      {{ $t('quoteProducts.view.read_more') }}
                    </a>
                  </p>
                </div>

                <div class="col-12">
                  <hr>
                </div>
              </div>
            </template>

            <div class="d-flex justify-content-center align-items-top mt-5 mb-5">
              <div v-if="hasDiscount" class="d-flex align-items-center justify-content-center">
                <i class="fal fa-3x mr-3 fa-piggy-bank"></i>
                <div>
                  <strong style="text-transform: uppercase;">
                    {{ $t('quoteProducts.view.discount_title') }}
                  </strong>
                  <br>
                  <span style="font-size: 1.5em;" class="text-highlight">
                                    {{
                      $t('quoteProducts.view.discount', {
                        discount: quoteProductData.discount,
                        currency: quoteProductData.currency_name
                      })
                    }}
                                </span>
                </div>
              </div>
              <template
                v-if="!quoteProduct.hideTotals && (!quoteProductData.allows_user_assigned_price || quoteProductData.user_assigned_price)">
                <div v-if="hasDiscount" style="width: 80px; max-width: 5vw;"></div>
                <div class="d-flex align-items-center justify-content-center">
                  <i class="fal fa-3x mr-3 fa-shopping-cart"></i>
                  <div>
                    <strong>
                      {{ $t('quoteProducts.view.price_title') }}
                    </strong>
                    <br>
                    <span style="font-size: 1.5em;">
                                        {{
                        $t('quoteProducts.view.price', {
                          price: quoteProductData.price,
                          currency: quoteProductData.currency_name,
                          vat_suffix: quoteProductData.vat_suffix
                        })
                      }}
                                    </span>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template v-if="quoteOption">
            <div class="mb-5" v-for="(document, idx) in quoteOption.documents">
              <div :id="`pdf-container-${idx}`" class="d-flex justify-content-between p-2 bg-light">
                <div class="font-weight-bold">
                  {{ document.pdf_name }}
                </div>
                <div class="d-flex justify-content-between">
                  <a :href="document.pdf_url" class="btn download" download>
                    <i class="fal fa-download"></i>
                  </a>
                </div>
              </div>
              <pdf-all :pdflink="document.pdf_url" :is-pdf="document.is_pdf"></pdf-all>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
nav.navbar {
  display: flex !important;
}

.header-company-image {
  max-width: 50%;
  max-height: 80px;
  width: auto;
  height: auto;
}

.modal-header {
  position: relative;

  button[aria-expanded="true"] {
    i.when-collapsed {
      display: none;
    }
  }

  button[aria-expanded="false"] {
    i.when-expanded {
      display: none;
    }
  }

  .dropdown-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal-body {
  overflow-y: auto;
  height: calc(100vh - 196px);

  &::v-deep {
    .page-container {
      margin: 5px -16px;
      padding: 0px;

      canvas {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
      }

      &:first-child canvas {
        border-top: 0;
      }
    }
  }
}

.modal-footer {
  justify-content: center;
}

.buttons {
  margin-left: auto;

  a {
    font-size: 1.35rem;
  }

  a.download {
    font-size: 1.40rem;
    margin-right: 1.5rem;
  }
}

button[aria-expanded="true"] .when-collapsed {
  display: none;
}

button[aria-expanded="false"] .when-expanded {
  display: none;
}

.product-specification {
  dt, dd {
    display: inline;
  }

  dd {
    margin-right: 2em;
  }
}

.product-featured {
  max-height: 40vh;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  height: auto;
  object-position: center center;

}

.product-thumbnail {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  height: auto;
  object-position: left top;

}

.collapsable-description {
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height: 10000px;
  position: relative;

  & + .collapsable-description-toggle {
    position: relative;
    top: -20px;

    margin-left: auto;
    margin-right: auto;
  }
}

.collapsable-description-open {
  & + .collapsable-description-toggle {
    display: none;
  }
}

.collapsable-description-collapsed {
  cursor: pointer;
  max-height: 4em;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    text-align: center;
    margin: 0;
    padding: 30px 0;

    pointer-events: none;

    background-image: linear-gradient(to bottom, transparent, white);
  }
}


</style>

<script>

import {v4 as uuidv4} from 'uuid';
import axios from 'axios';

export default {
  props: {
    id: {type: String, default: "product-option-preview"},
    quote: {type: Object, default: null},
    quoteOption: {type: Object, default: null},
    quoteProduct: {type: Object, default: null}
  },
  data() {
    return {
      uuid: uuidv4(),
      openDescriptionForProductIdx: null,
      quoteProductData: null
    }
  },
  computed: {
    selected() {
      if (this.quoteOption || this.quoteProduct) {
        this.uuid = uuidv4();
      }

      return this.uuid;
    },
    hasDiscount() {
      return this.quoteProductData.price !== this.quoteProductData.original_price;
    }
  },
  watch: {
    selected() {
      window.jQuery(`#${this.id}`).modal('show');
      window.jQuery(this.$refs["modal"]).one('hidden.bs.modal', () => {
        this.$emit('modal-closed')
        this.quoteProductData = null;
      });
    },
    async quoteProduct(value){
      if (!value || !this.quote) {
        return null;
      }

      const url = route('quote_products.getFields', [this.quote.key, value.id])
      const response = await axios.get(url);
      this.quoteProductData = response.data;
    }
  },
  methods: {
    openDescription(productIdx) {
      if (this.openDescriptionForProductIdx !== productIdx) {
        this.openDescriptionForProductIdx = productIdx;
      }
    },
  }
}
</script>
