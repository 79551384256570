<template>
  <div class="row">
    <div class="col">
      <div class="row">

        <div class="col-md-7 mb-4">
          <div class="card h-100">
            <div class="card-header">Offertvärden</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <h6>Offertvärde (månad)</h6>
                  <stats-quoted-amount :user-id="userId" period="month" />
                </div>
                <div class="col-md-4">
                  <h6>Offertvärde (kvartal)</h6>
                  <stats-quoted-amount :user-id="userId" period="quarter" />
                </div>
                <div class="col-dm-4">
                  <h6>Offertvärde (år)</h6>
                  <stats-quoted-amount :user-id="userId" period="year" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 mb-4">
          <div class="card h-100">
            <div class="card-header">Aktivitet</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h6>Antal offerter (dag)</h6>
                  <stats-quote-count :user-id="userId" period="day" />
                </div>
                <div class="col-md-6">
                  <h6>Antal offerter (vecka)</h6>
                  <stats-quote-count :user-id="userId" period="week" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-7 mb-4">
          <div class="card h-100">
            <div class="card-header">
              Vunna offerter
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <h6>Offertvärde (månad)</h6>
                  <stats-closed-amount :user-id="userId" period="month" />
                </div>
                <div class="col-md-4">
                  <h6>Offertvärde (kvartal)</h6>
                  <stats-closed-amount :user-id="userId" period="quarter" />
                </div>
                <div class="col-dm-4">
                  <h6>Offertvärde (år)</h6>
                  <stats-closed-amount :user-id="userId" period="year" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 mb-4">
          <div class="card h-100">
            <div class="card-header">
              Hitrate och tid till signering
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h6>
                    Hitrate<br>
                    <small>(senaste 90 dagarna)</small>
                  </h6>
                  <stats-hit-rate :user-id="userId" :days="90" />
                </div>
                <div class="col-md-6">
                  <h6>
                    Genomsnittlig tid till signering<br>
                    <small>(senaste 90 dagarna)</small>
                  </h6>
                  <stats-time-to-sign-average :user-id="userId" :days="90" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <div class="card h-100">
            <div class="card-header">Antal dagar till signering</div>
            <div class="card-body">
              <stats-time-to-sign-distribution :user-id="userId" period="lifetime" :buckets="31" skip-first-day />
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <div class="card h-100">
            <div class="card-header">Hit rate per veckodag</div>
            <div class="card-body">
              <stats-hit-rate-distribution :user-id="userId" period="lifetime" buckets="weekday" />
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-4">
          <div class="card h-100">
            <div class="card-header">Hit rate per timme</div>
            <div class="card-body">
              <stats-hit-rate-distribution :user-id="userId" period="lifetime" buckets="hour_of_day" />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userId: { type: Number, default: null },
  },
}
</script>
