export default () => {
    window.jQuery('input[data-navigate-on-checked-url]').each((index, input) => {
        const url = input.getAttribute("data-navigate-on-checked-url");

        $(input).on('change', (ev) => {
            if (input.checked) {
                document.location = url;
            }
        });
    });

    window.jQuery('select[data-navigate-on-selected]').each((index, select) => {
        $(select).on('change', (ev) => {
            document.location = select.options[select.selectedIndex].getAttribute("data-navigate-on-selected-url");
        });
    });
}
