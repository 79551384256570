<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{textBlocks.upload_img}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Stäng">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body">
            <div class="form-group">
              <p>
                {{textBlocks.submit_document}}
              </p>

              <form method="post" :action="submitUrl" enctype="multipart/form-data" @submit="submitting = true;">
                <div class="mb-3">
                  <div class="input-group">
                    <input type="file" class="form-control" required name="file" accept="application/pdf, *.pdf, image/png, image/jpeg, *.png, *.jpg, *.jpeg" @change="onFileChanged">
                  </div>
                </div>

                <div v-if="isFileSelected" class="mb-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">{{$t('upload.desc')}}</div>
                    </div>
                    <input type="fileName" class="form-control" required name="name" v-model="fileName">
                  </div>
                </div>

                <button type="submit" class="btn btn-secondary" :disabled="submitting">
                  <i class="fas fa-paper-plane"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
form button {
    width: 100%;
}
</style>

<script>
export default {
    props: {
        quoteKey: String,
        textBlocks: Object
    },
    data() {
        return {
            fileName: undefined,
            isFileSelected: false,
            submitting: false
        };
    },
    methods: {
        onFileChanged(event) {
            const file = event.target.files[0];
            if (file && !this.isFileSelected) {
                this.isFileSelected = true;
                this.fileName = file.name.replace(/\.[^\.]+$/, '');
            }
        }
    },
    computed: {
        submitUrl() {
            return `/quote/${this.quoteKey}/documents`;
        }
    }
}
</script>
