<template>
    <charts-horizontal-bar v-if="distribution !== undefined"  :chartdata="chartdata" :options="options" />
    <div v-else class="spinner-border" role="status"><span class="sr-only">Laddar</span></div>
</template>

<script>
let numberFormat = Intl.NumberFormat('sv-SE', { style: 'percent' });
let backgroundColors = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(255, 205, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(201, 203, 207, 0.2)"
];
let borderColors = [
    "rgb(255, 99, 132)",
    "rgb(255, 159, 64)",
    "rgb(255, 205, 86)",
    "rgb(75, 192, 192)",
    "rgb(54, 162, 235)",
    "rgb(153, 102, 255)",
    "rgb(201, 203, 207)"
];
export default {
    props: {
        userId: { required: false, default: null },
        buckets: { required: true, type: Number },
        period: { required: true },
        skipFirstDay: { type: Boolean, default: false }
    },
    data() {
        return {
            distribution: undefined
        }
    },
    computed: {
        chartdata() {
            if (this.distribution === undefined) { return; }

            return {
                labels: this.distribution.map((obj) => obj.label),
                datasets: [{
                    borderWidth: 1,
                    label: "Antal dagar till signering",
                    data: this.distribution.map((obj) => obj.value),
                    backgroundColor: this.distribution.map((_obj, idx) => backgroundColors[idx % backgroundColors.length] ),
                    borderColor: this.distribution.map((_obj, idx) => borderColors[idx % borderColors.length] )
                }]
            };
        },
        options() {
            const suggestedMax = this.distribution ?
              this.distribution.reduce((memo, obj) => (obj.value + 0.1) > memo ? (obj.value + 0.1) : memo, 0) : 1;

            let options = {};
            options.legend = { display: false };
            options.responsive = true;
            options.maintainAspectRatio = false;
            options.scales = {};
            options.scales.xAxes = [{
                display: true,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: suggestedMax,
                    callback: (n) => numberFormat.format(n)
                }
            }];
            options.tooltips = {
                callbacks: {
                    label: (toolTipItem, _data) => numberFormat.format(toolTipItem.value)
                }
            };
            return options;
        }
    },
    async mounted() {
        await this.loadData();
    },
    watch: {
        userId: 'loadData',
        buckets: 'loadData',
        period: 'loadData'
    },
    methods: {
        async loadData() {
            const url = `/stats/time_to_sign_distribution`;
            const parameters = {
                user_id: this.userId,
                period: this.period,
                buckets: this.buckets
            };
            const resp = await axios.get(url, {params: parameters});
            this.distribution = resp.data.slice(this.skipFirstDay ? 1 : 0);
        }
    }
}
</script>

<style scoped>
</style>
