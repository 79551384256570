<template>
  <div>
    <p>
      Skicka meddelande till {{customerName}} (<template v-if="isSms">{{customerPhone}}<template v-if="isEmail">, </template></template><template v-if="isEmail">{{customerEmail}}</template>):</p>

    <axios-form method="post" :action="route('quotes.vibe', quoteId)" @success="$page.alert('Ditt meddelande har skickats!'); $page.reload()" @failure="$page.alert('Något gick fel, försök igen!')">
      <div class="input-group mb-3">
        <label :for="`{uuid}-send_type`" class="input-group-prepend"><div class="input-group-text">Utskicksmetod</div></label>
        <select :id="`{uuid}-send_type`" class="custom-select" required name="send_type" v-model="selectedSendType">
          <option v-for="ast in allowedSendTypes" :key="ast.id" :value="ast.id">{{ast.label}}</option>
        </select>
      </div>
      <div v-if="isEmail" class="input-group mb-3">
        <label :for="`{uuid}-subject`" class="input-group-prepend"><div class="input-group-text">Ämnesrad för e-post</div></label>
        <input :id="`{uuid}-subject`" class="form-control" type="text" required name="subject" v-model="subject">
      </div>
      <textarea class="form-control mb-3" rows=5 maxlength=280 required name="message" v-model="message"></textarea>
      <button type="submit" class="btn btn-primary">Skicka</button>
    </axios-form>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default {
  props: {
    quoteId: { type: Number, required: true },
    customerName: { type: String, required: true },
    sendType: { type: String, required: true },
    customerEmail: { type: String, default: null },
    customerPhone: { type: String, default: null },
    defaultSubject: { type: String, required: null },
    defaultMessage: { type: String, required: null }
  },
  data() {
    let selectedSendType = null;
    if (this.sendType !== 'none') {
      selectedSendType = this.sendType;
    } else if (this.customerPhone && this.customerEmail) {
      selectedSendType = 'dual';
    } else if (this.customerPhone) {
      selectedSendType = 'sms';
    } else if (this.customerEmail) {
      selectedSendType = 'email';
    }

    return {
      selectedSendType,
      uuid: uuidv4(),
      subject: this.defaultSubject ?? '',
      message: this.defaultMessage ?? '',
      submitting: false
    }
  },
  computed: {
    allowedSendTypes() {
      const types = [];

      if (this.customerPhone && this.customerEmail) {
        types.push({ id: 'dual', label: 'SMS & E-post' });
      }

      if (this.customerPhone) {
        types.push({ id: 'sms', label: 'SMS' });
      }

      if (this.customerEmail) {
        types.push({ id: 'email', label: 'E-post' });
      }

      return types;
    },
    isSms() {
      return this.selectedSendType === 'sms' || this.selectedSendType === 'dual';
    },
    isEmail() {
      return this.selectedSendType === 'email' || this.selectedSendType === 'dual';
    }
  },
  methods: {
    async onSubmit() {
      const { data: newNote } = await axios.post(route('quote.notes.store', this.quoteId), {
        note: this.note,
        users_to_notify: this.users
      });
      this.$emit('note-added', newNote);
      this.note = null;
      this.users = null;
    }
  }
}
</script>
