<template>
    <div>
        <div v-if="editing" class="container-fluid d-flex shadow py-2" style="border:1px solid var(--primary-color); background:white">
            <form class="form" @submit.prevent="submit">
                <div class="container-fluid">
                    <div class="row">
                        <b-form-group class="col-6">
                            <label for="name">Systemnamn</label>
                            <b-form-input id="name" v-model="name" type="text" required :readonly="isFixed"/>
                        </b-form-group>
                        <b-form-group class="col-6">
                            <label for="label">Fältnamn</label>
                            <b-form-input id="label" v-model="label" type="text" required/>
                        </b-form-group>
                        <b-form-group class="col-6">
                            <label for="placeholder">Typ</label>
                            <b-form-select id="type" v-model="inputType" required :disabled="isFixed">
                                <b-form-select-option key="-1" :value="null" disabled>Select</b-form-select-option>
                                <b-form-select-option v-for="(type, index) in inputTypes" :key="index" :value="type.value">{{type.label}}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group class="col-6">
                            <label for="placeholder">Visningsnamn</label>
                            <b-form-input id="placeholder" v-model="placeholder" type="text" />
                        </b-form-group>
                    </div>
                </div>
                <b-form-checkbox v-model="enabled" name="check-button" switch>
                    Används <i v-bind:class="{'active-color':enabled}" class="fal fa-square-check"></i>
                </b-form-checkbox>
                <b-form-checkbox  v-model="hideForContact" switch>
                    Visa ej för kontakten <i v-bind:class="{'active-color':!hideForContact}" class="fal fa-eye"></i>
                </b-form-checkbox>
                <b-form-checkbox  v-model="required" switch>
                    Obilgatorisk för kontakten vid order <i v-bind:class="{'active-color':required}" class="fal fa-octagon-exclamation"></i>
                </b-form-checkbox>
                <b-form-checkbox v-model="editableByContact" switch>
                    Editerbar för kontakten vid order <i v-bind:class="{'active-color':editableByContact}" class="fal fa-pencil"></i>
                </b-form-checkbox>

                <hr/>

                <div class="d-flex" style="gap:5px">
                    <button type="submit" class="btn btn-primary btn-sm">Spara</button>
                    <button type="button" class="btn btn-secondary btn-sm" @click="$emit('cancelled')">Avbryt</button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped lang="scss">

.active-color {
    color: var(--primary-color);
}
</style>

<script>
export default {
    name: "QuoteField",
    props: {
        attribute: Object,
        inputTypes: Array
    },
    mounted() {
        this.name = this.attribute.name;
        this.label = this.attribute.label;
        this.inputType = this.attribute.type;
        this.fieldType = this.attribute.fieldType;
        this.enabled = this.attribute.enabled;
        this.required = this.attribute.required;
        this.placeholder = this.attribute.placeholder;
        this.hideForContact = this.attribute.hideForContact;
        this.editableByContact = this.attribute.editableByContact;
        this.editing = true;
    },
    computed: {
        isFixed() {
            return this.fieldType === "fixed";
        }
    },
    methods: {
        submit() {
            let payload = this.attribute;
            payload.name = this.name;
            payload.label = this.label;
            payload.type = this.inputType;
            payload.fieldType = this.fieldType;
            payload.enabled = this.enabled;
            payload.required = this.required;
            payload.placeholder = this.placeholder;
            payload.hideForContact = this.hideForContact;
            payload.editableByContact = this.editableByContact;

            this.$emit("submitted", payload);
            this.editing = false;
        }
    },
    data: function() {
        return {
            name: undefined,
            label: undefined,
            inputType: undefined,
            fieldType: undefined,
            enabled: undefined,
            required: undefined,
            placeholder: undefined,
            hideForContact: undefined,
            editableByContact: undefined,
            editing: false
        };
    }
}
</script>

