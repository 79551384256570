<template>
  <fieldset>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Miljö</div>
      </div>
      <select class="custom-select" required v-model="environment" @change="onUpdate">
        <option value="production">Produktionsmiljö</option>
        <option value="playground">Testmiljö</option>
      </select>
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Merchant Id</div>
      </div>
      <input type="text" class="form-control" required v-model="merchantId" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Secret</div>
      </div>
      <input type="text" class="form-control" required v-model="secret" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Partner Key</div>
      </div>
      <input type="text" class="form-control" required v-model="partnerKey" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Länk till era köpvilkor</div>
      </div>
      <input type="url" class="form-control" required v-model="termsUrl" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: Adressrad 1</div>
      </div>
      <input type="text" class="form-control" required v-model="fieldAddress1" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: Adressrad 2</div>
      </div>
      <input type="text" class="form-control" v-model="fieldAddress2" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: Postnr</div>
      </div>
      <input type="text" class="form-control" required v-model="fieldZipCode" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: City</div>
      </div>
      <input type="text" class="form-control" required v-model="fieldCity" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: ID. nr</div>
      </div>
      <input type="text" class="form-control" v-model="fieldIdNumber" @input="onUpdate">
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <div class="input-group-text">Fältnamn: CheckoutOrderId </div>
      </div>
      <input type="text" class="form-control" v-model="fieldCheckoutOrderId" @input="onUpdate">
    </div>
  </fieldset>
</template>

<script>
export default {
  emits: [
    'input'
  ],
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      merchantId: null,
      secret: null,
      partnerKey: null,
      termsUrl: null,
      environment: null,
      fieldAddress1: null,
      fieldAddress2: null,
      fieldZipCode: null,
      fieldCity: null,
      fieldIdNumber: null,
      fieldCheckoutOrderId: null,
    }
  },
  computed: {
    serializedValue() {
      return {
        merchant_id: this.merchantId,
        secret: this.secret,
        partner_key: this.partnerKey,
        terms_url: this.termsUrl,
        environment: this.environment,
        field_address1: this.fieldAddress1,
        field_address2: this.fieldAddress2,
        field_zip_code: this.fieldZipCode,
        field_city: this.fieldCity,
        field_id_number: this.fieldIdNumber,
        field_checkout_order_id: this.fieldCheckoutOrderId,
      };
    }
  },
  watch: {
    value: {
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
          this.merchantId = this.value.merchant_id;
          this.secret = this.value.secret;
          this.partnerKey = this.value.partner_key;
          this.termsUrl = this.value.terms_url;
          this.environment = this.value.environment;
          this.fieldAddress1 = this.value.field_address1;
          this.fieldAddress2 = this.value.field_address2;
          this.fieldZipCode = this.value.field_zip_code;
          this.fieldCity = this.value.field_city;
          this.fieldIdNumber = this.value.field_id_number;
          this.fieldCheckoutOrderId= this.value.field_checkout_order_id;

          if (!this.fieldAddress1) {
            this.fieldAddress1 = 'Adress';
          }

          if (!this.fieldZipCode) {
            this.fieldZipCode = 'Postnummer';
          }

          if (!this.fieldCity) {
            this.fieldCity = 'Ort';
          }

          if (JSON.stringify(this.value) !== JSON.stringify(this.serializedValue)) {
            this.onUpdate();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('input', this.serializedValue);
    },
  }
};
</script>
