<template>
  <data-analysis-quotes-selector @filterConfig="onFilterConfig">
    <template #header>
      <div class="col-md-12 mb-3 row no-gutters">
        <div class="col">
        </div>
        <div class="col-6 text-center">
          <h2>Analys</h2>
        </div>
        <div class="col">
          <a v-if="excelExportUrl !== null" class="btn btn-primary float-right" :href="excelExportUrl"><i class="fal fa-file-excel"></i> Exportera </a>
        </div>
      </div>
    </template>

    <template #after-filters>
      <div v-if="isLoaded" class="card mb-4">
        <div class="card-header">Sammanställning</div>
        <div class="card-body">
          <div v-if="isLoaded" class="row">
            <div class="col-md-4 text-center">
              <h5>Antal</h5>
              <p>{{count}}</p>
            </div>
            <div class="col-md-4 text-center">
              <h5>Medelvärde</h5>
              <p>{{average}}</p>
            </div>
            <div class="col-md-4 text-center">
              <h5>Summa</h5>
              <p>{{sum}}</p>
            </div>
          </div>
          <div v-else class="text-center">
            <b-spinner label="Laddar.."></b-spinner>
          </div>
        </div>
      </div>
    </template>
  </data-analysis-quotes-selector>
</template>

<style lang="scss" scoped>
</style>

<script>
const LOCALE = 'sv-SE';
const CURRENCY = 'SEK';
const CURRENCY_FORMATTER = new Intl.NumberFormat(LOCALE, { style: 'currency', currency: CURRENCY });

import axios from 'axios';

export default {
  props: {
  },
  data() {
    return {
      isLoaded: false,
      count: null,
      sum: null,
      average: null,
      excelExportUrl: null,
    };
  },
  computed: {
  },
  methods: {
    async onFilterConfig(filterConfig) {
      this.isLoaded = false;
      const { data: stats } = await axios.get(route("data_analysis.quotes.stats", {
        _query: { filter_config: filterConfig }
      }));

      this.count = stats.count;
      this.sum = CURRENCY_FORMATTER.format(stats.sum);
      this.average = CURRENCY_FORMATTER.format(stats.count !== 0 ? Math.round(stats.sum / stats.count) : 0);
      this.excelExportUrl = this.setExcelExportUrl(filterConfig);
      this.isLoaded = true;
    },
    setExcelExportUrl(filterConfig){
      return route("data_analysis.quotes.export.excel", {
        _query: {filter_config: filterConfig}
      });
    }
  }
}
</script>
