<template>
<div>
  <fieldset class="mt-3">
    <template v-if="initialized">
      <div v-for="page, idx in existingPages" :key="page.uuid" class="my-3">
        <h5>
          {{$t('readmore.page')}} {{idx + 1}}

          <a type="button" class="text-danger ml-2" @click="page.deleted = true" :title="$t('general.remove')">
            <i class="fal fa-trash-alt"></i>
          </a>
        </h5>

        <input type="hidden" :name="`read_more_pages[${page.uuid}][id]`" :value="page.id">
        <input type="hidden" :name="`read_more_pages[${page.uuid}][_deleted]`" value="0">
        <input type="hidden" :name="`read_more_pages[${page.uuid}][_image_from_template_id]`" :value="page.image_from_template_id ? page.image_from_template_id : 0">

        <div class="mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <template v-if="page.image_url">
                  {{$t('readmore.replaceImage')}}
                </template>
                <template v-else>
                  {{$t('readmore.image')}} *
                </template>
              </div>
            </div>
            <image-cropper-input
                class="form-control h-auto"
                :required="!page.image_url"
                :name="`read_more_pages[${page.uuid}][image]`"
                :current-image="page.image_url"
                :aspect-ratio="page.is_fullwidth ? 3/2 : 1/1"
            ></image-cropper-input>
          </div>
        </div>


        <div class="mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">{{$t('readmore.title')}} *</div>
            </div>
            <input type="text" class="form-control" :name="`read_more_pages[${page.uuid}][title]`" required v-model="page.title">
          </div>
        </div>

        <div class="mb-3">
          <div class="input-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" :id="`page-${page.uuid}-id_fullwidth`" :name="`read_more_pages[${page.uuid}][is_fullwidth]`" v-model="page.is_fullwidth">
              <label class="custom-control-label" :for="`page-${page.uuid}-id_fullwidth`">
                {{$t('readmore.isFullwidth')}}
              </label>
            </div>
            <label></label>
          </div>
        </div>

        <h6 class="font-weight-bold">{{$t('readmore.pageContentBlocks')}}</h6>
        <div v-for="(block, idx) in page.blocks.filter((b) => !b.deleted)" :key="block.uuid">
          <h6>
            {{$t('readmore.pageContentBlock')}} {{ idx + 1 }}

            <a type="button" class="text-danger ml-2" :title="$t('general.remove')" @click="block.deleted = true" v-if="page.blocks.length > 1">
              <i class="fal fa-trash-alt"></i>
            </a>
          </h6>

          <input type="hidden" :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][id]`" :value="block.id">
          <input type="hidden" :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][_deleted]`" value="0">
          <input type="hidden" :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][_image_from_template_id]`" :value="block.image_from_template_id ? block.image_from_template_id : 0">

          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <template v-if="block.image_url">
                    {{$t('readmore.replaceImage')}}
                  </template>
                  <template v-else>
                    {{$t('readmore.image')}}
                  </template>
                </div>
              </div>
              <image-cropper-input
                  class="form-control h-auto"
                  :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][image]`"
                  :current-image="block.image_url"
              ></image-cropper-input>
            </div>
          </div>

          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">{{$t('readmore.title')}}</div>
              </div>
              <input type="text" class="form-control"
                     :placeholder="$t('general.placeholderOptional')"
                     :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][title]`"
                     v-model="block.title"
              >
            </div>
          </div>

          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">{{$t('readmore.body')}}</div>
              </div>
              <textarea rows="8" class="form-control"
                        :placeholder="$t('general.placeholderOptional')"
                        :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][body]`"
                        v-model="block.body"
              ></textarea>
            </div>
          </div>

          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">{{$t('readmore.youtube_url')}}</div>
              </div>
              <input type="text" class="form-control" pattern="https://youtu.be/(p-)?[A-z0-9]+"
                     :placeholder="`https://youtu.be/dQw4w9WgXcQ ${$t('general.placeholderOptional')}`"
                     :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][youtube_url]`"
                     v-model="block.youtube_url"
              >
            </div>
          </div>

          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">{{$t('readmore.cta_title')}}</div>
              </div>
              <input type="text" class="form-control"
                     :placeholder="$t('readmore.cta_placeholderOptional')"
                     :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][cta_title]`"
                     v-model="block.cta_title"
              >
            </div>
          </div>

          <div class="mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">{{$t('readmore.cta_url')}}</div>
              </div>
              <input type="text" class="form-control" pattern="https?://.+"
                     :placeholder="`https://example.com/ ${$t('readmore.cta_placeholderOptional')}`"
                     :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][cta_url]`"
                     v-model="block.cta_url"
              >
            </div>
          </div>

        </div>

        <div v-for="block in page.blocks.filter((b) => b.deleted)" :key="block.uuid">
          <input type="hidden" :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][id]`" :value="block.id">
          <input type="hidden" :name="`read_more_pages[${page.uuid}][content_blocks][${block.uuid}][_deleted]`" value="1">
        </div>


        <div class="input-group">
          <b-form-select v-model="selectedBlockTemplate" :options="blockTemplateOptions"></b-form-select>
          <button type="button" class="btn btn-secondary" @click="addBlock(page, selectedBlockTemplate); selectedBlockTemplate = null">{{ $t('readmore.addPageContentBlock') }}</button>
        </div>
      </div>

      <div v-for="page in deletedPages" :key="page.uuid">
        <input type="hidden" :name="`read_more_pages[${page.uuid}][id]`" :value="page.id">
        <input type="hidden" :name="`read_more_pages[${page.uuid}][_deleted]`" value="1">
      </div>

      <hr>

      <div class="input-group">
        <b-form-select v-model="selectedPageTemplate" :options="pageTemplateOptions"></b-form-select>
        <button type="button" class="btn btn-secondary" @click="addPage(selectedPageTemplate); selectedPageTemplate = null">{{ $t('readmore.addPage') }}</button>
      </div>
    </template>
    <template v-else>
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">{{$t('general.loading')}}...</span>
      </div>
    </template>
  </fieldset>
</div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    quoteProfileId: { type: Number }
  },
  data() {
    return {
      templatePages: undefined,
      templateBlocks: undefined,
      pages: undefined,
      selectedPageTemplate: null,
      selectedBlockTemplate: null
    };
  },
  computed: {
    initialized() {
      return this.templatePages !== undefined && this.templateBlocks !== undefined && this.pages !== undefined;
    },
    existingPages() {
      return this.pages.filter((page) => !page.deleted);
    },
    deletedPages() {
      return this.pages.filter((page) => page.deleted);
    },
    pageTemplateOptions() {
      const options = [];
      options.push({
        text: this.$t('readmore.noTemplateSelected'),
        value: null
      });

      for (const page of this.templatePages) {
        options.push({
          text: `${this.$t('readmore.templatePrefix')} ${page.title}`,
          value: page
        });
      }

      return options;
    },
    blockTemplateOptions() {
      const options = [];
      options.push({
        text: this.$t('readmore.noTemplateSelected'),
        value: null
      });

      for (const block of this.templateBlocks) {
        options.push({
          text: `${this.$t('readmore.templatePrefix')} ${block.title}`,
          value: block
        });
      }

      return options;
    }
  },
  async mounted() {
    await this.loadTemplatePages();
    await this.loadTemplateContentBlocks();
    await this.loadPages();
  },
  methods: {
    async loadTemplatePages() {
      let response = await axios.get(`/read_more_page_templates`);
      const pages = response.data.data;
      for (const page of pages) {
        let blocksResp = await axios.get(`/read_more_page_templates/${page.id}/content_blocks`);
        page.blocks = blocksResp.data.data;
      }
      this.templatePages = pages;
    },
    async loadTemplateContentBlocks() {
      let response = await axios.get(`/read_more_page_content_block_templates`);
      this.templateBlocks = response.data.data;
    },
    async loadPages() {
      let response = await axios.get(`/quote_profile/${this.quoteProfileId}/read_more_pages`);
      const pages = response.data.data;
      for (const page of pages) {
        page.uuid = uuidv4();
        page.deleted = false;

        const blocksResp = await axios.get(`/quote_profile/${this.quoteProfileId}/read_more_pages/${page.id}/content_blocks`);
        page.blocks = blocksResp.data.data;
        page.blocks.forEach((block) => {
          block.uuid = uuidv4();
          block.deleted = false;
        });
      }
      this.pages = pages;
    },
    addPage(template) {
      this.pages.push({
        uuid: uuidv4(),
        id: 0,
        title: template ? template.title : null,
        image_url: template ? template.image_url : undefined,
        image_from_template_id: (template && template.image_url) ? template.id : 0,
        is_fullwidth: true,
        blocks: [],
        deleted: false
      });
      const page = this.pages[this.pages.length - 1];
      if (template) {
        for (const blockTemplate of template.blocks) {
          this.addBlock(page, blockTemplate);
        }
      }

      if (page.blocks.length == 0) {
        this.addBlock(page);
      }
    },
    addBlock(page, template) {
      page.blocks.push({
        uuid: uuidv4(),
        id: 0,
        title: template ? template.title : undefined,
        body: template ? template.body : undefined,
        image_url: template ? template.image_url : undefined,
        image_from_template_id: (template && template.image_url) ? template.id : 0,
        youtube_url: template ? template.youtube_url : undefined,
        cta_title: template ? template.cta_title : undefined,
        cta_url: template ? template.cta_url : undefined,
        deleted: false
      });
    }
  }
}
</script>
