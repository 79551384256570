/**
 * Load project-wide dependencies
 */
import "./bootstrap";
import Vue from "vue";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap";
import Alpine from "alpinejs";
import intlTelInput from "intl-tel-input/intlTelInputWithUtils";
import axios from "axios";

window.Vue = Vue;
window.$ = window.jQuery = $;
window.Popper = Popper;
window.Alpine = Alpine;
window.IntlTelInput = intlTelInput;
window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const activeCompanyIdMeta = document.head.querySelector(
    'meta[name="active-company-id"]'
);
if (activeCompanyIdMeta) {
    window.axios.defaults.headers.common["X-Salesbyte-Active-Company-Id"] =
        activeCompanyIdMeta.content;
}

/**
 * (Optional) Livewire-Vue Bridge
 *   If you still need to conditionally import livewire-vue only when `window.livewire` is present,
 *   you can do a dynamic import:
 */

if (window.livewire) {
    import("livewire-vue");
}

/**
 * Sentry Setup
 *   Convert `process.env.MIX_*` to `import.meta.env.VITE_*`.
 */
import * as Sentry from "@sentry/vue";
import {
    BrowserTracing,
    BrowserProfilingIntegration,
    Replay,
} from "@sentry/vue";

const {
    VITE_SENTRY_DSN,
    VITE_SENTRY_RELEASE,
    VITE_SENTRY_ENVIRONMENT,
    VITE_SENTRY_TRACES_SAMPLE_RATE,
    VITE_SENTRY_PROFILES_SAMPLE_RATE,
} = import.meta.env;

if (VITE_SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: VITE_SENTRY_DSN,
        release: VITE_SENTRY_RELEASE,
        environment: VITE_SENTRY_ENVIRONMENT,

        integrations: [
            new BrowserTracing(),
            new BrowserProfilingIntegration(),
            new Replay(),
        ],

        // Performance monitoring
        tracesSampleRate: parseFloat(VITE_SENTRY_TRACES_SAMPLE_RATE) || 0.0,
        profilesSampleRate: parseFloat(VITE_SENTRY_PROFILES_SAMPLE_RATE) || 0.0,

        tracePropagationTargets: [
            /^http:\/\/localhost/,
            /^http:\/\/127.0.0.1/,
            /^\//,
            /^https:\/\/[^\.]+\.salesbyte\.se/,
        ],

        replaysSessionSampleRate: 0.001,
        replaysOnErrorSampleRate: 1.0,

        // Track Vue components
        trackComponents: true,
    });

    // Add axios context to Sentry
    Sentry.addEventProcessor((event, hint) => {
        if (hint && hint.originalException) {
            const ex = hint.originalException;
            if (ex.isAxiosError && ex.response) {
                event.contexts = event.contexts || {};
                event.contexts.axios = {
                    Request: {
                        method: ex.response.config.method,
                        url: ex.response.config.url,
                    },
                    Response: {
                        status: ex.response.status,
                    },
                };
            }
        }
        return event;
    });
}

/**
 * PDF.js (Vue 2 + pdfjs-dist legacy build)
 */
import * as pdfjs from "pdfjs-dist/legacy/build/pdf.js";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/legacy/build/pdf.worker.js",
    import.meta.url
);
window.pdfjs = pdfjs;

/**
 * BootstrapVue
 */
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

/**
 * PortalVue
 */
import PortalVue from "portal-vue";
Vue.use(PortalVue);

/**
 * Vue Signature Pad (Note: This library may require Vue 2.7+ or Vue 3 for certain versions)
 */
import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

/**
 * SweetAlert2
 */
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

/**
 * Vue I18n
 */
import VueInternationalization from "vue-i18n";
import Locale from "./vue-i18n-locales.generated";

Vue.use(VueInternationalization);

const lang = document.documentElement.lang.substr(0, 2);
const i18n = new VueInternationalization({
    locale: lang,
    fallbackLocale: "sv",
    messages: Locale,
});

/**
 * Ziggy Vue
 */
import { ZiggyVue } from "ziggy";
Vue.use(ZiggyVue);

/**
 * vue-qr
 */
import VueQr from "vue-qr";
Vue.component("vue-qr", VueQr);

/**
 * vue-select
 */
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

/**
 * Flicking
 */
import Flicking from "@egjs/vue-flicking";
Vue.use(Flicking);

// Import components
import AxiosForm from "./components/axiosForm.vue";
import PdfMultiView from "./components/pdfMultiView.vue";
import PdfView from "./components/pdfView.vue";
import PdfAll from "./components/pdfAll.vue";
import PrimarySignaturePrompt from "./components/primarySignaturePrompt.vue";
import BankIdSign from "./components/bankIdSign.vue";
import OnScreenSign from "./components/onScreenSign.vue";
import ExternalSign from "./components/externalSign.vue";
import SimpleSign from "./components/simpleSign.vue";
import BankIdV2Sign from "./components/bankIdV2/sign.vue";
import BankIdV2Prompt from "./components/bankIdV2/prompt.vue";
import QuoteProductPayment from "./components/quoteProductPayment.vue";
import QuotesIndex from "./components/quotes/index.vue";
import QuotesContactInput from "./components/quotes/contact-input.vue";
import QuotesCreateMultiDropFile from "./components/quotes/create-multi-drop-file.vue";
import QuotesDuplicateValidatedInput from "./components/quotes/duplicate-validated-input.vue";
import QuoteSignaturePoller from "./components/quotes/signature-poller.vue";
import QuoteEditTags from "./components/quotes/edit-tags.vue";
import QuoteMessagesCreate from "./components/quote_messages/create.vue";
import QuotesProjectApproval from "./components/quotes/project_approval.vue";
import ExpandableCard from "./components/expandableCard.vue";
import QuotesOptionsView from "./components/quotes/options/view.vue";
import QuotesProductsCreate from "./components/quotes/products/create.vue";
import QuotesProductsCreateContacts from "./components/quotes/products/create-contacts.vue";
import QuotesProductsEdit from "./components/quotes/products/edit.vue";
import QuotesProductsView from "./components/quotes/products/view.vue";
import QuotesProductsCreateMulti from "./components/quotes/products/create-multi.vue";
import QuotesProductsAlternativeEditor from "./components/quotes/products/alternative-editor.vue";
import QuotesProductsSignableEditor from "./components/quotes/products/signable-editor.vue";
import ProductsEditor from "./components/products/editor.vue";
import ProductsSearch from "./components/products/search.vue";
import SurveysEditor from "./components/surveys/editor.vue";
import SurveysInput from "./components/surveys/input.vue";
import SurveysPendingPopup from "./components/surveys/pending-popup.vue";
import SurveysData from "./components/surveys/data.vue";
import ProductsBundlesEditor from "./components/products/bundles/editor.vue";
import IntegrationsEditor from "./components/integrations/editor.vue";
import QuotesModalsAddSignature from "./components/quotes/modals/add_signature.vue";
import QuotesModalsInvite from "./components/quotes/modals/invite.vue";
import QuotesDocumentsView from "./components/quotes/documents/view.vue";
import QuoteDocumentsModalsCustomerUpload from "./components/quote_documents/modals/customer_upload.vue";
import QuoteChatMessagesViewLatest from "./components/quote_chat_messages/view-latest.vue";
import QuoteChatMessagesModal from "./components/quote_chat_messages/modal.vue";
import QuoteChatMessagesChat from "./components/quote_chat_messages/chat.vue";
import QuoteChatsIndex from "./components/quote_chats/index.vue";
import GoogleTranslateLanguageSelect from "./components/google_translate/language_select.vue";
import LeadsForm from "./components/leads/form.vue";
import LeadsFieldsetCaparolBasic from "./components/leads/fieldset/caparol/basic.vue";
import LeadsIndex from "./components/leads/index.vue";
import TermsFieldset from "./components/terms/fieldset.vue";
import TermsShow from "./components/terms/show.vue";
import ImageCarouselItemsFieldset from "./components/image_carousel_items/fieldset.vue";
import ReadmoreV2Fieldset from "./components/readmore_v2/fieldset.vue";
import ReadmoreV2View from "./components/readmore_v2/view.vue";
import ModelAuthenticationView from "./components/model_authentication/view.vue";
import CallBack from "./components/viewComponents/callBack.vue";
import SplashScreen from "./components/viewComponents/splashScreen.vue";
import ViewHeader from "./components/viewComponents/viewHeader.vue";
import UspBlock from "./components/viewComponents/uspBlock.vue";
import ReadMore from "./components/viewComponents/readMore.vue";
import StatsQuotedAmount from "./components/stats/quotedAmount.vue";
import StatsClosedAmount from "./components/stats/closedAmount.vue";
import StatsQuoteCount from "./components/stats/quoteCount.vue";
import StatsTimeToSignAverage from "./components/stats/timeToSignAverage.vue";
import StatsTimeToSignDistribution from "./components/stats/timeToSignDistribution.vue";
import StatsHitRate from "./components/stats/hitRate.vue";
import StatsHitRateDistribution from "./components/stats/hitRateDistribution.vue";
import StatsDashboard from "./components/stats/dashboard.vue";
import ChartsBar from "./components/charts/bar.vue";
import ChartsHorizontalBar from "./components/charts/horizontalBar.vue";
import DataAnalysisStats from "./components/data_analysis/stats.vue";
import DataAnalysisQuotes from "./components/data_analysis/quotes.vue";
import DataAnalysisQuotesSelector from "./components/data_analysis/quotesSelector.vue";
import TiptapEditor from "./components/tiptapEditor.vue";
import CalendlyPopup from "./components/calendly/popup.vue";
import MeetingRequestsPopup from "./components/meeting_requests/popup.vue";
import BilettoPopup from "./components/billetto/popup.vue";
import BilettoInline from "./components/billetto/inline.vue";
import BillectaAccountsRetrieval from "./components/billecta/accounts-retrieval.vue";
import PoweredBySalesbyte from "./components/poweredBySalesbyte.vue";
import ImageCropperInput from "./components/image_cropper/input.vue";
import OrganisationSelector from "./components/organisationSelector.vue";
import ImgBadge from "./components/imgBadge.vue";
import PhoneInput from "./components/phone-input.vue";
import DeleteCompanyUserBtn from "./components/delete-company-user-button.vue";
import QuoteFieldset from "./components/quote_fieldset/index.vue";
import QuoteField from "./components/quote_fieldset/field.vue";

// Register components
Vue.component("axios-form", AxiosForm);
Vue.component("pdf-multi-view", PdfMultiView);
Vue.component("pdf-view", PdfView);
Vue.component("pdf-all", PdfAll);
Vue.component("primary-signature-prompt", PrimarySignaturePrompt);
Vue.component("bank-id-sign", BankIdSign);
Vue.component("on-screen-sign", OnScreenSign);
Vue.component("external-sign", ExternalSign);
Vue.component("simple-sign", SimpleSign);
Vue.component("bank-id-v2-sign", BankIdV2Sign);
Vue.component("bank-id-v2-prompt", BankIdV2Prompt);
Vue.component("quote-product-payment", QuoteProductPayment);
Vue.component("quotes-index", QuotesIndex);
Vue.component("quotes-contact-input", QuotesContactInput);
Vue.component("quotes-create-multi-drop-file", QuotesCreateMultiDropFile);
Vue.component(
    "quotes-duplicate-validated-input",
    QuotesDuplicateValidatedInput
);
Vue.component("quote-signature-poller", QuoteSignaturePoller);
Vue.component("quote-edit-tags", QuoteEditTags);
Vue.component("quote-messages-create", QuoteMessagesCreate);
Vue.component("quotes-project-approval", QuotesProjectApproval);
Vue.component("expandable-card", ExpandableCard);
Vue.component("quotes-options-view", QuotesOptionsView);
Vue.component("quotes-products-create", QuotesProductsCreate);
Vue.component("quotes-products-create-contacts", QuotesProductsCreateContacts);
Vue.component("quotes-products-edit", QuotesProductsEdit);
Vue.component("quotes-products-view", QuotesProductsView);
Vue.component("quotes-products-create-multi", QuotesProductsCreateMulti);
Vue.component(
    "quotes-products-alternative-editor",
    QuotesProductsAlternativeEditor
);
Vue.component("quotes-products-signable-editor", QuotesProductsSignableEditor);
Vue.component("products-editor", ProductsEditor);
Vue.component("products-search", ProductsSearch);
Vue.component("surveys-editor", SurveysEditor);
Vue.component("surveys-input", SurveysInput);
Vue.component("surveys-pending-popup", SurveysPendingPopup);
Vue.component("surveys-data", SurveysData);
Vue.component("products-bundles-editor", ProductsBundlesEditor);
Vue.component("integrations-editor", IntegrationsEditor);
Vue.component("quotes-modals-add-signature", QuotesModalsAddSignature);
Vue.component("quotes-modals-invite", QuotesModalsInvite);
Vue.component("quotes-documents-view", QuotesDocumentsView);
Vue.component(
    "quote-documents-modals-customer-upload",
    QuoteDocumentsModalsCustomerUpload
);
Vue.component("quote-chat-messages-view-latest", QuoteChatMessagesViewLatest);
Vue.component("quote-chat-messages-modal", QuoteChatMessagesModal);
Vue.component("quote-chat-messages-chat", QuoteChatMessagesChat);
Vue.component("quote-chats-index", QuoteChatsIndex);
Vue.component(
    "google-translate-language-select",
    GoogleTranslateLanguageSelect
);
Vue.component("leads-form", LeadsForm);
Vue.component("leads-fieldset-caparol-basic", LeadsFieldsetCaparolBasic);
Vue.component("leads-index", LeadsIndex);
Vue.component("terms-fieldset", TermsFieldset);
Vue.component("terms-show", TermsShow);
Vue.component("image-carousel-items-fieldset", ImageCarouselItemsFieldset);
Vue.component("readmore-v2-fieldset", ReadmoreV2Fieldset);
Vue.component("readmore-v2-view", ReadmoreV2View);
Vue.component("model-authentication-view", ModelAuthenticationView);
Vue.component("call-back", CallBack);
Vue.component("splash-screen", SplashScreen);
Vue.component("view-header", ViewHeader);
Vue.component("usp-block", UspBlock);
Vue.component("read-more", ReadMore);
Vue.component("stats-quoted-amount", StatsQuotedAmount);
Vue.component("stats-closed-amount", StatsClosedAmount);
Vue.component("stats-quote-count", StatsQuoteCount);
Vue.component("stats-time-to-sign-average", StatsTimeToSignAverage);
Vue.component("stats-time-to-sign-distribution", StatsTimeToSignDistribution);
Vue.component("stats-hit-rate", StatsHitRate);
Vue.component("stats-hit-rate-distribution", StatsHitRateDistribution);
Vue.component("stats-dashboard", StatsDashboard);
Vue.component("charts-bar", ChartsBar);
Vue.component("charts-horizontal-bar", ChartsHorizontalBar);
Vue.component("data-analysis-stats", DataAnalysisStats);
Vue.component("data-analysis-quotes", DataAnalysisQuotes);
Vue.component("data-analysis-quotes-selector", DataAnalysisQuotesSelector);
Vue.component("tiptap-editor", TiptapEditor);
Vue.component("calendly-popup", CalendlyPopup);
Vue.component("meeting-requests-popup", MeetingRequestsPopup);
Vue.component("billetto-popup", BilettoPopup);
Vue.component("billetto-inline", BilettoInline);
Vue.component("billecta-accounts-retrieval", BillectaAccountsRetrieval);
Vue.component("powered-by-salesbyte", PoweredBySalesbyte);
Vue.component("image-cropper-input", ImageCropperInput);
Vue.component("organisation-selector", OrganisationSelector);
Vue.component("img-badge", ImgBadge);
Vue.component("phone-input", PhoneInput);
Vue.component("delete-company-user-btn", DeleteCompanyUserBtn);
Vue.component("quote-fieldset", QuoteFieldset);
Vue.component("quote-field", QuoteField);

// Autoload components
const modules = import.meta.glob(
    [
        "./components/autoload/**/*.vue",
        "!./components/autoload/**/_*.vue", // Exclude files starting with underscore
    ],
    { eager: true }
);

Object.entries(modules).forEach(([path, module]) => {
    // Remove './components/autoload/' from the start and '.vue' from the end
    const name = path
        .substring("./components/autoload/".length, path.length - 4)
        .replace(/\//g, "-");
    Vue.component(name, module.default);
});

// Plugins
import ActionLog from "./vue-plugins/action-log.js";
import Document from "./vue-plugins/document.js";
import Page from "./vue-plugins/page.js";
import Underscore from "./vue-plugins/underscore.js";

Vue.use(ActionLog);
Vue.use(Document);
Vue.use(Page);
Vue.use(Underscore);

// Vue/Alpine app initialization
const appRoot = document.querySelector("#app:not(.disable-vue)");
if (appRoot) {
    new Vue({
        el: appRoot,
        i18n,
    });
} else {
    Alpine.start();
}

// Convert require.context for window functions to import.meta.glob
const windowScripts = import.meta.glob("./windowFunctions/*.js", {
    eager: true,
});
Object.entries(windowScripts).forEach(([key, module]) => {
    // Remove leading './' and trailing '.js'
    const functionName = key
        .replace("./windowFunctions/", "")
        .replace(".js", "");

    window[functionName] = module.default;
});

// Import the jQuery-based scripts as ES modules
import actionLogPriorityFilter from "./src/actionLogPriorityFilter.js";
import selectDisableUnless from "./src/selectDisableUnless.js";
import checkboxDisablesFormField from "./src/checkboxDisablesFormField.js";
import assignsStyleFromOption from "./src/assignsStyleFromOption.js";
import requiredIfInput from "./src/requiredIfInput.js";
import requiredIfSelect from "./src/requiredIfSelect.js";
import navigateOnChecked from "./src/navigateOnChecked.js";
import disableGlobalFileDrops from "./src/disableGlobalFileDrops.js";
import docHeight from "./src/docHeight.js";
import swedishPersonalIdNumberInput from "./src/swedishPersonalIdNumberInput.js";
import swedishBankInput from "./src/swedishBankInput.js";
import disableOnSubmit from "./src/disableOnSubmit.js";
import toggleOnChange from "./src/toggleOnChange.js";

// When the document is ready, run the imported functions
window.jQuery(document).ready(() => {
    actionLogPriorityFilter();
    selectDisableUnless();
    checkboxDisablesFormField();
    assignsStyleFromOption();
    requiredIfInput();
    requiredIfSelect();
    navigateOnChecked();
    disableGlobalFileDrops();
    docHeight();
    swedishPersonalIdNumberInput();
    swedishBankInput();
    disableOnSubmit();
    toggleOnChange();
});
