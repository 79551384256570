<template>
    <div class="modal fade viewmodal" tabindex="-1" role="dialog" aria-hidden="true" style="padding-right: 0px !important" ref="modal">
        <div class="modal-dialog modal-full" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <nav class="navbar navbar-light w-100">
                      <slot name="quote-option-selector-button"></slot>
                      <div class="buttons">
                        <a class="btn" data-dismiss="modal" aria-label="Stäng" @click="userSelected = undefined">
                          <i class="fal fa-times"></i>
                        </a>
                      </div>
                      <slot name="quote-option-selector-collapsible"></slot>
                    </nav>
                </div>

                <div class="modal-body" @scroll="handleModalScroll">
                  <div class="mb-5" v-for="(document, idx) in documents">
                    <div :id="`pdf-container-${idx}`" class="d-flex justify-content-between p-2 bg-light">
                      <div class="font-weight-bold">
                        {{document.pdf_name}}
                      </div>
                      <div class="d-flex justify-content-between">
                        <a v-if="printUrl" :href="printUrl" class="btn print">
                          <i class="fal fa-print"></i>
                        </a>

                        <a :href="document.pdf_url" class="btn download" download>
                          <i class="fal fa-download"></i>
                        </a>
                      </div>
                    </div>
                    <pdf-all  :pdflink="document.pdf_url" :is-pdf="document.is_pdf"></pdf-all>
                  </div>
                </div>

                <div class="modal-footer" v-if="!isSigned && signable">
                    <a href="#" style="color:black" @click="openModal(signModalTarget);">
                        <div class="signbuttonmodal">
                          {{signCopy}}
                          <i class="far fa-arrow-right ml-2"></i>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
nav.navbar {
    display: flex !important;
}
.header-company-image {
  max-width: 50%;
  max-height: 80px;
  width: auto;
  height: auto;
}
.modal-header {
    position: relative;

    button[aria-expanded="true"] {
        i.when-collapsed { display: none; }
    }

    button[aria-expanded="false"] {
        i.when-expanded { display: none; }
    }

    .dropdown-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.modal-body {
    overflow-y: auto;
    height: calc(100vh - 196px);

    &::v-deep {
      .page-container {
        margin: 5px -16px;
        padding: 0px;

        canvas {
          border-left: 0;
          border-right: 0;
          border-bottom: 0;
        }

        &:first-child canvas {
          border-top: 0;
        }
      }
    }
}
.modal-footer {
    justify-content: center;
}

.buttons {
  margin-left: auto;

  a {
    font-size: 1.35rem;
  }
  a.print {
    font-size: 1.40rem;
    margin-right: 1.5rem;
  }
  a.download {
    font-size: 1.40rem;
    margin-right: 1.5rem;
  }
}
</style>

<script>

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default {
    props: {
        companyImageUrl: String,
        quoteKey: String,
        quoteOptionId: Number,
        quoteOptionName: String,
        printUrl: { type: String, default: null },
        mainPdfName: String,
        mainPdfUrl: String,
        mainPdfIsPdf: Boolean,
        isSigned: Boolean,
        isPostSale: Boolean,
        extraDocuments: {
            type: Array,
            default: () => []
        },
        initiallySelected: {
            type: Number,
            default: 0
        },
        signCopy: {
            type: String,
            default: 'Gå vidare'
        },
        signModalTarget: {
            type: String,
            default: '#bankid'
        },
        signable: Boolean,
        companyColor: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            uuid: uuidv4(),
            userSelected: undefined,
            logged: []
        }
    },
    computed: {
        documents: function(){
          return [{pdf_name: this.mainPdfName, pdf_url: this.mainPdfUrl, is_pdf: this.mainPdfIsPdf}].concat(this.extraDocuments);
        },
        selected: function() {
            return this.userSelected !== undefined ? this.userSelected : this.initiallySelected;
        }
    },
    mounted() {
        const thisModal = window.jQuery(this.$refs["modal"]);
        thisModal.on('shown.bs.modal', () => this.logDocumentOpen());
    },
  watch: {
      async selected(){
        await this.logDocumentOpen();
      },
      quoteOptionId(){
        this.logged = [];
        //Because initially selected will always be the 0th index and therefore selected never changes
        this.logDocumentOpen();
      }
  },
  methods: {
        async handleModalScroll(){
          const pattern = /^pdf-container-\d+$/;
          Array.from(document.querySelectorAll('div[id^="pdf-container-"]'))
            .filter(div => pattern.test(div.id))
            .forEach((div) => {
            const {top, bottom} = div.getBoundingClientRect();
            const vHeight = (window.innerHeight || document.documentElement.clientHeight);

            if ((top > 0 || bottom > 0) && top < (vHeight/2)){
              this.userSelected = Number(div.id.split("-")[2]);
            }
          })
        },
        async logDocumentOpen() {
            if (this.logged.includes(this.selected)){
              return;
            }

            const name = this.documents[this.selected].pdf_name;

            if (this.isPostSale) {
                await this.$actionLog.logQuoteDocumentOpen(this.quoteKey, name);
            } else if (this.selected === 0) {
                await axios.post(this.route('quote.options.storeOpened', [this.quoteKey, this.quoteOptionId]));
            } else {
                await this.$actionLog.logQuoteOptionExtraDocumentOpen(this.quoteKey, this.quoteOptionName + "/" + name);
            }

          this.logged.push(this.selected)
        },
        openModal(modalId) {
          this.$emit('move-forward-with-quote-option',this.quoteOptionId);
          window.jQuery(this.$refs["modal"]).one('hidden.bs.modal', () => window.jQuery(modalId).modal('show'));
          window.jQuery(this.$refs["modal"]).modal('hide');
        }
    }
}
</script>
