<template>
    <div>
        <b-input-group class="mb-3">
            <b-input-group-prepend>
                <b-input-group-text>Filtrera* <i class="fal fa-search ml-2"></i></b-input-group-text>
            </b-input-group-prepend>

            <b-form-input v-model="filterText" type="text"></b-form-input>
        </b-input-group>

        <b-table :items="items" :fields="fields" :primary-key="primaryKey" :sort-by="sortBy" :sort-desc="sortDesc" :filter="filterText" :filter-included-fields="filterIncludedFields">
            <template #cell(client_company)="data">
                <small v-if="data.value === 'Privatkund'" style="opacity:0.5">
                    <i class="fal fa-user-alt mr-1"></i> {{data.value}}
                </small>
                <small v-else>
                    <i class="fal fa-industry-alt mr-1"></i> {{data.value}}
                </small>
            </template>

            <template #cell(statuses)="data">
                <a v-for="(statusItem, statusIdx) in data.value" :key="statusIdx" :href="statusItem.href" class="ml-1">
                    <b-badge pill :variant="statusItem.type">
                        {{statusItem.title}}
                        <i v-if="statusItem.icon" :class="`fal fa-${statusItem.icon} ml-1`"></i>
                    </b-badge>
                </a>
            </template>

            <template #cell(links)="data" class="text-right">
                <div class="text-right">
                    <a v-for="(linkItem, linkIndex) in data.value" :key="linkIndex" :href="linkItem.href" :title="linkItem.title" class="btn btn-default btn-sm">
                        <i v-if="linkItem.icon" :class="`fal fa-${linkItem.icon}`"></i>
                    </a>
                </div>
            </template>
        </b-table>
        <div v-if="!isLoaded" class="text-center">
            <b-spinner label="Laddar.."></b-spinner>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
    props: {
    },
    data() {
        return {
            primaryKey: "id",
            sortBy: "created_at",
            sortDesc: true,
            filterIncludedFields: ["created_at", "customer_name", "client_company", "statuses"],
            filterText: "",
            fields: [
                {
                    key: "created_at",
                    label: "Skickad datum",
                    sortable: true,
                    formatter: (value, key, item) => value.substring(0, 10),
                    filterByFormatted: true,
                    sortByFormatted: true
                },
                {
                    key: "customer_name",
                    label: "Kund",
                    sortable: true
                },
                {
                    key: "client_company",
                    label: "Typ",
                    sortable: true,
                    formatter: (value, key, item) => (value ? value : "Privatkund"),
                    filterByFormatted: true,
                    sortByFormatted: true
                },
                {
                    key: "statuses",
                    label: "Status",
                    sortable: true,
                    sortByFormatted: (value, key, item) => (value.length > 0 ? value[0].title : null),
                    filterByFormatted: (value, key, item) => (value.length > 0 ? value : null)
                },
                {
                    key: "links",
                    label: "",
                    sortable: false
                }
            ],
            items: [],
            isLoaded: false
        };
    },
    async mounted() {
        let response = await axios.get(`/leads`);
        this.items = response.data.data;
        this.isLoaded = true;
    }
}
</script>
